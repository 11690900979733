import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetAutopilotManageEngageResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import { Close, InfoOutlined } from '@mui/icons-material';
import { getAPI } from 'src/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  examplesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  instructionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    width: 20,
    height: 20,
    minHeight: 0,
    minWidth: 0,
    marginLeft: 'auto',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  formLabel: {
    width: '100%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
  enrichmentModal: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: 600,
  },
}));

type Props = {
  campaignId: string;
  open: boolean;
  emailCol: GetAutopilotManageEngageResponse['emailCols'][number] | null;
  onClose: () => void;
};

const defaultInstruction = `Write three short personalized mini-paragraphs (1-2 sentences each) based on the following structure:

First paragraph: interesting trend specific to the industry or sub-industry of the company ("Weve been keeping a keen eye on [interesting trend with some stat]")

Second paragraph: something specific and impressive that the company does / offers ("We're genuinely impressed by [something the company does well / it's product and its impact]")

Third paragraph: follow up asking for a conversation in their specific industry ("For context, I'm an entrepreneur looking to build a career in [specific industry]. I'm working alongside 20 industry veterans who are personally backing me to partner with a company in the space as a transitioning CEO or as a close operator to the CEO. I would love the chance to swap introductions in the event there's a chance for us to partner down the road")

Keep it relatively concise, casual, and friendly. `;

const defaultExample1 = `We've been keeping a keen eye on the supply chain and transportation industry in the northeast. The global supply chain and logistics management industry is set to grow at a rate of 11% annually, reaching $42M in 2028. Alcohol consumption in the US is also set to grow to by 3% annually, significant for a mature industry.

We're genuinely impressed by Elenteny Imports' rich history, deep client relationships, and modern approach to the domestic alcohol supply chain. What really stands out is your full-service offerings and expertise across the three-tier system.

For context, I'm an entrepreneur looking to build a career in the transport & logistics space. I'm working alongside 20 industry veterans who are personally backing me to partner with a company in the space as a transitioning CEO or as a close operator to the CEO. I would love the chance to swap introductions in the event there's a chance for us to partner down the road.`;

const defaultExample2 = `We've been keeping a keen eye on the passenger and auto transportation industry in the northeast, within which growth is moderate and incumbents are strong.

We're genuinely impressed by A&A Metro Transportation's rich history, significant asset and equipment base, and presence in Massachusetts. What really stands out is your distinctive branding strategy and story and focus on service and amenities.

For context, I'm an entrepreneur looking to build a career in the passenger transportation space. I'm working alongside 20 industry veterans who are personally backing me to partner with a company in the space as a transitioning CEO or as a close operator to the CEO. I would love the chance to swap introductions in the event there's a chance for us to partner down the road.`;

const defaultExample3 = `We've been keeping a keen eye on the passenger and auto transportation industry in the northeast, within which growth is moderate and incumbents are strong.

We're genuinely impressed by Above All Transportation's rich history, significant asset and equipment base, and presence in Massachusetts. What really stands out is your distinctive branding strategy and story and focus on service and amenities.

We'd welcome a conversation as we continue to explore the passenger transportation industry in the northeast and Above All Transportation's prominent role.`;

const defaultExample4 = `We've been keeping an eye on the welding industry, which admirably serves a wide variety of industries. Notably, The welding industry obvserves a 6.39% CAGR showing its long standing and constant growth.

We're genuinely impressed by the strides SDMI is making in transforming the welding industry. Your commitment to innovation, particularly evident in your utilization of robotic welding and employment of AWS-certified welders, is notable. This approach truly differentiates SDMI as a leader in welding technology and innovation.

We'd welcome a conversation to discuss the future of the welding industry.`;

const defaultExample5 = `We've been keeping a keen eye on the supply chain and transportation industry in the northeast. The global supply chain and logistics management industry is set to grow at a rate of 11% annually, reaching $42M in 2028.

We're genuinely impressed by United Distribution Services's rich history and deep client relationships. What really stands out is your wide array of industry expertise and talent and your comprehensive transportation and delivery services.

We'd welcome a conversation as we continue to explore global transportation and supply chains in the northeast and United Distribution Services's prominent role.`;

export default function EmailModal({
  campaignId,
  open,
  emailCol,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      instruction: defaultInstruction,
      waitForDays: 0,
    },
    values: {
      instruction: emailCol?.instruction ?? defaultInstruction,
      waitForDays: emailCol?.waitForDays ?? 0,
    },
  });
  const [examples, setExamples] = useState<string[]>([]);
  const onCloseWithReset = useCallback(() => {
    onClose();
    reset();
    setExamples([]);
  }, [onClose, reset]);
  const onSubmit: SubmitHandler<{
    instruction: string;
    waitForDays: number;
  }> = async (data) => {
    const api = getAPI();
    if (emailCol) {
      await api.autopilot.editEmailCol(campaignId, emailCol.id, {
        instruction: data.instruction,
        examples,
        waitForDays: data.waitForDays,
      });
    } else {
      await api.autopilot.createEmailCol(campaignId, {
        instruction: data.instruction,
        examples,
        waitForDays: data.waitForDays,
      });
    }
    onCloseWithReset();
  };
  useEffect(() => {
    if (emailCol == null) {
      setExamples([
        defaultExample1,
        defaultExample2,
        defaultExample3,
        defaultExample4,
        defaultExample5,
      ]);
      return;
    }
    setExamples(emailCol.examples);
  }, [emailCol]);
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onCloseWithReset();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerRow}>
              <Typography level="h4">Add an Email</Typography>
            </div>
            <Typography level="body-md">
              Craft personalized emails based on the company's description,
              products, services, and end markets served
            </Typography>
          </div>
          <ControlledInput
            required
            label="# days after sequence start"
            name="waitForDays"
            control={control}
            error={errors.waitForDays}
            inputProps={{
              type: 'number',
            }}
          />
          <br />
          <Typography level="body-md">
            <b>Instruction</b>
          </Typography>
          <Typography level="body-sm">
            Provide detailed instructions for how you want AI to structure your
            message
          </Typography>
          <FormControl error={Boolean(errors.instruction)}>
            <Controller
              name="instruction"
              control={control}
              rules={{ required: 'This is required!' }}
              render={({ field }) => (
                <Textarea minRows={3} size="md" {...field} />
              )}
            />
            {errors.instruction && (
              <FormHelperText>
                <InfoOutlined />
                {errors.instruction.message}
              </FormHelperText>
            )}
          </FormControl>
          <br />
          <Typography level="body-md">
            <b>Examples</b>
          </Typography>
          <Typography level="body-sm">
            To get better results, provide some examples of the copy you have in
            mind. The more examples you give, the better AI will understand your
            ask.
          </Typography>
          <div className={classes.examplesList}>
            {examples.map((example, idx) => (
              <FormControl key={idx}>
                <FormLabel className={classes.formLabel}>
                  Example {idx + 1}
                  <IconButton
                    color="danger"
                    variant="outlined"
                    className={classes.closeButton}
                    onClick={() => {
                      setExamples((prev) => {
                        const newVal = [...prev];
                        newVal.splice(idx, 1);
                        return newVal;
                      });
                    }}
                  >
                    <Close className={classes.closeIcon} />
                  </IconButton>
                </FormLabel>
                <Textarea
                  size="md"
                  minRows={3}
                  value={example}
                  onChange={(e) => {
                    setExamples((prev) => {
                      const newVal = [...prev];
                      newVal[idx] = e.target.value;
                      return newVal;
                    });
                  }}
                />
              </FormControl>
            ))}
          </div>
          <Button
            variant="outlined"
            color="success"
            onClick={() => {
              setExamples((prev) => [...prev, '']);
            }}
          >
            Add Example
          </Button>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.submitButton}
          >
            {emailCol == null ? 'Create' : 'Edit'}
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
