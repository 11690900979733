import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';
import {
  GetAutopilotsResponse,
  V1GetListsResponse,
  SearchResponse,
} from 'src/generated/api';
import {
  Button,
  Checkbox,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Select,
  Skeleton,
  Table,
  Theme,
  Typography,
  useTheme,
  Option,
} from '@mui/joy';
import {
  Add,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  RocketLaunch,
} from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import { getAPI } from 'src/api';
import { SnackbarContext } from 'src/contexts/snackbar';
import { logEvent } from 'src/analytics';
import CreateListModal from 'src/modals/CreateListModal';
import SearchResult from './SearchResult';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  table: {
    height: '100%',
  },
  footer: {
    width: '100%',
  },
  footerCell: {
    width: '100%',
  },
  footerContents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  perPage: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    marginLeft: 'auto',
    alignItems: 'flex-end',
  },
  checkboxColumn: {
    width: 36,
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    gap: theme.spacing(1),
  },
  thead: {
    height: 48,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    gap: theme.spacing(1),
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '100%',
  },
  listsMenu: {
    width: 220,
    maxHeight: 400,
    overflowY: 'auto',
  },
  checkboxMenu: {
    width: 220,
    maxHeight: 400,
    overflowY: 'auto',
  },
  checkboxMenuCheckbox: {
    padding: 0,
    minHeight: 0,
  },
  checkboxMenuBtn: {
    padding: 0,
    minHeight: 0,
  },
}));

type Props = {
  hasDescription: boolean;
  productsAndServices: string[];
  customerSegments: string[];
  numCurrentQueryProductServices: number;
  numCurrentQueryCustomerSegments: number;
  numCurrentQueryServiceAreas: number;
  results: SearchResponse | null;
  pageNum: number;
  loadingSearchResults: boolean;
  setPage: (pageNum: number) => void;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  setSearchFormData: React.Dispatch<React.SetStateAction<SearchData>>;
  setSearchResults: React.Dispatch<React.SetStateAction<SearchResponse | null>>;
  lists: V1GetListsResponse['results'] | null;
  autopilots: GetAutopilotsResponse['autopilots'] | null;
  refetchListsAndAutopilots: () => void;
  addAllToList: (listId: string, listName: string) => void;
  showBusinessDrawer: (businessId: string) => void;
};

type ShowCreateModalMode = 'ALL_RESULTS' | 'SELECTED' | null;

export type SearchData = {
  description: string;
  productsAndServices: string[];
  customerSegments: string[];
  productServiceWeight: number;
  customerSegmentWeight: number;
  geoFilter?: {
    hqStates: string[];
    serviceAreasStates: string[];
    mustHaveServiceAreas: boolean;
  };
  employeeFilter?: {
    minEmployees: number | null;
    maxEmployees: number | null;
  };
  revenueFilter?: {
    minRevenue: number | null;
    maxRevenue: number | null;
    mustHave: boolean;
  };
  fundingFilter?: {
    funded?: {
      minFunding: number | null;
      maxFunding: number | null;
    };
    mustHave: boolean;
  };
  sellsToFilter?: {
    mustSellToBusinesses: boolean;
    mustSellToConsumers: boolean;
    mustSellToGovernment: boolean;
  };
  excludeKeywords?: {
    productServices: string[];
    customerSegments: string[];
  };
  excludeLists?: string[];
};

function SearchResults({
  hasDescription,
  productsAndServices,
  customerSegments,
  numCurrentQueryProductServices,
  numCurrentQueryCustomerSegments,
  numCurrentQueryServiceAreas,
  results,
  pageNum,
  setPage,
  pageSize,
  setPageSize,
  loadingSearchResults,
  setSearchResults,
  setSearchFormData,
  lists,
  autopilots,
  refetchListsAndAutopilots,
  addAllToList,
  showBusinessDrawer,
}: Props) {
  const { showSnackbar } = useContext(SnackbarContext);
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showAddAllMenu, setShowAddAllMenu] = useState(false);
  const [showCreateListModal, setShowCreateListModal] =
    useState<ShowCreateModalMode>(null);
  const intercom = useIntercom();
  const api = getAPI();
  const addToList = useCallback(
    async (listId: string, listName: string) => {
      // Optimisitic update
      setSearchResults((prev) => ({
        ...prev!,
        results: prev!.results.map((r) => ({
          ...r,
          lists: selectedIds.includes(r.businessId)
            ? [...new Set([...r.lists, listId])]
            : r.lists,
        })),
      }));
      setSelectedIds([]);
      await api.lists.addToList(listId, {
        businessIds: selectedIds,
      });
      showSnackbar({
        color: 'success',
        message: `Added ${selectedIds.length} businesses to ${listName}`,
      });
    },
    [selectedIds, api, showSnackbar, setSearchResults],
  );
  const addToAutopilot = useCallback(
    async (autopilotId: string, listName: string) => {
      // Optimisitic update
      setSearchResults((prev) => ({
        ...prev!,
        results: prev!.results.map((r) => ({
          ...r,
          autopilots: selectedIds.includes(r.businessId)
            ? [...new Set([...r.autopilots, autopilotId])]
            : r.autopilots,
        })),
      }));
      setSelectedIds([]);
      await api.autopilot.addToAutopilot(autopilotId, {
        businessIds: selectedIds,
      });
      showSnackbar({
        color: 'success',
        message: `Added ${selectedIds.length} businesses to ${listName}`,
      });
    },
    [selectedIds, api, showSnackbar, setSearchResults],
  );
  const allSelected =
    (results?.results.every((r) => selectedIds.includes(r.businessId)) &&
      !loadingSearchResults) ??
    false;
  return (
    <>
      {results?.results.length === 0 && !loadingSearchResults ? (
        <div className={classes.emptyState}>
          <Typography level="h4">No results found</Typography>
          <Typography level="body-lg">
            If you&apos;re having issues searching for businesses, chat with us
            using the button below
          </Typography>
          <Button onClick={() => intercom.show()} size="lg">
            Contact Support
          </Button>
        </div>
      ) : (
        <>
          <Table className={classes.table} stickyFooter stickyHeader>
            <thead className={classes.thead}>
              <tr>
                <th className={classes.checkboxColumn}>
                  {!loadingSearchResults &&
                    results &&
                    results.results.length > 0 && (
                      <Dropdown
                        onOpenChange={(_, o) => {
                          if (!o) {
                            setShowAddAllMenu(false);
                          }
                        }}
                      >
                        <MenuButton
                          variant="plain"
                          className={classes.checkboxMenuBtn}
                        >
                          <Checkbox
                            size="lg"
                            className={classes.checkboxMenuCheckbox}
                            checked={allSelected}
                          />
                        </MenuButton>
                        <Menu
                          placement="bottom-start"
                          className={classes.checkboxMenu}
                        >
                          {showAddAllMenu ? (
                            <>
                              {lists?.map((l) => (
                                <MenuItem
                                  key={l.id}
                                  onClick={() => addAllToList(l.id, l.name)}
                                >
                                  {l.name}
                                </MenuItem>
                              ))}
                              <MenuItem
                                onClick={() =>
                                  setShowCreateListModal('ALL_RESULTS')
                                }
                              >
                                <Add />
                                Create List
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem
                                onClick={() => {
                                  if (allSelected) {
                                    setSelectedIds((prev) =>
                                      prev.filter(
                                        (id) =>
                                          !results!.results.some(
                                            (r) => r.businessId === id,
                                          ),
                                      ),
                                    );
                                  } else {
                                    setSelectedIds((prev) => [
                                      ...new Set([
                                        ...prev,
                                        ...results!.results.map(
                                          (r) => r.businessId,
                                        ),
                                      ]),
                                    ]);
                                  }
                                }}
                              >
                                {allSelected
                                  ? `Unselect (${results?.results.length})`
                                  : `Select (${results?.results.length})`}
                              </MenuItem>
                              <MenuItem>
                                <div
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setShowAddAllMenu(true);
                                  }}
                                >
                                  Add All to List ({results?.numResults})
                                </div>
                              </MenuItem>
                            </>
                          )}
                        </Menu>
                      </Dropdown>
                    )}
                </th>
                <th colSpan={1}>
                  <div className={classes.toolbar}>
                    {selectedIds.length > 0 && (
                      <Dropdown>
                        <MenuButton color="success" variant="solid" size="sm">
                          Add to List
                        </MenuButton>
                        <Menu
                          placement="bottom-start"
                          className={classes.listsMenu}
                        >
                          {autopilots?.map((a) => (
                            <MenuItem
                              variant="soft"
                              color="primary"
                              key={a.id}
                              onClick={() => addToAutopilot(a.id, a.name)}
                            >
                              <RocketLaunch />
                              {a.name}
                            </MenuItem>
                          ))}
                          {lists?.map((l) => (
                            <MenuItem
                              key={l.id}
                              onClick={() => addToList(l.id, l.name)}
                            >
                              {l.name}
                            </MenuItem>
                          ))}
                          <MenuItem
                            onClick={() => setShowCreateListModal('SELECTED')}
                          >
                            <Add />
                            Create List
                          </MenuItem>
                        </Menu>
                      </Dropdown>
                    )}
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {loadingSearchResults || !results
                ? [...Array(results?.pageSize ?? 20).keys()].map((n) => (
                    <tr key={n}>
                      <td />
                      <td>
                        <Skeleton variant="text" sx={{ width: 150 }} />
                        <Skeleton variant="text" sx={{ width: 100 }} />
                        <Skeleton variant="text" sx={{ width: '90%' }} />
                        <Skeleton variant="text" sx={{ width: '80%' }} />
                      </td>
                    </tr>
                  ))
                : results.results.map((result) => (
                    <SearchResult
                      selectedIds={selectedIds}
                      setSelectedIds={setSelectedIds}
                      key={result.domain}
                      hasDescription={hasDescription}
                      numProductServices={numCurrentQueryProductServices}
                      numCustomerSegments={numCurrentQueryCustomerSegments}
                      numServiceAreas={numCurrentQueryServiceAreas}
                      result={result}
                      setSelectedBusinessId={showBusinessDrawer}
                      lists={lists ?? null}
                      autopilots={autopilots ?? null}
                      productsAndServices={productsAndServices}
                      addProductService={(ps) => {
                        setSearchFormData((prev) => ({
                          ...prev!,
                          productsAndServices: [
                            ...prev!.productsAndServices,
                            ps,
                          ],
                        }));
                      }}
                      customerSegments={customerSegments}
                      addCustomerSegment={(cs) => {
                        setSearchFormData((prev) => ({
                          ...prev!,
                          customerSegments: [...prev!.customerSegments, cs],
                        }));
                      }}
                    />
                  ))}
            </tbody>
            <tfoot>
              <tr className={classes.footer}>
                <td className={classes.footerCell} colSpan={2}>
                  <div className={classes.footerContents}>
                    {results && (
                      <div className={classes.pagination}>
                        <IconButton
                          variant="outlined"
                          onClick={() => {
                            logEvent({
                              name: 'search_prev_page',
                              type: 'click',
                              extra: {
                                pageNum: pageNum.toString(),
                                numResults: results.numResults.toString(),
                              },
                            });
                            setPage(pageNum - 1);
                          }}
                          disabled={pageNum <= 0 || loadingSearchResults}
                        >
                          <KeyboardArrowLeft />
                        </IconButton>
                        <Typography level="body-lg">
                          {pageNum * results.pageSize + 1} -{' '}
                          {pageNum * results.pageSize + results.results.length}{' '}
                          of {results.numResults}
                        </Typography>
                        <IconButton
                          variant="outlined"
                          onClick={() => {
                            logEvent({
                              name: 'search_next_page',
                              type: 'click',
                              extra: {
                                pageNum: pageNum.toString(),
                                numResults: results.numResults.toString(),
                              },
                            });
                            setPage(pageNum + 1);
                          }}
                          disabled={
                            results.pageSize * pageNum +
                              results.results.length >=
                              results.numResults || loadingSearchResults
                          }
                        >
                          <KeyboardArrowRight />
                        </IconButton>
                      </div>
                    )}
                    <div className={classes.perPage}>
                      <Select
                        value={pageSize}
                        onChange={(e, val) => setPageSize(val!)}
                      >
                        <Option value={5}>5</Option>
                        <Option value={20}>20</Option>
                        <Option value={100}>100</Option>
                        <Option value={250}>250</Option>
                        <Option value={500}>500</Option>
                      </Select>
                      <Typography level="body-lg">per page</Typography>
                    </div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </Table>
        </>
      )}
      <CreateListModal
        open={showCreateListModal !== null}
        onClose={(id: string, name: string) => {
          if (showCreateListModal === 'ALL_RESULTS') {
            addAllToList(id, name);
          } else {
            addToList(id, name);
          }
          refetchListsAndAutopilots();
          setShowCreateListModal(null);
        }}
      />
    </>
  );
}
export default React.memo(SearchResults);
