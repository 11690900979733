import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tss } from 'tss-react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  ChipDelete,
  CircularProgress,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Table,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { ClickAwayListener, Popper } from '@mui/material';
import { getAPI } from 'src/api';
import { GetSimilarCompanies } from 'src/generated/api';
import LoadingContainer from 'src/components/LoadingContainer';
import { compactNumber } from 'src/utils';
import { SnackbarContext } from 'src/contexts/snackbar';
import normalizeUrl from 'normalize-url';
import {
  Language,
  LocationOn,
  Remove,
  Map,
  Block,
  Paid,
  Person,
  Tune,
} from '@mui/icons-material';
import { NumericFormatAdapter } from 'src/components/FormattedNumberInput';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 850,
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    overflowY: 'scroll',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  statsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginLeft: 'auto',
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(1),
  },
  chipsWithLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    gap: 4,
    width: '50%',
  },
  chipsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  companyTitle: {
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  employeeChipContents: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    padding: theme.spacing(1),
  },
  chip: {
    '&:hover': {
      filter: 'brightness(120%)',
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listingRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  description: {
    fontSize: 12,
    marginTop: 4,
  },
  saChip: {
    fontSize: 10,
  },
  table: {
    height: '100%',
  },
  thead: {
    height: 48,
  },
  checkboxMenuCheckbox: {
    padding: 0,
    minHeight: 0,
  },
  checkboxColumn: {
    width: 36,
  },
  footerButtons: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  website: {
    cursor: 'pointer',
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  subtitleIcon: {
    width: 16,
    height: 16,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  checkboxColBtn: {
    width: 24,
    height: 24,
    maxWidth: 24,
    maxHeight: 24,
    minHeight: 0,
    minWidth: 0,
    borderRadius: 4,
  },
  checkboxColBtnIcon: {
    width: 18,
    height: 18,
  },
  popper: {
    boxShadow: theme.shadow.lg,
    backgroundColor: theme.palette.background.popup,
    zIndex: 9999,
    borderRadius: theme.spacing(1),
  },
  popperSheet: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowY: 'scroll',
    padding: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginRight: theme.spacing(4),
  },
  filterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  filterContentsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'flex-end',
  },
  geoFilterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  geoFilterRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  field: {
    flex: 1,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginRight: 'auto',
  },
  autocompleteRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  autocomplete: {
    width: '100%',
  },
  revenueInput: {
    maxWidth: 140,
  },
  employeeInput: {
    maxWidth: 80,
  },
  popperTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

type Props = {
  listId: string;
  onClose: () => void;
};

type Filters = {
  geoFilterHqStates: string[];
  geoFilterServiceAreaStates: string[];
  employeeFilterMin: number | null;
  employeeFilterMax: number | null;
  revenueFilterMin: number | null;
  revenueFilterMax: number | null;
  excludeProductService: string[];
  excludeCustomerSegment: string[];
};

const maxProductServicesShown = 5;
const maxCustomerSegmentsShown = 5;
export default function SimilarBusinessesModal({ listId, onClose }: Props) {
  const theme = useTheme();
  const { showSnackbar } = useContext(SnackbarContext);
  const { classes, cx } = useStyles({ theme });
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [businesses, setBusinesses] = useState<
    GetSimilarCompanies['businesses'] | null
  >(null);
  const [showMoreProductServices, setShowMoreProductServices] = useState(false);
  const [showMoreCustomerSegments, setShowMoreCustomerSegments] =
    useState(false);
  const [popperEl, setPopperEl] = useState<HTMLAnchorElement | null>(null);
  const [filters, setFilters] = useState<Filters>({
    geoFilterHqStates: [],
    geoFilterServiceAreaStates: [],
    employeeFilterMin: null,
    employeeFilterMax: null,
    revenueFilterMin: null,
    revenueFilterMax: null,
    excludeProductService: [],
    excludeCustomerSegment: [],
  });
  const api = getAPI();
  const fetch = useCallback(
    async (f: Filters) => {
      const res = await api.lists.getSimilarCompanies(listId, f);
      setBusinesses(res.data.businesses);
    },
    [api, listId],
  );
  const addToList = useCallback(async () => {
    showSnackbar({
      color: 'success',
      message: `Added ${selectedIds.length} businesses to list`,
    });
    await api.lists.addToList(listId, {
      businessIds: selectedIds,
    });
    onClose();
  }, [onClose, showSnackbar, api, listId, selectedIds]);
  const removeSuggestions = useCallback(async () => {
    await api.lists.removeSimilarBuinesses(listId, {
      businessIds: selectedIds,
    });
    setSelectedIds([]);
    await fetch(filters);
  }, [fetch, api, listId, selectedIds, filters]);
  useEffect(() => {
    fetch({
      geoFilterHqStates: [],
      geoFilterServiceAreaStates: [],
      employeeFilterMin: null,
      employeeFilterMax: null,
      revenueFilterMin: null,
      revenueFilterMax: null,
      excludeProductService: [],
      excludeCustomerSegment: [],
    });
  }, [fetch]);
  const allSelected =
    businesses?.every((r) => selectedIds.includes(r.businessId)) ?? false;

  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        <div>
          <div className={classes.headerRow}>
            <Typography level="h4">Similar Businesses</Typography>
            <Button
              variant="outlined"
              onClick={(e) => setPopperEl(popperEl ? null : e.currentTarget)}
            >
              Filters
            </Button>
            <Popper
              placement="bottom-end"
              open={Boolean(popperEl)}
              anchorEl={popperEl}
              className={classes.popper}
            >
              <ClickAwayListener onClickAway={() => setPopperEl(null)}>
                <Sheet className={classes.popperSheet}>
                  <Typography level="h4" className={classes.popperTitle}>
                    <Tune />
                    Filters
                  </Typography>
                  <div className={classes.filterContents}>
                    <div className={classes.filterContentsRow}>
                      <Typography
                        level="body-md"
                        className={classes.filterTitle}
                      >
                        <Map />
                        <b>Geography</b>
                      </Typography>
                    </div>
                    <FormControl className={classes.field}>
                      <div className={classes.simpleRow}>
                        <FormLabel>HQ (State)</FormLabel>
                      </div>
                      <Autocomplete
                        freeSolo
                        multiple
                        placeholder="NY, CA, TX"
                        value={filters.geoFilterHqStates}
                        autoSelect
                        onChange={(e, val) => {
                          setFilters((prev) => ({
                            ...prev,
                            geoFilterHqStates: val,
                          }));
                        }}
                        onInputChange={(e, newVal) => {
                          if (newVal.endsWith(',')) {
                            (e.target as HTMLElement).blur();
                            (e.target as HTMLElement).focus();
                          }
                        }}
                        renderTags={(tags, getTagProps) =>
                          tags.map((item, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              color="primary"
                              onClick={undefined}
                              key={item}
                              endDecorator={
                                <ChipDelete
                                  onDelete={getTagProps({ index }).onClick}
                                />
                              }
                            >
                              {item}
                            </Chip>
                          ))
                        }
                        slotProps={{
                          input: {
                            maxLength: 2,
                          },
                        }}
                        options={[]}
                      />
                    </FormControl>
                    <FormControl className={classes.field}>
                      <FormLabel>Service Area (State)</FormLabel>
                      <Autocomplete
                        freeSolo
                        multiple
                        placeholder="NY, CA, TX"
                        value={filters.geoFilterServiceAreaStates}
                        onChange={(e, val) => {
                          setFilters((prev) => ({
                            ...prev,
                            geoFilterServiceAreaStates: val.map((v) =>
                              v.toUpperCase(),
                            ),
                          }));
                        }}
                        autoSelect
                        onInputChange={(e, newVal) => {
                          if (newVal.endsWith(',')) {
                            (e.target as HTMLElement).blur();
                            (e.target as HTMLElement).focus();
                          }
                        }}
                        renderTags={(tags, getTagProps) =>
                          tags.map((item, index) => (
                            <Chip
                              {...getTagProps({ index })}
                              color="primary"
                              onClick={undefined}
                              endDecorator={
                                <ChipDelete
                                  onDelete={getTagProps({ index }).onClick}
                                />
                              }
                            >
                              {item}
                            </Chip>
                          ))
                        }
                        options={[]}
                        slotProps={{
                          input: {
                            maxLength: 2,
                          },
                        }}
                      />
                    </FormControl>
                  </div>
                  <div className={classes.filterContents}>
                    <Typography level="body-md" className={classes.filterTitle}>
                      <Block />
                      <b>Exclude</b>
                    </Typography>
                    <FormControl className={classes.field}>
                      <FormLabel>Products & Services</FormLabel>
                      <div className={classes.autocompleteRow}>
                        <Autocomplete
                          freeSolo
                          className={classes.autocomplete}
                          placeholder="rigging, hvac, hydro blasting"
                          multiple
                          value={filters.excludeProductService}
                          onChange={(e, val) => {
                            setFilters((prev) => ({
                              ...prev,
                              excludeProductService: val,
                            }));
                          }}
                          autoSelect
                          onInputChange={(e, newVal) => {
                            const inputEl = e.target as HTMLInputElement;
                            if (newVal.endsWith(',')) {
                              inputEl.blur();
                              inputEl.focus();
                            }
                          }}
                          options={[]}
                          renderTags={(tags, getTagProps) =>
                            tags.map((item, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                color="warning"
                                onClick={undefined}
                                endDecorator={
                                  <ChipDelete
                                    onDelete={getTagProps({ index }).onClick}
                                  />
                                }
                              >
                                {item}
                              </Chip>
                            ))
                          }
                        />
                      </div>
                    </FormControl>
                    <FormControl className={classes.field}>
                      <FormLabel>End Customer</FormLabel>
                      <div className={classes.autocompleteRow}>
                        <Autocomplete
                          freeSolo
                          multiple
                          autoSelect
                          className={classes.autocomplete}
                          placeholder="industrial companies, construction, municipal"
                          value={filters.excludeCustomerSegment}
                          onChange={(e, val) => {
                            setFilters((prev) => ({
                              ...prev,
                              excludeCustomerSegment: val,
                            }));
                          }}
                          onInputChange={(e, newVal) => {
                            if (newVal.endsWith(',')) {
                              (e.target as HTMLElement).blur();
                              (e.target as HTMLElement).focus();
                            }
                          }}
                          renderTags={(tags, getTagProps) =>
                            tags.map((item, index) => (
                              <Chip
                                {...getTagProps({ index })}
                                color="success"
                                onClick={undefined}
                                endDecorator={
                                  <ChipDelete
                                    onDelete={getTagProps({ index }).onClick}
                                  />
                                }
                              >
                                {item}
                              </Chip>
                            ))
                          }
                          options={[]}
                        />
                      </div>
                    </FormControl>
                  </div>
                  <div className={classes.filterContents}>
                    <Typography level="body-md" className={classes.filterTitle}>
                      <Paid />
                      <b>Revenue</b>
                    </Typography>
                    <div className={classes.filterContentsRow}>
                      <FormControl>
                        <FormLabel>Min</FormLabel>
                        <Input
                          value={filters.revenueFilterMin?.toString()}
                          startDecorator="$"
                          className={classes.revenueInput}
                          slotProps={{
                            input: {
                              inputMode: 'numeric',
                              component: NumericFormatAdapter,
                            },
                          }}
                          onChange={(e) =>
                            setFilters((prev) => ({
                              ...prev,
                              revenueFilterMin: parseInt(e.target.value, 10),
                            }))
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max</FormLabel>
                        <Input
                          value={filters.revenueFilterMax?.toString()}
                          startDecorator="$"
                          className={classes.revenueInput}
                          slotProps={{
                            input: {
                              inputMode: 'numeric',
                              component: NumericFormatAdapter,
                            },
                          }}
                          onChange={(e) =>
                            setFilters((prev) => ({
                              ...prev,
                              revenueFilterMax: parseInt(e.target.value, 10),
                            }))
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                  <div className={classes.filterContents}>
                    <Typography level="body-md" className={classes.filterTitle}>
                      <Person />
                      <b>Employees</b>
                    </Typography>
                    <div className={classes.filterContentsRow}>
                      <FormControl>
                        <FormLabel>Min</FormLabel>
                        <Input
                          value={filters.employeeFilterMin?.toString()}
                          className={classes.employeeInput}
                          slotProps={{
                            input: {
                              inputMode: 'numeric',
                            },
                          }}
                          onChange={(e) =>
                            setFilters((prev) => ({
                              ...prev,
                              employeeFilterMin: parseInt(e.target.value, 10),
                            }))
                          }
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Max</FormLabel>
                        <Input
                          value={filters.employeeFilterMax?.toString()}
                          className={classes.employeeInput}
                          slotProps={{
                            input: {
                              inputMode: 'numeric',
                            },
                          }}
                          onChange={(e) =>
                            setFilters((prev) => ({
                              ...prev,
                              employeeFilterMax: parseInt(e.target.value, 10),
                            }))
                          }
                        />
                      </FormControl>
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      setPopperEl(null);
                      setBusinesses(null);
                      fetch(filters);
                    }}
                  >
                    Apply
                  </Button>
                </Sheet>
              </ClickAwayListener>
            </Popper>
          </div>
          <Typography>
            Add other businesses that are similar to the ones on this list.
          </Typography>
        </div>
        <div className={classes.tableContainer}>
          <LoadingContainer isLoading={businesses == null}>
            <Table className={classes.table} stickyHeader>
              <thead className={classes.thead}>
                <tr>
                  <th className={classes.checkboxColumn}>
                    <Checkbox
                      size="lg"
                      className={classes.checkboxMenuCheckbox}
                      checked={allSelected}
                      onClick={() => {
                        if (allSelected) {
                          setSelectedIds([]);
                        } else {
                          setSelectedIds((prev) => [
                            ...new Set([
                              ...prev,
                              ...businesses!.map((r) => r.businessId),
                            ]),
                          ]);
                        }
                      }}
                    />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {businesses?.map((result) => (
                  <tr key={result.businessId}>
                    <td>
                      <div className={classes.col}>
                        <Checkbox
                          size="lg"
                          checked={selectedIds.includes(result.businessId)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedIds((prev) => [
                                ...new Set([...prev, result.businessId]),
                              ]);
                            } else {
                              setSelectedIds((prev) =>
                                prev.filter((id) => id !== result.businessId),
                              );
                            }
                          }}
                          className={classes.checkboxColBtn}
                          slotProps={{
                            checkbox: {
                              onClick: (e) => {
                                e.stopPropagation();
                              },
                            },
                          }}
                        />
                        <IconButton
                          size="sm"
                          color="danger"
                          variant="outlined"
                          className={classes.checkboxColBtn}
                          onClick={async () => {
                            await api.lists.removeSimilarBuinesses(listId, {
                              businessIds: [result.businessId],
                            });
                            setBusinesses((prev) =>
                              prev!.filter(
                                (b) => b.businessId !== result.businessId,
                              ),
                            );
                          }}
                        >
                          <Remove className={classes.checkboxColBtnIcon} />
                        </IconButton>
                      </div>
                    </td>
                    <td>
                      <div className={classes.contents}>
                        <div className={classes.row}>
                          <div className={classes.companyInfo}>
                            <div className={classes.companyTitle}>
                              <Typography level="body-md">
                                {result.name}
                              </Typography>
                            </div>
                            <div className={classes.row}>
                              <Typography
                                level="body-xs"
                                className={cx(
                                  classes.subtitleRow,
                                  classes.website,
                                )}
                                onClick={(e) => {
                                  window.open(normalizeUrl(result.domain));
                                  e.stopPropagation();
                                  e.preventDefault();
                                }}
                              >
                                <Language className={classes.subtitleIcon} />
                                {result.domain}
                              </Typography>
                              <Typography
                                level="body-xs"
                                className={classes.subtitleRow}
                              >
                                <LocationOn className={classes.subtitleIcon} />
                                {result.city}, {result.state}
                              </Typography>
                              {result.serviceAreaStates.length > 0 && (
                                <div className={classes.chipsSection}>
                                  {result.serviceAreaStates
                                    .slice(0, 8)
                                    .map((s) => (
                                      <Chip
                                        color="primary"
                                        size="sm"
                                        key={`${result.businessId}-${s}`}
                                        className={classes.saChip}
                                      >
                                        {s}
                                      </Chip>
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className={classes.statsRow}>
                            {result.revenue ? (
                              <div className={classes.score}>
                                <Typography level="body-md">
                                  <b>${compactNumber(result.revenue)}</b>
                                </Typography>
                                <Typography level="body-xs">Revenue</Typography>
                              </div>
                            ) : null}
                            {result.totalFunding ? (
                              <div className={classes.score}>
                                <Typography level="body-md">
                                  <b>${compactNumber(result.totalFunding)}</b>
                                </Typography>
                                <Typography level="body-xs">Funding</Typography>
                              </div>
                            ) : null}
                            {result.numEmployees ? (
                              <div className={classes.score}>
                                <Typography level="body-md">
                                  <b>{result.numEmployees}</b>
                                </Typography>
                                <Typography level="body-xs">
                                  Employees
                                </Typography>
                              </div>
                            ) : null}
                            <div className={classes.score}>
                              <CircularProgress
                                determinate
                                color="warning"
                                size="sm"
                                value={
                                  result.avgProductAndServiceSimilarity * 100
                                }
                              >
                                <Typography level="body-xs">
                                  {Math.round(
                                    result.avgProductAndServiceSimilarity * 100,
                                  )}
                                </Typography>
                              </CircularProgress>
                              <Typography level="body-xs">Products</Typography>
                            </div>
                            <div className={classes.score}>
                              <CircularProgress
                                determinate
                                size="sm"
                                color="success"
                                value={
                                  result.avgCustomerSegmentSimilarity * 100
                                }
                              >
                                <Typography level="body-xs">
                                  {Math.round(
                                    result.avgCustomerSegmentSimilarity * 100,
                                  )}
                                </Typography>
                              </CircularProgress>
                              <Typography level="body-xs">Customers</Typography>
                            </div>
                          </div>
                        </div>
                        <Typography
                          level="body-sm"
                          className={classes.description}
                        >
                          {result.description}
                        </Typography>
                        <div className={classes.chips}>
                          {result.productsAndServices.length > 0 && (
                            <div className={classes.chipsWithLabel}>
                              <Typography level="body-xs">
                                Products and Services
                              </Typography>
                              <div className={classes.chipsSection}>
                                {result.productsAndServices
                                  .slice(
                                    0,
                                    showMoreProductServices
                                      ? 10
                                      : maxProductServicesShown,
                                  )
                                  .map((ps) => (
                                    <Tooltip
                                      arrow
                                      key={`${result.businessId}-${ps.keyPhrase}`}
                                      title={
                                        <Box className={classes.tooltip}>
                                          <Typography
                                            level="body-md"
                                            textColor={
                                              theme.palette.common.white
                                            }
                                          >
                                            {ps.description}
                                          </Typography>
                                        </Box>
                                      }
                                    >
                                      <Chip
                                        color="warning"
                                        size="sm"
                                        slotProps={{
                                          label: {
                                            className: classes.chip,
                                          },
                                        }}
                                      >
                                        {ps.keyPhrase.toLowerCase()}
                                      </Chip>
                                    </Tooltip>
                                  ))}
                                {!showMoreProductServices &&
                                  result.productsAndServices.length >
                                    maxProductServicesShown && (
                                    <Chip
                                      variant="outlined"
                                      size="sm"
                                      className={classes.chip}
                                      onClick={(e) => {
                                        setShowMoreProductServices(true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      +
                                      {result.productsAndServices.length -
                                        maxProductServicesShown}
                                    </Chip>
                                  )}
                              </div>
                            </div>
                          )}
                          {result.customerSegments.length > 0 && (
                            <div className={classes.chipsWithLabel}>
                              <Typography level="body-xs">
                                End Customers
                              </Typography>
                              <div className={classes.chipsSection}>
                                {result.customerSegments
                                  .slice(
                                    0,
                                    showMoreCustomerSegments
                                      ? 10
                                      : maxCustomerSegmentsShown,
                                  )
                                  .map((cs) => (
                                    <Tooltip
                                      arrow
                                      title={
                                        <Box className={classes.tooltip}>
                                          <Typography
                                            level="body-md"
                                            textColor={
                                              theme.palette.common.white
                                            }
                                          >
                                            {cs.description}
                                          </Typography>
                                        </Box>
                                      }
                                      key={`${result.businessId}-${cs.keyPhrase}`}
                                    >
                                      <Chip
                                        size="sm"
                                        color="success"
                                        slotProps={{
                                          label: {
                                            className: classes.chip,
                                          },
                                        }}
                                      >
                                        {cs.keyPhrase.toLowerCase()}
                                      </Chip>
                                    </Tooltip>
                                  ))}
                                {!showMoreCustomerSegments &&
                                  result.customerSegments.length >
                                    maxCustomerSegmentsShown && (
                                    <Chip
                                      variant="outlined"
                                      size="sm"
                                      className={classes.chip}
                                      onClick={(e) => {
                                        setShowMoreCustomerSegments(true);
                                        e.stopPropagation();
                                      }}
                                    >
                                      +
                                      {result.customerSegments.length -
                                        maxCustomerSegmentsShown}
                                    </Chip>
                                  )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </LoadingContainer>
        </div>
        {selectedIds.length > 0 && (
          <div className={classes.footerButtons}>
            <Button
              color="danger"
              variant="outlined"
              fullWidth
              onClick={removeSuggestions}
            >
              Remove Suggestions
            </Button>
            <Button color="success" fullWidth onClick={addToList}>
              Add {selectedIds.length} Businesses
            </Button>
          </div>
        )}
      </ModalDialog>
    </Modal>
  );
}
