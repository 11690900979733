import React, { useEffect, useState } from 'react';
import { tss } from 'tss-react';

import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import {
  EnrichColType,
  EnrichScrapeDataType,
  EnrichmentClassificationExtra,
  V1GetListResponse,
  ScrapeDataSource,
  Type24EnumsEnrichColType,
  Type24EnumsEnrichScrapeDataType,
  Type24EnumsScrapeDataSource,
} from 'src/generated/api';
import {
  ChevronLeft,
  Delete,
  Google,
  InfoOutlined,
  Language,
  LinkedIn,
} from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { logEvent } from 'src/analytics';
import DataSourceButton from 'src/components/DataSourceButton';
import { assetLink } from 'src/utils';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  enrichmentHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  enrichmentButton: {
    width: 24,
    height: 24,
    minHeight: 0,
    minWidth: 0,
  },
  enrichmentIcon: {
    width: 16,
    height: 16,
  },
  categoryOptions: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  categoryCard: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  categoryRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  deleteCategoryButton: {
    width: 32,
    height: 32,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
  taskTypeContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  taskCard: {
    gap: 0,
    padding: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.outlinedHoverBg,
    },
    flexBasis: '48%',
  },
  dataSources: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  icon: {
    width: 20,
    height: 20,
  },
  questionType: {
    marginTop: theme.spacing(2),
  },
  sources: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}));

export type ScrapeWebsiteDefaultVals = {
  name: string;
  prompt: string;
  dataType: EnrichScrapeDataType;
  dataTypeExtra: EnrichmentClassificationExtra;
};

type Props = {
  enrichment: V1GetListResponse['enrichmentColumns'][number] | null;
  defaultVals?: ScrapeWebsiteDefaultVals;
  listId: string;
  onClose: (modified?: { id: string; type: EnrichColType }) => void;
  onBack: () => void;
};

export default function ScrapeWebsite({
  enrichment,
  defaultVals,
  listId,
  onClose,
  onBack,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const [dataType, setDataType] = useState<EnrichScrapeDataType | null>(null);
  const [dataSources, setDataSources] = useState<Set<ScrapeDataSource>>(
    new Set([Type24EnumsScrapeDataSource.WEBSITE]),
  );
  const [datatTypeExtra, setDataTypeExtra] =
    useState<EnrichmentClassificationExtra>({
      options: [
        {
          name: 'B2B',
          description:
            'Assign this category if this company primarily sells to other companies',
        },
        {
          name: 'B2C',
          description:
            'Assign this category if this company primarily sells to consumers',
        },
      ],
    });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: 'AI Question',
      prompt: '',
      extractInfoTopics: '',
      useReasoning: false,
    },
    values: {
      name: enrichment?.name ?? defaultVals?.name ?? 'AI Question',
      prompt: enrichment?.scrape?.prompt ?? defaultVals?.prompt ?? '',
      extractInfoTopics: enrichment?.scrape?.extractInfoTopics ?? '',
      useReasoning: enrichment?.scrape?.useReasoning ?? false,
    },
  });
  const onSubmit: SubmitHandler<{
    name: string;
    prompt: string;
    extractInfoTopics: string;
    useReasoning: boolean;
  }> = async (data) => {
    // TODO: More Validation
    let modifiedId: string;
    if (enrichment) {
      await api.enrichments.editScrape(listId, enrichment.id, {
        name: data.name,
        prompt: data.prompt,
        dataType: dataType!,
        dataTypeExtra: dataType === 'CLASSIFICATION' ? datatTypeExtra : null,
        dataSources: [...dataSources],
        extractInfoTopics: data.extractInfoTopics,
        useReasoning: data.useReasoning,
      });
      modifiedId = enrichment.id;
      logEvent({
        name: 'enrichment_update_submit',
        type: 'click',
        extra: {
          enrichmentType: 'SCRAPE_WEBSITE',
        },
      });
    } else {
      const res = await api.enrichments.createScrape(listId, {
        name: data.name,
        prompt: data.prompt,
        dataType: dataType!,
        dataTypeExtra: dataType === 'CLASSIFICATION' ? datatTypeExtra : null,
        dataSources: [...dataSources],
        extractInfoTopics: data.extractInfoTopics,
        useReasoning: data.useReasoning,
      });
      modifiedId = res.data.id;
      logEvent({
        name: 'enrichment_create_submit',
        type: 'click',
        extra: {
          enrichmentType: 'SCRAPE_WEBSITE',
        },
      });
    }
    onClose({
      id: modifiedId,
      type: Type24EnumsEnrichColType.SCRAPE,
    });
    reset();
  };
  useEffect(() => {
    if (enrichment?.scrape == null) {
      return;
    }
    setDataType(enrichment.scrape.dataType);
    setDataTypeExtra(
      enrichment.scrape.dataTypeExtra ?? {
        options: [],
      },
    );
    setDataSources(
      new Set(
        enrichment.scrape.dataSources ?? [Type24EnumsScrapeDataSource.WEBSITE],
      ),
    );
  }, [enrichment]);
  useEffect(() => {
    if (defaultVals == null) {
      return;
    }
    setDataType(defaultVals.dataType);
    setDataTypeExtra(defaultVals.dataTypeExtra);
  }, [defaultVals]);
  return (
    <>
      <div className={classes.headerRow}>
        {!enrichment && (
          <IconButton
            onClick={() => {
              if (dataType == null) {
                onBack();
              } else {
                setDataType(null);
              }
            }}
            className={classes.backButton}
          >
            <ChevronLeft />
          </IconButton>
        )}
        <Typography level="h4">
          <b>Ask AI</b>
        </Typography>
      </div>
      <Typography level="body-md">
        Use an AI agent to crawl a data source to answer a question about them,
        or to extract key information
      </Typography>
      {dataType != null && (
        <div className={classes.sources}>
          <div>
            <FormLabel>Data Sources</FormLabel>
            <div className={classes.dataSources}>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.WEBSITE}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <Language />
                Website
              </DataSourceButton>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.LINKEDIN}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <LinkedIn />
                LinkedIn
              </DataSourceButton>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.PITCHBOOK}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <img
                  src={assetLink('pitchbook.png')}
                  className={classes.icon}
                />
                PitchBook
              </DataSourceButton>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.CRUNCHBASE}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <img
                  src={assetLink('crunchbase.png')}
                  className={classes.icon}
                />
                Crunchbase
              </DataSourceButton>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.GOOGLE_REVIEWS}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <Google />
                Google Places
              </DataSourceButton>
              <DataSourceButton
                source={Type24EnumsScrapeDataSource.YELP}
                selectedDataSources={dataSources}
                setDataSources={setDataSources}
              >
                <img src={assetLink('yelp.png')} className={classes.icon} />
                Yelp
              </DataSourceButton>
            </div>
          </div>
          <ControlledInput
            name="extractInfoTopics"
            label="(Optional) Topics to extract from source(s) to answer question"
            control={control}
            inputProps={{
              placeholder: 'products, services, about us',
            }}
          />
          <Controller
            name="useReasoning"
            control={control}
            render={({ field }) => (
              <Checkbox
                label="Use Reasoning"
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
            )}
          />
        </div>
      )}
      {dataType == null && (
        <div className={classes.questionType}>
          <Typography level="body-md">
            <b>Question Type</b>
          </Typography>
          <div className={classes.taskTypeContainer}>
            <Card
              className={classes.taskCard}
              onClick={() =>
                setDataType(Type24EnumsEnrichScrapeDataType.BOOLEAN)
              }
            >
              <Typography level="body-md">
                <b>Binary Question</b>
              </Typography>
              <Typography level="body-sm">
                <i>Does this clinic accept Medicaid?</i>
              </Typography>
            </Card>
            <Card
              className={classes.taskCard}
              onClick={() =>
                setDataType(Type24EnumsEnrichScrapeDataType.STRING)
              }
            >
              <Typography level="body-md">
                <b>Qualitative Question</b>
              </Typography>
              <Typography level="body-sm">
                <i>What types of jet engines does this company service?</i>
              </Typography>
            </Card>
            <Card
              className={classes.taskCard}
              onClick={() =>
                setDataType(Type24EnumsEnrichScrapeDataType.NUMBER)
              }
            >
              <Typography level="body-md">
                <b>Quantitative Question</b>
              </Typography>
              <Typography level="body-sm">
                <i>What is the price of the cheapest monthly plan offered?</i>
              </Typography>
            </Card>
            <Card
              className={classes.taskCard}
              onClick={() =>
                setDataType(Type24EnumsEnrichScrapeDataType.CLASSIFICATION)
              }
            >
              <Typography level="body-md">
                <b>Classification Question</b>
              </Typography>
              <Typography level="body-sm">
                <i>Assign this to one of the following: B2B, B2C, B2B/B2C</i>
              </Typography>
            </Card>
          </div>
        </div>
      )}
      {dataType != null && (
        <ControlledInput
          required
          label="Column Name"
          name="name"
          control={control}
          error={errors.name}
          inputProps={{
            size: 'md',
          }}
        />
      )}
      {dataType === Type24EnumsEnrichScrapeDataType.BOOLEAN && (
        <>
          <FormLabel>Provide a simple "yes" or "no" question</FormLabel>
          <FormControl error={Boolean(errors.prompt)}>
            <Controller
              name="prompt"
              control={control}
              rules={{
                required: 'This is required',
              }}
              render={({ field }) => (
                <Textarea
                  size="md"
                  minRows={2}
                  placeholder="Does this clinic accept Medicaid?"
                  {...field}
                />
              )}
            />
            {errors.prompt && (
              <FormHelperText>
                <InfoOutlined />
                {errors.prompt.message}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
      {dataType === Type24EnumsEnrichScrapeDataType.STRING && (
        <>
          <FormLabel>
            Provide a clear instruction on what information you would like to
            retrieve.
          </FormLabel>
          <FormControl error={Boolean(errors.prompt)}>
            <Controller
              name="prompt"
              control={control}
              rules={{
                required: 'This is required',
              }}
              render={({ field }) => (
                <Textarea
                  size="md"
                  minRows={2}
                  placeholder="What types of jet engines does this company service?"
                  {...field}
                />
              )}
            />
            {errors.prompt && (
              <FormHelperText>
                <InfoOutlined />
                {errors.prompt.message}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
      {dataType === Type24EnumsEnrichScrapeDataType.NUMBER && (
        <>
          <FormLabel>
            Provide a clear instruction on what information you would like to
            retrieve.
          </FormLabel>
          <FormControl error={Boolean(errors.prompt)}>
            <Controller
              name="prompt"
              control={control}
              rules={{
                required: 'This is required',
              }}
              render={({ field }) => (
                <Textarea
                  size="md"
                  minRows={2}
                  placeholder="What is the price of cheapest monthly plan this company offers?"
                  {...field}
                />
              )}
            />
            {errors.prompt && (
              <FormHelperText>
                <InfoOutlined />
                {errors.prompt.message}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
      {dataType === 'CLASSIFICATION' && (
        <>
          <FormLabel>Question</FormLabel>
          <FormControl error={Boolean(errors.prompt)}>
            <Controller
              name="prompt"
              control={control}
              rules={{
                required: 'This is required',
              }}
              render={({ field }) => (
                <Textarea
                  size="md"
                  minRows={2}
                  placeholder="What is the primary business model for this company?"
                  {...field}
                />
              )}
            />
            {errors.prompt && (
              <FormHelperText>
                <InfoOutlined />
                {errors.prompt.message}
              </FormHelperText>
            )}
          </FormControl>
          <FormLabel>
            Possible categories and their respective definitions to assign to a
            business
          </FormLabel>
          <div className={classes.categoryOptions}>
            {datatTypeExtra.options.map((option, idx) => (
              <Card className={classes.categoryCard} key={idx}>
                <div className={classes.categoryRow}>
                  <FormControl>
                    <FormLabel>Category {idx + 1} - Name</FormLabel>
                    <Input
                      size="md"
                      value={option.name}
                      onChange={(e) => {
                        setDataTypeExtra((prev) => {
                          const newVal = { ...prev };
                          newVal.options[idx].name = e.target.value;
                          return newVal;
                        });
                      }}
                    />
                  </FormControl>
                  <IconButton
                    size="md"
                    className={classes.deleteCategoryButton}
                    variant="outlined"
                    color="danger"
                    onClick={() => {
                      setDataTypeExtra((prev) => {
                        const newVal = { ...prev };
                        newVal.options.splice(idx, 1);
                        return newVal;
                      });
                    }}
                  >
                    <Delete />
                  </IconButton>
                </div>
                <FormControl>
                  <FormLabel>Category {idx + 1} - Description</FormLabel>
                  <Textarea
                    size="md"
                    minRows={2}
                    value={option.description}
                    onChange={(e) => {
                      setDataTypeExtra((prev) => {
                        const newVal = { ...prev };
                        newVal.options[idx].description = e.target.value;
                        return newVal;
                      });
                    }}
                  />
                </FormControl>
              </Card>
            ))}
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                setDataTypeExtra((prev) => ({
                  options: [
                    ...prev.options,
                    {
                      name: '',
                      description: '',
                    },
                  ],
                }));
              }}
            >
              Add Category
            </Button>
          </div>
        </>
      )}
      {dataType != null && (
        <Button onClick={handleSubmit(onSubmit)}>
          {enrichment == null ? 'Create' : 'Edit'}
        </Button>
      )}
    </>
  );
}
