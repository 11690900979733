import posthog from 'posthog-js';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import { analytics } from 'src/analytics';
import { ApiError, getAPI } from 'src/api';
import { getConfig } from 'src/config';
import { WhoAmIResponse } from 'src/generated/api';

export type Org = {
  id: string;
  name: string;
};

interface AuthContextInterface {
  creditsLeft: number | null;
  user: WhoAmIResponse['user'] | null;
  org: WhoAmIResponse['org'] | null;
  refetch: () => Promise<void>;
  refetchCreditsLeft: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextInterface>({
  creditsLeft: null,
  user: null,
  org: null,
  refetch: async () => {},
  refetchCreditsLeft: async () => {},
});

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export function AuthContextProvider({ children }: Props) {
  const [user, setUser] = useState<WhoAmIResponse['user'] | null>(null);
  const [org, setOrg] = useState<WhoAmIResponse['org'] | null>(null);
  const [creditsLeft, setCreditsLeft] = useState<number | null>(null);
  const api = getAPI();
  const config = getConfig();
  const fetchAuth = useCallback(async () => {
    try {
      const res = await api.auth.me();
      setUser(res.data.user);
      setOrg(res.data.org);
      const traits = {
        email: res.data.user?.email,
        orgId: res.data.org?.id,
        userId: res.data.user?.id,
      };
      posthog.identify(res.data.user?.email, traits);
      analytics.identify(res.data.user?.email, traits);
    } catch (err) {
      const error = err as ApiError;
      if (error.status === 401) {
        posthog.reset();
        window.location.href = config.hosts.landing;
        return;
      }
      throw err;
    }
  }, [api, config.hosts.landing]);
  useEffect(() => {
    fetchAuth();
  }, [fetchAuth]);
  const fetchCreditsLeft = useCallback(async () => {
    const numCreditsRes = await api.billing.getCreditsLeft();
    setCreditsLeft(numCreditsRes.data.creditsLeft);
  }, [api]);
  useEffect(() => {
    fetchCreditsLeft();
  }, [fetchCreditsLeft]);
  return (
    <AuthContext.Provider
      value={{
        user,
        org,
        refetch: fetchAuth,
        creditsLeft,
        refetchCreditsLeft: fetchCreditsLeft,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
