import React, { useContext, useEffect } from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import { Theme, Typography, useTheme } from '@mui/joy';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';

const useStyles = tss.withParams<{ theme: Theme }>().create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: 48,
    marginBottom: 48,
  },
  field: {
    width: 300,
    marginBottom: 12,
  },
  button: {
    margin: 12,
  },
});

export default function Onboarding() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/search');
  }, [navigate]);
  return (
    <LoadingContainer isLoading={!org || !user}>
      <div className={classes.container}>
        <Typography level="h2" color="primary">
          Onboarding
        </Typography>
      </div>
    </LoadingContainer>
  );
}
