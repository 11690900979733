import {
  Divider,
  IconButton,
  Button,
  Theme,
  useTheme,
  Typography,
} from '@mui/joy';
import { tss } from 'tss-react';
import React from 'react';
import { Control, FieldErrors, useFieldArray } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData } from './types';

type CompanyInfoProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'sticky',
    top: theme.spacing(8),
    backgroundColor: theme.palette.background.body,
    zIndex: 1,
    padding: theme.spacing(2, 0),
  },
}));

export default function Snapshot({
  control,
  errors,
  projectLocked,
}: CompanyInfoProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: peopleSummaryFields,
    append: appendPeopleSummary,
    remove: removePeopleSummary,
  } = useFieldArray({ control, name: 'peopleSummary' });

  const {
    fields: customerSummaryFields,
    append: appendCustomerSummary,
    remove: removeCustomerSummary,
  } = useFieldArray({ control, name: 'customerSummary' });

  const {
    fields: growthSummaryFields,
    append: appendGrowthSummary,
    remove: removeGrowthSummary,
  } = useFieldArray({ control, name: 'growthSummary' });

  const {
    fields: verticalsSummaryFields,
    append: appendVerticalsSummary,
    remove: removeVerticalsSummary,
  } = useFieldArray({ control, name: 'verticalsSummary' });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level="h3">Snapshot</Typography>
      </div>
      <Divider>People Facts</Divider>
      {peopleSummaryFields.map((field, index) => (
        <div key={field.id} className={classes.row}>
          <ControlledInput
            name={`peopleSummary.${index}.value`}
            control={control}
            error={errors.peopleSummary?.[index]?.value}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removePeopleSummary(index)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="success"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => appendPeopleSummary({ value: '' })}
        disabled={projectLocked}
      >
        Add People Fact
      </Button>
      <Divider>Customer Facts</Divider>
      {customerSummaryFields.map((field, index) => (
        <div key={field.id} className={classes.row}>
          <ControlledInput
            name={`customerSummary.${index}.value`}
            control={control}
            error={errors.customerSummary?.[index]?.value}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeCustomerSummary(index)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="success"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => appendCustomerSummary({ value: '' })}
        disabled={projectLocked}
      >
        Add Customer Fact
      </Button>
      <Divider>Growth Facts</Divider>
      {growthSummaryFields.map((field, index) => (
        <div key={field.id} className={classes.row}>
          <ControlledInput
            name={`growthSummary.${index}.value`}
            control={control}
            error={errors.growthSummary?.[index]?.value}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeGrowthSummary(index)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="success"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => appendGrowthSummary({ value: '' })}
        disabled={projectLocked}
      >
        Add Growth Fact
      </Button>
      <Divider>Verticals Facts</Divider>
      {verticalsSummaryFields.map((field, index) => (
        <div key={field.id} className={classes.row}>
          <ControlledInput
            name={`verticalsSummary.${index}.value`}
            control={control}
            error={errors.verticalsSummary?.[index]?.value}
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeVerticalsSummary(index)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        color="success"
        startDecorator={<AddIcon />}
        variant="outlined"
        onClick={() => appendVerticalsSummary({ value: '' })}
        disabled={projectLocked}
      >
        Add Verticals Fact
      </Button>
    </div>
  );
}
