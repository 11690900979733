import React from 'react';
import { tss } from 'tss-react';
import { Theme, Typography, useTheme } from '@mui/joy';
import { GetAutopilotManageResearchResponse } from 'src/generated/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  companyInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'center',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  companyTitle: {
    fontWeight: 600,
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  booleanField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  booleanIcon: {
    width: 16,
    height: 16,
  },
}));

type Props = {
  business: GetAutopilotManageResearchResponse['businesses'][number];
  setDrawerId: (id: string) => void;
};

export default function RequirementSummaryCell({
  business,
  setDrawerId,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div
      onClick={() => setDrawerId(business.id)}
      className={classes.companyInfoRow}
    >
      <div className={classes.companyInfo}>
        <Typography
          level="body-md"
          sx={{
            display: '-webkit-box',
            overflow: 'hidden',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 1,
          }}
        >
          <b>{business.name}</b>
        </Typography>
        <Typography level="body-sm">{business.domain}</Typography>
      </div>
    </div>
  );
}
