import {
  ChevronLeft,
  Facebook,
  Google,
  Instagram,
  ListAlt,
  OpenInNew,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import React, {
  Accordion,
  AccordionGroup,
  AccordionSummary,
  Card,
  Chip,
  Drawer,
  Dropdown,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Theme,
  Tooltip,
  Typography,
  useTheme,
  AccordionDetails,
  Button,
} from '@mui/joy';
import normalizeUrl from 'normalize-url';
import { useCallback, useEffect, useState } from 'react';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import {
  GetBusinessDetailedResponse,
  V1GetListsResponse,
  SimilarCompaniesResponse,
  V1GetListResponse,
} from 'src/generated/api';
import { assetLink, compactNumber } from 'src/utils';
import { tss } from 'tss-react';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  figure: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
    gap: theme.spacing(1),
  },
  figuresRow: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: theme.spacing(3),
  },
  row: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
  },
  keyFactsList: {
    margin: 0,
    paddingLeft: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  cta: {
    marginTop: theme.spacing(4),
  },
  chipsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },
  enrichmentCard: {
    padding: theme.spacing(1),
    gap: 0,
  },
  linkIcon: {
    width: 24,
    height: 24,
  },
  linksSection: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  extraHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  extraContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: theme.spacing(1),
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  addToListButton: {
    height: 36,
    width: 36,
    marginLeft: 'auto',
  },
  cards: {
    gap: theme.spacing(2),
  },
  card: {
    display: 'inline-flex',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    gap: 0,
  },
  accordionGroup: {
    marginTop: theme.spacing(2),
  },
  openSimilar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  openSimilarButton: {
    width: 24,
    height: 24,
    minWidth: 0,
    minHeight: 0,
    marginLeft: theme.spacing(1),
  },
  openSimilarIcon: {
    width: 20,
    height: 20,
  },
  searchForSimilar: {
    marginTop: theme.spacing(2),
  },
  chipsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  chip: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '&:hover': {
      filter: 'brightness(120%)',
      cursor: 'pointer',
    },
  },
  accordion: {
    paddingLeft: 0,
    paddingRight: 0,
  },
}));

type Props = {
  initialBusinessId: string | null;
  onClose: () => void;
  addToList?: (listId: string, listName: string) => void;
  lists?: V1GetListsResponse['results'];
  search?: {
    performSearch: (
      productsAndServices: string[],
      customerSegments: string[],
    ) => void;
  };
  currentList?: {
    id: string;
    add: (id: string) => void;
  };
  enrichments?: {
    cols: V1GetListResponse['enrichmentColumns'];
    values?: V1GetListResponse['businesses'][number]['enrichmentValues'];
  };
};

export function BusinessDrawer({
  onClose,
  initialBusinessId,
  addToList,
  lists,
  currentList,
  enrichments,
  search,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [business, setBusiness] = useState<GetBusinessDetailedResponse | null>(
    null,
  );
  const [similar, setSimilar] = useState<SimilarCompaniesResponse | null>(null);
  const [businessesStack, setBusinessesStack] = useState<string[]>([]);
  const businessId =
    businessesStack.length === 0
      ? null
      : businessesStack[businessesStack.length - 1];
  const onBack = useCallback(() => {
    if (businessesStack.length > 1) {
      const stackCopy = [...businessesStack];
      stackCopy.pop();
      setBusinessesStack(stackCopy);
    } else {
      onClose();
    }
  }, [onClose, businessesStack]);
  const fetch = useCallback(async () => {
    if (!businessId) {
      return;
    }
    const api = getAPI();
    const res = await api.businesses.getBusinessDetailed(businessId);
    setBusiness(res.data);
  }, [businessId]);
  useEffect(() => {
    const fetchSimilar = async () => {
      setSimilar(null);
      if (!businessId) {
        return;
      }
      const api = getAPI();
      const res = await api.businesses.getSimilar(businessId);
      setSimilar(res.data);
    };
    setBusiness(null);
    fetch();
    fetchSimilar();
  }, [businessId, fetch]);
  useEffect(() => {
    if (initialBusinessId) {
      setBusinessesStack([initialBusinessId]);
    } else {
      setBusinessesStack([]);
    }
  }, [initialBusinessId]);
  const scrapeEnrichmentsWithValue = enrichments?.values
    ?.filter((x) => x.scrape?.value != null)
    .map((x) => ({
      col: enrichments!.cols.find((col) => col.id === x.colId)!,
      val: x.scrape!,
    }));
  return (
    <Drawer anchor="right" open={Boolean(businessId)} onClose={onClose}>
      <LoadingContainer isLoading={business == null}>
        {business && (
          <>
            <div className={classes.header}>
              <IconButton onClick={onBack}>
                <ChevronLeft />
              </IconButton>
              <div className={classes.headerLeft}>
                <Typography level="h4">{business.name}</Typography>
                <Typography level="body-lg">
                  {business.city}, {business.state}
                </Typography>
              </div>
              {lists && addToList && (
                <Dropdown>
                  <Tooltip title="Add to List">
                    <MenuButton
                      color="success"
                      variant="solid"
                      className={classes.addToListButton}
                    >
                      <ListAlt />
                    </MenuButton>
                  </Tooltip>
                  <Menu placement="bottom-end" sx={{ zIndex: 'modal' }}>
                    {lists.map((l) => (
                      <MenuItem
                        key={l.id}
                        onClick={() => addToList(l.id, l.name)}
                      >
                        {l.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              )}
              {currentList && !business.lists.includes(currentList.id) && (
                <Tooltip title="Add to List">
                  <IconButton
                    color="success"
                    variant="solid"
                    className={classes.addToListButton}
                    onClick={async () => {
                      await currentList.add(businessId!);
                      await fetch();
                    }}
                  >
                    <ListAlt />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className={classes.contents}>
              <div className={classes.figuresRow}>
                {business?.revenue && (
                  <div className={classes.figure}>
                    <Typography level="h3">
                      ${compactNumber(business?.revenue)}
                    </Typography>
                    <Typography level="body-sm">Revenue</Typography>
                  </div>
                )}
                {business.numEmployees && (
                  <div className={classes.figure}>
                    <Typography level="h3">{business.numEmployees}</Typography>
                    <Typography level="body-sm">Employees</Typography>
                  </div>
                )}
                {business.crunchbaseData?.foundedYear && (
                  <div className={classes.figure}>
                    <Typography level="h3">
                      {business.crunchbaseData.foundedYear}
                    </Typography>
                    <Typography level="body-sm">Founded</Typography>
                  </div>
                )}
                {business.crunchbaseData?.totalFunding ? (
                  <div className={classes.figure}>
                    <Typography level="h3">
                      ${compactNumber(business.crunchbaseData.totalFunding)}
                    </Typography>
                    <Typography level="body-sm">Funding</Typography>
                  </div>
                ) : null}
              </div>
              <div className={classes.linksSection}>
                <Typography level="body-md">
                  <b>Links</b>
                </Typography>
                <div className={classes.links}>
                  {business?.website && (
                    <Button
                      variant="outlined"
                      size="sm"
                      color="neutral"
                      onClick={() => {
                        window.open(normalizeUrl(business!.website));
                      }}
                    >
                      Website
                    </Button>
                  )}
                  {business?.gmbUrl && (
                    <Tooltip title="Open Google Maps">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(normalizeUrl(business!.gmbUrl!));
                        }}
                      >
                        <Google className={classes.linkIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.scrapeData.facebookLink && (
                    <Tooltip title="Open Facebook Page">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.scrapeData.facebookLink!),
                          );
                        }}
                      >
                        <Facebook className={classes.linkIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.scrapeData.twitterLink && (
                    <Tooltip title="Open Twitter Profile">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.scrapeData.twitterLink!),
                          );
                        }}
                      >
                        <Twitter className={classes.linkIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.scrapeData.youtubeLink && (
                    <Tooltip title="Open YouTube Profile">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.scrapeData.youtubeLink!),
                          );
                        }}
                      >
                        <YouTube className={classes.linkIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.scrapeData.instagramLink && (
                    <Tooltip title="Open Instagram Profile">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.scrapeData.instagramLink!),
                          );
                        }}
                      >
                        <Instagram className={classes.linkIcon} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.scrapeData.yelpLink && (
                    <Tooltip title="Open Yelp Profile">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.scrapeData.yelpLink!),
                          );
                        }}
                      >
                        <img
                          className={classes.linkIcon}
                          src={assetLink('yelp.png')}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                  {business?.crunchbaseData?.url && (
                    <Tooltip title="Open Crunchbase Profile">
                      <IconButton
                        variant="plain"
                        onClick={() => {
                          window.open(
                            normalizeUrl(business!.crunchbaseData!.url!),
                          );
                        }}
                      >
                        <img
                          className={classes.linkIcon}
                          src={assetLink('crunchbase.png')}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              {business.scrapeData.productsAndServices.length > 0 && (
                <div className={classes.chips}>
                  <Typography level="body-xs">Products and Services</Typography>
                  <div className={classes.chipsSection}>
                    {business.scrapeData.productsAndServices.map((ps) => (
                      <Tooltip arrow key={ps.keyPhrase} title={ps.description}>
                        <Chip
                          color="warning"
                          slotProps={{
                            label: {
                              className: classes.chip,
                            },
                          }}
                        >
                          {ps.keyPhrase.toLowerCase()}
                        </Chip>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
              {business.scrapeData.customerSegments.length > 0 && (
                <div className={classes.chips}>
                  <Typography level="body-xs">End Customers</Typography>
                  <div className={classes.chipsSection}>
                    {business.scrapeData.customerSegments.map((cs) => (
                      <Tooltip arrow key={cs.keyPhrase} title={cs.description}>
                        <Chip
                          key={cs.keyPhrase}
                          color="success"
                          slotProps={{
                            label: {
                              className: classes.chip,
                            },
                          }}
                        >
                          {cs.keyPhrase.toLowerCase()}
                        </Chip>
                      </Tooltip>
                    ))}
                  </div>
                </div>
              )}
              {business.scrapeData.serviceAreas.length > 0 && (
                <div className={classes.chips}>
                  <Typography level="body-xs">Service Areas</Typography>
                  <div className={classes.chipsSection}>
                    {business.scrapeData.serviceAreas.map((sa) => (
                      <Chip
                        key={sa}
                        color="primary"
                        slotProps={{
                          label: {
                            className: classes.chip,
                          },
                        }}
                      >
                        {sa}
                      </Chip>
                    ))}
                  </div>
                </div>
              )}
              {scrapeEnrichmentsWithValue &&
                scrapeEnrichmentsWithValue.length > 0 &&
                businessesStack.length <= 1 && (
                  <div className={classes.section}>
                    <Typography level="h4" className={classes.sectionTitle}>
                      Enrichments
                    </Typography>
                    {scrapeEnrichmentsWithValue.map((e) => (
                      <Card className={classes.enrichmentCard} key={e.col.id}>
                        <Typography level="body-md">
                          <b>{e.col.name}</b>: {e.col.scrape?.prompt}
                        </Typography>
                        <br />
                        <Typography level="body-md" color="primary">
                          <b>{JSON.stringify(e.val.value)}</b>
                        </Typography>
                      </Card>
                    ))}
                  </div>
                )}
              <AccordionGroup className={classes.accordionGroup}>
                <Accordion defaultExpanded className={classes.accordion}>
                  <AccordionSummary>
                    <Typography level="h4" className={classes.sectionTitle}>
                      About
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography level="body-sm">
                      {business.scrapeData.description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {business.crunchbaseData &&
                  (business.crunchbaseData.fundingRounds.length > 0 ||
                    business.crunchbaseData.founderNames.length > 0) && (
                    <Accordion className={classes.accordion}>
                      <AccordionSummary>
                        <div className={classes.sectionTitle}>
                          <Typography level="h4">Crunchbase Data</Typography>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        {business.crunchbaseData.founderNames.length > 0 && (
                          <Typography level="body-md">
                            <b>Founders</b> -{' '}
                            {business.crunchbaseData.founderNames.join(', ')}
                          </Typography>
                        )}
                        <div className={classes.cards}>
                          {business.crunchbaseData.fundingRounds.map((fr) => (
                            <Card
                              variant="outlined"
                              key={fr.roundName}
                              className={classes.card}
                            >
                              <Typography level="body-md">
                                {fr.roundName}
                              </Typography>
                              {fr.amountRaised && (
                                <Typography level="body-md">
                                  <b>${compactNumber(fr.amountRaised)}</b>
                                </Typography>
                              )}
                              <Typography level="body-sm">
                                <i>Lead by {fr.leadInvestors.join(', ')}</i>
                              </Typography>
                              <Typography level="body-sm">
                                {new Date(
                                  fr.announcedDate,
                                ).toLocaleDateString()}
                              </Typography>
                            </Card>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )}
                <LoadingContainer isLoading={similar == null}>
                  <>
                    {similar && similar.similar.length > 0 && (
                      <Accordion defaultExpanded className={classes.accordion}>
                        <AccordionSummary>
                          <div className={classes.sectionTitle}>
                            <Typography level="h4">
                              Similar Companies
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.cards}>
                            {similar?.similar.map((s) => (
                              <Card
                                variant="outlined"
                                key={s.id}
                                className={classes.card}
                              >
                                <Typography
                                  level="body-md"
                                  className={classes.openSimilar}
                                >
                                  {s.name}
                                  <Tooltip title="Open">
                                    <IconButton
                                      onClick={() =>
                                        setBusinessesStack((prev) => [
                                          ...prev,
                                          s.id,
                                        ])
                                      }
                                      className={classes.openSimilarButton}
                                    >
                                      <OpenInNew
                                        className={classes.openSimilarIcon}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Typography>
                                <Typography level="body-sm">
                                  {s.description}
                                </Typography>
                              </Card>
                            ))}
                          </div>
                          {search && (
                            <Button
                              variant="outlined"
                              className={classes.searchForSimilar}
                              onClick={() =>
                                search.performSearch(
                                  business.scrapeData!.productsAndServices.map(
                                    (ps) => ps.keyPhrase,
                                  ),
                                  business.scrapeData!.customerSegments.map(
                                    (cs) => cs.keyPhrase,
                                  ),
                                )
                              }
                            >
                              Search Similar ({similar.numSimilar})
                            </Button>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    )}
                  </>
                </LoadingContainer>
              </AccordionGroup>
            </div>
          </>
        )}
      </LoadingContainer>
    </Drawer>
  );
}
