import React, { useState } from 'react';
import { tss } from 'tss-react';

import {
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { ChevronLeft } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import LoadingContainer from 'src/components/LoadingContainer';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  enrichmentHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  enrichmentButton: {
    width: 24,
    height: 24,
    minHeight: 0,
    minWidth: 0,
  },
  enrichmentIcon: {
    width: 16,
    height: 16,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
}));

type Props = {
  onNext: (
    prompt: string,
    highCategory: string,
    medCategory: string,
    lowCategory: string,
  ) => void;
  onBack: () => void;
};

export default function StrategicFit({ onNext, onBack }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      productServices: '',
      endMarkets: '',
      otherConsiderations: '',
    },
  });
  const [loading, setLoading] = useState(false);
  const onSubmit: SubmitHandler<{
    productServices: string;
    endMarkets: string;
    otherConsiderations: string;
  }> = async (data) => {
    setLoading(true);
    const res = await api.lists.generateStrategicFit({
      targetProductServices: data.productServices,
      targetEndMarkets: data.endMarkets,
      otherConsiderations: data.otherConsiderations,
    });
    onNext(
      res.data.prompt,
      res.data.highCategory.map((c) => `- ${c}`).join('\n'),
      res.data.medCategory.map((c) => `- ${c}`).join('\n'),
      res.data.lowCategory.map((c) => `- ${c}`).join('\n'),
    );
  };
  return (
    <LoadingContainer isLoading={loading}>
      <>
        <div className={classes.headerRow}>
          <IconButton
            onClick={() => {
              onBack();
            }}
            className={classes.backButton}
          >
            <ChevronLeft />
          </IconButton>
          <Typography level="h4">
            <b>Strategic Fit</b>
          </Typography>
        </div>
        <Typography level="body-md">
          Use AI to generate a criteria for determining if a business is a
          strategic fit
        </Typography>
        <FormLabel>Target products / services</FormLabel>
        <FormControl error={Boolean(errors.productServices)}>
          <Controller
            name="productServices"
            control={control}
            render={({ field }) => (
              <Textarea
                size="md"
                minRows={2}
                placeholder="machinery moving, hydro blasting, plumbing"
                {...field}
              />
            )}
          />
        </FormControl>
        <FormLabel>Target end markets</FormLabel>
        <FormControl error={Boolean(errors.endMarkets)}>
          <Controller
            name="endMarkets"
            control={control}
            render={({ field }) => (
              <Textarea
                size="md"
                minRows={2}
                placeholder="industrial, residential, utility companies"
                {...field}
              />
            )}
          />
        </FormControl>
        <FormLabel>Other considerations</FormLabel>
        <FormControl error={Boolean(errors.otherConsiderations)}>
          <Controller
            name="otherConsiderations"
            control={control}
            render={({ field }) => (
              <Textarea size="md" minRows={3} {...field} />
            )}
          />
        </FormControl>
        <Button onClick={handleSubmit(onSubmit)}>Next</Button>
      </>
    </LoadingContainer>
  );
}
