import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tss } from 'tss-react';
import {
  Autocomplete,
  Button,
  Card,
  Checkbox,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Option,
  Select,
  Sheet,
  Slider,
  Switch,
  Theme,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  Groups,
  Paid,
  TrendingUp,
  Map,
  Close,
  Clear,
  Block,
  PlaylistRemove,
  Category,
  Storefront,
  Tune,
} from '@mui/icons-material';
import { Popper, ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import {
  GetAutopilotsResponse,
  SearchResponse2,
  V1GetListsResponse,
} from 'src/generated/api';
import { NumericFormatAdapter } from 'src/components/FormattedNumberInput';
import OptimizedInput from 'src/components/OptimizedInput';
import { logEvent } from 'src/analytics';
import { SnackbarContext } from 'src/contexts/snackbar';
import SearchResults, { SearchData } from './SearchResults';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    width: '100%',
    gap: theme.spacing(2),
  },
  contents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentsRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  field: {
    flex: 1,
  },
  searchResults: {
    overflowY: 'auto',
    height: '75vh',
    boxShadow: theme.shadow.lg,
    marginTop: theme.spacing(1),
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
  },
  filterOptions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
  },
  filterButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  deleteFilterIcon: {
    width: 16,
    height: 16,
  },
  deleteFilterButton: {
    width: 20,
    height: 20,
    minWidth: 0,
    minHeight: 0,
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  domainField: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'center',
    marginTop: theme.spacing(2),
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  headerButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'row',
  },
  historyButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  similarButton: {
    height: 48,
  },
  footerButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    flexDirection: 'row',
    marginLeft: 'auto',
    padding: theme.spacing(1),
  },
  revenueInput: {
    maxWidth: 140,
  },
  employeeInput: {
    maxWidth: 80,
  },
  filterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  filterContentsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  geoFilterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  geoFilterRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  weightOptions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  weightOption: {
    borderRadius: 0,
    padding: theme.spacing(1),
  },
  scrapingText: {
    alignSelf: 'center',
  },
  card: {
    boxShadow: theme.shadow.lg,
    padding: theme.spacing(1),
    width: '100%',
  },
  headerCard: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  filtersSection: {
    marginTop: theme.spacing(2),
    maxWidth: 500,
  },
  searchParams: {
    marginTop: theme.spacing(2),
    maxWidth: 500,
  },
  searchParamsContent: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    gap: theme.spacing(4),
  },
  searchParamsCategory: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    gap: theme.spacing(2),
    padding: 0,
    boxShadow: theme.shadow.lg,
    marginTop: theme.spacing(1),
    minWidth: 400,
    height: '75vh',
  },
  cardsScrollContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowY: 'auto',
    padding: theme.spacing(1),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  icon: {
    width: 32,
    height: 32,
  },
  cardTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  rankingTitle: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  criteriaRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: theme.spacing(2),
  },
  criteriaTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  domainModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    maxWidth: 400,
  },
  domainModalLoading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  domainModalLoadingSpinner: {
    marginTop: theme.spacing(2),
  },
  mustSellToList: {
    display: 'flex',
    flexDirection: 'column',
  },
  suggestions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  suggestion: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  tuneButton: {
    width: 32,
    height: 32,
  },
  inputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  autocomplete: {
    width: '100%',
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  selectButton: {
    padding: theme.spacing(1),
  },
  popper: {
    boxShadow: theme.shadow.lg,
    backgroundColor: theme.palette.background.popup,
    zIndex: theme.zIndex.popup,
  },
  popperSheet: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    overflowY: 'scroll',
    padding: theme.spacing(1),
  },
  nullState: {
    marginTop: theme.spacing(1),
    gap: theme.spacing(2),
    boxShadow: theme.shadow.lg,
    width: '100%',
    height: '75vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nullStateCard: {
    width: 500,
  },
  searchButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    marginTop: 'auto',
    padding: theme.spacing(1),
  },
  softwareOnlyCheck: {
    marginTop: theme.spacing(1),
  },
  criteriaFields: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  input: {
    width: '100%',
  },
  slider: {
    paddingTop: 0,
  },
}));

export default function Search() {
  const theme = useTheme();
  const [loadingSearchResults, setLoadingSearchResults] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);

  // Search Query
  const [pageSize, setPageSize] = useState(100);
  const [showProductService, setShowProductService] = useState(false);
  const [showCustomerSegment, setShowCustomerSegment] = useState(false);
  const [descriptionPopperEl, setDescriptionPopperEl] =
    useState<HTMLAnchorElement | null>(null);
  const [productsPopperEl, setProductsPopperEl] =
    useState<HTMLAnchorElement | null>(null);
  const [customersPopperEl, setCustomersPopperEl] =
    useState<HTMLAnchorElement | null>(null);
  const [searchFormData, setSearchFormData] = useState<SearchData>({
    productsAndServices: '',
    customerSegments: '',
    description: '',
    descriptionWeight: 1,
    descriptionSemanticThreshold: 0.3,
    productServiceWeight: 1,
    productServiceSemanticThreshold: 0.4,
    customerSegmentWeight: 1,
    customerSegmentSemanticThreshold: 0.4,
    textSearchWeight: 2,
    semanticSearchWeight: 1,
    mustHaveSemanticMatch: false,
    mustHaveTextMatch: false,
    softwareOnly: false,
  });
  const [currentSearchQuery, setCurrentSearchQuery] = useState<
    (SearchData & { pageNum: number }) | null
  >(null);
  const [searchResults, setSearchResults] = useState<SearchResponse2 | null>(
    null,
  );
  const [lists, setLists] = useState<V1GetListsResponse['results'] | null>(
    null,
  );
  const [autopilots, setAutopilots] = useState<
    GetAutopilotsResponse['autopilots'] | null
  >(null);
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  const [hasError, setHasError] = useState(false);

  // Actions
  const api = getAPI();
  const onClear = useCallback(() => {
    setSearchFormData({
      productsAndServices: '',
      customerSegments: '',
      description: '',
      descriptionWeight: 1,
      descriptionSemanticThreshold: 0.3,
      productServiceWeight: 1,
      productServiceSemanticThreshold: 0.4,
      customerSegmentWeight: 1,
      customerSegmentSemanticThreshold: 0.4,
      textSearchWeight: 2,
      semanticSearchWeight: 1,
      mustHaveSemanticMatch: false,
      mustHaveTextMatch: false,
      softwareOnly: false,
    });
    setSearchResults(null);
  }, []);
  const onSearch = useCallback(
    async (data?: SearchData) => {
      const query = data ?? searchFormData;
      logEvent({
        name: 'search_submit',
        type: 'click',
        extra: {
          query: JSON.stringify(query),
        },
      });
      if (
        query!.description.length === 0 &&
        query!.productsAndServices.length === 0 &&
        query!.customerSegments.length === 0
      ) {
        setHasError(true);
        logEvent({
          name: 'search_validation_error',
          type: 'error',
          extra: {
            query: JSON.stringify(query),
          },
        });
        return;
      }
      setCurrentSearchQuery({
        description: query.description,
        descriptionWeight: query.descriptionWeight,
        descriptionSemanticThreshold: query.descriptionSemanticThreshold,
        productsAndServices: query.productsAndServices,
        productServiceWeight: query.productServiceWeight,
        productServiceSemanticThreshold: query.productServiceSemanticThreshold,
        customerSegments: query.customerSegments,
        customerSegmentWeight: query.customerSegmentWeight,
        customerSegmentSemanticThreshold:
          query.customerSegmentSemanticThreshold,
        textSearchWeight: query.textSearchWeight,
        semanticSearchWeight: query.semanticSearchWeight,
        mustHaveSemanticMatch: query.mustHaveSemanticMatch,
        mustHaveTextMatch: query.mustHaveTextMatch,
        geoFilter: query.geoFilter,
        employeeFilter: query.employeeFilter,
        revenueFilter: query.revenueFilter,
        fundingFilter: query.fundingFilter,
        excludeKeywords: query.excludeKeywords,
        excludeLists: query.excludeLists,
        softwareOnly: query.softwareOnly ?? false,
        pageNum: 0,
      });
    },
    [searchFormData],
  );
  const performSearch = useCallback(() => {
    // const searchQuery = {
    //   productsAndServices: ps,
    //   customerSegments: cs,
    //   softwareOnly: false,
    //   description: '',
    // };
    // setSearchFormData(searchQuery);
    // onSearch(searchQuery);
  }, []);
  const addAllToAutopilot = useCallback(
    async (campaignId: string, campaignName: string) => {
      const queryCopy = { ...currentSearchQuery! };
      // @ts-expect-error We dont want this in the params
      delete queryCopy.pageNum;
      showSnackbar({
        color: 'success',
        message: `Added all search results to ${campaignName}`,
      });
      setSearchResults((prev) => ({
        ...prev!,
        results: prev!.results.map((r) => ({
          ...r,
          autopilots: r.autopilots.includes(campaignId)
            ? r.autopilots
            : [...r.autopilots, campaignId],
        })),
      }));
      // await api.autopilot.addAllFromSearch(campaignId, {
      //   searchQuery: queryCopy,
      // });
    },
    [currentSearchQuery, showSnackbar],
  );
  const addAllToList = useCallback(
    async (listId: string, listName: string) => {
      const queryCopy = { ...currentSearchQuery! };
      // @ts-expect-error We dont want this in the params
      delete queryCopy.pageNum;
      showSnackbar({
        color: 'success',
        message: `Added all search results to ${listName}`,
      });
      setSearchResults((prev) => ({
        ...prev!,
        results: prev!.results.map((r) => ({
          ...r,
          lists: r.lists.includes(listId) ? r.lists : [...r.lists, listId],
        })),
      }));
      // await api.lists.addAllFromSearch(listId, {
      //   searchQuery: queryCopy,
      // });
    },
    [currentSearchQuery, showSnackbar],
  );
  useEffect(() => {
    if (hasError && searchFormData.description.length > 0) {
      setHasError(false);
    }
  }, [searchFormData, hasError]);
  useEffect(() => {
    const search = async () => {
      if (currentSearchQuery == null) {
        return;
      }
      logEvent({
        name: 'search_request',
        type: 'click',
        extra: {
          query: JSON.stringify(currentSearchQuery),
        },
      });
      setLoadingSearchResults(true);
      const res = await api.businesses.searchBusinesses2({
        ...currentSearchQuery,
        pageSize,
      });
      logEvent({
        name: 'search_success',
        type: 'response',
        extra: {
          query: JSON.stringify(currentSearchQuery),
          numResults: res.data.results.length.toString(),
        },
      });
      setSearchResults(res.data);
      setLoadingSearchResults(false);
    };
    search();
  }, [currentSearchQuery, api, pageSize]);
  const fetchLists = useCallback(async () => {
    const listsRes = await api.lists.getLists();
    setLists(listsRes.data.results);
    const autopilotsRes = await api.autopilot.getAutopilots();
    setAutopilots(autopilotsRes.data.autopilots);
  }, [api]);
  useEffect(() => {
    fetchLists();
  }, [fetchLists]);

  return (
    <>
      <LoadingContainer isLoading={!org || !user}>
        <div className={classes.container}>
          <div className={classes.contents}>
            <div className={classes.headerCard}>
              <div className={classes.header}>
                <Typography level="h3" className={classes.title}>
                  <SearchIcon className={classes.icon} />
                  Search Businesses
                </Typography>
              </div>
            </div>
            <div className={classes.contentsRow}>
              <Card variant="plain" className={classes.cards}>
                <div className={classes.cardsScrollContainer}>
                  <div>
                    <div className={classes.cardContent}>
                      {hasError && (
                        <Card color="danger" variant="soft">
                          <Typography level="body-sm">
                            <b>A search query is required</b>
                          </Typography>
                        </Card>
                      )}
                      <FormControl error={hasError} className={classes.field}>
                        <div className={classes.inputRow}>
                          <OptimizedInput
                            className={classes.input}
                            value={searchFormData.description}
                            onBlurWithValue={(val) => {
                              setSearchFormData((prev) => ({
                                ...prev,
                                description: val,
                              }));
                            }}
                            placeholder="Generative AI"
                          />
                          <IconButton
                            className={classes.tuneButton}
                            variant="outlined"
                            onClick={(e) => {
                              if (descriptionPopperEl) {
                                setDescriptionPopperEl(null);
                              } else {
                                setDescriptionPopperEl(e.currentTarget);
                              }
                            }}
                          >
                            <Tune />
                          </IconButton>
                          <Popper
                            anchorEl={descriptionPopperEl}
                            open={Boolean(descriptionPopperEl)}
                            placement="bottom-end"
                            className={classes.popper}
                          >
                            <ClickAwayListener
                              onClickAway={() => setDescriptionPopperEl(null)}
                            >
                              <Sheet className={classes.popperSheet}>
                                <FormControl>
                                  <FormLabel>Importance</FormLabel>
                                  <ToggleButtonGroup
                                    value={searchFormData.descriptionWeight.toString()}
                                    onChange={(e, val) =>
                                      setSearchFormData((prev) => ({
                                        ...prev,
                                        descriptionWeight: parseInt(val!, 10),
                                      }))
                                    }
                                  >
                                    <Button
                                      value="1"
                                      className={classes.selectButton}
                                    >
                                      Low
                                    </Button>
                                    <Button
                                      value="2"
                                      className={classes.selectButton}
                                    >
                                      Mid
                                    </Button>
                                    <Button
                                      value="3"
                                      className={classes.selectButton}
                                    >
                                      High
                                    </Button>
                                  </ToggleButtonGroup>
                                </FormControl>
                                <FormControl>
                                  <FormLabel>Threshold</FormLabel>
                                  <Slider
                                    classes={{
                                      root: classes.slider,
                                    }}
                                    min={0}
                                    max={100}
                                    onChange={(e, val) =>
                                      setSearchFormData((prev) => ({
                                        ...prev,
                                        descriptionSemanticThreshold:
                                          (val as number) / 100,
                                      }))
                                    }
                                    value={Math.round(
                                      searchFormData.descriptionSemanticThreshold *
                                        100,
                                    )}
                                    valueLabelDisplay="auto"
                                  />
                                </FormControl>
                              </Sheet>
                            </ClickAwayListener>
                          </Popper>
                        </div>
                        <Checkbox
                          label="Software Companies Only"
                          checked={searchFormData.softwareOnly}
                          className={classes.softwareOnlyCheck}
                          onChange={(e) =>
                            setSearchFormData((prev) => ({
                              ...prev,
                              softwareOnly: e.target.checked,
                            }))
                          }
                        />
                      </FormControl>
                      <div className={classes.criteriaRow}>
                        <div className={classes.criteriaTitle}>
                          <Typography level="h4">Detailed</Typography>
                        </div>
                      </div>
                      <div className={classes.filterOptions}>
                        {!showProductService && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => setShowProductService(true)}
                            className={classes.filterButton}
                          >
                            <Category />
                            Products
                          </Button>
                        )}
                        {!showCustomerSegment && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() => setShowCustomerSegment(true)}
                            className={classes.filterButton}
                          >
                            <Storefront />
                            End Customer
                          </Button>
                        )}
                      </div>
                      <div className={classes.criteriaFields}>
                        {showProductService && (
                          <FormControl
                            error={hasError}
                            className={classes.field}
                          >
                            <FormLabel>Products / Services</FormLabel>
                            <div className={classes.inputRow}>
                              <OptimizedInput
                                className={classes.input}
                                value={searchFormData.productsAndServices}
                                onBlurWithValue={(val) => {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    productsAndServices: val,
                                  }));
                                }}
                                placeholder="machinery moving, dry ice blasting, plumbing"
                              />
                              <IconButton
                                className={classes.tuneButton}
                                variant="outlined"
                                onClick={(e) => {
                                  if (productsPopperEl) {
                                    setProductsPopperEl(null);
                                  } else {
                                    setProductsPopperEl(e.currentTarget);
                                  }
                                }}
                              >
                                <Tune />
                              </IconButton>
                              <Popper
                                anchorEl={productsPopperEl}
                                open={Boolean(productsPopperEl)}
                                placement="bottom-end"
                                className={classes.popper}
                              >
                                <ClickAwayListener
                                  onClickAway={() => setProductsPopperEl(null)}
                                >
                                  <Sheet className={classes.popperSheet}>
                                    <FormControl>
                                      <FormLabel>Importance</FormLabel>
                                      <ToggleButtonGroup
                                        value={searchFormData.productServiceWeight.toString()}
                                        onChange={(e, val) =>
                                          setSearchFormData((prev) => ({
                                            ...prev,
                                            productServiceWeight: parseInt(
                                              val!,
                                              10,
                                            ),
                                          }))
                                        }
                                      >
                                        <Button
                                          value="1"
                                          className={classes.selectButton}
                                        >
                                          Low
                                        </Button>
                                        <Button
                                          value="2"
                                          className={classes.selectButton}
                                        >
                                          Mid
                                        </Button>
                                        <Button
                                          value="3"
                                          className={classes.selectButton}
                                        >
                                          High
                                        </Button>
                                      </ToggleButtonGroup>
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel>Threshold</FormLabel>
                                      <div className={classes.slider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(e, val) =>
                                            setSearchFormData((prev) => ({
                                              ...prev,
                                              productServiceSemanticThreshold:
                                                (val as number) / 100,
                                            }))
                                          }
                                          value={Math.round(
                                            searchFormData.productServiceSemanticThreshold *
                                              100,
                                          )}
                                          valueLabelDisplay="auto"
                                        />
                                      </div>
                                    </FormControl>
                                  </Sheet>
                                </ClickAwayListener>
                              </Popper>
                            </div>
                          </FormControl>
                        )}
                        {showCustomerSegment && (
                          <FormControl
                            error={hasError}
                            className={classes.field}
                          >
                            <FormLabel>End Customer</FormLabel>
                            <div className={classes.inputRow}>
                              <OptimizedInput
                                className={classes.input}
                                value={searchFormData.customerSegments}
                                onBlurWithValue={(val) => {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    customerSegments: val,
                                  }));
                                }}
                                placeholder="consumers, hospitality, industrial sector"
                              />
                              <IconButton
                                className={classes.tuneButton}
                                variant="outlined"
                                onClick={(e) => {
                                  if (customersPopperEl) {
                                    setCustomersPopperEl(null);
                                  } else {
                                    setCustomersPopperEl(e.currentTarget);
                                  }
                                }}
                              >
                                <Tune />
                              </IconButton>
                              <Popper
                                anchorEl={customersPopperEl}
                                open={Boolean(customersPopperEl)}
                                placement="bottom-end"
                                className={classes.popper}
                              >
                                <ClickAwayListener
                                  onClickAway={() => setCustomersPopperEl(null)}
                                >
                                  <Sheet className={classes.popperSheet}>
                                    <FormControl>
                                      <FormLabel>Importance</FormLabel>
                                      <ToggleButtonGroup
                                        value={searchFormData.customerSegmentWeight.toString()}
                                        onChange={(e, val) =>
                                          setSearchFormData((prev) => ({
                                            ...prev,
                                            customerSegmentWeight: parseInt(
                                              val!,
                                              10,
                                            ),
                                          }))
                                        }
                                      >
                                        <Button
                                          value="1"
                                          className={classes.selectButton}
                                        >
                                          Low
                                        </Button>
                                        <Button
                                          value="2"
                                          className={classes.selectButton}
                                        >
                                          Mid
                                        </Button>
                                        <Button
                                          value="3"
                                          className={classes.selectButton}
                                        >
                                          High
                                        </Button>
                                      </ToggleButtonGroup>
                                    </FormControl>
                                    <FormControl>
                                      <FormLabel>Threshold</FormLabel>
                                      <div className={classes.slider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          onChange={(e, val) =>
                                            setSearchFormData((prev) => ({
                                              ...prev,
                                              customerSegmentSemanticThreshold:
                                                (val as number) / 100,
                                            }))
                                          }
                                          value={Math.round(
                                            searchFormData.customerSegmentSemanticThreshold *
                                              100,
                                          )}
                                          valueLabelDisplay="auto"
                                        />
                                      </div>
                                    </FormControl>
                                  </Sheet>
                                </ClickAwayListener>
                              </Popper>
                            </div>
                          </FormControl>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.filtersSection}>
                    <div className={classes.cardContent}>
                      <Typography level="h4" className={classes.cardTitle}>
                        Filters
                      </Typography>
                      <div className={classes.filterOptions}>
                        {!searchFormData.employeeFilter && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                employeeFilter: {
                                  minEmployees: 1,
                                  maxEmployees: 100,
                                },
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <Groups />
                            Employees
                          </Button>
                        )}
                        {!searchFormData.revenueFilter && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                revenueFilter: {
                                  minRevenue: 100000,
                                  maxRevenue: 100000000,
                                  mustHave: false,
                                },
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <Paid />
                            Revenue
                          </Button>
                        )}
                        {!searchFormData.fundingFilter && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                fundingFilter: {
                                  funded: {
                                    minFunding: 1000000,
                                    maxFunding: 100000000,
                                  },
                                  mustHave: false,
                                },
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <TrendingUp />
                            Funding (Software)
                          </Button>
                        )}
                        {!searchFormData.geoFilter && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                geoFilter: {
                                  hqStates: [],
                                  serviceAreasStates: [],
                                  mustHaveServiceAreas: false,
                                },
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <Map />
                            Geography
                          </Button>
                        )}
                        {!searchFormData.excludeKeywords && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                excludeKeywords: {
                                  productServices: [],
                                  customerSegments: [],
                                },
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <Block />
                            Exclude Keywords
                          </Button>
                        )}
                        {!searchFormData.excludeLists && (
                          <Button
                            variant="outlined"
                            color="neutral"
                            onClick={() =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                excludeLists: [],
                              }))
                            }
                            className={classes.filterButton}
                          >
                            <PlaylistRemove />
                            Exclude Lists
                          </Button>
                        )}
                      </div>
                      <div className={classes.filters}>
                        {searchFormData.employeeFilter && (
                          <Card className={classes.filterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <Groups />
                              <b>Employees</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      employeeFilter: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <div className={classes.filterContentsRow}>
                              <FormControl>
                                <FormLabel>Min</FormLabel>
                                <Input
                                  value={searchFormData.employeeFilter.minEmployees?.toString()}
                                  className={classes.employeeInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      employeeFilter: {
                                        ...prev.employeeFilter!,
                                        minEmployees: parseInt(
                                          e.target.value,
                                          10,
                                        ),
                                      },
                                    }))
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>Max</FormLabel>
                                <Input
                                  value={searchFormData.employeeFilter.maxEmployees?.toString()}
                                  className={classes.employeeInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      employeeFilter: {
                                        ...prev.employeeFilter!,
                                        maxEmployees: parseInt(
                                          e.target.value,
                                          10,
                                        ),
                                      },
                                    }))
                                  }
                                />
                              </FormControl>
                            </div>
                          </Card>
                        )}
                        {searchFormData.revenueFilter && (
                          <Card className={classes.filterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <Paid />
                              <b>Revenue</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      revenueFilter: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <div className={classes.filterContentsRow}>
                              <FormControl>
                                <FormLabel>Min</FormLabel>
                                <Input
                                  value={searchFormData.revenueFilter.minRevenue?.toString()}
                                  startDecorator="$"
                                  className={classes.revenueInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      revenueFilter: {
                                        ...prev.revenueFilter!,
                                        minRevenue: parseInt(
                                          e.target.value,
                                          10,
                                        ),
                                      },
                                    }))
                                  }
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel>Max</FormLabel>
                                <Input
                                  value={searchFormData.revenueFilter.maxRevenue?.toString()}
                                  startDecorator="$"
                                  className={classes.revenueInput}
                                  slotProps={{
                                    input: {
                                      inputMode: 'numeric',
                                      component: NumericFormatAdapter,
                                    },
                                  }}
                                  onChange={(e) =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      revenueFilter: {
                                        ...prev.revenueFilter!,
                                        maxRevenue: parseInt(
                                          e.target.value,
                                          10,
                                        ),
                                      },
                                    }))
                                  }
                                />
                              </FormControl>
                            </div>
                            <Checkbox
                              label="Exclude if revenue unknown"
                              checked={searchFormData.revenueFilter.mustHave}
                              onChange={() =>
                                setSearchFormData((prev) => ({
                                  ...prev,
                                  revenueFilter: {
                                    ...prev.revenueFilter!,
                                    mustHave: !prev.revenueFilter!.mustHave,
                                  },
                                }))
                              }
                            />
                          </Card>
                        )}
                        {searchFormData.fundingFilter && (
                          <Card className={classes.filterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <TrendingUp />
                              <b>Funding (Software)</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      fundingFilter: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <Switch
                              startDecorator="Bootstrapped"
                              endDecorator="Funded"
                              checked={
                                searchFormData.fundingFilter.funded != null
                              }
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    fundingFilter: {
                                      funded: {
                                        minFunding: 1000000,
                                        maxFunding: 1000000000,
                                      },
                                      mustHave:
                                        prev.fundingFilter?.mustHave ?? false,
                                    },
                                  }));
                                } else {
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    fundingFilter: {
                                      mustHave:
                                        prev.fundingFilter?.mustHave ?? false,
                                    },
                                  }));
                                }
                              }}
                            />
                            <div className={classes.filterContentsRow}>
                              {searchFormData.fundingFilter.funded && (
                                <>
                                  <FormControl>
                                    <FormLabel>Min</FormLabel>
                                    <Input
                                      value={searchFormData.fundingFilter.funded.minFunding?.toString()}
                                      startDecorator="$"
                                      className={classes.revenueInput}
                                      slotProps={{
                                        input: {
                                          inputMode: 'numeric',
                                          component: NumericFormatAdapter,
                                        },
                                      }}
                                      onChange={(e) =>
                                        setSearchFormData((prev) => ({
                                          ...prev,
                                          fundingFilter: {
                                            ...prev.fundingFilter!,
                                            funded: {
                                              ...prev.fundingFilter!.funded!,
                                              minFunding: parseInt(
                                                e.target.value,
                                                10,
                                              ),
                                            },
                                          },
                                        }))
                                      }
                                    />
                                  </FormControl>
                                  <FormControl>
                                    <FormLabel>Max</FormLabel>
                                    <Input
                                      value={searchFormData.fundingFilter.funded.maxFunding?.toString()}
                                      startDecorator="$"
                                      className={classes.revenueInput}
                                      slotProps={{
                                        input: {
                                          inputMode: 'numeric',
                                          component: NumericFormatAdapter,
                                        },
                                      }}
                                      onChange={(e) =>
                                        setSearchFormData((prev) => ({
                                          ...prev,
                                          fundingFilter: {
                                            ...prev.fundingFilter!,
                                            funded: {
                                              ...prev.fundingFilter!.funded!,
                                              maxFunding: parseInt(
                                                e.target.value,
                                                10,
                                              ),
                                            },
                                          },
                                        }))
                                      }
                                    />
                                  </FormControl>
                                </>
                              )}
                            </div>
                            <Checkbox
                              label="Exclude if funding unknown"
                              checked={searchFormData.fundingFilter.mustHave}
                              onChange={() =>
                                setSearchFormData((prev) => ({
                                  ...prev,
                                  fundingFilter: {
                                    ...prev.fundingFilter!,
                                    mustHave: !prev.fundingFilter!.mustHave,
                                  },
                                }))
                              }
                            />
                          </Card>
                        )}
                        {searchFormData.geoFilter && (
                          <Card className={classes.geoFilterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <Map />
                              <b>Geography</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      geoFilter: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <div className={classes.simpleRow}>
                                <FormLabel>HQ (State)</FormLabel>
                              </div>
                              <Autocomplete
                                freeSolo
                                multiple
                                placeholder="NY, CA, TX"
                                value={searchFormData.geoFilter.hqStates}
                                autoSelect
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev!,
                                    geoFilter: {
                                      ...prev.geoFilter!,
                                      hqStates: val,
                                    },
                                  }))
                                }
                                onInputChange={(e, newVal) => {
                                  if (newVal.endsWith(',')) {
                                    (e.target as HTMLElement).blur();
                                    (e.target as HTMLElement).focus();
                                  }
                                }}
                                renderTags={(tags, getTagProps) =>
                                  tags.map((item, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      color="primary"
                                      onClick={undefined}
                                      endDecorator={
                                        <ChipDelete
                                          onDelete={
                                            getTagProps({ index }).onClick
                                          }
                                        />
                                      }
                                    >
                                      {item}
                                    </Chip>
                                  ))
                                }
                                slotProps={{
                                  input: {
                                    maxLength: 2,
                                  },
                                }}
                                options={[]}
                              />
                            </FormControl>
                            <FormControl className={classes.field}>
                              <FormLabel>Service Area (State)</FormLabel>
                              <Autocomplete
                                freeSolo
                                multiple
                                placeholder="NY, CA, TX"
                                value={
                                  searchFormData.geoFilter.serviceAreasStates
                                }
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev!,
                                    geoFilter: {
                                      ...prev.geoFilter!,
                                      serviceAreasStates: val.map((v) =>
                                        v.toUpperCase(),
                                      ),
                                    },
                                  }))
                                }
                                autoSelect
                                onInputChange={(e, newVal) => {
                                  if (newVal.endsWith(',')) {
                                    (e.target as HTMLElement).blur();
                                    (e.target as HTMLElement).focus();
                                  }
                                }}
                                renderTags={(tags, getTagProps) =>
                                  tags.map((item, index) => (
                                    <Chip
                                      {...getTagProps({ index })}
                                      color="primary"
                                      onClick={undefined}
                                      endDecorator={
                                        <ChipDelete
                                          onDelete={
                                            getTagProps({ index }).onClick
                                          }
                                        />
                                      }
                                    >
                                      {item}
                                    </Chip>
                                  ))
                                }
                                options={[]}
                                slotProps={{
                                  input: {
                                    maxLength: 2,
                                  },
                                }}
                              />
                            </FormControl>
                            <Checkbox
                              label="Exclude if no service areas"
                              checked={
                                searchFormData.geoFilter.mustHaveServiceAreas
                              }
                              onChange={() =>
                                setSearchFormData((prev) => ({
                                  ...prev,
                                  geoFilter: {
                                    ...prev.geoFilter!,
                                    mustHaveServiceAreas:
                                      !prev.geoFilter!.mustHaveServiceAreas,
                                  },
                                }))
                              }
                            />
                          </Card>
                        )}
                        {searchFormData.excludeKeywords && (
                          <Card className={classes.filterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <Block />
                              <b>Exclude Keywords</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      excludeKeywords: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <FormLabel>Products & Services</FormLabel>
                              <div className={classes.inputRow}>
                                <Autocomplete
                                  freeSolo
                                  className={classes.autocomplete}
                                  placeholder="rigging, hvac, hydro blasting"
                                  multiple
                                  value={
                                    searchFormData.excludeKeywords
                                      .productServices
                                  }
                                  onChange={(e, val) =>
                                    setSearchFormData((prev) => ({
                                      ...prev!,
                                      excludeKeywords: {
                                        ...prev.excludeKeywords!,
                                        productServices: val,
                                      },
                                    }))
                                  }
                                  autoSelect
                                  onInputChange={(e, newVal) => {
                                    const inputEl =
                                      e.target as HTMLInputElement;
                                    if (newVal.endsWith(',')) {
                                      inputEl.blur();
                                      inputEl.focus();
                                    }
                                  }}
                                  options={[]}
                                  renderTags={(tags, getTagProps) =>
                                    tags.map((item, index) => (
                                      <Chip
                                        {...getTagProps({ index })}
                                        color="warning"
                                        onClick={undefined}
                                        endDecorator={
                                          <ChipDelete
                                            onDelete={
                                              getTagProps({ index }).onClick
                                            }
                                          />
                                        }
                                      >
                                        {item}
                                      </Chip>
                                    ))
                                  }
                                />
                              </div>
                            </FormControl>
                            <FormControl
                              className={classes.field}
                              error={hasError}
                            >
                              <FormLabel>End Customer</FormLabel>
                              <div className={classes.inputRow}>
                                <Autocomplete
                                  freeSolo
                                  multiple
                                  autoSelect
                                  className={classes.autocomplete}
                                  placeholder="industrial companies, construction, municipal"
                                  value={
                                    searchFormData.excludeKeywords
                                      .customerSegments
                                  }
                                  onChange={(e, val) =>
                                    setSearchFormData((prev) => ({
                                      ...prev!,
                                      excludeKeywords: {
                                        ...prev.excludeKeywords!,
                                        customerSegments: val,
                                      },
                                    }))
                                  }
                                  onInputChange={(e, newVal) => {
                                    if (newVal.endsWith(',')) {
                                      (e.target as HTMLElement).blur();
                                      (e.target as HTMLElement).focus();
                                    }
                                  }}
                                  renderTags={(tags, getTagProps) =>
                                    tags.map((item, index) => (
                                      <Chip
                                        {...getTagProps({ index })}
                                        color="success"
                                        onClick={undefined}
                                        endDecorator={
                                          <ChipDelete
                                            onDelete={
                                              getTagProps({ index }).onClick
                                            }
                                          />
                                        }
                                      >
                                        {item}
                                      </Chip>
                                    ))
                                  }
                                  options={[]}
                                />
                              </div>
                            </FormControl>
                          </Card>
                        )}
                        {searchFormData.excludeLists && (
                          <Card className={classes.filterContents}>
                            <Typography
                              level="body-md"
                              className={classes.filterTitle}
                            >
                              <PlaylistRemove />
                              <b>Exclude Lists</b>
                              <Tooltip title="Remove filter">
                                <IconButton
                                  size="sm"
                                  variant="outlined"
                                  color="danger"
                                  className={classes.deleteFilterButton}
                                  onClick={() =>
                                    setSearchFormData((prev) => ({
                                      ...prev,
                                      excludeLists: undefined,
                                    }))
                                  }
                                >
                                  <Close className={classes.deleteFilterIcon} />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                            <FormControl className={classes.field}>
                              <FormLabel>
                                Lists to exclude from results
                              </FormLabel>
                              <Select
                                multiple
                                value={searchFormData.excludeLists}
                                onChange={(e, val) =>
                                  setSearchFormData((prev) => ({
                                    ...prev,
                                    excludeLists: val,
                                  }))
                                }
                              >
                                {lists?.map((l) => (
                                  <Option value={l.id}>{l.name}</Option>
                                ))}
                              </Select>
                            </FormControl>
                          </Card>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={classes.searchParams}>
                    <Typography level="h4" className={classes.cardTitle}>
                      Advanced Options
                    </Typography>
                    <div className={classes.searchParamsContent}>
                      <div className={classes.searchParamsCategory}>
                        <Typography level="body-md">
                          <b>Text</b>
                        </Typography>
                        <FormControl>
                          <ToggleButtonGroup
                            value={searchFormData.textSearchWeight.toString()}
                            onChange={(e, val) =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                textSearchWeight: parseInt(val!, 10),
                              }))
                            }
                          >
                            <Button value="1" className={classes.selectButton}>
                              Low
                            </Button>
                            <Button value="2" className={classes.selectButton}>
                              Mid
                            </Button>
                            <Button value="3" className={classes.selectButton}>
                              High
                            </Button>
                          </ToggleButtonGroup>
                        </FormControl>
                        <Checkbox
                          label="Must match"
                          checked={searchFormData.mustHaveTextMatch}
                          onChange={(e) =>
                            setSearchFormData((prev) => ({
                              ...prev,
                              mustHaveTextMatch: e.target.checked,
                            }))
                          }
                        />
                      </div>
                      <div className={classes.searchParamsCategory}>
                        <Typography level="body-md">
                          <b>Semantic</b>
                        </Typography>
                        <FormControl>
                          <ToggleButtonGroup
                            value={searchFormData.semanticSearchWeight.toString()}
                            onChange={(e, val) =>
                              setSearchFormData((prev) => ({
                                ...prev,
                                semanticSearchWeight: parseInt(val!, 10),
                              }))
                            }
                          >
                            <Button value="1" className={classes.selectButton}>
                              Low
                            </Button>
                            <Button value="2" className={classes.selectButton}>
                              Mid
                            </Button>
                            <Button value="3" className={classes.selectButton}>
                              High
                            </Button>
                          </ToggleButtonGroup>
                        </FormControl>
                        <Checkbox
                          label="Must match"
                          checked={searchFormData.mustHaveSemanticMatch}
                          onChange={(e) =>
                            setSearchFormData((prev) => ({
                              ...prev,
                              mustHaveSemanticMatch: e.target.checked,
                            }))
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.searchButtons}>
                  <Button
                    variant="outlined"
                    onClick={onClear}
                    size="lg"
                    color="danger"
                  >
                    <Clear />
                    Clear
                  </Button>
                  <Button onClick={() => onSearch()} size="lg" color="success">
                    <SearchIcon />
                    Search
                  </Button>
                </div>
              </Card>
              {searchResults || loadingSearchResults ? (
                <div className={classes.searchResults}>
                  <SearchResults
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    loadingSearchResults={loadingSearchResults}
                    hasDescription={currentSearchQuery!.description.length > 0}
                    results={searchResults}
                    pageNum={currentSearchQuery!.pageNum}
                    numCurrentQueryProductServices={
                      currentSearchQuery!.productsAndServices.length
                    }
                    numCurrentQueryCustomerSegments={
                      currentSearchQuery!.customerSegments.length
                    }
                    numCurrentQueryServiceAreas={
                      currentSearchQuery!.geoFilter?.serviceAreasStates
                        .length ?? 0
                    }
                    setSearchFormData={setSearchFormData}
                    setPage={(pageNum: number) =>
                      setCurrentSearchQuery((prev) => ({ ...prev!, pageNum }))
                    }
                    setSearchResults={setSearchResults}
                    performSearch={performSearch}
                    lists={lists}
                    autopilots={autopilots}
                    refetchListsAndAutopilots={fetchLists}
                    addAllToList={addAllToList}
                    addAllToAutopilot={addAllToAutopilot}
                  />
                </div>
              ) : (
                <Card variant="plain" className={classes.nullState}>
                  <Typography level="h3">
                    Start a Thesis-Driven Business Search
                  </Typography>
                  <Card className={classes.nullStateCard}>
                    <Typography level="body-lg">
                      1. If you don&apos;t have a specific criteria in mind,
                      start with an industry, vertical, or technology that
                      you&apos;re interested in and click "Search".
                    </Typography>
                  </Card>
                  <Card className={classes.nullStateCard}>
                    <Typography level="body-lg">
                      2. Chisel down your thesis by adding Products & Services
                      or End Markets served to your search criteria
                    </Typography>
                  </Card>
                  <Card className={classes.nullStateCard}>
                    <Typography level="body-lg">
                      3. Add businesses that interest you to a list
                    </Typography>
                  </Card>
                  <Card className={classes.nullStateCard}>
                    <Typography level="body-lg">
                      4. Add enrichments to your list to find contact info of a
                      business owner, have an AI agent research and answer
                      questions about a business, or draft a personalized email
                    </Typography>
                  </Card>
                </Card>
              )}
            </div>
          </div>
        </div>
      </LoadingContainer>
    </>
  );
}
