import { ColorPaletteProp, Snackbar } from '@mui/joy';
import React, { createContext, useCallback, useState } from 'react';

interface SnackbarOptions {
  color: ColorPaletteProp;
  message: string;
}

interface SnackbarContextInterface {
  showSnackbar: (props: SnackbarOptions) => void;
}

export const SnackbarContext = createContext<SnackbarContextInterface>({
  showSnackbar: () => {},
});

interface Props {
  children: React.ReactNode | React.ReactNode[];
}

export function SnackbarContextProvider({ children }: Props) {
  const [, setQueue] = useState<SnackbarOptions[]>([]);
  const [current, setCurrent] = useState<SnackbarOptions | null>(null);
  const showSnackbar = useCallback((props: SnackbarOptions) => {
    setCurrent((c) => {
      if (c) {
        setQueue((q) => [...q, props]);
        return c;
      }
      return props;
    });
  }, []);
  const onClose = useCallback(() => {
    setQueue((q) => {
      if (q.length === 0) {
        setCurrent(null);
        return q;
      }
      setCurrent(q[0]);
      return q.slice(1);
    });
  }, []);
  return (
    <SnackbarContext.Provider
      value={{
        showSnackbar,
      }}
    >
      {children}
      {current && (
        <Snackbar
          color={current.color}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open
          variant="solid"
          onClose={onClose}
          autoHideDuration={3000}
        >
          {current.message}
        </Snackbar>
      )}
    </SnackbarContext.Provider>
  );
}
