import React from 'react';
import { tss } from 'tss-react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 300,
    padding: theme.spacing(2),
  },
}));

type Props = {
  id: string;
  currentName: string;
  open: boolean;
  onClose: () => void;
};

export default function RenameModal({ id, currentName, open, onClose }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      name: currentName,
    },
  });
  const api = getAPI();
  const onCreate: SubmitHandler<{
    name: string;
  }> = async (data) => {
    await api.autopilot.renameCampaign(id, {
      name: data.name,
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level="h4">Rename Campaign</Typography>
        <ControlledInput
          required
          label="Name"
          name="name"
          control={control}
          error={errors.name}
        />
        <Button onClick={handleSubmit(onCreate)}>Submit</Button>
      </ModalDialog>
    </Modal>
  );
}
