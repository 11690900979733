import React from 'react';
import { AddOnInfo, SponsorInfo } from 'src/generated/api';
import LoadingContainer from 'src/components/LoadingContainer';
import FinancialBuyersList from 'src/components/FinancialBuyersList';
import { getAPI } from 'src/api';

type Props = {
  projectId: string;
  potentialBuyers?:
    | {
        matchNotes: string;
        /** @format double */
        matchScore: number;
        addOnsInfo: AddOnInfo[] | null;
        sponsorsInfo: SponsorInfo[] | null;
        acquirerDescription: string | null;
        acquirerWebsite: string;
        acquirerName: string;
      }[]
    | null;
  loading: boolean;
};

export default function FinancialBuyersTabContent({
  projectId,
  potentialBuyers,
  loading,
}: Props) {
  const api = getAPI();

  const handleExportCSV = async () => {
    const res = await api.prospects.getBuyerListCsv(projectId, {
      format: 'arrayBuffer',
    });
    const blob = new Blob([res.data], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'buyers.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <LoadingContainer isLoading={loading} loadingText="Generating Buyers...">
      <FinancialBuyersList
        potentialBuyers={potentialBuyers}
        handleDownloadCSV={handleExportCSV}
      />
    </LoadingContainer>
  );
}
