import React, { useState, useCallback, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { tss } from 'tss-react';
import {
  Theme,
  useTheme,
  Typography,
  IconButton,
  CircularProgress,
} from '@mui/joy';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.mjs`;

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  navigation: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  pageInfo: {
    margin: theme.spacing(0, 2),
  },
}));

type PDFPageViewerProps = {
  numPages: number;
  width: number;
  showNavigation?: boolean;
};

function PDFPageViewer({
  width,
  numPages,
  showNavigation = true,
}: PDFPageViewerProps) {
  const theme = useTheme();
  const [pageNumber, setPageNumber] = useState(1);
  const { classes } = useStyles({ theme });

  function changePage(offset: number) {
    setPageNumber((prevPageNumber) =>
      Math.min(Math.max(1, prevPageNumber + offset), numPages),
    );
  }

  return (
    <div className={classes.container}>
      <Page
        pageNumber={pageNumber}
        width={width}
        error={null}
        loading={
          <div
            className={classes.loadingContainer}
            style={{ width, height: width / 2 }}
          >
            <CircularProgress />
          </div>
        }
      />
      {showNavigation && (
        <div className={classes.navigation}>
          <IconButton
            variant="soft"
            size="lg"
            onClick={() => changePage(-1)}
            disabled={pageNumber <= 1}
          >
            <ChevronLeft />
          </IconButton>
          <Typography className={classes.pageInfo}>
            Page {pageNumber} of {numPages}
          </Typography>
          <IconButton
            variant="soft"
            size="lg"
            onClick={() => changePage(1)}
            disabled={pageNumber >= numPages}
          >
            <ChevronRight />
          </IconButton>
        </div>
      )}
    </div>
  );
}

type PDFViewerProps = {
  url: string;
  width: number;
  showNavigation?: boolean;
  refreshTrigger?: number;
  customHeaders?: Record<string, string>;
  withCredentials?: boolean;
};

function PDFViewer({
  url,
  width,
  showNavigation = true,
  customHeaders,
  refreshTrigger,
  withCredentials = true,
}: PDFViewerProps) {
  const theme = useTheme();
  const [numPages, setNumPages] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { classes } = useStyles({ theme });

  const onDocumentLoadSuccess = useCallback(
    ({ numPages: n }: { numPages: number }) => {
      setNumPages(n);
      setIsLoading(false);
    },
    [],
  );

  useEffect(() => {
    setIsLoading(true);
    setNumPages(null);
  }, [refreshTrigger]);

  return (
    <div className={classes.container}>
      {isLoading && (
        <div
          className={classes.loadingContainer}
          style={{ width, height: width / 2 }}
        >
          <CircularProgress />
          <Typography level="body-sm" sx={{ ml: 2 }}>
            Loading PDF...
          </Typography>
        </div>
      )}
      <Document
        key={refreshTrigger}
        file={url}
        options={{
          withCredentials,
          httpHeaders: customHeaders,
        }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={null}
      >
        {numPages !== null && !isLoading && (
          <PDFPageViewer
            numPages={numPages}
            width={width}
            showNavigation={showNavigation}
          />
        )}
      </Document>
    </div>
  );
}

export default React.memo(PDFViewer);
