import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAPI } from 'src/api';
import { GetProjectResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import {
  Theme,
  useTheme,
  Typography,
  IconButton,
  Chip,
  Table,
  CircularProgress,
  Card,
} from '@mui/joy';
import { ChevronLeft } from '@mui/icons-material';
import { uploadedLink } from 'src/utils';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  section: {
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  dataRow: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  label: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    minWidth: '200px',
  },
  chipContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '8px',
  },
  longFormText: {
    whiteSpace: 'pre-wrap',
  },
  sectionCard: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    boxShadow: theme.shadow.lg,
  },
  leadershipEntry: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    width: '100%',
  },
  leadershipEntryCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    flex: 1,
  },
  uploadZone: {
    height: 200,
    width: 200,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
  uploadedImage: {
    height: 200,
    width: 200,
    objectFit: 'cover',
  },
}));

const formatCurrency = (value: number | null | undefined): string => {
  if (value == null) return 'N/A';
  return `$${value.toLocaleString()}`;
};

export default function Intake() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState<GetProjectResponse>();
  const api = getAPI();
  const fetch = useCallback(async () => {
    const res = await api.clients.getProject(id!);
    setData(res.data);
  }, [id, api]);
  useEffect(() => {
    fetch();
  }, [fetch]);
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  if (!data || !data.intakeData) {
    return (
      <div className={classes.container}>
        <CircularProgress />
      </div>
    );
  }

  const { intakeData } = data;

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton onClick={() => navigate(`/clients/${id}`)}>
          <ChevronLeft />
        </IconButton>
        <Typography level="h2">Intake Data</Typography>
      </div>
      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          General Information
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Business Name:</Typography>
          <Typography>{intakeData.businessName || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Contact Name:</Typography>
          <Typography>{intakeData.contactName || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Contact Title:</Typography>
          <Typography>{intakeData.contactTitle || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Contact Work Email:</Typography>
          <Typography>{intakeData.contactWorkEmail || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Contact Personal Email:
          </Typography>
          <Typography>{intakeData.contactPersonalEmail || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Contact Phone Number:
          </Typography>
          <Typography>{intakeData.contactPhoneNumber || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Year Founded:</Typography>
          <Typography>{intakeData.yearFounded || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Incorporation State:
          </Typography>
          <Typography>{intakeData.incorporationState || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>HQ City:</Typography>
          <Typography>{intakeData.hqCity || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>HQ State:</Typography>
          <Typography>{intakeData.hqState || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Other Locations:</Typography>
          <div className={classes.chipContainer}>
            {intakeData.otherLocations?.map((location, index) => (
              <Chip key={index} color="primary" variant="soft">
                {location}
              </Chip>
            )) || 'N/A'}
          </div>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Corp Type:</Typography>
          <Typography>{intakeData.corpType || 'N/A'}</Typography>
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Company Overview
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Company Overview:</Typography>
          <Typography className={classes.longFormText}>
            {intakeData.companyOverview || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Objective Description:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.objectiveDescription || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Valuation Expectation:
          </Typography>
          <Typography>{intakeData.valuationExpectation || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Primary Product Description:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.primaryProductDescription || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Number of Customers:
          </Typography>
          <Typography>{intakeData.numCustomers || 'N/A'}</Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Founder Post-Transaction Plan:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.founderPostTransactionPlan || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            CEO Post-Transaction Plan:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.ceoPostTransactionPlan || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Other Post-Transaction Plan:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.otherPostTransactionPlan || 'N/A'}
          </Typography>
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Headcount
        </Typography>
        <Table>
          <thead>
            <tr>
              <th>Role</th>
              <th>Full-Time</th>
              <th>Part-Time</th>
              <th>Contractors</th>
            </tr>
          </thead>
          <tbody>
            {intakeData.headcountByRole?.map((role, index) => (
              <tr key={index}>
                <td>{role.role}</td>
                <td>{role.numFTEs}</td>
                <td>{role.numPTEs}</td>
                <td>{role.numContractors}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Leadership
        </Typography>
        {intakeData.leadershipBios?.map((leader, index) => (
          <div key={index} className={classes.leadershipEntry}>
            {leader.pictureFileId && (
              <div className={classes.uploadZone}>
                <img
                  src={uploadedLink(leader.pictureFileId)}
                  alt={`Leader ${index + 1}`}
                  className={classes.uploadedImage}
                />
              </div>
            )}
            <div className={classes.leadershipEntryCol}>
              <Typography className={classes.label}>
                Leadership Bio {index + 1}:
              </Typography>
              <Typography className={classes.longFormText}>
                {leader.bio}
              </Typography>
            </div>
          </div>
        ))}
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Customer Information
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Retention Description:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.retentionDescription || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Revenue Geo Distribution:
          </Typography>
          {intakeData.showRevenueGeo ? (
            <div className={classes.chipContainer}>
              {intakeData.revenueGeoDistribution?.map((geo, index) => (
                <Chip key={index} color="primary" variant="soft">
                  {`${geo.name}: ${geo.percentage}%`}
                </Chip>
              )) || 'N/A'}
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Customer Concentration:
          </Typography>
          {intakeData.showCustomerConcentration ? (
            <div>
              <div className={classes.chipContainer}>
                {intakeData.topCustomersByRevenue?.map((revenue, index) => (
                  <Chip key={index} color="neutral" variant="soft">
                    {revenue}%
                  </Chip>
                ))}
              </div>
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Concentration Details:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.revenueConcentrationDetails || 'N/A'}
          </Typography>
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          GTM and Growth
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Customer Acquisition Channels:
          </Typography>
          {intakeData.showCustomerAcquisition ? (
            <div className={classes.chipContainer}>
              {intakeData.acquisitionChannels?.map((channel, index) => (
                <Chip key={index} color="primary" variant="soft">
                  {`${channel.name}: ${channel.percentage}%`}
                </Chip>
              )) || 'N/A'}
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Customer Acquisition Details:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.customerAcquisitionDetails || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Growth Opportunities:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.growthOpportunitiesDetails || 'N/A'}
          </Typography>
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Industry Information
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Verticals:</Typography>
          <div className={classes.chipContainer}>
            {intakeData.verticals?.map((vertical, index) => (
              <Chip key={index} color="success" variant="soft">
                {vertical}
              </Chip>
            ))}
          </div>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Vendors and Partners:
          </Typography>
          {intakeData.showVendorsAndPartners ? (
            <div className={classes.chipContainer}>
              {intakeData.vendersAndPartners?.map((vendor, index) => (
                <Chip key={index} color="warning" variant="soft">
                  {vendor}
                </Chip>
              ))}
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Financial Information
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Financial Info:</Typography>
          <Table>
            <thead>
              <tr>
                <th>Period</th>
                <th>Revenue</th>
                <th>Gross Profit</th>
                <th>Adj EBITDA</th>
              </tr>
            </thead>
            <tbody>
              {intakeData.financialInfo?.map((info, index) => (
                <tr key={index}>
                  <td>{info.period}</td>
                  <td>{formatCurrency(info.revenue)}</td>
                  <td>{formatCurrency(info.grossProfit)}</td>
                  <td>{formatCurrency(info.adjEbitda)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>EBITDA Adjustments:</Typography>
          <Typography className={classes.longFormText}>
            {intakeData.ebitdaAdjustments || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Revenue Mix:</Typography>
          {intakeData.showRevenueMix ? (
            <div>
              <div className={classes.chipContainer}>
                {intakeData.revenueMix?.map((mix, index) => (
                  <Chip key={index} color="primary" variant="soft">
                    {`${mix.name}: ${mix.percentage}%`}
                  </Chip>
                ))}
              </div>
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Revenue Notes:</Typography>
          <Typography className={classes.longFormText}>
            {intakeData.revenueNotes || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Recurring Revenue:</Typography>
          {intakeData.showRecurringRevenue ? (
            <div>
              <Table>
                <thead>
                  <tr>
                    <th>Period</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {intakeData.recurringRevenue?.map((revenue, index) => (
                    <tr key={index}>
                      <td>{revenue.period}</td>
                      <td>{formatCurrency(revenue.amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Recurring Revenue Notes:
          </Typography>
          <Typography className={classes.longFormText}>
            {intakeData.recurringRevenueNotes || 'N/A'}
          </Typography>
        </div>
      </Card>

      <Card className={classes.sectionCard} variant="plain">
        <Typography level="h3" className={classes.sectionTitle}>
          Additional Information
        </Typography>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Additional Info:</Typography>
          <Typography className={classes.longFormText}>
            {intakeData.additionalInfo || 'N/A'}
          </Typography>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>
            Other Investment Considerations:
          </Typography>
          <div className={classes.chipContainer}>
            {intakeData.otherInvestmentConsiderations?.map(
              (consideration, index) => (
                <Chip key={index} color="primary" variant="soft">
                  {consideration}
                </Chip>
              ),
            ) || 'N/A'}
          </div>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Prospective Buyers:</Typography>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Website</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {intakeData.prospectiveBuyers?.map((buyer, index) => (
                <tr key={index}>
                  <td>{buyer.name}</td>
                  <td>{buyer.website}</td>
                  <td>{buyer.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Buyer Exclusions:</Typography>
          <Table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Website</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {intakeData.buyerExclusions?.map((buyer, index) => (
                <tr key={index}>
                  <td>{buyer.name}</td>
                  <td>{buyer.website}</td>
                  <td>{buyer.email}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Awards:</Typography>
          {intakeData.showAwards ? (
            <div className={classes.chipContainer}>
              {intakeData.awards?.map((award, index) => (
                <Chip key={index} color="primary" variant="soft">
                  {award}
                </Chip>
              )) || 'N/A'}
            </div>
          ) : (
            <Typography>N/A</Typography>
          )}
        </div>
        <div className={classes.dataRow}>
          <Typography className={classes.label}>Certifications:</Typography>
          <div className={classes.chipContainer}>
            {intakeData.certifications?.map((certification, index) => (
              <Chip key={index} color="primary" variant="soft">
                {certification}
              </Chip>
            )) || 'N/A'}
          </div>
        </div>
      </Card>
    </div>
  );
}
