import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 300,
    padding: theme.spacing(2),
  },
}));

type Props = {
  // id: string;
  prospect: {
    id: string;
    name: string;
  };
  open: boolean;
  onClose: () => void;
};

export default function RenameProspectModal({
  prospect,
  open,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      name: prospect.name,
    },
  });

  const [loading, setLoading] = useState(false);

  const onCreate: SubmitHandler<{
    name: string;
  }> = async (data) => {
    const api = getAPI();
    setLoading(true);
    await api.prospects.updateProspectName(prospect.id, {
      name: data.name,
    });
    setLoading(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level="h4">Rename Prospect</Typography>
        <ControlledInput
          required
          label="Name"
          name="name"
          control={control}
          error={errors.name}
        />
        <Button loading={loading} onClick={handleSubmit(onCreate)}>
          Submit
        </Button>
      </ModalDialog>
    </Modal>
  );
}
