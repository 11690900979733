import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Business,
  ChevronLeft,
  HourglassTop,
  NotStarted,
  Save,
  ScheduleSend,
  Science,
  Settings,
  Tune,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { tss } from 'tss-react';
import {
  Button,
  ButtonGroup,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  Select,
  Theme,
  Typography,
  useTheme,
  Option,
  Tooltip,
} from '@mui/joy';
import {
  GetAutopilotManageResponse,
  Type24EnumsDayOfWeek,
} from 'src/generated/api';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import TimeSelect from 'src/components/TimeSelect';
import { SnackbarContext } from 'src/contexts/snackbar';
import FiltersModal from './FiltersModal';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  contents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 900,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(6),
    marginTop: theme.spacing(3),
  },
  list: {
    boxShadow: theme.shadow.lg,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    borderRadius: theme.spacing(2),
    justifyContent: 'space-between',
  },
  listItem: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    cursor: 'pointer',
    width: '100%',
  },
  listItemRight: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    marginLeft: 'auto',
  },
  listItemRow: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  settings: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    boxShadow: theme.shadow.lg,
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
  settingsRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  textInput: {
    width: 80,
  },
  windowInput: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  runOnField: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  runOnOptions: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    maxWidth: '75%',
    justifyContent: 'flex-end',
  },
  dayButton: {
    width: 56,
  },
  selectedDay: {
    // So the size doesnt change from outlined -> solid
    paddingTop: 7,
    paddingBottom: 7,
  },
  saveButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    gap: theme.spacing(1),
  },
  buttonsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

export default function AutopilotManage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { showSnackbar } = useContext(SnackbarContext);
  const { id } = useParams();
  const { classes, cx } = useStyles({ theme });
  const [showFiltersModal, setShowFiltersModal] = useState(false);
  const [autopilotData, setAutopilotData] =
    useState<GetAutopilotManageResponse | null>(null);
  const api = getAPI();
  const fetchData = useCallback(async () => {
    const res = await api.autopilot.getAutopilotManage(id!);
    setAutopilotData(res.data);
  }, [api, id]);
  const saveConfig = useCallback(async () => {
    await api.autopilot.configureAutopilot(id!, {
      maxBusinessReviewedPerDay: autopilotData!.maxBusinessReviewedPerDay,
      maxSuccessPerDay: autopilotData!.maxSuccessPerDay,
      daysActive: autopilotData!.daysActive,
      timeWindowTimeZone: autopilotData!.timeWindowTimeZone,
      timeWindowStartHour: autopilotData!.timeWindowStartHour,
      timeWindowEndHour: autopilotData!.timeWindowEndHour,
      autoApprove: autopilotData!.autoApprove,
      sendNewEmails: autopilotData!.sendNewEmails,
      processNewBusinesses: autopilotData!.processNewBusinesses,
    });
    showSnackbar({
      message: 'Saved Autopilot Configuration',
      color: 'success',
    });
  }, [api, id, autopilotData, showSnackbar]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  let notStartedCount = 0;
  let passedCount = 0;
  let approvedCount = 0;
  let researched = 0;
  let startedCount = 0;
  if (autopilotData?.statusCounts) {
    notStartedCount = autopilotData.statusCounts.NOT_STARTED!;
    passedCount =
      autopilotData.statusCounts.WAITING_FOR_APPROVAL! +
      autopilotData.statusCounts.APPROVED! +
      autopilotData.statusCounts.SEQUENCE_STARTED! +
      autopilotData.statusCounts.SEQUENCE_COMPLETED!;
    researched =
      passedCount +
      autopilotData.statusCounts.FAILED_REQ! +
      autopilotData.statusCounts.NO_CONTACT_INFO! +
      autopilotData.statusCounts.PROCESSING!;
    approvedCount =
      passedCount - autopilotData.statusCounts.WAITING_FOR_APPROVAL!;
    startedCount =
      autopilotData.statusCounts.SEQUENCE_STARTED! +
      autopilotData.statusCounts.SEQUENCE_COMPLETED!;
  }
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <IconButton onClick={() => navigate(`/autopilot/${id}`)}>
            <ChevronLeft />
          </IconButton>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <Settings />
              Manage Autopilot
            </Typography>
            <Typography level="body-lg">
              Don&apos;t change anything here without talking to your OffDeal
              representative.
            </Typography>
          </div>
        </div>
        <LoadingContainer isLoading={autopilotData == null}>
          <div className={classes.contentRow}>
            <List className={classes.list}>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(`/autopilot/${id}/manage/pipeline`)}
                >
                  <div className={classes.listItem}>
                    <div className={classes.listItemRow}>
                      <Typography level="h4">All Businesses</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        <Business />
                        <b>{autopilotData?.size}</b>
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Researched Today</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {autopilotData?.numResearchedToday}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Matched Today</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {autopilotData?.numMatchesToday}
                      </Typography>
                    </div>
                    {autopilotData && autopilotData.numResearchedToday > 0 && (
                      <div className={classes.listItemRow}>
                        <Typography level="body-lg">
                          Match Rate (Today)
                        </Typography>
                        <Typography
                          level="body-lg"
                          className={classes.listItemRight}
                        >
                          {Math.floor(
                            (autopilotData.numMatchesToday /
                              autopilotData.numResearchedToday) *
                              100,
                          )}
                          %
                        </Typography>
                      </div>
                    )}
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(`/autopilot/${id}/manage/queue`)}
                >
                  <div className={classes.listItem}>
                    <div className={classes.listItemRow}>
                      <Typography level="h4">Not Started</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        <NotStarted />
                        <b>{notStartedCount}</b>
                      </Typography>
                    </div>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(`/autopilot/${id}/manage/research`)}
                >
                  <div className={classes.listItem}>
                    <div className={classes.listItemRow}>
                      <Typography level="h4">Researched</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        <Science />
                        <b>{researched}</b>
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Processing</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {autopilotData?.statusCounts.PROCESSING}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Passed</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                        color="success"
                      >
                        {passedCount}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">
                        Failed Requirements
                      </Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                        color="danger"
                      >
                        {autopilotData?.statusCounts.FAILED_REQ}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">No Contact Info</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                        color="danger"
                      >
                        {autopilotData?.statusCounts.NO_CONTACT_INFO}
                      </Typography>
                    </div>
                  </div>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(`/autopilot/${id}/manage/engage`)}
                >
                  <div className={classes.listItem}>
                    <div className={classes.listItemRow}>
                      <Typography level="h4" color="warning">
                        Waiting For Approval
                      </Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                        color="warning"
                      >
                        <HourglassTop />
                        <b>
                          {autopilotData?.statusCounts.WAITING_FOR_APPROVAL}
                        </b>
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="h4" color="success">
                        Approved
                      </Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                        color="success"
                      >
                        <ScheduleSend />
                        <b>{approvedCount}</b>
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">
                        Email Sequence Started
                      </Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {startedCount}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Replied</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {autopilotData?.statusCounts.REPLIED}
                      </Typography>
                    </div>
                    <div className={classes.listItemRow}>
                      <Typography level="body-lg">Completed</Typography>
                      <Typography
                        level="body-lg"
                        className={classes.listItemRight}
                      >
                        {autopilotData?.statusCounts.SEQUENCE_COMPLETED}
                      </Typography>
                    </div>
                  </div>
                </ListItemButton>
              </ListItem>
            </List>
            <div className={classes.settings}>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Find More Matches</b>
                </Typography>
                <div className={classes.buttonsRow}>
                  <ButtonGroup>
                    <Button
                      color="success"
                      variant={
                        autopilotData?.processNewBusinesses
                          ? 'solid'
                          : 'outlined'
                      }
                      onClick={() =>
                        setAutopilotData((prev) => ({
                          ...prev!,
                          processNewBusinesses: true,
                        }))
                      }
                    >
                      On
                    </Button>
                    <Button
                      color="neutral"
                      variant={
                        autopilotData?.processNewBusinesses
                          ? 'outlined'
                          : 'solid'
                      }
                      onClick={() =>
                        setAutopilotData((prev) => ({
                          ...prev!,
                          processNewBusinesses: false,
                        }))
                      }
                    >
                      Off
                    </Button>
                  </ButtonGroup>
                  <Tooltip title="Modify Filters">
                    <Button
                      variant="outlined"
                      onClick={() => setShowFiltersModal(true)}
                    >
                      <Tune />
                    </Button>
                  </Tooltip>
                </div>
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Automatically Send Emails</b>
                </Typography>
                <ButtonGroup>
                  <Button
                    color="success"
                    variant={
                      autopilotData?.sendNewEmails ? 'solid' : 'outlined'
                    }
                    onClick={() =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        sendNewEmails: true,
                      }))
                    }
                  >
                    On
                  </Button>
                  <Button
                    color="neutral"
                    variant={
                      autopilotData?.sendNewEmails ? 'outlined' : 'solid'
                    }
                    onClick={() =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        sendNewEmails: false,
                      }))
                    }
                  >
                    Off
                  </Button>
                </ButtonGroup>
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Auto-approve Matches</b>
                </Typography>
                <ButtonGroup>
                  <Button
                    color="success"
                    variant={autopilotData?.autoApprove ? 'solid' : 'outlined'}
                    onClick={() =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        autoApprove: true,
                      }))
                    }
                  >
                    On
                  </Button>
                  <Button
                    color="neutral"
                    variant={autopilotData?.autoApprove ? 'outlined' : 'solid'}
                    onClick={() =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        autoApprove: false,
                      }))
                    }
                  >
                    Off
                  </Button>
                </ButtonGroup>
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Daily Criteria Matches</b>
                </Typography>
                <Input
                  type="number"
                  size="md"
                  className={classes.textInput}
                  value={autopilotData?.maxSuccessPerDay ?? 0}
                  onChange={(e) => {
                    setAutopilotData((prev) => ({
                      ...prev!,
                      maxSuccessPerDay: parseInt(e.target.value, 10),
                    }));
                  }}
                />
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Max Daily Researched</b>
                </Typography>
                <Input
                  type="number"
                  size="md"
                  className={classes.textInput}
                  value={autopilotData?.maxBusinessReviewedPerDay ?? 0}
                  onChange={(e) => {
                    setAutopilotData((prev) => ({
                      ...prev!,
                      maxBusinessReviewedPerDay: parseInt(e.target.value, 10),
                    }));
                  }}
                />
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Email Window</b>
                </Typography>
                <div className={classes.windowInput}>
                  <TimeSelect
                    value={autopilotData?.timeWindowStartHour ?? 8}
                    setValue={(val) =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        timeWindowStartHour: val,
                      }))
                    }
                  />
                  <TimeSelect
                    value={autopilotData?.timeWindowEndHour ?? 18}
                    setValue={(val) =>
                      setAutopilotData((prev) => ({
                        ...prev!,
                        timeWindowEndHour: val,
                      }))
                    }
                  />
                </div>
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Time Zone</b>
                </Typography>
                <Select
                  value={autopilotData?.timeWindowTimeZone ?? ''}
                  onChange={(e, val) =>
                    setAutopilotData((prev) => ({
                      ...prev!,
                      timeWindowTimeZone: val!,
                    }))
                  }
                >
                  {
                    // @ts-expect-error This actually exists
                    Intl.supportedValuesOf('timeZone').map((val: string) => (
                      <Option value={val}>
                        {val} (
                        {
                          new Intl.DateTimeFormat('en', {
                            timeZone: val,
                            timeZoneName: 'shortOffset',
                          })
                            .formatToParts()
                            .find((part) => part.type === 'timeZoneName')!.value
                        }
                        )
                      </Option>
                    ))
                  }
                </Select>
              </div>
              <div className={classes.settingsRow}>
                <Typography level="body-md">
                  <b>Run On</b>
                </Typography>
                <div className={classes.runOnOptions}>
                  <Button
                    size="sm"
                    color="primary"
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.SUNDAY,
                      ),
                    })}
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.SUNDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.SUNDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.SUNDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.SUNDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Sun
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.MONDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.MONDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.MONDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.MONDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.MONDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Mon
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.TUESDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.TUESDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.TUESDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.TUESDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.TUESDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Tue
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.WEDNESDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.WEDNESDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.WEDNESDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.WEDNESDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.WEDNESDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Wed
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.THURSDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.THURSDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.THURSDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.THURSDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.THURSDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Thu
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.FRIDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.FRIDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.FRIDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.FRIDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.FRIDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Fri
                  </Button>
                  <Button
                    color="primary"
                    variant={
                      autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.SATURDAY,
                      )
                        ? 'solid'
                        : 'outlined'
                    }
                    className={cx(classes.dayButton, {
                      [classes.selectedDay]: autopilotData?.daysActive.includes(
                        Type24EnumsDayOfWeek.SATURDAY,
                      ),
                    })}
                    onClick={() => {
                      if (
                        autopilotData?.daysActive.includes(
                          Type24EnumsDayOfWeek.SATURDAY,
                        )
                      ) {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: prev!.daysActive.filter(
                            (d) => d !== Type24EnumsDayOfWeek.SATURDAY,
                          ),
                        }));
                      } else {
                        setAutopilotData((prev) => ({
                          ...prev!,
                          daysActive: [
                            ...prev!.daysActive,
                            Type24EnumsDayOfWeek.SATURDAY,
                          ],
                        }));
                      }
                    }}
                  >
                    Sat
                  </Button>
                </div>
              </div>
              <Button
                variant="solid"
                color="success"
                size="lg"
                className={classes.saveButton}
                onClick={saveConfig}
              >
                <Save />
                Save
              </Button>
            </div>
          </div>
        </LoadingContainer>
      </div>
      {showFiltersModal && (
        <FiltersModal
          id={id!}
          geoFilterHqStates={autopilotData!.geoFilterHqStates}
          geoFilterServiceAreaStates={autopilotData!.geoFilterServiceAreaStates}
          employeeFilterMin={autopilotData!.employeeFilterMin}
          employeeFilterMax={autopilotData!.employeeFilterMax}
          revenueFilterMin={autopilotData!.revenueFilterMin}
          revenueFilterMax={autopilotData!.revenueFilterMax}
          excludeProductService={autopilotData!.excludeProductService}
          excludeCustomerSegment={autopilotData!.excludeCustomerSegment}
          onClose={async () => {
            setShowFiltersModal(false);
            await fetchData();
          }}
        />
      )}
    </div>
  );
}
