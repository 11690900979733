import React, { useCallback, useEffect, useState } from 'react';
import { tss } from 'tss-react';

import {
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import {
  AutopilotDataSource,
  GetAutopilotManageResearchResponse,
  Type24EnumsAutopilotDataSource,
} from 'src/generated/api';
import { Google, InfoOutlined, Language, LinkedIn } from '@mui/icons-material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { assetLink } from 'src/utils';
import DataSourceButton from 'src/components/DataSourceButton';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  enrichmentHeader: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  enrichmentButton: {
    width: 24,
    height: 24,
    minHeight: 0,
    minWidth: 0,
  },
  enrichmentIcon: {
    width: 16,
    height: 16,
  },
  enrichmentModal: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: 600,
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  dataSources: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  dataSource: {},
  icon: {
    width: 20,
    height: 20,
  },
  btn: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    height: 32,
    padding: theme.spacing(1),
  },
  solidBtn: {
    // So the size doesnt change from outlined -> solid
    padding: 9,
  },
  dataSourcesContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = {
  campaignId: string;
  open: boolean;
  req: GetAutopilotManageResearchResponse['requirementCols'][number] | null;
  onClose: () => void;
};

export default function RequirementModal({
  campaignId,
  open,
  req,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: 'Requirement',
      prompt: '',
    },
    values: {
      name: req?.name ?? 'Requirement',
      prompt: req?.prompt ?? '',
    },
  });
  const [ifNotFound, setIfNotFound] = useState(false);
  const [hasDataSourceErr, setHasDataSourceErr] = useState(false);
  const [dataSources, setDataSources] = useState<Set<AutopilotDataSource>>(
    new Set([Type24EnumsAutopilotDataSource.WEBSITE]),
  );
  const onCloseWithReset = useCallback(() => {
    onClose();
    setHasDataSourceErr(false);
    setDataSources(new Set([Type24EnumsAutopilotDataSource.WEBSITE]));
    reset();
  }, [onClose, reset]);
  useEffect(() => {
    if (req) {
      setIfNotFound(req.ifNotFound);
      setDataSources(new Set(req.dataSources));
    } else {
      setIfNotFound(false);
      setDataSources(new Set([Type24EnumsAutopilotDataSource.WEBSITE]));
    }
  }, [req]);
  const onSubmit: SubmitHandler<{
    name: string;
    prompt: string;
  }> = async (data) => {
    // TODO: More Validation
    if (dataSources.size === 0) {
      setHasDataSourceErr(true);
      return;
    }
    if (req) {
      await api.autopilot.editRequirement(campaignId, req.id, {
        name: data.name,
        prompt: data.prompt,
        sources: [...dataSources],
        ifNotFound,
      });
    } else {
      await api.autopilot.createRequirement(campaignId, {
        name: data.name,
        prompt: data.prompt,
        ifNotFound,
        sources: [...dataSources],
      });
    }
    onCloseWithReset();
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onCloseWithReset();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        <div className={classes.headerRow}>
          <Typography level="h4">
            {req == null ? 'Add Requirement' : 'Edit Requirement'}
          </Typography>
        </div>
        <Typography level="body-md">
          Our AI broker will research each business to make sure they pass this
          requirement before reaching out to them.
        </Typography>
        <FormControl
          className={classes.dataSourcesContainer}
          error={hasDataSourceErr}
        >
          <FormLabel>Data Sources</FormLabel>
          <div className={classes.dataSources}>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.WEBSITE}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <Language />
              Website
            </DataSourceButton>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.LINKEDIN}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <LinkedIn />
              LinkedIn
            </DataSourceButton>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.PITCHBOOK}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <img src={assetLink('pitchbook.png')} className={classes.icon} />
              PitchBook
            </DataSourceButton>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.CRUNCHBASE}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <img src={assetLink('crunchbase.png')} className={classes.icon} />
              Crunchbase
            </DataSourceButton>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.GOOGLE_REVIEWS}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <Google />
              Google Places
            </DataSourceButton>
            <DataSourceButton
              source={Type24EnumsAutopilotDataSource.YELP}
              selectedDataSources={dataSources}
              setDataSources={setDataSources}
            >
              <img src={assetLink('yelp.png')} className={classes.icon} />
              Yelp
            </DataSourceButton>
          </div>
          {hasDataSourceErr && (
            <FormHelperText>
              <InfoOutlined />
              At least one source must be selected
            </FormHelperText>
          )}
        </FormControl>
        <ControlledInput
          required
          label="Column Name"
          name="name"
          control={control}
          error={errors.name}
          inputProps={{
            size: 'md',
          }}
        />
        <FormLabel>Provide a simple "yes" or "no" question</FormLabel>
        <FormControl error={Boolean(errors.prompt)}>
          <Controller
            name="prompt"
            control={control}
            rules={{
              required: 'This is required',
            }}
            render={({ field }) => (
              <Textarea
                size="md"
                minRows={2}
                placeholder="Does this clinic accept Medicaid?"
                {...field}
              />
            )}
          />
          {errors.prompt && (
            <FormHelperText>
              <InfoOutlined />
              {errors.prompt.message}
            </FormHelperText>
          )}
        </FormControl>
        <Checkbox
          label='Default to "Yes" if not enough info to answer'
          checked={ifNotFound}
          onChange={(e) => setIfNotFound(e.target.checked)}
        />
        <Button onClick={handleSubmit(onSubmit)}>
          {req == null ? 'Create' : 'Edit'}
        </Button>
      </ModalDialog>
    </Modal>
  );
}
