import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';

import {
  Sheet,
  Theme,
  Input,
  Typography,
  useTheme,
  FormLabel,
  Button,
  CircularProgress,
  Autocomplete,
  Chip,
  ChipDelete,
  FormControl,
} from '@mui/joy';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { ScrapeBuyerMatchBusiness } from 'src/generated/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(4),
  },
  content: {
    width: 800,
    display: 'flex',
    flexDirection: 'column',
  },
  sheet: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    boxShadow: theme.shadow.md,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export default function FindBuyers() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);
  const [domain, setDomain] = useState('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ScrapeBuyerMatchBusiness | null>(null);
  const onSubmitDomain = useCallback(async () => {
    const api = getAPI();
    setLoading(true);
    const res = await api.internal.scrapeBusiness({
      domain,
    });
    setLoading(false);
    setData(res.data);
  }, [domain]);
  const findBuyers = useCallback(async () => {
    const api = getAPI();
    setLoading(true);
    const res = await api.internal.getPotentialBuyersXlsx(
      {
        businessName: data!.name,
        domain,
        productServices: data!.productServices,
        customerSegments: data!.customerSegments,
      },
      {
        format: 'arrayBuffer',
      },
    );
    setData(null);
    setDomain('');
    setLoading(false);
    const blob = new Blob([res.data], { type: 'application/octet-stream' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'potential_buyers.xlsx';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [data, domain]);
  return (
    <LoadingContainer isLoading={!org || !user}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography level="h3" className={classes.title}>
            Find Buyer Matches
          </Typography>
          <Sheet className={classes.sheet}>
            {loading ? (
              <CircularProgress />
            ) : data ? (
              <>
                <Typography level="h4">{data.name}</Typography>
                <FormControl>
                  <FormLabel>Products and Services</FormLabel>
                  <Autocomplete
                    freeSolo
                    size="lg"
                    multiple
                    value={data.productServices}
                    onChange={(e, val) =>
                      setData((prev) => ({
                        ...prev!,
                        productServices: val,
                      }))
                    }
                    autoSelect
                    onInputChange={(e, newVal) => {
                      const inputEl = e.target as HTMLInputElement;
                      if (newVal.endsWith(',')) {
                        inputEl.blur();
                        inputEl.focus();
                      }
                    }}
                    options={[]}
                    renderTags={(tags, getTagProps) =>
                      tags.map((item, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={item}
                          color="warning"
                          onClick={undefined}
                          endDecorator={
                            <ChipDelete
                              onDelete={getTagProps({ index }).onClick}
                            />
                          }
                        >
                          {item}
                        </Chip>
                      ))
                    }
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>End Customers</FormLabel>
                  <Autocomplete
                    freeSolo
                    size="lg"
                    multiple
                    value={data.customerSegments}
                    onChange={(e, val) =>
                      setData((prev) => ({
                        ...prev!,
                        customerSegments: val,
                      }))
                    }
                    autoSelect
                    onInputChange={(e, newVal) => {
                      const inputEl = e.target as HTMLInputElement;
                      if (newVal.endsWith(',')) {
                        inputEl.blur();
                        inputEl.focus();
                      }
                    }}
                    options={[]}
                    renderTags={(tags, getTagProps) =>
                      tags.map((item, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          key={item}
                          color="success"
                          onClick={undefined}
                          endDecorator={
                            <ChipDelete
                              onDelete={getTagProps({ index }).onClick}
                            />
                          }
                        >
                          {item}
                        </Chip>
                      ))
                    }
                  />
                </FormControl>
                <Button onClick={findBuyers}>Find Buyers</Button>
              </>
            ) : (
              <>
                <FormLabel>Website</FormLabel>
                <Input
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                />
                <Button onClick={onSubmitDomain}>Submit</Button>
              </>
            )}
          </Sheet>
        </div>
      </div>
    </LoadingContainer>
  );
}
