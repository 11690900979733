import React, { useState } from 'react';
import { tss } from 'tss-react';
import { Box, Chip, Theme, Tooltip, Typography, useTheme } from '@mui/joy';
import { V1GetListResponse } from 'src/generated/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  chips: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  chipsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    padding: theme.spacing(1),
  },
  chip: {
    '&:hover': {
      filter: 'brightness(110%)',
      cursor: 'pointer',
    },
    zIndex: 0,
  },
}));

type Props = {
  business: V1GetListResponse['businesses'][number];
};

const maxChipsCharNum = 25;
export default function CustomerSegmentsCell({ business }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [showMoreCustomerSegments, setShowMoreCustomerSegments] =
    useState(false);
  const sortedCustomerSegments = business.customerSegments.sort(
    (a, b) => a.keyPhrase.length - b.keyPhrase.length,
  );
  let maxCustomerSegmentsShown = 0;
  let numCustomerSegmentsChars = 0;
  let customerSegmentIdx = 0;
  while (
    numCustomerSegmentsChars < maxChipsCharNum &&
    customerSegmentIdx < sortedCustomerSegments.length
  ) {
    maxCustomerSegmentsShown += 1;
    numCustomerSegmentsChars +=
      sortedCustomerSegments[customerSegmentIdx].keyPhrase.length;
    customerSegmentIdx += 1;
  }
  return (
    <div className={classes.chips}>
      <div className={classes.chipsSection}>
        {sortedCustomerSegments
          .slice(
            0,
            showMoreCustomerSegments ? undefined : maxCustomerSegmentsShown,
          )
          .map((cs) => (
            <Tooltip
              arrow
              title={
                <Box className={classes.tooltip}>
                  <Typography
                    level="body-md"
                    textColor={theme.palette.common.white}
                  >
                    {cs.description}
                  </Typography>
                </Box>
              }
              key={`${business.id}-${cs.keyPhrase}`}
            >
              <Chip color="success" className={classes.chip}>
                {cs.keyPhrase.toLowerCase()}
              </Chip>
            </Tooltip>
          ))}
        {!showMoreCustomerSegments &&
          business.customerSegments.length > maxCustomerSegmentsShown && (
            <Chip
              variant="outlined"
              className={classes.chip}
              onClick={(e) => {
                setShowMoreCustomerSegments(true);
                e.stopPropagation();
              }}
            >
              +{business.customerSegments.length - maxCustomerSegmentsShown}
            </Chip>
          )}
      </div>
    </div>
  );
}
