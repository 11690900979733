import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  GetAutopilotsResponse,
  V1GetListsResponse,
  SearchResponse,
} from 'src/generated/api';
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/joy';
import { compactNumber } from 'src/utils';
import { Add, Language, LocationOn } from '@mui/icons-material';
import normalizeUrl from 'normalize-url';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  statsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginLeft: 'auto',
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: theme.spacing(1),
  },
  chipsWithLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    gap: 4,
    width: '50%',
  },
  chipsSection: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  companyTitle: {
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  score: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  employeeChipContents: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    padding: theme.spacing(1),
  },
  chip: {
    '&:hover': {
      filter: 'brightness(120%)',
      cursor: 'pointer',
    },
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listingRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  description: {
    fontSize: 12,
    marginTop: 4,
  },
  saChip: {
    fontSize: 10,
  },
  addIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
  },
  subtitleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  subtitleIcon: {
    width: 16,
    height: 16,
  },
}));

type Props = {
  selectedIds: string[];
  setSelectedIds: (prev: (ids: string[]) => string[]) => void;
  hasDescription: boolean;
  numProductServices: number;
  numCustomerSegments: number;
  numServiceAreas: number;
  result: SearchResponse['results'][number];
  setSelectedBusinessId: (id: string) => void;
  lists: V1GetListsResponse['results'] | null;
  autopilots: GetAutopilotsResponse['autopilots'] | null;
  productsAndServices: string[];
  addProductService: (productService: string) => void;
  customerSegments: string[];
  addCustomerSegment: (customerSegment: string) => void;
};

const maxChipsCharNum = 100;
function SearchResult({
  selectedIds,
  setSelectedIds,
  hasDescription,
  numProductServices,
  numCustomerSegments,
  result,
  setSelectedBusinessId,
  lists,
  autopilots,
  productsAndServices,
  addProductService,
  customerSegments,
  addCustomerSegment,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  let maxProductServicesShown = 0;
  let productServiceIdx = 0;
  let numProductServicesChars = 0;
  while (
    numProductServicesChars < maxChipsCharNum &&
    productServiceIdx < result.productsAndServices.length
  ) {
    maxProductServicesShown += 1;
    numProductServicesChars +=
      result.productsAndServices[productServiceIdx].keyPhrase.length;
    productServiceIdx += 1;
  }
  let maxCustomerSegmentsShown = 0;
  let customerSegmentIdx = 0;
  let numCustomerSegmentsChars = 0;
  while (
    numCustomerSegmentsChars < maxChipsCharNum &&
    customerSegmentIdx < result.customerSegments.length
  ) {
    maxCustomerSegmentsShown += 1;
    numCustomerSegmentsChars +=
      result.customerSegments[customerSegmentIdx].keyPhrase.length;
    customerSegmentIdx += 1;
  }
  const [showMoreProductServices, setShowMoreProductServices] = useState(false);
  const [showMoreCustomerSegments, setShowMoreCustomerSegments] =
    useState(false);
  return (
    <tr
      key={result.businessId}
      className={classes.listingRow}
      onClick={() => setSelectedBusinessId(result.businessId)}
    >
      <td>
        <Checkbox
          size="lg"
          checked={selectedIds.includes(result.businessId)}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedIds((prev) => [
                ...new Set([...prev, result.businessId]),
              ]);
            } else {
              setSelectedIds((prev) =>
                prev.filter((id) => id !== result.businessId),
              );
            }
          }}
          slotProps={{
            checkbox: {
              onClick: (e) => {
                e.stopPropagation();
              },
            },
          }}
        />
      </td>
      <td>
        <div className={classes.contents}>
          <div className={classes.row}>
            <div className={classes.companyInfo}>
              <div className={classes.companyTitle}>
                <Typography level="body-md">{result.name}</Typography>
                {autopilots &&
                  result.autopilots.map((l) => (
                    <Chip size="sm" key={l} color="primary">
                      {autopilots.find((l2) => l === l2.id)?.name}
                    </Chip>
                  ))}
                {lists &&
                  result.lists.map((l) => (
                    <Chip size="sm" key={l}>
                      {lists.find((l2) => l === l2.id)?.name}
                    </Chip>
                  ))}
              </div>
              <div className={classes.row}>
                <Typography
                  level="body-xs"
                  className={classes.subtitleRow}
                  onClick={(e) => {
                    window.open(normalizeUrl(result.domain));
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <Language className={classes.subtitleIcon} />
                  {result.domain}
                </Typography>
                <Typography level="body-xs" className={classes.subtitleRow}>
                  <LocationOn className={classes.subtitleIcon} />
                  {result.city}, {result.state}
                </Typography>
                {result.serviceAreaStates.length > 0 && (
                  <div className={classes.chipsSection}>
                    {result.serviceAreaStates.slice(0, 8).map((s) => (
                      <Chip
                        color="primary"
                        size="sm"
                        key={`${result.businessId}-${s}`}
                        className={classes.saChip}
                      >
                        {s}
                      </Chip>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={classes.statsRow}>
              {result.revenue && (
                <div className={classes.score}>
                  <Typography level="body-md">
                    <b>${compactNumber(result.revenue)}</b>
                  </Typography>
                  <Typography level="body-xs">Revenue</Typography>
                </div>
              )}
              {result.totalFunding && (
                <div className={classes.score}>
                  <Typography level="body-md">
                    <b>${compactNumber(result.totalFunding)}</b>
                  </Typography>
                  <Typography level="body-xs">Funding</Typography>
                </div>
              )}
              {result.numEmployees && (
                <div className={classes.score}>
                  <Typography level="body-md">
                    <b>{result.numEmployees}</b>
                  </Typography>
                  <Typography level="body-xs">Employees</Typography>
                </div>
              )}
              {hasDescription && (
                <div className={classes.score}>
                  <CircularProgress
                    determinate
                    color="neutral"
                    size="sm"
                    value={result.descriptionSimilarity * 100}
                  >
                    <Typography level="body-xs">
                      {Math.round(result.descriptionSimilarity * 100)}
                    </Typography>
                  </CircularProgress>
                  <Typography level="body-xs">Description</Typography>
                </div>
              )}
              {numProductServices > 0 && (
                <div className={classes.score}>
                  <CircularProgress
                    determinate
                    color="warning"
                    size="sm"
                    value={result.avgProductAndServiceSimilarity * 100}
                  >
                    <Typography level="body-xs">
                      {Math.round(result.avgProductAndServiceSimilarity * 100)}
                    </Typography>
                  </CircularProgress>
                  <Typography level="body-xs">Products</Typography>
                </div>
              )}
              {numCustomerSegments > 0 && (
                <div className={classes.score}>
                  <CircularProgress
                    determinate
                    size="sm"
                    color="success"
                    value={result.avgCustomerSegmentSimilarity * 100}
                  >
                    <Typography level="body-xs">
                      {Math.round(result.avgCustomerSegmentSimilarity * 100)}
                    </Typography>
                  </CircularProgress>
                  <Typography level="body-xs">Customers</Typography>
                </div>
              )}
            </div>
          </div>
          <Typography level="body-sm" className={classes.description}>
            {result.description}
          </Typography>
          <div className={classes.chips}>
            {result.productsAndServices.length > 0 && (
              <div className={classes.chipsWithLabel}>
                <Typography level="body-xs">Products and Services</Typography>
                <div className={classes.chipsSection}>
                  {result.productsAndServices
                    .slice(
                      0,
                      showMoreProductServices ? 10 : maxProductServicesShown,
                    )
                    .map((ps) => (
                      <Tooltip
                        arrow
                        key={`${result.businessId}-${ps.keyPhrase}`}
                        title={
                          <Box className={classes.tooltip}>
                            <Typography
                              level="body-md"
                              textColor={theme.palette.common.white}
                            >
                              {ps.description}
                            </Typography>
                          </Box>
                        }
                      >
                        <Chip
                          color="warning"
                          size="sm"
                          slotProps={{
                            label: {
                              className: classes.chip,
                            },
                          }}
                          onClick={
                            !productsAndServices.find(
                              (x) =>
                                x.toLowerCase() === ps.keyPhrase.toLowerCase(),
                            )
                              ? (e) => {
                                  addProductService(ps.keyPhrase);
                                  e.stopPropagation();
                                }
                              : undefined
                          }
                        >
                          {!productsAndServices.find(
                            (x) =>
                              x.toLowerCase() === ps.keyPhrase.toLowerCase(),
                          ) && <Add className={classes.addIcon} />}
                          {ps.keyPhrase.toLowerCase()}
                        </Chip>
                      </Tooltip>
                    ))}
                  {!showMoreProductServices &&
                    result.productsAndServices.length >
                      maxProductServicesShown && (
                      <Chip
                        variant="outlined"
                        size="sm"
                        className={classes.chip}
                        onClick={(e) => {
                          setShowMoreProductServices(true);
                          e.stopPropagation();
                        }}
                      >
                        +
                        {result.productsAndServices.length -
                          maxProductServicesShown}
                      </Chip>
                    )}
                </div>
              </div>
            )}
            {result.customerSegments.length > 0 && (
              <div className={classes.chipsWithLabel}>
                <Typography level="body-xs">End Customers</Typography>
                <div className={classes.chipsSection}>
                  {result.customerSegments
                    .slice(
                      0,
                      showMoreCustomerSegments ? 10 : maxCustomerSegmentsShown,
                    )
                    .map((cs) => (
                      <Tooltip
                        arrow
                        title={
                          <Box className={classes.tooltip}>
                            <Typography
                              level="body-md"
                              textColor={theme.palette.common.white}
                            >
                              {cs.description}
                            </Typography>
                          </Box>
                        }
                        key={`${result.businessId}-${cs.keyPhrase}`}
                      >
                        <Chip
                          size="sm"
                          color="success"
                          slotProps={{
                            label: {
                              className: classes.chip,
                            },
                          }}
                          onClick={
                            !customerSegments.find(
                              (x) =>
                                x.toLowerCase() === cs.keyPhrase.toLowerCase(),
                            )
                              ? (e) => {
                                  addCustomerSegment(cs.keyPhrase);
                                  e.stopPropagation();
                                }
                              : undefined
                          }
                        >
                          {!customerSegments.find(
                            (x) =>
                              x.toLowerCase() === cs.keyPhrase.toLowerCase(),
                          ) && <Add className={classes.addIcon} />}
                          {cs.keyPhrase.toLowerCase()}
                        </Chip>
                      </Tooltip>
                    ))}
                  {!showMoreCustomerSegments &&
                    result.customerSegments.length >
                      maxCustomerSegmentsShown && (
                      <Chip
                        variant="outlined"
                        size="sm"
                        className={classes.chip}
                        onClick={(e) => {
                          setShowMoreCustomerSegments(true);
                          e.stopPropagation();
                        }}
                      >
                        +
                        {result.customerSegments.length -
                          maxCustomerSegmentsShown}
                      </Chip>
                    )}
                </div>
              </div>
            )}
          </div>
        </div>
      </td>
    </tr>
  );
}

export default React.memo(SearchResult);
