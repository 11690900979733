import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  Input,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuButton,
  MenuItem,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import LoadingContainer from 'src/components/LoadingContainer';
import { getAPI } from 'src/api';
import { Delete, Edit, ListAlt, MoreVert } from '@mui/icons-material';
import CreateProspectModal from 'src/modals/CreateProspectModal';
import RenameProspectModal from './RenameProspectModal';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: theme.spacing(4),
  },
  contents: {
    maxWidth: 650,
    width: '100%',
  },
  field: {
    width: 300,
    marginBottom: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  createButton: {
    marginLeft: 'auto',
  },
  lists: {
    marginTop: theme.spacing(4),
    boxShadow: theme.shadow.lg,
    overflowY: 'auto',
    maxHeight: '70vh',
  },
  listItem: {
    padding: theme.spacing(3),
  },
  openCol: {
    width: 100,
  },
  openBtn: {
    marginLeft: 'auto',
  },
  icon: {
    width: 32,
    height: 32,
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  listItemRight: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyState: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadow.lg,
  },
  optionsMenu: {
    padding: theme.spacing(1),
    width: 24,
    height: 24,
  },
  optionsIcon: {
    width: 18,
    height: 18,
  },
}));

export default function CallPrep() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [prospects, setProspects] = useState<
    { id: string; name: string }[] | null
  >();
  const [renameModalData, setRenameModalData] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const api = getAPI();

  const fetchData = useCallback(async () => {
    const res = await api.prospects.getProspectList();
    setProspects(res.data.prospects);
  }, [api.prospects]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [searchTerm, setSearchTerm] = useState<string>('');
  const filteredProspects = useMemo(
    () =>
      prospects?.filter((prospect) =>
        prospect.name?.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    [prospects, searchTerm],
  );

  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={!prospects}>
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography level="h3" className={classes.title}>
                <ListAlt className={classes.icon} />
                Your Prospects
              </Typography>
              <Typography level="body-lg">Create a prospect</Typography>
            </div>
            <Button
              className={classes.createButton}
              size="lg"
              color="success"
              onClick={() => setShowCreateModal(true)}
            >
              Create
            </Button>
          </div>
          <div
            style={{
              marginTop: theme.spacing(2),
            }}
          >
            <Input
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {filteredProspects?.length === 0 ? (
            <Card className={classes.emptyState} variant="plain">
              <Typography level="h4">You have no prospects</Typography>
              <Typography level="body-lg">
                Create your first prospect
              </Typography>
            </Card>
          ) : (
            <List className={classes.lists}>
              {filteredProspects?.map((p) => (
                <ListItem key={p.id}>
                  <ListItemButton
                    className={classes.listItem}
                    onClick={() => navigate(`/prep/${p.id}`)}
                  >
                    <Typography level="h4">{p.name}</Typography>
                    <Dropdown>
                      <MenuButton
                        color="neutral"
                        variant="plain"
                        className={classes.optionsMenu}
                        slotProps={{
                          root: {
                            onClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            },
                          },
                        }}
                      >
                        <MoreVert className={classes.optionsIcon} />
                      </MenuButton>
                      <Menu placement="bottom-end">
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setRenameModalData({ ...p });
                          }}
                        >
                          <Edit />
                          Rename
                        </MenuItem>
                        <MenuItem
                          color="danger"
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await api.prospects.deleteProspect(p.id);
                            await fetchData();
                          }}
                        >
                          <Delete />
                          Delete
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </div>
      </LoadingContainer>
      <CreateProspectModal
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false);
          fetchData();
        }}
        navigateOnCreate
      />
      {renameModalData && (
        <RenameProspectModal
          open
          prospect={renameModalData}
          onClose={() => {
            fetchData();
            setRenameModalData(null);
          }}
        />
      )}
    </div>
  );
}
