/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  Button,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Theme,
  Typography,
  useTheme,
  Box,
} from '@mui/joy';
import React from 'react';
import { Control, FieldErrors, useFieldArray, useWatch } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { tss } from 'tss-react';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData } from './types';

export type ProductServicesProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
  productTabNum: number;
  setProductTabNum: (tabNum: number) => void;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  tabPanel: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'sticky',
    top: theme.spacing(8),
    backgroundColor: theme.palette.background.body,
    gap: theme.spacing(2),
    zIndex: 1,
    padding: theme.spacing(2, 0),
  },
  tabList: {
    flexGrow: 1,
    overflowX: 'auto',
    '&::-webkit-scrollbar': {
      height: '8px',
      display: 'block',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.neutral[400],
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.level1,
    },
  },
  tabListContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  tabItem: {
    minWidth: 'auto',
    flex: '0 0 auto',
  },
}));

type ProductServiceItemProps = {
  index: number;
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
  removeProductService: (index: number) => void;
  setProductTabNum: (tabNum: number) => void;
  productsAndServicesLength: number;
};

function ProductServiceItem({
  index,
  control,
  errors,
  projectLocked,
  removeProductService,
  setProductTabNum,
  productsAndServicesLength,
}: ProductServiceItemProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: features,
    append: appendFeature,
    remove: removeFeature,
  } = useFieldArray({
    control,
    name: `productsAndServices.${index}.features`,
  });

  const {
    fields: valueProps,
    append: appendValueProp,
    remove: removeValueProp,
  } = useFieldArray({
    control,
    name: `productsAndServices.${index}.valueProps`,
  });

  return (
    <div className={classes.tabPanel}>
      <div className={classes.row}>
        <ControlledInput
          label="Name"
          name={`productsAndServices.${index}.name`}
          control={control}
          error={errors.productsAndServices?.[index]?.name}
          required
          className={classes.fullWidth}
          inputProps={{ disabled: projectLocked }}
        />
        <IconButton
          onClick={() => {
            removeProductService(index);
            setProductTabNum(Math.max(0, productsAndServicesLength - 2));
          }}
          color="danger"
          variant="soft"
          size="lg"
          disabled={projectLocked}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <ControlledInput
        label="Description"
        name={`productsAndServices.${index}.description`}
        control={control}
        error={errors.productsAndServices?.[index]?.description}
        required
        textArea
        className={classes.fullWidth}
        inputProps={{ disabled: projectLocked }}
      />
      <Typography level="body-md" sx={{ mt: 2 }}>
        Features
      </Typography>
      {features.map((_, featureIndex) => (
        <div key={featureIndex} className={classes.row}>
          <ControlledInput
            name={`productsAndServices.${index}.features.${featureIndex}.value`}
            control={control}
            error={
              errors.productsAndServices?.[index]?.features?.[featureIndex]
                ?.value
            }
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeFeature(featureIndex)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        startDecorator={<AddIcon />}
        onClick={() => appendFeature({ value: '' })}
        variant="outlined"
        color="success"
        disabled={projectLocked}
      >
        Add Feature
      </Button>
      <Typography level="body-md" sx={{ mt: 2 }}>
        Value Propositions
      </Typography>
      {valueProps.map((_, valuePropIndex) => (
        <div key={valuePropIndex} className={classes.row}>
          <ControlledInput
            name={`productsAndServices.${index}.valueProps.${valuePropIndex}.value`}
            control={control}
            error={
              errors.productsAndServices?.[index]?.valueProps?.[valuePropIndex]
                ?.value
            }
            required
            className={classes.fullWidth}
            inputProps={{ disabled: projectLocked }}
          />
          <IconButton
            onClick={() => removeValueProp(valuePropIndex)}
            size="lg"
            color="danger"
            variant="outlined"
            disabled={projectLocked}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ))}
      <Button
        startDecorator={<AddIcon />}
        onClick={() => appendValueProp({ value: '' })}
        variant="outlined"
        color="success"
        disabled={projectLocked}
      >
        Add Value Proposition
      </Button>
    </div>
  );
}

export default function ProductServices({
  control,
  errors,
  projectLocked,
  productTabNum,
  setProductTabNum,
}: ProductServicesProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: productsAndServicesFields,
    append: appendProductService,
    remove: removeProductService,
  } = useFieldArray({ control, name: 'productsAndServices' });

  // Watch for changes in product names
  const productNames = useWatch({
    control,
    name: 'productsAndServices',
    defaultValue: [],
  });

  const handleAddProduct = () => {
    appendProductService({
      name: '',
      description: '',
      features: [],
      valueProps: [],
    });
    setProductTabNum(productsAndServicesFields.length);
  };

  return (
    <div className={classes.container}>
      <Tabs
        value={productTabNum}
        onChange={(event, newValue) => setProductTabNum(newValue as number)}
        sx={{ borderRadius: 'lg', width: '100%' }}
      >
        <Box className={classes.header}>
          <Typography level="h3">Products and Services</Typography>
          <div className={classes.tabListContainer}>
            <TabList className={classes.tabList}>
              {productsAndServicesFields.map((field, index) => (
                <Tab key={field.id} className={classes.tabItem}>
                  {productNames[index]?.name || `Product ${index + 1}`}
                </Tab>
              ))}
            </TabList>
            <IconButton
              disabled={projectLocked}
              onClick={handleAddProduct}
              sx={{ ml: 1, flexShrink: 0 }}
            >
              <AddIcon />
            </IconButton>
          </div>
        </Box>
        {productsAndServicesFields.map((field, index) => (
          <TabPanel value={index} key={field.id}>
            <ProductServiceItem
              index={index}
              control={control}
              errors={errors}
              projectLocked={projectLocked}
              removeProductService={removeProductService}
              setProductTabNum={setProductTabNum}
              productsAndServicesLength={productsAndServicesFields.length}
            />
          </TabPanel>
        ))}
      </Tabs>
    </div>
  );
}
