import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tss } from 'tss-react';
import { Button, IconButton, Theme, Typography, useTheme } from '@mui/joy';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRowSelectionModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import {
  ChevronLeft,
  NotStarted,
  SavedSearch,
  UploadFile,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import { GetAutopilotManageQueueResponse } from 'src/generated/api';
import { getAPI } from 'src/api';
import { SnackbarContext } from 'src/contexts/snackbar';
import AboutCell from './AboutCell';
import SimpleCell from '../../../Lists/SimpleCell';
import FinancialCell from '../../../Lists/Cells/FinancialCell';
import ImportModal from '../../ImportModal';
import SimilarBusinessesModal from './SimilarBusinessesModal';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  contents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    height: '75vh',
  },
  tableScroller: {
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 12,
      height: 12,
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  cell: {
    wordBreak: 'break-word',
  },
  actionRow: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    marginTop: 'auto',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  statusCell: {
    padding: '0 !important',
  },
  statusContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  headerButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

export default function AutopilotNotStarted() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { id } = useParams();
  const navigate = useNavigate();
  const [showSuggestedModal, setShowSuggestedModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 2000,
  });
  const { showSnackbar } = useContext(SnackbarContext);
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [businessDrawerId, setBusinessDrawerId] = React.useState<string | null>(
    null,
  );
  const api = getAPI();
  const [list, setList] = useState<GetAutopilotManageQueueResponse | null>(
    null,
  );
  const fetchData = useCallback(async () => {
    const listRes = await api.autopilot.getManageQueue(id!, {
      pageNum: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
    setList(listRes.data);
  }, [api, paginationModel, id]);
  const removeRows = useCallback(async () => {
    await api.autopilot.removeFromAutopilot(id!, {
      businessIds: rowSelectionModel as string[],
    });
    await fetchData();
    setRowSelectionModel([]);
  }, [api, rowSelectionModel, id, fetchData]);
  const runRows = useCallback(async () => {
    api.autopilot.runSequences(id!, {
      businessIds: rowSelectionModel as string[],
    });
    showSnackbar({
      message: `Processing ${rowSelectionModel.length} businesses...`,
      color: 'success',
    });
    setRowSelectionModel([]);
    await fetchData();
  }, [api, rowSelectionModel, id, showSnackbar, fetchData]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const columns: GridColDef[] = [
    {
      field: 'about',
      width: 350,
      disableReorder: true,
      headerName: 'About',
      valueGetter: (
        data: GridValueGetterParams<
          GetAutopilotManageQueueResponse['businesses'][number]
        >,
      ) => data.row.name,
      renderCell: (data) => (
        <AboutCell business={data.row} setDrawerId={setBusinessDrawerId} />
      ),
      disableColumnMenu: true,
      renderHeader: () => (
        <Typography level="body-md">
          <b>About</b>
        </Typography>
      ),
    },
    {
      field: 'description',
      width: 800,
      disableColumnMenu: true,
      headerName: 'Description',
      cellClassName: classes.cell,
      renderCell: (data) => <SimpleCell value={data.value} />,
      renderHeader: () => (
        <Typography level="body-md">
          <b>Description</b>
        </Typography>
      ),
    },
    {
      field: 'city',
      width: 120,
      disableColumnMenu: true,
      headerName: 'City',
      cellClassName: classes.cell,
      renderHeader: () => (
        <Typography level="body-md">
          <b>HQ City</b>
        </Typography>
      ),
    },
    {
      field: 'state',
      width: 120,
      disableColumnMenu: true,
      headerName: 'HQ State',
      cellClassName: classes.cell,
      renderHeader: () => (
        <Typography level="body-md">
          <b>HQ State</b>
        </Typography>
      ),
    },
    {
      field: 'revenue',
      width: 120,
      disableColumnMenu: true,
      headerName: 'Revenue',
      renderCell: (data) => <FinancialCell num={data.row.revenue} />,
      renderHeader: () => (
        <Typography level="body-md">
          <b>Revenue</b>
        </Typography>
      ),
    },
  ];
  const gridApiRef = useGridApiRef();
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <IconButton onClick={() => navigate(`/autopilot/${id}/manage`)}>
            <ChevronLeft />
          </IconButton>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <NotStarted />
              Queued Businesses
            </Typography>
            <Typography level="body-lg">
              Add business with Search, and our AI broker will automatically vet
              and engage qualified targets daily
            </Typography>
          </div>
          <div className={classes.actionRow}>
            <Button
              onClick={() => setShowImportModal(true)}
              color="primary"
              size="lg"
              variant="outlined"
              className={classes.headerButton}
            >
              <UploadFile />
              Import
            </Button>
            {list?.hasAnySequence && (
              <Button
                onClick={() => setShowSuggestedModal(true)}
                color="warning"
                size="lg"
                variant="outlined"
                className={classes.headerButton}
              >
                <SavedSearch />
                Add Suggested
              </Button>
            )}
            {rowSelectionModel.length > 0 && (
              <>
                <Button color="danger" size="lg" onClick={removeRows}>
                  Remove ({rowSelectionModel.length})
                </Button>
                <Button color="success" onClick={runRows}>
                  Run Pipeline ({rowSelectionModel.length})
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={classes.tableContainer}>
          <DataGridPro
            pagination
            checkboxSelection
            apiRef={gridApiRef}
            disableRowSelectionOnClick
            rowCount={list?.size}
            loading={list == null}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            pageSizeOptions={[2000]}
            showColumnVerticalBorder
            classes={{
              virtualScroller: classes.tableScroller,
            }}
            pinnedColumns={{
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'about'],
            }}
            columnHeaderHeight={32}
            columns={columns}
            rows={list?.businesses ?? []}
          />
        </div>
      </div>
      {list && (
        <BusinessDrawer
          initialBusinessId={businessDrawerId}
          onClose={() => setBusinessDrawerId(null)}
        />
      )}
      {showImportModal && (
        <ImportModal id={id!} onClose={() => setShowImportModal(false)} />
      )}
      {showSuggestedModal && (
        <SimilarBusinessesModal
          autopilotId={id!}
          onClose={() => {
            setShowSuggestedModal(false);
            fetchData();
          }}
        />
      )}
    </div>
  );
}
