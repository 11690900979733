import React, { useState } from 'react';
import { tss } from 'tss-react';
import {
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  useTheme,
  Card,
  Typography,
} from '@mui/joy';
import { FileUploadOutlined, Language } from '@mui/icons-material';
import FileUpload from './FileUpload';
import Domains from './Domains';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 500,
    padding: theme.spacing(2),
  },
  importTypes: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
}));

type Props = {
  id: string;
  onClose: (imported: boolean) => void;
};

type ImportType = 'FILE' | 'DOMAINS';

export default function ImportModal({ id, onClose }: Props) {
  const theme = useTheme();
  const [importType, setImportType] = useState<ImportType | null>(null);
  const { classes } = useStyles({ theme });
  return (
    <Modal
      open
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose(false);
        }
      }}
    >
      <ModalDialog className={classes.container}>
        <ModalClose />
        {importType == null ? (
          <div className={classes.importTypes}>
            <Card
              onClick={() => setImportType('FILE')}
              className={classes.card}
            >
              <Typography level="h4" className={classes.title}>
                <FileUploadOutlined />
                File Upload
              </Typography>
              <Typography level="body-md">
                Upload a CSV with the businesses you want to import
              </Typography>
            </Card>
            <Card
              onClick={() => setImportType('DOMAINS')}
              className={classes.card}
            >
              <Typography level="h4" className={classes.title}>
                <Language />
                Add by Domain
              </Typography>
              <Typography level="body-md">
                Manually add businesses to import by domain
              </Typography>
            </Card>
          </div>
        ) : importType === 'FILE' ? (
          <FileUpload id={id} onClose={onClose} />
        ) : (
          <Domains id={id} onClose={onClose} />
        )}
      </ModalDialog>
    </Modal>
  );
}
