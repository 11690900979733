import React from 'react';
import { tss } from 'tss-react';
import {
  Card,
  Chip,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { Groups, Paid, TrendingUp } from '@mui/icons-material';
import { compactNumber } from 'src/utils';

export type SearchData = {
  description: string;
  softwareOnly: boolean;
  productsAndServices: string[];
  productsAndServicesMatchAll: boolean;
  customerSegments: string[];
  customerSegmentsMatchAll: boolean;
  productServiceWeight: number;
  customerSegmentWeight: number;
  geoFilter?: {
    hqStates: string[];
    serviceAreasStates: string[];
    mustHaveServiceAreas: boolean;
  };
  employeeFilter?: {
    minEmployees: number | null;
    maxEmployees: number | null;
  };
  revenueFilter?: {
    minRevenue: number | null;
    maxRevenue: number | null;
    mustHave: boolean;
  };
  fundingFilter?: {
    funded?: {
      minFunding: number | null;
      maxFunding: number | null;
    };
    mustHave: boolean;
  };
  sellsToFilter?: {
    mustSellToBusinesses: boolean;
    mustSellToConsumers: boolean;
    mustSellToGovernment: boolean;
  };
  excludeKeywords?: {
    productServices: string[];
    customerSegments: string[];
  };
  excludeLists?: string[];
};

export type SearchHistoryEntry = {
  searchData: SearchData;
  time: string;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 600,
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    overflowX: 'scroll',
  },
  card: {
    boxShadow: theme.shadow.sm,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  filterCards: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
  filterCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    padding: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 1,
  },
  filterCardValue: {
    marginLeft: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
}));

type Props = {
  open: boolean;
  searchHistory: SearchHistoryEntry[];
  onClose: (selectedHistory?: SearchData) => void;
};

export default function SearchHistoryModal({
  searchHistory,
  open,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  return (
    <Modal open={open} onClose={() => onClose()}>
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level="h3">Search History</Typography>
        <div className={classes.content}>
          {searchHistory.length === 0 ? (
            <>
              <Typography level="body-lg">
                You have no previous searches on this device.
              </Typography>
            </>
          ) : (
            <>
              <Typography level="body-md">
                Select a previous search query to load
              </Typography>
              {searchHistory.map((search) => (
                <Card
                  variant="plain"
                  className={classes.card}
                  key={search.time}
                  onClick={() => {
                    onClose(search.searchData);
                  }}
                >
                  <Typography level="body-md">
                    <b>
                      {new Date(search.time).toLocaleDateString()} @{' '}
                      {new Date(search.time).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </b>
                  </Typography>
                  {search.searchData.description?.length > 0 && (
                    <Typography level="body-md">
                      <b>{search.searchData.description}</b>
                    </Typography>
                  )}
                  {search.searchData.productsAndServices.length > 0 && (
                    <>
                      <Typography level="body-xs">
                        Products and Services
                      </Typography>
                      <div className={classes.chips}>
                        {search.searchData.productsAndServices.map((ps) => (
                          <Chip color="warning" key={ps}>
                            {ps}
                          </Chip>
                        ))}
                      </div>
                    </>
                  )}
                  {search.searchData.customerSegments.length > 0 && (
                    <>
                      <Typography level="body-xs">End Customers</Typography>
                      <div className={classes.chips}>
                        {search.searchData.customerSegments.map((cs) => (
                          <Chip color="success" key={cs}>
                            {cs}
                          </Chip>
                        ))}
                      </div>
                    </>
                  )}
                  {search.searchData.geoFilter?.serviceAreasStates &&
                    search.searchData.geoFilter?.serviceAreasStates.length >
                      0 && (
                      <>
                        <Typography level="body-xs">Service Areas</Typography>
                        <div className={classes.chips}>
                          {search.searchData.geoFilter.serviceAreasStates.map(
                            (s) => (
                              <Chip color="primary" key={s}>
                                {s}
                              </Chip>
                            ),
                          )}
                        </div>
                      </>
                    )}
                  {search.searchData.geoFilter?.hqStates &&
                    search.searchData.geoFilter?.hqStates.length > 0 && (
                      <>
                        <Typography level="body-xs">HQ States</Typography>
                        <div className={classes.chips}>
                          {search.searchData.geoFilter.hqStates.map((s) => (
                            <Chip color="neutral" key={s}>
                              {s}
                            </Chip>
                          ))}
                        </div>
                      </>
                    )}
                  <div className={classes.filterCards}>
                    {search.searchData.employeeFilter && (
                      <Card className={classes.filterCard}>
                        <Groups />
                        <Typography level="body-sm">Employees</Typography>
                        <Typography
                          level="body-sm"
                          className={classes.filterCardValue}
                        >
                          <b>
                            {search.searchData.employeeFilter.minEmployees}-{' '}
                            {search.searchData.employeeFilter.maxEmployees}
                          </b>
                        </Typography>
                      </Card>
                    )}
                    {search.searchData.revenueFilter && (
                      <Card className={classes.filterCard}>
                        <Paid />
                        <Typography level="body-sm">Revenue</Typography>
                        <Typography
                          level="body-sm"
                          className={classes.filterCardValue}
                        >
                          <b>
                            $
                            {search.searchData.revenueFilter.maxRevenue
                              ? compactNumber(
                                  search.searchData.revenueFilter.maxRevenue,
                                )
                              : 'ANY'}
                            -{' '}
                            {search.searchData.revenueFilter.maxRevenue
                              ? compactNumber(
                                  search.searchData.revenueFilter.maxRevenue,
                                )
                              : 'ANY'}
                          </b>
                        </Typography>
                      </Card>
                    )}
                    {search.searchData.fundingFilter?.funded && (
                      <Card className={classes.filterCard}>
                        <TrendingUp />
                        <Typography level="body-sm">Funding</Typography>
                        <Typography
                          level="body-sm"
                          className={classes.filterCardValue}
                        >
                          <b>
                            $
                            {search.searchData.fundingFilter.funded.minFunding
                              ? compactNumber(
                                  search.searchData.fundingFilter.funded
                                    .minFunding,
                                )
                              : 'ANY'}
                            -{' '}
                            {search.searchData.fundingFilter.funded.maxFunding
                              ? compactNumber(
                                  search.searchData.fundingFilter.funded
                                    .maxFunding,
                                )
                              : 'ANY'}
                          </b>
                        </Typography>
                      </Card>
                    )}
                    {search.searchData.sellsToFilter?.mustSellToBusinesses && (
                      <Card className={classes.filterCard}>
                        <Typography level="body-sm">
                          Sells to Businesses
                        </Typography>
                      </Card>
                    )}
                    {search.searchData.sellsToFilter?.mustSellToConsumers && (
                      <Card className={classes.filterCard}>
                        <Typography level="body-sm">
                          Sells to Consumers
                        </Typography>
                      </Card>
                    )}
                    {search.searchData.sellsToFilter?.mustSellToGovernment && (
                      <Card className={classes.filterCard}>
                        <Typography level="body-sm">
                          Sells to Government
                        </Typography>
                      </Card>
                    )}
                  </div>
                </Card>
              ))}
            </>
          )}
        </div>
      </ModalDialog>
    </Modal>
  );
}
