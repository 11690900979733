import React, { useCallback, useState } from 'react';
import { tss } from 'tss-react';
import {
  Theme,
  Typography,
  useTheme,
  Button,
  Autocomplete,
  Chip,
  ChipDelete,
} from '@mui/joy';
import { getAPI } from 'src/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

type Props = {
  id: string;
  onClose: (imported: boolean) => void;
};

export default function Domains({ id, onClose }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [domains, setDomains] = useState<string[]>([]);
  const onSubmit = useCallback(async () => {
    const api = getAPI();
    await api.autopilot.importDomains(id, {
      domains,
    });
    onClose(true);
  }, [onClose, domains, id]);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level="h4">Add by Domain</Typography>
        <Typography level="body-md">
          Add businesses to this campaign by domain
        </Typography>
      </div>
      <Autocomplete
        multiple
        freeSolo
        autoSelect
        placeholder="domain.com"
        size="lg"
        value={domains}
        onChange={(e, val) => setDomains(val)}
        onInputChange={(e, newVal) => {
          if (newVal.endsWith(',')) {
            (e.target as HTMLElement).blur();
            (e.target as HTMLElement).focus();
          }
        }}
        renderTags={(tags, getTagProps) =>
          tags.map((item, index) => (
            <Chip
              {...getTagProps({ index })}
              color="primary"
              onClick={undefined}
              endDecorator={
                <ChipDelete onDelete={getTagProps({ index }).onClick} />
              }
            >
              {item}
            </Chip>
          ))
        }
        options={[]}
      />
      <Button
        color="success"
        onClick={onSubmit}
        disabled={domains.length === 0}
      >
        Import Businesses
      </Button>
    </div>
  );
}
