import React from 'react';
import { Theme, Typography, useTheme } from '@mui/joy';
import { tss } from 'tss-react';
import { V1GetListResponse } from 'src/generated/api';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { LinkedIn } from '@mui/icons-material';
import { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  cell: {
    wordBreak: 'break-word',
  },
  clickableCell: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    gap: 2,
    wordBreak: 'break-word',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  enrichmentHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  colHeaderText: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

type SimpleContactValueProps = {
  value: string | null;
};

function SimpleContactValue({ value }: SimpleContactValueProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value) {
    return (
      <div className={classes.cell}>
        <Typography level="body-sm" textColor="tertiary">
          <i>Not Available</i>
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cell}>
      <Typography level="body-sm" textColor="text.primary">
        {value}
      </Typography>
    </div>
  );
}

type ColumnHeaderProps = {
  title: string;
};

function ColumnHeader({ title }: ColumnHeaderProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div className={classes.enrichmentHeader}>
      <Typography level="body-md" className={classes.colHeaderText}>
        <b>{title}</b>
      </Typography>
    </div>
  );
}

function LinkedInContactValue({ value }: SimpleContactValueProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value) {
    return (
      <div className={classes.cell}>
        <Typography level="body-sm" textColor="tertiary">
          <i>Not Available</i>
        </Typography>
      </div>
    );
  }
  const linkedInUsername = value.split('/').slice(-1);
  return (
    <div className={classes.clickableCell} onClick={() => window.open(value)}>
      <LinkedIn />
      <Typography level="body-sm" textColor="text.primary">
        {linkedInUsername}
      </Typography>
    </div>
  );
}

export function getColumns(enrichmentColProps: EnrichmentColProps) {
  return [
    {
      field: `${enrichmentColProps.col.id}-first-name`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-first-name`
        ] ?? 200,
      sortable: true,
      disableColumnMenu: true,
      headerName: `${enrichmentColProps.col.name} - First Name`,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.firstName;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.firstName;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="First Name" />,
    },
    {
      field: `${enrichmentColProps.col.id}-last-name`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-last-name`
        ] ?? 200,
      sortable: true,
      disableColumnMenu: true,
      headerName: `${enrichmentColProps.col.name} - Last Name`,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.lastName;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.lastName;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Last Name" />,
    },
    {
      field: `${enrichmentColProps.col.id}-title`,
      headerName: `${enrichmentColProps.col.name} - Title`,
      width:
        enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-title`] ??
        200,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.title;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.title;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Job Title" />,
    },
    {
      field: `${enrichmentColProps.col.id}-email`,
      headerName: `${enrichmentColProps.col.name} - Email`,
      width:
        enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-email`] ??
        300,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.emailAddress;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.emailAddress;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Email Address" />,
    },
    {
      field: `${enrichmentColProps.col.id}-linkedin`,
      headerName: `${enrichmentColProps.col.name} - LinkedIn`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-linkedin`
        ] ?? 300,
      sortable: true,
      disableColumnMenu: true,
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.linkedIn;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <LinkedInContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="LinkedIn" />,
    },
    {
      field: `${enrichmentColProps.col.id}-mobile-phone`,
      headerName: `${enrichmentColProps.col.name} - Mobile Phone`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-mobile-phone`
        ] ?? 300,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.mobilePhone;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.mobilePhone;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Mobile Phone" />,
    },
    {
      field: `${enrichmentColProps.col.id}-personal-email`,
      headerName: `${enrichmentColProps.col.name} - Personal Email`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-personal-email`
        ] ?? 300,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.contactInfo?.personalEmail;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.contactInfo?.personalEmail;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <SimpleContactValue value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Personal Email" />,
    },
  ];
}
