import React from 'react';
import { tss } from 'tss-react';
import { Typography, Theme, useTheme } from '@mui/joy';
import { getAPI } from 'src/api';
import { V1GetListResponse } from 'src/generated/api';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Cancel, CheckCircle } from '@mui/icons-material';
import EnrichmentHeader, { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  cell: {
    wordBreak: 'break-word',
  },
  clickableCell: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    gap: 2,
    wordBreak: 'break-word',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  booleanField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
  },
  booleanIcon: {
    width: 16,
    height: 16,
  },
}));

export type ScrapeProps = {
  col: NonNullable<V1GetListResponse['enrichmentColumns'][number]['scrape']>;
  value: V1GetListResponse['businesses'][number]['enrichmentValues'][number]['scrape'];
};

function Scrape({ col, value }: ScrapeProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value || value.value == null) {
    return (
      <div className={classes.cell}>
        <Typography level="body-sm" textColor="tertiary">
          <i>Inconclusive</i>
        </Typography>
      </div>
    );
  }
  if (col.dataType === 'BOOLEAN') {
    return (
      <div className={classes.cell}>
        <Typography
          level="body-sm"
          textColor="text.primary"
          className={classes.booleanField}
        >
          {value.value ? (
            <>
              <CheckCircle color="success" />
              Yes
            </>
          ) : (
            <>
              <Cancel color="error" />
              No
            </>
          )}
        </Typography>
      </div>
    );
  }
  if (value.value == null) {
    return (
      <div className={classes.cell}>
        <Typography level="body-sm" textColor="tertiary">
          <i>Not enough info to answer</i>
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cell}>
      <Typography level="body-sm" textColor="text.primary">
        {value.value as string}
      </Typography>
    </div>
  );
}

export function getColumns(enrichmentColProps: EnrichmentColProps) {
  let colType = 'string';
  if (enrichmentColProps.col.scrape!.dataType === 'BOOLEAN') {
    colType = 'boolean';
  } else if (enrichmentColProps.col.scrape!.dataType === 'NUMBER') {
    colType = 'number';
  }
  return [
    {
      field: enrichmentColProps.col.id,
      width: enrichmentColProps.columnWidths[enrichmentColProps.col.id] ?? 300,
      disableColumnMenu: true,
      headerName: enrichmentColProps.col.name,
      type: colType,
      valueGetter: (
        params: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) =>
        params.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        )?.scrape?.value,
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return (
          <EnrichmentCellWrapper
            value={val}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
            onInfoClick={
              enrichmentColProps.onInfoClick && val?.scrape != null
                ? async () => {
                    const api = getAPI();
                    const enrichmentDetails =
                      await api.lists.getEnrichmentDetails(
                        val.colId,
                        data.row.id,
                      );
                    if (
                      enrichmentColProps.onInfoClick &&
                      val?.scrape != null &&
                      enrichmentDetails.data.scrape!.explanation
                    ) {
                      enrichmentColProps.onInfoClick({
                        prompt: enrichmentColProps.col.scrape!.prompt,
                        ...enrichmentDetails.data.scrape!,
                      });
                    }
                  }
                : undefined
            }
          >
            <Scrape col={enrichmentColProps.col.scrape!} value={val?.scrape} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <EnrichmentHeader {...enrichmentColProps} />,
    },
  ];
}
