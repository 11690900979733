import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Search from './pages/Search';
import Search2 from './pages/Search2';
import Search3 from './pages/Search3';
import Search4 from './pages/Search4';
import Search5 from './pages/Search5';
import Search6 from './pages/Search6';
import { Layout } from './layout';
import Onboarding from './pages/Onboarding';
import Lists from './pages/Lists';
import Settings from './pages/Settings';
import Business from './pages/Business';
import List from './pages/Lists/List';
import Clients from './pages/Clients';
import Autopilots from './pages/Autopilot';
import AutopilotManagePipeline from './pages/Autopilot/Manage/Pipeline';
import AutopilotManageResearch from './pages/Autopilot/Manage/Research';
import AutopilotManageEngage from './pages/Autopilot/Manage/Engage';
import AutopilotManageQueued from './pages/Autopilot/Manage/Queued';
import AutopilotManage from './pages/Autopilot/Manage';
import AutopilotView from './pages/Autopilot/Autopilot';
import AutopilotCreate from './pages/Autopilot/Create';
import Prep from './pages/Prep';
import FindBuyers from './pages/FindBuyers';
import Client from './pages/Clients/Client';
import DataRoom from './pages/Clients/DataRoom';
import Intake from './pages/Clients/Intake';
import { BlindProfile } from './pages/Clients/BlindProfile';
import { CIM } from './pages/Clients/CIM';
import { BuyerList } from './pages/Clients/BuyerList';
import ValuationCalculator from './pages/Clients/ValuationCalculator';
import BuyerManagement from './pages/Clients/BuyerManagement';
import Prospect from './pages/Prep/Prospect';

export default function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Onboarding />} />
          <Route path="/autopilot" element={<Autopilots />} />
          <Route path="/autopilot/create" element={<AutopilotCreate />} />
          <Route path="/autopilot/:id" element={<AutopilotView />} />
          <Route path="/autopilot/:id/manage" element={<AutopilotManage />} />
          <Route
            path="/autopilot/:id/manage/queue"
            element={<AutopilotManageQueued />}
          />
          <Route
            path="/autopilot/:id/manage/pipeline"
            element={<AutopilotManagePipeline />}
          />
          <Route
            path="/autopilot/:id/manage/research"
            element={<AutopilotManageResearch />}
          />
          <Route
            path="/autopilot/:id/manage/engage"
            element={<AutopilotManageEngage />}
          />
          <Route path="/search" element={<Search />} />
          <Route path="/search2" element={<Search2 />} />
          <Route path="/search3" element={<Search3 />} />
          <Route path="/search4" element={<Search4 />} />
          <Route path="/search5" element={<Search5 />} />
          <Route path="/search6" element={<Search6 />} />
          <Route path="/lists" element={<Lists />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/clients/:id" element={<Client />} />
          <Route path="/clients/:id/blind_profile" element={<BlindProfile />} />
          <Route path="/clients/:id/cim" element={<CIM />} />
          <Route path="/clients/:id/buyer_list" element={<BuyerList />} />
          <Route path="/clients/:id/data_room" element={<DataRoom />} />
          <Route path="/clients/:id/intake" element={<Intake />} />
          <Route
            path="/clients/:id/buyer_management"
            element={<BuyerManagement />}
          />
          <Route
            path="/clients/:id/valuation_calculator"
            element={<ValuationCalculator />}
          />
          <Route path="/lists/:id" element={<List />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/business/:id" element={<Business />} />
          <Route path="/prep" element={<Prep />} />
          <Route path="/prep/:id" element={<Prospect />} />
          <Route path="/find_buyers" element={<FindBuyers />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
