import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { Card, Typography, Theme, useTheme, Button } from '@mui/joy';
import { tss } from 'tss-react';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(1),
  },
  card: {
    width: 400,
    boxShadow: theme.shadow.xl,
    padding: theme.spacing(3),
  },
}));

export function ErrorView() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const intercom = useIntercom();
  return (
    <div className={classes.container}>
      <Card variant="plain" className={classes.card}>
        <Typography level="h4">Oh, no! Something broke 😢</Typography>
        <Typography level="body-lg">
          Try refreshing the page, or chatting with us by clicking the button
          below
        </Typography>
        <Button onClick={() => intercom.show()}>Contact Support</Button>
      </Card>
    </div>
  );
}
