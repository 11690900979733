import React from 'react';
import { Control, FieldPath, FieldValues, FieldError } from 'react-hook-form';
import { InputProps } from '@mui/joy';
import { ControlledInput } from './ControlledInput';

interface CurrencyInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  name: TName;
  control: Control<TFieldValues>;
  inputProps?: InputProps;
  label?: string;
  required?: boolean;
  error?: FieldError;
}

export default function CurrencyInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  control,
  label,
  required,
  error,
  inputProps,
}: CurrencyInputProps<TFieldValues, TName>) {
  const formatValue = (val: string | number) => {
    if (typeof val !== 'string' && typeof val !== 'number') return '';
    const numericValue = String(val).replace(/[^-0-9.]/g, '');
    if (!numericValue) return '';
    if (numericValue === '-' || numericValue === '.') return numericValue;
    const [intPart, decPart] = numericValue.split('.');
    const absValue = Math.abs(parseFloat(intPart || '0'));
    const sign = numericValue.startsWith('-') ? '-' : '';
    const formattedInt = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 0,
    }).format(absValue);
    return `${sign}${formattedInt}${decPart !== undefined ? `.${decPart.slice(0, 2)}` : ''}`;
  };

  const parseValue = (val: string | number | undefined) => {
    if (val === undefined || val === '') return undefined;
    const stringVal = String(val);
    const parsed = stringVal.replace(/[^-0-9.]/g, '');
    if (parsed === '' || parsed === '-' || parsed === '.') return undefined;
    if (parsed === '0') return 0;
    return parseFloat(parsed);
  };

  return (
    <ControlledInput
      label={label}
      name={name}
      control={control}
      inputProps={{
        type: 'text',
        inputMode: 'numeric',
        startDecorator: '$',
        ...inputProps,
      }}
      required={required}
      error={error}
      extraRules={{
        setValueAs: parseValue,
        validate: (value) => {
          if (required && (value === undefined || value === null)) {
            return 'This field is required';
          }
          if (
            value !== undefined &&
            (Number.isNaN(value) || !Number.isFinite(value))
          ) {
            return 'Please enter a valid number';
          }
          return true;
        },
      }}
    >
      {(field) => ({
        ...field,
        onChange: (e: React.ChangeEvent<HTMLInputElement> | string) => {
          const value = typeof e === 'string' ? e : e.target.value;
          if (
            value === '' ||
            value === '-' ||
            value === '.' ||
            value.endsWith('.') ||
            /^\d*\.\d{0,2}$/.test(value)
          ) {
            field.onChange(value);
          } else {
            const parsedValue = parseValue(value);
            field.onChange(parsedValue);
          }
        },
        onBlur: () => {
          field.onBlur();
          const parsedValue = parseValue(field.value);
          field.onChange(parsedValue);
        },
        value:
          field.value !== undefined
            ? typeof field.value === 'string'
              ? field.value
              : formatValue(field.value)
            : '',
      })}
    </ControlledInput>
  );
}
