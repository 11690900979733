import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tss } from 'tss-react';
import { Button, IconButton, Theme, Typography, useTheme } from '@mui/joy';
import {
  DataGridPro,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueGetterParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { ChevronLeft, Email, PlayArrow } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { GetAutopilotManageEngageResponse } from 'src/generated/api';
import { SnackbarContext } from 'src/contexts/snackbar';
import { BusinessDrawer } from 'src/components/BusinessDrawer';
import { getAPI } from 'src/api';
import AboutCell from './AboutCell';
import EmailCell from './EmailCell';
import EmailModal from './EmailModal';
import ConfigureModal from './ConfigureModal';
import ColHeader from '../ColHeader';
import OverwriteEmailModal from './OverwriteEmailModal';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  contents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    height: '75vh',
  },
  tableScroller: {
    '::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 12,
      height: 12,
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  cell: {
    wordBreak: 'break-word',
  },
  buttons: {
    marginTop: 'auto',
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  approvedRow: {
    backgroundColor: theme.palette.success.softBg,
  },
  headerButton: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    padding: theme.spacing(1),
  },
}));

export default function AutopilotEngage() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const { classes } = useStyles({ theme });
  const [rowSelectionModel, setRowSelectionModel] =
    React.useState<GridRowSelectionModel>([]);
  const [businessDrawerId, setBusinessDrawerId] = React.useState<string | null>(
    null,
  );
  const [overwriteEmailVal, setOverwriteEmailVal] = useState<{
    businessId: string;
    sequence: GetAutopilotManageEngageResponse['businesses'][number]['sequence'];
  } | null>(null);
  const { showSnackbar } = useContext(SnackbarContext);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showConfigureModal, setShowConfigureModal] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 2000,
  });
  const [list, setList] = useState<GetAutopilotManageEngageResponse | null>(
    null,
  );
  const api = getAPI();
  const [emailModalVal, setEmailModalVal] = useState<
    GetAutopilotManageEngageResponse['emailCols'][number] | null
  >(null);
  const fetchData = useCallback(async () => {
    const listRes = await api.autopilot.getManageEngage(id!, {
      pageNum: paginationModel.page,
      pageSize: paginationModel.pageSize,
    });
    setList(listRes.data);
  }, [api, paginationModel, id]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  const gridApiRef = useGridApiRef();
  const runOnSelected = useCallback(async () => {
    showSnackbar({
      color: 'success',
      message: `Writing emails for ${rowSelectionModel.length} businesses`,
    });
    setRowSelectionModel([]);
    await api.autopilot.regenerateEmail(id!, {
      businessIds: rowSelectionModel as string[],
    });
  }, [showSnackbar, rowSelectionModel, api.autopilot, id]);
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      fetchData();
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, api, list]);
  const columns: GridColDef[] = [
    {
      field: 'about',
      width: 350,
      disableReorder: true,
      headerName: 'About',
      cellClassName: (
        params: GridCellParams<
          GetAutopilotManageEngageResponse['businesses'][number]
        >,
      ) => (params.row.sequence.approved ? classes.approvedRow : ''),
      valueGetter: (
        data: GridValueGetterParams<
          GetAutopilotManageEngageResponse['businesses'][number]
        >,
      ) => data.row.name,
      renderCell: (
        data: GridRenderCellParams<
          GetAutopilotManageEngageResponse['businesses'][number]
        >,
      ) => (
        <AboutCell
          campaignId={id!}
          business={data.row}
          setDrawerId={setBusinessDrawerId}
          refetch={fetchData}
          showOverwriteEmails={() =>
            setOverwriteEmailVal({
              businessId: data.row.id,
              sequence: data.row.sequence,
            })
          }
        />
      ),
      disableColumnMenu: true,
      renderHeader: () => (
        <Typography level="body-md">
          <b>About</b>
        </Typography>
      ),
    },
    ...(list?.emailCols.map((col, idx) => ({
      field: col.id,
      width: 500,
      disableColumnMenu: true,
      sortable: false,
      valueGetter: (
        params: GridRenderCellParams<
          GetAutopilotManageEngageResponse['businesses'][number]
        >,
      ) => params.row.sequence.emails.find((x) => x.colId === col.id)?.value,
      renderCell: (
        data: GridRenderCellParams<
          GetAutopilotManageEngageResponse['businesses'][number]
        >,
      ) => {
        const val = data.row.sequence.emails.find((x) => x.colId === col.id);
        const { subject } = data.row.sequence;
        if (!val) {
          return <div />;
        }
        return (
          <EmailCell
            subject={idx === 0 ? subject : `Re: ${subject}`}
            email={val}
            isApproved={data.row.sequence.approved}
          />
        );
      },
      renderHeader: () => (
        <ColHeader
          title={`Email #${idx + 1} (Day ${col.waitForDays + 1})`}
          showEditModal={() => {
            setEmailModalVal(col);
            setShowEmailModal(true);
          }}
          onDelete={async () => {
            await api.autopilot.deleteEmailCol(id!, col.id);
            fetchData();
          }}
        />
      ),
    })) ?? []),
  ];
  useEffect(() => {
    // Hacky workaround until websockets are added
    const handle = setInterval(() => {
      fetchData();
    }, 3000);
    return () => {
      clearInterval(handle);
    };
  }, [fetchData, api, list]);
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <IconButton onClick={() => navigate(`/autopilot/${id}/manage`)}>
            <ChevronLeft />
          </IconButton>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <Email />
              Engage Acquisition Targets
            </Typography>
            <Typography level="body-lg">
              Personalized email campaigns, managed by our AI broker, to
              qualified targets
            </Typography>
          </div>
          <div className={classes.buttons}>
            {rowSelectionModel.length > 0 && (
              <>
                <Button
                  color="success"
                  onClick={runOnSelected}
                  className={classes.headerButton}
                >
                  <PlayArrow />
                  Run ({rowSelectionModel.length})
                </Button>
              </>
            )}
            <Button
              variant="outlined"
              onClick={() => setShowConfigureModal(true)}
            >
              Configure
            </Button>
            <Button
              color="success"
              variant="outlined"
              onClick={() => setShowEmailModal(true)}
            >
              Add Email
            </Button>
          </div>
        </div>
        <div className={classes.tableContainer}>
          <DataGridPro
            pagination
            checkboxSelection
            rowSelectionModel={rowSelectionModel}
            onRowSelectionModelChange={setRowSelectionModel}
            apiRef={gridApiRef}
            disableRowSelectionOnClick
            rowCount={list?.size}
            loading={list == null}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            getRowHeight={() => 'auto'}
            showCellVerticalBorder
            pageSizeOptions={[2000]}
            showColumnVerticalBorder
            classes={{
              virtualScroller: classes.tableScroller,
            }}
            pinnedColumns={{
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'about'],
            }}
            columnHeaderHeight={32}
            columns={columns}
            rows={list?.businesses ?? []}
          />
        </div>
      </div>
      <EmailModal
        campaignId={id!}
        open={showEmailModal}
        emailCol={emailModalVal}
        onClose={() => {
          setEmailModalVal(null);
          setShowEmailModal(false);
          fetchData();
        }}
      />
      {list && (
        <ConfigureModal
          campaignId={id!}
          open={showConfigureModal}
          onClose={() => {
            setShowConfigureModal(false);
            fetchData();
          }}
          autopilot={list}
        />
      )}
      {overwriteEmailVal && (
        <OverwriteEmailModal
          campaignId={id!}
          businessId={overwriteEmailVal.businessId}
          sequence={overwriteEmailVal.sequence}
          onClose={() => setOverwriteEmailVal(null)}
        />
      )}
      {list && (
        <BusinessDrawer
          initialBusinessId={businessDrawerId}
          onClose={() => setBusinessDrawerId(null)}
        />
      )}
    </div>
  );
}
