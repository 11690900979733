import React from 'react';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
  Alert,
} from '@mui/joy';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { getAPI } from 'src/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 600,
    padding: theme.spacing(2),
    maxHeight: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    overflow: 'auto',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  transcriptContainer: {
    maxHeight: 500,
    overflow: 'auto',
  },
  footer: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: theme.vars.palette.background.surface,
    borderTop: `1px solid ${theme.vars.palette.divider}`,
    paddingTop: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  onClose: () => void;
};

export default function CreateClientModal({ onClose }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();
  const [error, setError] = React.useState<string | null>(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      domain: '',
      name: '',
      codeName: '',
      transcript: '',
      ownerEmail: '',
      advisorName: '',
      advisorTitle: '',
      advisorEmail: '',
      advisorPhone: '',
      firmName: '',
    },
  });
  const api = getAPI();
  const onCreate: SubmitHandler<{
    domain: string;
    name: string;
    codeName: string;
    transcript: string;
    ownerEmail: string;
    advisorName: string;
    advisorTitle: string;
    advisorEmail: string;
    advisorPhone: string;
    firmName: string;
  }> = async (data) => {
    try {
      setError(null);
      const res = await api.clients.createProject({
        domain: data.domain,
        name: data.name,
        codeName: data.codeName,
        transcript: data.transcript,
        ownerEmail: data.ownerEmail,
        advisorName: data.advisorName,
        advisorTitle: data.advisorTitle,
        advisorEmail: data.advisorEmail,
        advisorPhone: data.advisorPhone,
        firmName: data.firmName,
      });
      navigate(`/clients/${res.data.id}`);
    } catch (err: any) {
      if (err.error && err.error.message) {
        setError(err.error.message);
      } else {
        setError('An unexpected error occurred.');
      }
    }
  };

  return (
    <Modal open onClose={onClose}>
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level="h4">Create Client</Typography>
        {error && (
          <Alert color="danger" variant="soft">
            {error}
          </Alert>
        )}
        <div className={classes.form}>
          <div className={classes.row}>
            <ControlledInput
              required
              label="Client Legal Name"
              name="name"
              control={control}
              error={errors.name}
            />
            <ControlledInput
              required
              label="Project Name"
              name="codeName"
              control={control}
              error={errors.codeName}
            />
          </div>
          <div className={classes.row}>
            <ControlledInput
              required
              label="Domain"
              name="domain"
              control={control}
              error={errors.domain}
            />
            <ControlledInput
              required
              label="Client Email"
              name="ownerEmail"
              control={control}
              error={errors.ownerEmail}
            />
          </div>
          <div className={classes.row}>
            <ControlledInput
              required
              label="Advisor Name"
              name="advisorName"
              control={control}
              error={errors.advisorName}
            />
            <ControlledInput
              required
              label="Advisor Title"
              name="advisorTitle"
              control={control}
              error={errors.advisorTitle}
            />
          </div>
          <div className={classes.row}>
            <ControlledInput
              required
              label="Advisor Email"
              name="advisorEmail"
              control={control}
              error={errors.advisorEmail}
            />
            <ControlledInput
              required
              label="Advisor Phone"
              name="advisorPhone"
              control={control}
              error={errors.advisorPhone}
            />
          </div>
          <ControlledInput
            required
            label="Firm Name"
            name="firmName"
            control={control}
            error={errors.firmName}
          />
          <div className={classes.transcriptContainer}>
            <ControlledInput
              textArea
              label="Onboarding Transcript"
              name="transcript"
              control={control}
              error={errors.transcript}
              inputProps={{
                fullWidth: true,
              }}
            />
          </div>
        </div>
        <div className={classes.footer}>
          <Button onClick={handleSubmit(onCreate)} fullWidth>
            Create
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
