import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import {
  Select,
  Theme,
  Typography,
  useTheme,
  Option,
  Button,
  Checkbox,
} from '@mui/joy';
import { SnackbarContext } from 'src/contexts/snackbar';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import { logEvent } from 'src/analytics';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 500,
    padding: theme.spacing(2),
  },
  uploadZone: {
    display: 'flex',
    padding: theme.spacing(6),
    marginTop: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderColor: theme.palette.neutral[800],
    borderRadius: theme.spacing(2),
    borderWidth: 1,
    borderStyle: 'dashed',
    cursor: 'pointer',
  },
  columnMapping: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  columnMappingRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  select: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 300,
  },
}));

type Props = {
  id: string;
  onClose: (imported: boolean) => void;
};

type ColumnMapping = {
  website: string | null;
  revenue: string | null;
  funding: string | null;
  numEmployees: string | null;
  city: string | null;
  state: string | null;
};

export default function FileUpload({ id, onClose }: Props) {
  const theme = useTheme();
  const { showSnackbar } = useContext(SnackbarContext);
  const { classes } = useStyles({ theme });
  const [loading, setLoading] = useState(false);
  const [fileId, setFileId] = useState<string | null>(null);
  const [columnMapping, setColumnMapping] = useState<ColumnMapping>({
    website: null,
    revenue: null,
    funding: null,
    numEmployees: null,
    city: null,
    state: null,
  });
  const [columnNames, setColumnNames] = useState<string[]>([]);
  const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
    const api = getAPI();
    setLoading(true);
    const signedUrlRes = await api.files.createSignedUrl();
    await axios.put(signedUrlRes.data.url.replace(/"/g, ''), acceptedFiles[0], {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    const columnNamesRes = await api.lists.getImportColumnNames(
      signedUrlRes.data.fileId,
    );
    setColumnNames(columnNamesRes.data.columnNames);
    setFileId(signedUrlRes.data.fileId);
    setLoading(false);
  }, []);
  const onDropRejected = useCallback(async () => {
    showSnackbar({
      color: 'danger',
      message: 'Only CSV files are supported!',
    });
  }, [showSnackbar]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'text/csv': [],
    },
    onDropAccepted,
    onDropRejected,
    multiple: false,
  });
  const onSubmit = useCallback(async () => {
    const api = getAPI();
    setLoading(true);
    await api.lists.completeImport(fileId!, {
      mapping: {
        website: columnMapping.website!,
        city: columnMapping.city,
        state: columnMapping.state,
        revenue: columnMapping.revenue,
        funding: columnMapping.funding,
        numEmployees: columnMapping.numEmployees,
      },
      listId: id,
    });
    setLoading(false);
    setColumnNames([]);
    setFileId(null);
    setColumnMapping({
      website: null,
      revenue: null,
      funding: null,
      numEmployees: null,
      city: null,
      state: null,
    });
    logEvent({
      name: 'import_submit',
      type: 'click',
    });
    onClose(true);
  }, [onClose, fileId, columnMapping, id]);

  return (
    <LoadingContainer isLoading={loading}>
      {fileId == null ? (
        <>
          <Typography level="h4">Import Businesses</Typography>
          <Typography level="body-md">
            Upload a CSV file and import businesses to this list
          </Typography>
          <div className={classes.uploadZone} {...getRootProps()}>
            <input {...getInputProps()} />
            {<p>Drag and drop a CSV file here</p>}
          </div>
          <Checkbox label="Do not show to other users" />
        </>
      ) : (
        <>
          <Typography level="h4">Map Columns</Typography>
          <Typography level="body-md">
            Select the column in your uploaded file to map each attribute to
          </Typography>
          <div className={classes.columnMapping}>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b>Website*</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.website}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    website: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b>City</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.city}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    city: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b>State</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.state}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    state: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b>Revenue</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.revenue}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    revenue: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b>Total Funding ($)</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.funding}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    funding: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
            <div className={classes.columnMappingRow}>
              <Typography level="body-lg">
                <b># Employees</b>
              </Typography>
              <Select
                className={classes.select}
                value={columnMapping.numEmployees}
                onChange={(e, newVal) => {
                  setColumnMapping((prev) => ({
                    ...prev,
                    numEmployees: newVal,
                  }));
                }}
              >
                {columnNames.map((c) => (
                  <Option value={c} key={c}>
                    {c}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <Button disabled={!columnMapping.website} onClick={onSubmit}>
            Submit
          </Button>
        </>
      )}
    </LoadingContainer>
  );
}
