import React, { useCallback, useContext, useState } from 'react';
import { tss } from 'tss-react';
import {
  Autocomplete,
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { Paid, Person, Map, Block } from '@mui/icons-material';
import { NumericFormatAdapter } from 'src/components/FormattedNumberInput';
import { SnackbarContext } from 'src/contexts/snackbar';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    width: 500,
    padding: theme.spacing(2),
    gap: theme.spacing(3),
  },
  filterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  filterContentsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'flex-end',
  },
  geoFilterContents: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  geoFilterRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filterTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    marginRight: 'auto',
  },
  revenueInput: {
    maxWidth: 140,
  },
  employeeInput: {
    maxWidth: 80,
  },
  field: {
    flex: 1,
  },
  simpleRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  autocompleteRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  autocomplete: {
    width: '100%',
  },
}));

type Props = {
  id: string;
  geoFilterHqStates: string[];
  geoFilterServiceAreaStates: string[];
  employeeFilterMin: number | null;
  employeeFilterMax: number | null;
  revenueFilterMin: number | null;
  revenueFilterMax: number | null;
  excludeProductService: string[];
  excludeCustomerSegment: string[];
  onClose: () => void;
};

export default function FiltersModal({
  id,
  geoFilterHqStates,
  geoFilterServiceAreaStates,
  employeeFilterMin,
  employeeFilterMax,
  revenueFilterMin,
  revenueFilterMax,
  excludeProductService,
  excludeCustomerSegment,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const [data, setData] = useState({
    geoFilterHqStates,
    geoFilterServiceAreaStates,
    employeeFilterMin,
    employeeFilterMax,
    revenueFilterMin,
    revenueFilterMax,
    excludeProductService,
    excludeCustomerSegment,
  });
  const api = getAPI();
  const { showSnackbar } = useContext(SnackbarContext);
  const onSubmit = useCallback(async () => {
    await api.autopilot.configureSuggestions(id, {
      geoFilterHqStates: data.geoFilterHqStates,
      geoFilterServiceAreaStates: data.geoFilterServiceAreaStates,
      employeeFilterMin: data.employeeFilterMin,
      employeeFilterMax: data.employeeFilterMax,
      revenueFilterMin: data.revenueFilterMin,
      revenueFilterMax: data.revenueFilterMax,
      excludeProductService: data.excludeProductService,
      excludeCustomerSegment: data.excludeCustomerSegment,
    });
    showSnackbar({
      message: 'Updated filters',
      color: 'success',
    });
    onClose();
  }, [api, data, id, onClose, showSnackbar]);

  return (
    <Modal open onClose={onClose}>
      <ModalDialog className={classes.container}>
        <ModalClose />
        <Typography level="h4">Update Filters</Typography>
        <div className={classes.filterContents}>
          <div className={classes.filterContentsRow}>
            <Typography level="body-md" className={classes.filterTitle}>
              <Map />
              <b>Geography</b>
            </Typography>
          </div>
          <FormControl className={classes.field}>
            <div className={classes.simpleRow}>
              <FormLabel>HQ (State)</FormLabel>
            </div>
            <Autocomplete
              freeSolo
              multiple
              placeholder="NY, CA, TX"
              value={data.geoFilterHqStates}
              autoSelect
              onChange={(e, val) => {
                setData((prev) => ({
                  ...prev,
                  geoFilterHqStates: val,
                }));
              }}
              onInputChange={(e, newVal) => {
                if (newVal.endsWith(',')) {
                  (e.target as HTMLElement).blur();
                  (e.target as HTMLElement).focus();
                }
              }}
              renderTags={(tags, getTagProps) =>
                tags.map((item, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    color="primary"
                    onClick={undefined}
                    endDecorator={
                      <ChipDelete onDelete={getTagProps({ index }).onClick} />
                    }
                  >
                    {item}
                  </Chip>
                ))
              }
              slotProps={{
                input: {
                  maxLength: 2,
                },
              }}
              options={[]}
            />
          </FormControl>
          <FormControl className={classes.field}>
            <FormLabel>Service Area (State)</FormLabel>
            <Autocomplete
              freeSolo
              multiple
              placeholder="NY, CA, TX"
              value={data.geoFilterServiceAreaStates}
              onChange={(e, val) => {
                setData((prev) => ({
                  ...prev,
                  geoFilterServiceAreaStates: val.map((v) => v.toUpperCase()),
                }));
              }}
              autoSelect
              onInputChange={(e, newVal) => {
                if (newVal.endsWith(',')) {
                  (e.target as HTMLElement).blur();
                  (e.target as HTMLElement).focus();
                }
              }}
              renderTags={(tags, getTagProps) =>
                tags.map((item, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    color="primary"
                    onClick={undefined}
                    endDecorator={
                      <ChipDelete onDelete={getTagProps({ index }).onClick} />
                    }
                  >
                    {item}
                  </Chip>
                ))
              }
              options={[]}
              slotProps={{
                input: {
                  maxLength: 2,
                },
              }}
            />
          </FormControl>
        </div>
        <div className={classes.filterContents}>
          <Typography level="body-md" className={classes.filterTitle}>
            <Block />
            <b>Exclude</b>
          </Typography>
          <FormControl className={classes.field}>
            <FormLabel>Products & Services</FormLabel>
            <div className={classes.autocompleteRow}>
              <Autocomplete
                freeSolo
                className={classes.autocomplete}
                placeholder="rigging, hvac, hydro blasting"
                multiple
                value={data.excludeProductService}
                onChange={(e, val) => {
                  setData((prev) => ({
                    ...prev,
                    excludeProductService: val,
                  }));
                }}
                autoSelect
                onInputChange={(e, newVal) => {
                  const inputEl = e.target as HTMLInputElement;
                  if (newVal.endsWith(',')) {
                    inputEl.blur();
                    inputEl.focus();
                  }
                }}
                options={[]}
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      color="warning"
                      onClick={undefined}
                      endDecorator={
                        <ChipDelete onDelete={getTagProps({ index }).onClick} />
                      }
                    >
                      {item}
                    </Chip>
                  ))
                }
              />
            </div>
          </FormControl>
          <FormControl className={classes.field}>
            <FormLabel>End Customer</FormLabel>
            <div className={classes.autocompleteRow}>
              <Autocomplete
                freeSolo
                multiple
                autoSelect
                className={classes.autocomplete}
                placeholder="industrial companies, construction, municipal"
                value={data.excludeCustomerSegment}
                onChange={(e, val) => {
                  setData((prev) => ({
                    ...prev,
                    excludeCustomerSegment: val,
                  }));
                }}
                onInputChange={(e, newVal) => {
                  if (newVal.endsWith(',')) {
                    (e.target as HTMLElement).blur();
                    (e.target as HTMLElement).focus();
                  }
                }}
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      color="success"
                      onClick={undefined}
                      endDecorator={
                        <ChipDelete onDelete={getTagProps({ index }).onClick} />
                      }
                    >
                      {item}
                    </Chip>
                  ))
                }
                options={[]}
              />
            </div>
          </FormControl>
        </div>
        <div className={classes.filterContents}>
          <div className={classes.filterContentsRow}>
            <Typography level="body-md" className={classes.filterTitle}>
              <Paid />
              <b>Revenue</b>
            </Typography>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <Input
                value={data.revenueFilterMin?.toString()}
                startDecorator="$"
                className={classes.revenueInput}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    component: NumericFormatAdapter,
                  },
                }}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    revenueFilterMin: parseInt(e.target.value, 10),
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Max</FormLabel>
              <Input
                value={data.revenueFilterMax?.toString()}
                startDecorator="$"
                className={classes.revenueInput}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                    component: NumericFormatAdapter,
                  },
                }}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    revenueFilterMax: parseInt(e.target.value, 10),
                  }))
                }
              />
            </FormControl>
          </div>
        </div>
        <div className={classes.filterContents}>
          <div className={classes.filterContentsRow}>
            <Typography level="body-md" className={classes.filterTitle}>
              <Person />
              <b>Employees</b>
            </Typography>
            <FormControl>
              <FormLabel>Min</FormLabel>
              <Input
                value={data.employeeFilterMin?.toString()}
                className={classes.employeeInput}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                  },
                }}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    employeeFilterMin: parseInt(e.target.value, 10),
                  }))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>Max</FormLabel>
              <Input
                value={data.employeeFilterMax?.toString()}
                className={classes.employeeInput}
                slotProps={{
                  input: {
                    inputMode: 'numeric',
                  },
                }}
                onChange={(e) =>
                  setData((prev) => ({
                    ...prev,
                    employeeFilterMax: parseInt(e.target.value, 10),
                  }))
                }
              />
            </FormControl>
          </div>
        </div>
        <Button onClick={onSubmit}>Update</Button>
      </ModalDialog>
    </Modal>
  );
}
