import React from 'react';

import { Button, IconButton, Theme, Typography, useTheme } from '@mui/joy';
import {
  EnrichColType,
  V1GetListResponse,
  Type24EnumsEnrichColType,
} from 'src/generated/api';
import { tss } from 'tss-react';
import { ChevronLeft } from '@mui/icons-material';
import { getAPI } from 'src/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { logEvent } from 'src/analytics';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  closeButton: {
    width: 20,
    height: 20,
    minHeight: 0,
    minWidth: 0,
    marginLeft: 'auto',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  formLabel: {
    width: '100%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

type Props = {
  enrichment: V1GetListResponse['enrichmentColumns'][number] | null;
  listId: string;
  onClose: (modified?: { id: string; type: EnrichColType }) => void;
  onBack: () => void;
};

export default function IsBootstrapped({
  enrichment,
  listId,
  onClose,
  onBack,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: 'Bootstrapped',
    },
    values: {
      name: enrichment?.name ?? 'Bootstrapped',
    },
  });
  const onSubmit: SubmitHandler<{
    name: string;
  }> = async (data) => {
    const api = getAPI();
    let modifiedId: string;
    if (enrichment) {
      await api.enrichments.editIsBootstrapped(listId, enrichment.id, {
        name: data.name,
      });
      modifiedId = enrichment.id;
      logEvent({
        name: 'enrichment_update_submit',
        type: 'click',
        extra: {
          enrichmentType: 'IS_BOOTSTRAPPED',
        },
      });
    } else {
      const res = await api.enrichments.createIsBootstrapped(listId, {
        name: data.name,
      });
      modifiedId = res.data.id;
      logEvent({
        name: 'enrichment_create_submit',
        type: 'click',
        extra: {
          enrichmentType: 'IS_BOOTSTRAPPED',
        },
      });
    }
    onClose({
      id: modifiedId,
      type: Type24EnumsEnrichColType.IS_BOOTSTRAPPED,
    });
    reset();
  };
  return (
    <div className={classes.contents}>
      <div className={classes.header}>
        <div className={classes.headerRow}>
          {!enrichment && (
            <IconButton onClick={onBack} className={classes.backButton}>
              <ChevronLeft />
            </IconButton>
          )}
          <Typography level="h4">Bootstrapped</Typography>
        </div>
        <Typography level="body-md">
          Checks PitchBook to see if this business is funded or bootstrapped.
        </Typography>
      </div>
      <ControlledInput
        required
        label="Column Name"
        name="name"
        control={control}
        error={errors.name}
      />
      <Button onClick={handleSubmit(onSubmit)} className={classes.submitButton}>
        {enrichment == null ? 'Create' : 'Edit'}
      </Button>
    </div>
  );
}
