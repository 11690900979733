import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  IconButton,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import {
  EnrichColType,
  V1GetListResponse,
  Type24EnumsEnrichColType,
} from 'src/generated/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { tss } from 'tss-react';
import { ChevronLeft } from '@mui/icons-material';
import { logEvent } from 'src/analytics';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
}));

type Props = {
  enrichment: V1GetListResponse['enrichmentColumns'][number] | null;
  listId: string;
  onClose: (modified?: { id: string; type: EnrichColType }) => void;
  onBack: () => void;
};

export default function ContactInfo({
  enrichment,
  listId,
  onClose,
  onBack,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const [jobTitles, setJobTitles] = useState<string[]>([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: 'Owner',
    },
    values: {
      name: enrichment?.name ?? 'Owner',
    },
  });
  const onSubmit: SubmitHandler<{
    name: string;
  }> = async (data) => {
    // TODO: More Validation
    let modifiedId: string;
    if (enrichment) {
      await api.enrichments.editContactInfo(listId, enrichment.id, {
        name: data.name,
        jobTitles,
      });
      modifiedId = enrichment.id;
      logEvent({
        name: 'enrichment_update_submit',
        type: 'click',
        extra: {
          enrichmentType: 'CONTACT_INFO',
        },
      });
    } else {
      const res = await api.enrichments.createContactInfo(listId, {
        name: data.name,
        jobTitles,
      });
      modifiedId = res.data.id;
      logEvent({
        name: 'enrichment_create_submit',
        type: 'click',
        extra: {
          enrichmentType: 'CONTACT_INFO',
        },
      });
    }
    onClose({
      id: modifiedId,
      type: Type24EnumsEnrichColType.CONTACT_INFO,
    });
    reset();
  };
  useEffect(() => {
    if (enrichment?.contactInfo == null) {
      setJobTitles([
        'CEO',
        'Chief Executive Officer',
        'Owner',
        'President',
        'Chairman',
      ]);
      return;
    }
    setJobTitles(enrichment.contactInfo.jobTitles);
  }, [enrichment]);
  return (
    <div className={classes.contents}>
      <div className={classes.headerRow}>
        {!enrichment && (
          <IconButton onClick={onBack} className={classes.backButton}>
            <ChevronLeft />
          </IconButton>
        )}
        <Typography level="h4">Find Contact Info</Typography>
      </div>
      <Typography level="body-md">
        Identify a decision maker in businesses by job title and search for
        their name, email address, linkedin, and mobile number across our
        partner databases
      </Typography>
      <ControlledInput
        required
        label="Column Name"
        name="name"
        control={control}
        error={errors.name}
      />
      <FormControl>
        <FormLabel>Job Title</FormLabel>
        <Autocomplete
          multiple
          freeSolo
          autoSelect
          placeholder="CEO, Owner, VP of Engineering"
          value={jobTitles}
          onChange={(e, val) => setJobTitles(val)}
          onInputChange={(e, newVal) => {
            if (newVal.endsWith(',')) {
              (e.target as HTMLElement).blur();
              (e.target as HTMLElement).focus();
            }
          }}
          renderTags={(tags, getTagProps) =>
            tags.map((item, index) => (
              <Chip
                {...getTagProps({ index })}
                color="primary"
                onClick={undefined}
                endDecorator={
                  <ChipDelete onDelete={getTagProps({ index }).onClick} />
                }
              >
                {item}
              </Chip>
            ))
          }
          options={[]}
        />
      </FormControl>
      <Button onClick={handleSubmit(onSubmit)}>
        {enrichment == null ? 'Create' : 'Edit'}
      </Button>
    </div>
  );
}
