import React, { useContext } from 'react';
import {
  Button,
  FormControl,
  FormHelperText,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetAutopilotManageEngageResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import { InfoOutlined } from '@mui/icons-material';
import { getAPI } from 'src/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AuthContext } from 'src/contexts/auth';
import { ControlledInput } from 'src/components/ControlledInput';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  examplesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  instructionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    width: 20,
    height: 20,
    minHeight: 0,
    minWidth: 0,
    marginLeft: 'auto',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  formLabel: {
    width: '100%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
  enrichmentModal: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: 600,
  },
  autoApproveSwitch: {
    alignSelf: 'flex-start',
  },
}));

type Props = {
  campaignId: string;
  open: boolean;
  onClose: () => void;
  autopilot: GetAutopilotManageEngageResponse;
};

export default function ConfigureModal({
  campaignId,
  open,
  onClose,
  autopilot,
}: Props) {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      signature: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      subjectInstruction: `Use this format: [Business Owner Name] <> ${user?.firstName} Intro`,
    },
    values: {
      signature: autopilot.signature,
      subjectInstruction:
        autopilot.subjectInstruction ||
        `Use this format: [Business Owner Name] <> ${user?.firstName} Intro`,
      emailAddress: autopilot.senderEmailAddress,
      firstName: autopilot.senderFirstName,
      lastName: autopilot.senderLastName,
    },
  });
  const onSubmit: SubmitHandler<{
    signature: string;
    subjectInstruction: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
  }> = async (data) => {
    const api = getAPI();
    await api.autopilot.configureEngage(campaignId, {
      signature: data.signature,
      subjectInstruction: data.subjectInstruction,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
    });
    onClose();
  };
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerRow}>
              <Typography level="h4">Configure Emails</Typography>
            </div>
          </div>
          <ControlledInput
            label="First Name"
            name="firstName"
            control={control}
            error={errors.firstName}
          />
          <ControlledInput
            label="Last Name"
            name="lastName"
            control={control}
            error={errors.lastName}
          />
          <ControlledInput
            label="Email Address"
            name="emailAddress"
            control={control}
            error={errors.emailAddress}
          />
          <Typography level="body-md">
            <b>Subject Line - Instructions</b>
          </Typography>
          <Typography level="body-sm">
            Instructions to the AI broker on how to craft subject lines
          </Typography>
          <FormControl error={Boolean(errors.signature)}>
            <Controller
              name="subjectInstruction"
              control={control}
              rules={{ required: 'This is required!' }}
              render={({ field }) => (
                <Textarea minRows={3} size="md" {...field} />
              )}
            />
            {errors.subjectInstruction && (
              <FormHelperText>
                <InfoOutlined />
                {errors.subjectInstruction.message}
              </FormHelperText>
            )}
          </FormControl>
          <Typography level="body-md">
            <b>Signature Block</b>
          </Typography>
          <Typography level="body-sm">
            What should be included at the bottom of every email
          </Typography>
          <FormControl error={Boolean(errors.signature)}>
            <Controller
              name="signature"
              control={control}
              rules={{ required: 'This is required!' }}
              render={({ field }) => (
                <Textarea minRows={3} size="md" {...field} />
              )}
            />
            {errors.signature && (
              <FormHelperText>
                <InfoOutlined />
                {errors.signature.message}
              </FormHelperText>
            )}
          </FormControl>
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
