import React, { useCallback, useState } from 'react';

import {
  Autocomplete,
  Button,
  Chip,
  ChipDelete,
  FormControl,
  FormLabel,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { tss } from 'tss-react';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
  enrichmentModal: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: 600,
  },
}));

type Props = {
  currentJobTitles: string[];
  campaignId: string;
  onClose: () => void;
  open: boolean;
};

export default function ContactInfoModal({
  currentJobTitles,
  campaignId,
  onClose,
  open,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const [jobTitles, setJobTitles] = useState<string[]>(currentJobTitles);
  const onSubmit = useCallback(async () => {
    await api.autopilot.configureAutopilotJobTitles(campaignId, {
      jobTitles,
    });
    onClose();
  }, [api, onClose, campaignId, jobTitles]);
  return (
    <Modal
      open={open}
      onClose={(e, reason) => {
        if (reason === 'closeClick') {
          onClose();
        }
      }}
    >
      <ModalDialog className={classes.enrichmentModal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.headerRow}>
            <Typography level="h4">Find Contact Info</Typography>
          </div>
          <Typography level="body-md">
            Identify the target point of contact in the businesses by job title
            and search for their name, email address, linkedin, and mobile phone
            across our partner databases
          </Typography>
          <FormControl>
            <FormLabel>Job Title</FormLabel>
            <Autocomplete
              multiple
              freeSolo
              autoSelect
              placeholder="CEO, Owner, VP of Engineering"
              value={jobTitles}
              onChange={(e, val) => setJobTitles(val)}
              onInputChange={(e, newVal) => {
                if (newVal.endsWith(',')) {
                  (e.target as HTMLElement).blur();
                  (e.target as HTMLElement).focus();
                }
              }}
              renderTags={(tags, getTagProps) =>
                tags.map((item, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    color="primary"
                    onClick={undefined}
                    endDecorator={
                      <ChipDelete onDelete={getTagProps({ index }).onClick} />
                    }
                  >
                    {item}
                  </Chip>
                ))
              }
              options={[]}
            />
          </FormControl>
          <Button onClick={onSubmit}>Update</Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
