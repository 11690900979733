import React from 'react';
import {
  Button,
  Modal,
  ModalClose,
  ModalDialog,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { tss } from 'tss-react';
import { getAPI } from 'src/api';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { GetAutopilotBusinessViewResponse } from 'src/generated/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  modal: {
    padding: theme.spacing(2),
    overflowY: 'auto',
    width: 600,
  },
}));

type Props = {
  campaignId: string;
  businessId: string;
  sequence: GetAutopilotBusinessViewResponse['sequence'];
  onClose: () => void;
};

export default function SubjectModal({
  campaignId,
  businessId,
  sequence,
  onClose,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    values: {
      subject: sequence!.subject,
    },
  });
  const onSubmit: SubmitHandler<{
    subject: string;
  }> = async (data) => {
    const api = getAPI();
    await api.autopilot.updateSequenceSubject(campaignId, businessId, {
      subject: data.subject,
    });
    onClose();
  };
  return (
    <Modal open onClose={onClose}>
      <ModalDialog className={classes.modal}>
        <ModalClose />
        <div className={classes.contents}>
          <div className={classes.header}>
            <div className={classes.headerRow}>
              <Typography level="h4">Update Subject</Typography>
            </div>
          </div>
          <ControlledInput
            required
            label="Subject"
            name="subject"
            control={control}
            error={errors.subject}
          />
          <Button
            onClick={handleSubmit(onSubmit)}
            className={classes.submitButton}
          >
            Submit
          </Button>
        </div>
      </ModalDialog>
    </Modal>
  );
}
