import React, { useEffect, useState } from 'react';
import { Input, InputProps } from '@mui/joy';

type Props = {
  onBlurWithValue: (val: string) => void;
} & InputProps;

export default function OptimizedInput({ onBlurWithValue, ...props }: Props) {
  const [val, setVal] = useState(props.value as string);
  useEffect(() => {
    setVal(props.value as string);
  }, [props.value]);
  return (
    <Input
      {...props}
      value={val}
      onChange={(e) => setVal(e.target.value)}
      onBlur={(e) => {
        onBlurWithValue(val);
        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
    />
  );
}
