import React from 'react';
import { tss } from 'tss-react';
import { Chip, Theme, Typography, useTheme } from '@mui/joy';
import { GetAutopilotManageQueueResponse } from 'src/generated/api';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  companyInfoRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    paddingTop: theme.spacing(1),
    padding: theme.spacing(1),
    alignItems: 'center',
    height: '100%',
    width: '100%',
    cursor: 'pointer',
  },
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    justifyContent: 'space-between',
    width: '100%',
  },
  companyTitle: {
    fontWeight: 600,
  },
  companyInfo: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  actions: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
}));

type Props = {
  business: GetAutopilotManageQueueResponse['businesses'][number];
  setDrawerId: (id: string) => void;
};

export default function AboutCell({ business, setDrawerId }: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div
      onClick={() => setDrawerId(business.id)}
      className={classes.companyInfoRow}
    >
      <div className={classes.companyInfo}>
        <div className={classes.titleRow}>
          <Typography
            level="body-md"
            sx={{
              display: '-webkit-box',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            <b>{business.name}</b>
          </Typography>
          {business.isSuggested && (
            <Chip size="sm" color="warning">
              Suggested
            </Chip>
          )}
        </div>
        <Typography level="body-sm">{business.domain}</Typography>
      </div>
    </div>
  );
}
