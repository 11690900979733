import React, { useCallback, useContext, useEffect, useState } from 'react';
import { tss } from 'tss-react';

import {
  ListItemDecorator,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { AuthContext } from 'src/contexts/auth';
import LoadingContainer from 'src/components/LoadingContainer';
import { LocalPhone, Payment, Person } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { getAPI } from 'src/api';
import { GetProspectResponse } from 'src/generated/api';
import CallPrepTabContent from './CallPrepTabContent';
import FinancialBuyersTabContent from './FinancialBuyersTabContent';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(4),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  sheet: {
    marginTop: theme.spacing(4),
    padding: 0,
    boxShadow: theme.shadow.md,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

export default function Prospect() {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(0);
  const [prospect, setProspect] = useState<GetProspectResponse | null>(null);
  const [prepLoading, setPrepLoading] = useState(false);
  const [buyerListLoading, setBuyerListLoading] = useState(false);
  const { classes } = useStyles({ theme });
  const { user, org } = useContext(AuthContext);

  const { id } = useParams();
  const api = getAPI();

  const fetchData = useCallback(async () => {
    setPrepLoading(!prospect?.tearSheet);
    setBuyerListLoading(!prospect?.buyerList);
    if (id) {
      const res = await api.prospects.getProspect(id);
      setProspect(res.data);

      if (!res.data.tearSheet) {
        api.prospects.generateTearSheet(id).then(() => {
          fetchData();
        });
      } else {
        setPrepLoading(false);
      }

      if (!res.data.buyerList || res.data.buyerList.length === 0) {
        api.prospects.generateBuyerList(id).then(() => {
          fetchData();
        });
      } else {
        setBuyerListLoading(false);
      }
    }
  }, [api.prospects, id, prospect?.buyerList, prospect?.tearSheet]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!prospect) {
    return <div>Loading...</div>;
  }

  return (
    <LoadingContainer isLoading={!org || !user}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography level="h3" className={classes.title}>
            <LocalPhone />
            {prospect.name}
          </Typography>
          <Tabs
            size="lg"
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value as number)}
          >
            <TabList>
              <Tab color={selectedTab === 0 ? 'primary' : 'neutral'}>
                <ListItemDecorator>
                  <Person />
                </ListItemDecorator>
                Company Tear Sheet
              </Tab>
              <Tab color={selectedTab === 1 ? 'primary' : 'neutral'}>
                <ListItemDecorator>
                  <Payment />
                </ListItemDecorator>
                Financial Buyers List
              </Tab>
            </TabList>
            <TabPanel value={0}>
              <CallPrepTabContent
                data={prospect.tearSheet}
                loading={prepLoading}
              />
            </TabPanel>
            <TabPanel value={1}>
              <FinancialBuyersTabContent
                projectId={prospect.id}
                potentialBuyers={prospect.buyerList}
                loading={buyerListLoading}
              />
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </LoadingContainer>
  );
}
