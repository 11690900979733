/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateSignedUrlResponse {
  url: string;
  fileId: string;
}

export interface ScrapeInboundBusinessResponse {
  scrapeResult?: {
    customerSegments: string[];
    productServices: string[];
    name: string;
  };
  scrapeId: string;
}

export interface PotentialBuyersResponse {
  topMatches: {
    portco: {
      addOns: {
        /** @format date-time */
        dealDate: string | null;
        description: string;
        website: string;
        name: string;
      }[];
      /** @format double */
      score: number;
      /** @format double */
      customerSegmentSimilarity: number;
      customerSegments: string;
      /** @format double */
      productServiceSimilarity: number;
      productServices: string;
      explanation: string;
      description: string;
      website: string;
      name: string;
    };
    firms: {
      website: string;
      name: string;
    }[];
  }[];
  /** @format double */
  numMatches: number;
  inboundBusinessId: string;
}

export interface SubmitDomainResponse {
  endMarket: {
    description: string;
    name: string;
  }[];
  productsAndServices: {
    description: string;
    name: string;
  }[];
  description: string;
  businessName: string;
  landingValuationId: string;
}

export interface SubmitDomainBody {
  recaptcha: string;
  domain: string;
}

export interface SubmitFinancialsResponse {
  valuationResult?: {
    valuationRange: {
      sdeApproach: {
        /** @format double */
        median: number;
        /** @format double */
        high: number;
        /** @format double */
        low: number;
      };
      ebitdaApproach: {
        /** @format double */
        median: number;
        /** @format double */
        high: number;
        /** @format double */
        low: number;
      };
      revenueApproach: {
        /** @format double */
        median: number;
        /** @format double */
        high: number;
        /** @format double */
        low: number;
      };
    };
    mostRelevantTransactions: {
      /** @format double */
      mvicPrice: number;
      /** @format date-time */
      saleDate: string;
      /** @format double */
      ebitda: number;
      /** @format double */
      sde: number;
      /** @format double */
      grossProfit: number | null;
      /** @format double */
      netSales: number;
      /** @format double */
      daysOnMarket: number | null;
      state: string | null;
      /** @format double */
      employeeCount: number | null;
      /** @format double */
      businessAge: number | null;
      description: string;
      transactionId: string;
    }[];
    sdeMultiples: number[];
    ebitdaMultiples: number[];
    revenueMultiples: number[];
    valuationDrivers: {
      isNegative: boolean;
      impact: string;
      description: string;
    }[];
  };
  guardrailResult?: string;
}

export interface SubmitFinancialsBody {
  entryPoint: string;
  referringDomain: string;
  internalContent: string;
  utmTerm: string;
  utmContent: string;
  utmCampaign: string;
  utmMedium: string;
  utmSource: string;
  sessionReplayUrl: string;
  /** @format double */
  ownerCompensation: number;
  /** @format double */
  ebitda: number;
  /** @format double */
  revenue: number;
  landingValuationId: string;
}

export interface SubmitContactBody {
  sessionReplayUrl: string;
  requestCall: boolean;
  phoneNumber: string;
  emailAddress: string;
  lastName: string;
  firstName: string;
  landingValuationId: string;
}

export interface WhoAmIResponse {
  org: {
    name: string;
    id: string;
  };
  user: {
    intro: string;
    phone: string;
    email: string;
    lastName: string;
    firstName: string;
    id: string;
  };
}

export enum Type24EnumsAutopilotDataSource {
  WEBSITE = 'WEBSITE',
  PITCHBOOK = 'PITCHBOOK',
  CRUNCHBASE = 'CRUNCHBASE',
  LINKEDIN = 'LINKEDIN',
  GOOGLE_REVIEWS = 'GOOGLE_REVIEWS',
  YELP = 'YELP',
}

export type AutopilotDataSource = Type24EnumsAutopilotDataSource;

export enum Type24EnumsDayOfWeek {
  SUNDAY = 'SUNDAY',
  MONDAY = 'MONDAY',
  TUESDAY = 'TUESDAY',
  WEDNESDAY = 'WEDNESDAY',
  THURSDAY = 'THURSDAY',
  FRIDAY = 'FRIDAY',
  SATURDAY = 'SATURDAY',
}

export type DayOfWeek = Type24EnumsDayOfWeek;

export interface GetAutopilotsResponse {
  autopilots: {
    /** @format double */
    size: number;
    name: string;
    id: string;
  }[];
}

/** Construct a type with a set of properties K of type T */
export interface RecordTargetStatusNumber {
  /** @format double */
  NOT_STARTED?: number;
  /** @format double */
  PROCESSING?: number;
  /** @format double */
  FAILED_REQ?: number;
  /** @format double */
  NO_CONTACT_INFO?: number;
  /** @format double */
  WAITING_FOR_APPROVAL?: number;
  /** @format double */
  APPROVED?: number;
  /** @format double */
  SEQUENCE_STARTED?: number;
  /** @format double */
  SEQUENCE_COMPLETED?: number;
  /** @format double */
  REPLIED?: number;
}

export interface GetAutopilotManageResponse {
  excludeCustomerSegment: string[];
  excludeProductService: string[];
  /** @format double */
  revenueFilterMax: number | null;
  /** @format double */
  revenueFilterMin: number | null;
  /** @format double */
  employeeFilterMax: number | null;
  /** @format double */
  employeeFilterMin: number | null;
  geoFilterServiceAreaStates: string[];
  geoFilterHqStates: string[];
  /** @format double */
  maxBusinessReviewedPerDay: number;
  /** @format double */
  maxSuccessPerDay: number;
  daysActive: DayOfWeek[];
  timeWindowTimeZone: string;
  /** @format double */
  timeWindowEndHour: number;
  /** @format double */
  timeWindowStartHour: number;
  autoApprove: boolean;
  sendNewEmails: boolean;
  processNewBusinesses: boolean;
  /** @format double */
  numMatchesToday: number;
  /** @format double */
  numResearchedToday: number;
  /** @format double */
  size: number;
  /** Construct a type with a set of properties K of type T */
  statusCounts: RecordTargetStatusNumber;
  name: string;
  id: string;
}

/**
 * Stringified UUIDv4.
 * See [RFC 4112](https://tools.ietf.org/html/rfc4122)
 * @format uuid
 * @pattern [0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-4[0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12}
 */
export type UUID = string;

export enum TargetStatus {
  NOT_STARTED = 'NOT_STARTED',
  PROCESSING = 'PROCESSING',
  FAILED_REQ = 'FAILED_REQ',
  NO_CONTACT_INFO = 'NO_CONTACT_INFO',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  APPROVED = 'APPROVED',
  SEQUENCE_STARTED = 'SEQUENCE_STARTED',
  SEQUENCE_COMPLETED = 'SEQUENCE_COMPLETED',
  REPLIED = 'REPLIED',
}

export interface GetAutopilotManagePipelineResponse {
  businesses: {
    isSuggested: boolean;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    status: TargetStatus;
    id: string;
  }[];
  /** @format double */
  size: number;
}

export interface GetAutopilotManageQueueResponse {
  businesses: {
    isSuggested: boolean;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    id: string;
  }[];
  hasAnySequence: boolean;
  /** @format double */
  size: number;
}

export interface GetAutopilotManageResearchResponse {
  requirementCols: {
    dataSources: AutopilotDataSource[];
    ifNotFound: boolean;
    prompt: string;
    name: string;
    id: string;
  }[];
  businesses: {
    /** @format date-time */
    calculatedTime: string;
    requirements: {
      steps: {
        str: string;
        imgUrl: string | null;
      }[];
      explanation: string | null;
      value: any;
      error: string | null;
      id: string;
    }[];
    isProcessing: boolean;
    contactInfo: {
      emailAddress: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    domain: string;
    name: string;
    id: string;
  }[];
  jobTitles: string[];
  /** @format double */
  size: number;
}

export interface GetAutopilotManageEngageResponse {
  autoApprove: boolean;
  subjectInstruction: string;
  senderEmailAddress: string;
  senderLastName: string;
  senderFirstName: string;
  signature: string;
  jobTitles: string[];
  emailCols: {
    /** @format double */
    waitForDays: number;
    examples: string[];
    instruction: string;
    id: string;
  }[];
  businesses: {
    sequence: {
      /** @format date-time */
      calculatedTime: string;
      emails: {
        colId: string;
        /** @format date-time */
        sendTime: string | null;
        value: string;
      }[];
      subject: string;
      approved: boolean;
    };
    contactInfo: {
      emailAddress: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    domain: string;
    name: string;
    id: string;
  }[];
  /** @format double */
  size: number;
}

export interface GetAutopilotViewResponse {
  businesses: {
    domain: string;
    name: string;
    /** @format date-time */
    startDate: string | null;
    status: TargetStatus;
    id: string;
  }[];
  name: string;
}

export interface GetAutopilotBusinessViewResponse {
  requirementsResults: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    explanation: string | null;
    value: any;
    requirement: {
      dataSources: AutopilotDataSource[];
      ifNotFound: boolean;
      prompt: string;
      name: string;
      id: string;
    };
    error: string | null;
  }[];
  status: TargetStatus;
  /** @format date-time */
  calculatedTime: string;
  sequence: {
    emails: {
      trackingEvents: {
        /** @format date-time */
        eventTime: string;
        id: string;
      }[];
      sent: boolean;
      colId: string;
      /** @format date-time */
      sendTime: string | null;
      value: string;
    }[];
    subject: string;
    approved: boolean;
  };
  contactInfo: {
    jobTitle: string | null;
    emailAddress: string | null;
    lastName: string | null;
    firstName: string | null;
  };
  details: {
    crunchbaseUrl: string | null;
    instagramUrl: string | null;
    youtubeUrl: string | null;
    twitterUrl: string | null;
    facebookUrl: string | null;
    gmbUrl: string | null;
    website: string;
    /** @format double */
    funding: number | null;
    /** @format double */
    foundedYear: number | null;
    /** @format double */
    employees: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    serviceAreas: string[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    description: string;
  };
  forcePass: boolean;
  name: string;
  id: string;
}

export interface GetSimilarCompaniesResponse {
  businesses: {
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    /** @format double */
    avgCustomerSegmentSimilarity: number;
    /** @format double */
    avgProductAndServiceSimilarity: number;
    serviceAreaStates: string[];
    /** @format double */
    totalFunding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    description: string;
    domain: string;
    name: string;
    businessId: string;
  }[];
}

export interface GetSubscriptionResponse {
  subscription: {
    willCancel: boolean;
    /** @format double */
    nextBillingDate: number;
    /** @format double */
    monthlyCredits: number;
    /** @format double */
    price: number;
  } | null;
}

export interface GetCreditsLeftResponse {
  /** @format double */
  creditsLeft: number;
}

export interface SimilarSearchDataResponse {
  customerSegments: string[];
  productsAndServices: string[];
}

export interface GetBusinessDetailedResponse {
  lists: string[];
  pppData?: {
    zip: string | null;
    address: string | null;
  };
  crunchbaseData?: {
    fundingRounds: {
      /** @format date-time */
      announcedDate: string;
      /** @format double */
      amountRaised: number | null;
      leadInvestors: string[];
      roundName: string;
    }[];
    founderNames: string[];
    lastFundingType: string | null;
    /** @format date-time */
    lastFundedDate: string | null;
    /** @format double */
    totalFunding: number;
    /** @format double */
    foundedYear: number;
    url: string | null;
  };
  scrapeData: {
    googleLink: string | null;
    yelpLink: string | null;
    youtubeLink: string | null;
    instagramLink: string | null;
    twitterLink: string | null;
    facebookLink: string | null;
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreas: string[];
    serviceAreaStates: string[];
    sellsToConsumers: boolean;
    sellsToBusinesses: boolean;
    sellsToGovernment: boolean;
    description: string;
  };
  /** @format double */
  numEmployees: number | null;
  /** @format double */
  revenue: number | null;
  gmbUrl: string | null;
  phone: string | null;
  website: string;
  state: string | null;
  city: string | null;
  name: string;
  /**
   * Stringified UUIDv4.
   * See [RFC 4112](https://tools.ietf.org/html/rfc4122)
   */
  id: UUID;
}

export interface SimilarCompaniesResponse {
  /** @format double */
  numSimilar: number;
  similar: {
    description: string;
    name: string;
    id: string;
  }[];
}

export interface SearchResponseItem {
  autopilots: string[];
  lists: string[];
  /** @format double */
  numOverlapServiceAreas: number;
  customerSegmentsInfo: string;
  productsAndServicesInfo: string;
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  /** @format double */
  avgCustomerSegmentSimilarity: number;
  /** @format double */
  avgProductAndServiceSimilarity: number;
  /** @format double */
  descriptionSimilarity: number;
  serviceAreaStates: string[];
  /** @format double */
  totalFunding: number | null;
  /** @format double */
  revenue: number | null;
  state: string | null;
  city: string | null;
  /** @format double */
  numEmployees: number | null;
  description: string;
  domain: string;
  name: string;
  businessId: string;
}

export interface SearchResponse {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: SearchResponseItem[];
}

export interface SearchResponseItem2 {
  autopilots: string[];
  lists: string[];
  customerSegmentsHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  customerSegmentsInfo: string;
  productsAndServicesHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  productsAndServicesInfo: string;
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  /** @format double */
  hybridRank: number;
  /** @format double */
  textRank: number;
  /** @format double */
  semanticRank: number;
  /** @format double */
  avgCustomerSegmentSimilarity: number;
  /** @format double */
  avgProductAndServiceSimilarity: number;
  /** @format double */
  descriptionSimilarity: number;
  serviceAreaStates: string[];
  /** @format double */
  totalFunding: number | null;
  /** @format double */
  revenue: number | null;
  state: string | null;
  city: string | null;
  /** @format double */
  numEmployees: number | null;
  descriptionHighlights: {
    /** @format double */
    length: number;
    /** @format double */
    startIdx: number;
  }[];
  description: string;
  domain: string;
  name: string;
  businessId: string;
}

export interface SearchResponse2 {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: SearchResponseItem2[];
}

export interface SearchResponseItem3 {
  lists: string[];
  customerSegmentsInfo: string;
  productsAndServicesInfo: string;
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  /** @format double */
  avgCustomerSegmentSimilarity: number;
  /** @format double */
  avgProductAndServiceSimilarity: number;
  /** @format double */
  descriptionSimilarity: number;
  serviceAreaStates: string[];
  /** @format double */
  totalFunding: number | null;
  /** @format double */
  revenue: number | null;
  state: string | null;
  city: string | null;
  /** @format double */
  numEmployees: number | null;
  description: string;
  domain: string;
  name: string;
  businessId: string;
}

export interface SearchResponse3 {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: SearchResponseItem3[];
}

export interface RelatedKeywordsResponse {
  customerSegments: string[];
  productsAndServices: string[];
}

export enum Type24EnumsListingBuyerType {
  PRIVATE_EQUITY = 'PRIVATE_EQUITY',
  STRATEGIC = 'STRATEGIC',
  SEARCH_FUND = 'SEARCH_FUND',
  INDIVIDUAL = 'INDIVIDUAL',
}

export type ListingBuyerType = Type24EnumsListingBuyerType;

export interface ListingWhoAmIResponse {
  previousAcquisitions: string[];
  criteriaIndustryFocus: string[];
  /** @format double */
  criteriaDealSizeMax: number | null;
  /** @format double */
  criteriaDealSizeMin: number | null;
  criteriaEbitdaRange: string | null;
  criteriaRevenueRange: string | null;
  type: ListingBuyerType | null;
  jobTitle: string | null;
  firmName: string | null;
  linkedinUrl: string | null;
  phoneNumber: string | null;
  emailAddress: string;
  lastName: string | null;
  firstName: string | null;
}

/** From T, pick a set of properties whose keys are in the union K */
export interface PickListingWhoAmIResponseExcludeKeysEmailAddress {
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  linkedinUrl: string | null;
  firmName: string | null;
  jobTitle: string | null;
  type: ListingBuyerType | null;
  criteriaRevenueRange: string | null;
  criteriaEbitdaRange: string | null;
  /** @format double */
  criteriaDealSizeMin: number | null;
  /** @format double */
  criteriaDealSizeMax: number | null;
  criteriaIndustryFocus: string[];
  previousAcquisitions: string[];
}

/** Construct a type with the properties of T except for those in type K. */
export type OmitListingWhoAmIResponseEmailAddress =
  PickListingWhoAmIResponseExcludeKeysEmailAddress;

export enum ListingBuyerNDAStatus {
  REQUESTED = 'REQUESTED',
  BUYER_SIGNED = 'BUYER_SIGNED',
  ADVISOR_APPROVED = 'ADVISOR_APPROVED',
  ADVISOR_REJECTED = 'ADVISOR_REJECTED',
  SELLER_SIGNED = 'SELLER_SIGNED',
  SELLER_REJECTED = 'SELLER_REJECTED',
  RELEASED = 'RELEASED',
  REMOVED_BY_ADVISOR_AFTER_NDA = 'REMOVED_BY_ADVISOR_AFTER_NDA',
  REMOVED_BY_OWNER_AFTER_NDA = 'REMOVED_BY_OWNER_AFTER_NDA',
}

export interface GetListingResponse {
  dataRoom?: {
    files: {
      requestId: string;
      /** @format double */
      size: number;
      name: string;
    }[];
    category: string;
  }[];
  isTeaserCustom: boolean;
  scheduleMeetingDeadline: string;
  codeName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorName: string;
  ndaRequestStatus: ListingBuyerNDAStatus | null;
  latestPeriod: string;
  adjEbitdaStr: string;
  grossProfitStr: string;
  revenueStr: string;
  description: string;
  name: string;
}

export enum Type24EnumsListingBuyerNDARequestFinancingStatus {
  YES = 'YES',
  IN_PROGRESS = 'IN_PROGRESS',
  NO = 'NO',
}

export type ListingBuyerNDARequestFinancingStatus =
  Type24EnumsListingBuyerNDARequestFinancingStatus;

export interface GetProjectsResponse {
  projects: {
    intakeCompleted: boolean;
    intakeGenerated: boolean;
    codeName: string;
    name: string;
    domain: string;
    id: string;
  }[];
}

export type InvestmentConsiderations = {
  explanation: string;
  headline: string;
}[];

export type HeadcountByRole = {
  /** @format double */
  numContractors: number;
  /** @format double */
  numPTEs: number;
  /** @format double */
  numFTEs: number;
  role: string;
}[];

export type LeadershipBios = {
  bio: string;
  pictureFileId: string;
}[];

export type RevenueGeoDistribution = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type AcquisitionChannels = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type FinancialInfo = {
  /** @format double */
  adjEbitda: number;
  /** @format double */
  grossProfit: number;
  /** @format double */
  revenue: number;
  period: string;
}[];

export type RevenueMix = {
  /** @format double */
  percentage: number;
  name: string;
}[];

export type RecurringRevenue = {
  /** @format double */
  amount: number;
  period: string;
}[];

export type ProspectiveBuyers = {
  email: string | null;
  website: string;
  name: string;
}[];

export type BuyerExclusions = {
  email: string | null;
  website: string;
  name: string;
}[];

export interface ProductService {
  valueProps: string[];
  features: string[];
  description: string;
  name: string;
}

export type GrowthOpportunities = {
  keyPoints: string[];
  title: string;
}[];

export interface AddOnInfo {
  url: string;
  description: string;
  name: string;
}

export interface SponsorInfo {
  pocName: string;
  url: string;
  name: string;
}

export interface MatchedBuyer {
  sponsorsInfo: SponsorInfo[];
  addOnsInfo: AddOnInfo[];
  matchNotes: string;
  /** @format double */
  matchScore: number;
  acquirerDescription: string | null;
  acquirerName: string;
  acquirerWebsite: string;
}

export interface GetProjectResponse {
  hasListing: boolean;
  matchedBuyerList: {
    matchedBuyers: MatchedBuyer[];
    isCompleted: boolean;
  } | null;
  cimData: {
    growthOppertunities: GrowthOpportunities;
    keyMetricsHighlights: string[];
    marketingStrategyHighlights: string[];
    customerAcquisitionHighlights: string[];
    footerLogoUrl: string | null;
    approvedByOwner: boolean;
    isCompleted: boolean;
    balanceSheetImgUrl: string | null;
    incomeStatementImgUrl: string | null;
    productsAndServices: ProductService[];
    verticalsSummary: string[];
    growthSummary: string[];
    customerSummary: string[];
    peopleSummary: string[];
    investmentConsiderations: string[];
    valueProps: string[];
    companyOverview: string;
    oneLinerDescription: string;
    ownerTitle: string;
    ownerName: string;
    processBlurb: string | null;
    name: string;
  };
  intakeData: {
    showBalanceSheet: boolean;
    showIncomeStatement: boolean;
    showGrowthOpportunities: boolean;
    showCustomerAcquisition: boolean;
    showAwards: boolean;
    showRecurringRevenue: boolean;
    showRevenueMix: boolean;
    showVendorsAndPartners: boolean;
    showCustomerConcentration: boolean;
    showRevenueGeo: boolean;
    completed: boolean;
    certifications: string[];
    awards: string[];
    buyerExclusions: BuyerExclusions;
    prospectiveBuyers: ProspectiveBuyers;
    otherInvestmentConsiderations: string[];
    additionalInfo: string | null;
    recurringRevenueNotes: string | null;
    recurringRevenue: RecurringRevenue;
    revenueNotes: string | null;
    revenueMix: RevenueMix;
    ebitdaAdjustments: string | null;
    financialInfo: FinancialInfo;
    growthOpportunitiesDetails: string | null;
    customerAcquisitionDetails: string | null;
    acquisitionChannels: AcquisitionChannels | null;
    vendersAndPartners: string[];
    verticals: string[];
    revenueConcentrationDetails: string | null;
    topCustomersByRevenue: number[];
    revenueGeoDistribution: RevenueGeoDistribution;
    retentionDescription: string | null;
    /** @format double */
    numCustomers: number | null;
    leadershipBios: LeadershipBios;
    primaryProductDescription: string | null;
    headcountByRole: HeadcountByRole;
    otherPostTransactionPlan: string | null;
    ceoPostTransactionPlan: string | null;
    founderPostTransactionPlan: string | null;
    valuationExpectation: string | null;
    objectiveDescription: string | null;
    companyOverview: string | null;
    corpType: string | null;
    otherLocations: string[];
    hqState: string | null;
    hqCity: string | null;
    incorporationState: string | null;
    /** @format double */
    yearFounded: number | null;
    contactPhoneNumber: string | null;
    contactPersonalEmail: string | null;
    contactWorkEmail: string | null;
    contactTitle: string | null;
    contactName: string | null;
    businessName: string | null;
  };
  blindProfileData: {
    isCompleted: boolean;
    approvedByOwner: boolean;
    headerLogoUrl: string;
    certifications: string[];
    vendorsAndPartners: string[];
    verticals: string[];
    investmentConsiderations: InvestmentConsiderations;
    primaryProductServices: string[];
    description: string;
    subtitle: string;
    name: string;
  } | null;
  customCimUrl: string | null;
  customBlindProfileUrl: string | null;
  accessCode: string;
  name: string;
  transcripts: string[];
  firmName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorTitle: string;
  advisorName: string;
  ownerEmail: string | null;
  codeName: string;
  domain: string;
  id: string;
}

export interface CreateProjectBody {
  transcript?: string;
  firmName: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorTitle: string;
  advisorName: string;
  ownerEmail: string;
  codeName: string;
  name: string;
  domain: string;
}

export interface GetClientDataRoomRequestsResponse {
  requests: {
    files: {
      /** @format date-time */
      uploadedAt: string;
      /** @format double */
      size: number;
      name: string;
    }[];
    isCompleted: boolean;
    preLOI: boolean;
    category: string;
    description: string;
    name: string;
    id: string;
  }[];
}

export interface AdjustedEBITDAInfoResponse {
  possibleAddBacks: {
    questions: string[];
    /** @format double */
    amount: number;
    expenseName: string;
  }[];
  /** @format double */
  totalPossibleAddBacks: number;
  /** @format double */
  initialMultiplier: number;
  ebitdaExplanation: string;
  /** @format double */
  ebitda: number;
}

export interface ValuationInfoResponse {
  multipleAdjustments: {
    operationalTrackRecord: {
      observation: string;
      /** @format double */
      score: number;
    };
    foundersAndManagement: {
      observation: string;
      /** @format double */
      score: number;
    };
    technologyIP: {
      observation: string;
      /** @format double */
      score: number;
    };
    competitiveLandscape: {
      observation: string;
      /** @format double */
      score: number;
    };
    teamOperationalEfficiency: {
      observation: string;
      /** @format double */
      score: number;
    };
    customerAcquisition: {
      observation: string;
      /** @format double */
      score: number;
    };
    customerConcentration: {
      observation: string;
      /** @format double */
      score: number;
    };
    growthOpportunities: {
      observation: string;
      /** @format double */
      score: number;
    };
    recurringRevenue: {
      observation: string;
      /** @format double */
      score: number;
    };
    revenueEbitdaGrowth: {
      observation: string;
      /** @format double */
      score: number;
    };
  };
  expenseAdjustments: {
    explanation: string;
    /** @format double */
    adjustedAmount: number;
    /** @format double */
    originalAmount: number;
    expenseName: string;
  }[];
  adjustedEbitdaExplanation: string;
  /** @format double */
  adjustedEBITDA: number;
}

export enum Type24EnumsListingBuyerPostNDAStatus {
  PENDING = 'PENDING',
  REQUESTED_INITIAL_MEETING = 'REQUESTED_INITIAL_MEETING',
  INITIAL_MEETING_SCHEDULED = 'INITIAL_MEETING_SCHEDULED',
  IN_DISCUSSIONS = 'IN_DISCUSSIONS',
  LOI = 'LOI',
  NOT_INTERESTED = 'NOT_INTERESTED',
}

export type ListingBuyerPostNDAStatus = Type24EnumsListingBuyerPostNDAStatus;

export enum Type24EnumsListingBuyerActivityType {
  OPEN_LISTING = 'OPEN_LISTING',
  DOWNLOAD_TEASER = 'DOWNLOAD_TEASER',
  DOWNLOAD_CIM = 'DOWNLOAD_CIM',
  DOWNLOAD_DATA_ROOM_FILE = 'DOWNLOAD_DATA_ROOM_FILE',
  DOWNLOAD_ALL_DATA_ROOM_FILES = 'DOWNLOAD_ALL_DATA_ROOM_FILES',
}

export type ListingBuyerActivityType = Type24EnumsListingBuyerActivityType;

export interface DownloadDataRoomFileActivityExtraData {
  requestId: string;
  fileName: string;
}

export interface GetBrokerListingResponse {
  ndaRequests: {
    activities: {
      /** @format date-time */
      createdAt: string;
      extraData?: DownloadDataRoomFileActivityExtraData;
      type: ListingBuyerActivityType;
    }[];
    /** @format date-time */
    ndaReleaseDate: string | null;
    /** @format date-time */
    sellerSignDate: string | null;
    /** @format date-time */
    buyerSignDate: string | null;
    postNDAStatus: ListingBuyerPostNDAStatus;
    referringDomain: string | null;
    financingStatusExplanation: string;
    financingStatus: ListingBuyerNDARequestFinancingStatus;
    ownerPostTransactionExpectation: string;
    relevantExperience: string | null;
    stage: ListingBuyerNDAStatus;
    buyerType: ListingBuyerType;
    buyerEmailAddress: string;
    buyerFirmName: string;
    buyerJobTitle: string;
    buyerName: string;
  }[];
  scheduleMeetingDeadline: string;
  description: string;
  name: string;
}

export interface GetListingBuyerResponse {
  previousAcquisitions: string[];
  criteriaIndustryFocus: string[];
  /** @format double */
  criteriaDealSizeMax: number;
  /** @format double */
  criteriaDealSizeMin: number;
  criteriaEbitdaRange: string;
  criteriaRevenueRange: string;
  type: ListingBuyerType;
  linkedinUrl: string | null;
  jobTitle: string | null;
  firmName: string | null;
  lastName: string;
  firstName: string;
  buyerPhoneNumber: string | null;
  buyerEmailAddress: string;
}

export enum Type24EnumsListingBuyerAdvisorReviewStatus {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export type ListingBuyerAdvisorReviewStatus =
  Type24EnumsListingBuyerAdvisorReviewStatus;

export interface GetUnauthenticatedClientPortalDataResponse {
  name: string;
  domain: string;
}

export interface GetClientPortalDataResponse {
  hasPendingNDARequests: boolean;
  hasPendingDataRoomRequests: boolean;
  firmName: string;
  advisorTitle: string;
  advisorPhone: string;
  advisorEmail: string;
  advisorName: string;
  cimApproved: boolean;
  cimReady: boolean;
  blindProfileApproved: boolean;
  blindProfileReady: boolean;
  intakeCompleted: boolean;
  ownerEmail: string;
  name: string;
  domain: string;
}

export interface GetClientPortalIntakeResponse {
  showCustomerAcquisition: boolean;
  showAwards: boolean;
  showRecurringRevenue: boolean;
  showRevenueMix: boolean;
  showVendorsAndPartners: boolean;
  showCustomerConcentration: boolean;
  showRevenueGeo: boolean;
  isCompleted: boolean;
  additionalInfo: string | null;
  recurringRevenueNotes: string | null;
  recurringRevenue: RecurringRevenue;
  revenueNotes: string | null;
  revenueMix: RevenueMix;
  ebitdaAdjustments: string | null;
  financialInfo: FinancialInfo;
  certifications: string[];
  awards: string[];
  buyerExclusions: BuyerExclusions;
  prospectiveBuyers: ProspectiveBuyers;
  otherInvestmentConsiderations: string[];
  growthOpportunitiesDetails: string | null;
  customerAcquisitionDetails: string | null;
  acquisitionChannels: AcquisitionChannels | null;
  vendersAndPartners: string[];
  verticals: string[] | null;
  revenueConcentrationDetails: string | null;
  topCustomersByRevenue: number[];
  revenueGeoDistribution: RevenueGeoDistribution;
  retentionDescription: string | null;
  leadershipBios: LeadershipBios | null;
  /** @format double */
  numCustomers: number | null;
  primaryProductDescription: string | null;
  headcountByRole: HeadcountByRole | null;
  otherPostTransactionPlan: string | null;
  ceoPostTransactionPlan: string | null;
  founderPostTransactionPlan: string | null;
  valuationExpectation: string | null;
  objectiveDescription: string | null;
  companyOverview: string | null;
  corpType: string | null;
  otherLocations: string[];
  hqState: string | null;
  hqCity: string | null;
  incorporationState: string | null;
  /** @format double */
  yearFounded: number | null;
  contactPhoneNumber: string | null;
  contactPersonalEmail: string | null;
  contactWorkEmail: string | null;
  contactTitle: string | null;
  contactName: string | null;
  businessName: string | null;
}

export interface GetClientPortalDataRoomResponse {
  requests: {
    files: {
      /** @format date-time */
      uploadedAt: string;
      /** @format double */
      size: number;
      name: string;
    }[];
    isCompleted: boolean;
    preLOI: boolean;
    category: string;
    description: string;
    name: string;
    id: string;
  }[];
}

export interface CreateSignedDataRoomUrlResponse {
  url: string;
}

export interface GetClientPortalBuyersResponse {
  ndaRequests: {
    activities: {
      /** @format date-time */
      createdAt: string;
      extraData?: DownloadDataRoomFileActivityExtraData;
      type: ListingBuyerActivityType;
    }[];
    /** @format date-time */
    ndaReleaseDate: string | null;
    /** @format date-time */
    sellerSignDate: string | null;
    /** @format date-time */
    buyerSignDate: string | null;
    postNDAStatus: ListingBuyerPostNDAStatus;
    financingStatusExplanation: string;
    financingStatus: ListingBuyerNDARequestFinancingStatus;
    ownerPostTransactionExpectation: string;
    relevantExperience: string | null;
    stage: ListingBuyerNDAStatus;
    buyerType: ListingBuyerType;
    buyerEmailAddress: string;
    buyerFirmName: string;
    buyerJobTitle: string;
    buyerName: string;
  }[];
}

export interface GetClientPortalListingBuyerResponse {
  previousAcquisitions: string[];
  criteriaIndustryFocus: string[];
  /** @format double */
  criteriaDealSizeMax: number;
  /** @format double */
  criteriaDealSizeMin: number;
  criteriaEbitdaRange: string;
  criteriaRevenueRange: string;
  type: ListingBuyerType;
  linkedinUrl: string | null;
  jobTitle: string | null;
  firmName: string | null;
  lastName: string;
  firstName: string;
  buyerEmailAddress: string;
}

export enum ChecklistStep {
  CompleteOnboardingQuestionnaire = 'Complete Onboarding Questionnaire',
  CompletePhase1DataRoomChecklist = 'Complete Phase 1 Data Room Checklist',
  ReviewAndApproveTeaser = 'Review and Approve Teaser',
  ReviewAndApproveCIM = 'Review and Approve CIM',
  CompletePhase2DataRoomChecklist = 'Complete Phase 2 Data Room Checklist',
}

export interface ChecklistItem {
  isCompleted: boolean;
  text: ChecklistStep;
}

export interface GetChecklistResponse {
  checklist: ChecklistItem[];
}

export enum Type24EnumsEnrichScrapeDataType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  CLASSIFICATION = 'CLASSIFICATION',
}

export type EnrichScrapeDataType = Type24EnumsEnrichScrapeDataType;

export interface EnrichmentClassificationExtra {
  options: {
    description: string;
    name: string;
  }[];
}

export enum Type24EnumsScrapeDataSource {
  WEBSITE = 'WEBSITE',
  PITCHBOOK = 'PITCHBOOK',
  CRUNCHBASE = 'CRUNCHBASE',
  LINKEDIN = 'LINKEDIN',
  GOOGLE_REVIEWS = 'GOOGLE_REVIEWS',
  YELP = 'YELP',
}

export type ScrapeDataSource = Type24EnumsScrapeDataSource;

export interface PrepMeetingResponse {
  typicalEndCustomers: {
    description: string;
    name: string;
  }[];
  keyServices: {
    costFormula: string;
    revenueFormula: string;
    typicalMargin: string;
    businessModel: string;
    targetAudience: string;
    description: string;
    name: string;
  }[];
  locations: string[] | null;
  /** @format double */
  foundingYear: number | null;
  description: string;
  name: string;
}

export interface ScrapeBuyerMatchBusiness {
  customerSegments: string[];
  productServices: string[];
  name: string;
}

export interface GetPotentialBuyersResponse {
  potentialBuyers: MatchedBuyer[];
}

export interface V1GetListsResponse {
  results: {
    /** @format double */
    size: number;
    id: string;
    name: string;
  }[];
}

export enum Type24EnumsEnrichStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export type EnrichStatus = Type24EnumsEnrichStatus;

export enum Type24EnumsEnrichColType {
  PITCHBOOK = 'PITCHBOOK',
  LINKEDIN = 'LINKEDIN',
  SCRAPE = 'SCRAPE',
  CONTACT_INFO = 'CONTACT_INFO',
  PERSONALIZED_MESSAGE = 'PERSONALIZED_MESSAGE',
  SIMPLIFIED_NAME = 'SIMPLIFIED_NAME',
  AUTOPILOT_EMAIL = 'AUTOPILOT_EMAIL',
  IS_BOOTSTRAPPED = 'IS_BOOTSTRAPPED',
  IS_VALID = 'IS_VALID',
}

export type EnrichColType = Type24EnumsEnrichColType;

export interface V1GetListResponse {
  enrichmentColumns: {
    personalizedMessage?: {
      instruction: string;
      examples: string[];
    };
    contactInfo?: {
      jobTitles: string[];
    };
    scrape?: {
      useReasoning: boolean;
      dataSources: ScrapeDataSource[];
      dataTypeExtra: EnrichmentClassificationExtra | null;
      dataType: EnrichScrapeDataType;
      extractInfoTopics: string | null;
      prompt: string;
    };
    type: EnrichColType;
    name: string;
    id: string;
  }[];
  businessImportErrors: {
    error: string | null;
    /** @format double */
    numEmployees: number | null;
    /** @format double */
    funding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    website: string;
    id: string;
  }[];
  /** @format double */
  numBusinessesEnriching: number;
  /** @format double */
  numBusinessesImporting: number;
  businesses: {
    enrichmentValues: {
      isValid?: {
        explanation: string;
        value: boolean;
      };
      isBootstrapped?: {
        value: boolean;
      };
      linkedIn?: {
        size: string;
        /** @format double */
        numFollowers: number;
        /** @format double */
        yearFounded: number | null;
        /** @format double */
        connectedEmployees: number;
        url: string;
      };
      pitchbook?: {
        acquirer: string | null;
        ownershipStatus: string | null;
        latestDealType: string | null;
        financingStatus: string | null;
        /** @format double */
        employeeCount: number | null;
        status: string | null;
        url: string;
      };
      simplifiedName?: {
        result: string;
      };
      personalizedMessage?: {
        result: string;
      };
      contactInfo?: {
        personalEmail: string | null;
        mobilePhone: string | null;
        linkedIn: string | null;
        emailAddress: string | null;
        title: string | null;
        lastName: string | null;
        firstName: string | null;
      };
      scrape?: {
        value: any;
      };
      error: string | null;
      colId: string;
      status: EnrichStatus;
    }[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreaStates: string[];
    /** @format double */
    funding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    phone: string;
    description: string;
    domain: string;
    name: string;
    id: string;
  }[];
  /** @format double */
  size: number;
  name: string;
}

export interface GetEnrichmentDetails {
  isValid?: {
    explanation: string;
    value: boolean;
  };
  isBootstrapped?: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    pitchbookUrl: string | null;
    crunchbaseUrl: string | null;
    explanation: string;
    value: boolean;
  };
  linkedIn?: {
    size: string;
    /** @format double */
    numFollowers: number;
    /** @format double */
    yearFounded: number | null;
    /** @format double */
    connectedEmployees: number;
    url: string;
  };
  pitchbook?: {
    acquirer: string | null;
    ownershipStatus: string | null;
    latestDealType: string | null;
    financingStatus: string | null;
    /** @format double */
    employeeCount: number | null;
    status: string | null;
    url: string;
  };
  simplifiedName?: {
    result: string;
  };
  personalizedMessage?: {
    result: string;
  };
  contactInfo?: {
    personalEmail: string | null;
    mobilePhone: string | null;
    linkedIn: string | null;
    emailAddress: string | null;
    title: string | null;
    lastName: string | null;
    firstName: string | null;
  };
  scrape?: {
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
    explanation: string | null;
    value: any;
  };
  error: string | null;
  colId: string;
  status: EnrichStatus;
}

export interface GetSimilarCompanies {
  businesses: {
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    /** @format double */
    avgCustomerSegmentSimilarity: number;
    /** @format double */
    avgProductAndServiceSimilarity: number;
    serviceAreaStates: string[];
    /** @format double */
    totalFunding: number | null;
    /** @format double */
    revenue: number | null;
    state: string | null;
    city: string | null;
    /** @format double */
    numEmployees: number | null;
    description: string;
    domain: string;
    name: string;
    businessId: string;
  }[];
}

export interface GetImportColumnNamesResponse {
  columnNames: string[];
}

export interface StrategicFitResponse {
  lowCategory: string[];
  medCategory: string[];
  highCategory: string[];
  prompt: string;
}

export interface GetProspectListResponse {
  prospects: {
    name: string;
    domain: string;
    id: string;
  }[];
}

export interface Type24ResultDefaultSelectionPrisma24CallPrepTearSheetPayload {
  clientProjectId: string;
  /** @format date-time */
  updatedAt: string;
  /** @format date-time */
  createdAt: string;
  locations: string[];
  /** @format double */
  foundingYear: number;
  description: string;
  id: string;
  name: string;
}

/** Model CallPrepTearSheet */
export type CallPrepTearSheet =
  Type24ResultDefaultSelectionPrisma24CallPrepTearSheetPayload;

export interface Type24ResultDefaultSelectionPrisma24CallPrepKeyServicePayload {
  tearSheetId: string;
  costFormula: string;
  revenueFormula: string;
  typicalMargin: string;
  businessModel: string;
  targetAudience: string;
  description: string;
  id: string;
  name: string;
}

/** Model CallPrepKeyService */
export type CallPrepKeyService =
  Type24ResultDefaultSelectionPrisma24CallPrepKeyServicePayload;

export interface Type24ResultDefaultSelectionPrisma24CallPrepEndCustomerPayload {
  tearSheetId: string;
  description: string;
  id: string;
  name: string;
}

/** Model CallPrepEndCustomer */
export type CallPrepEndCustomer =
  Type24ResultDefaultSelectionPrisma24CallPrepEndCustomerPayload;

export interface GetProspectResponse {
  buyerList: {
    matchNotes: string;
    /** @format double */
    matchScore: number;
    addOnsInfo: AddOnInfo[] | null;
    sponsorsInfo: SponsorInfo[] | null;
    acquirerDescription: string | null;
    acquirerWebsite: string;
    acquirerName: string;
  }[];
  tearSheet: CallPrepTearSheet & {
    endCustomers: CallPrepEndCustomer[] | null;
    keyServices: CallPrepKeyService[] | null;
  };
  name: string;
  domain: string;
  id: string;
}

export interface V2WhoAmIResponse {
  email: string;
  lastName: string;
  firstName: string;
  id: string;
}

export interface V2GetBusinessResponse {
  website?: {
    domain: string;
    zip: string | null;
    state: string | null;
    city: string | null;
    addressLine2: string | null;
    addressLine1: string | null;
    isUnionized: boolean;
    serviceAreaStates: string[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreaInfo: string;
    customerSegmentInfo: string;
    productServiceInfo: string;
    generalInfo: string;
    shortDescription: string;
    description: string;
    casualName: string;
    name: string;
  };
  canonical?: {
    owner?: {
      usaData?: {
        /** @format double */
        adultChild2BirthYear: number | null;
        /** @format double */
        adultChild1BirthYear: number | null;
        /** @format double */
        retireIntent: number | null;
        /** @format double */
        sellBusinessIntent: number | null;
        childrenPresentInHousehold: boolean | null;
        /** @format double */
        otherHouseholdMemberBirthYear: number | null;
        /** @format double */
        birthYear: number | null;
        /** @format double */
        birthMonth: number | null;
        /** @format double */
        birthDay: number | null;
        /** @format double */
        age: number | null;
        isMarriedActual: boolean | null;
        isMarried: boolean | null;
      };
      linkedInSource: string | null;
      linkedInUrl: string | null;
      /** @format double */
      birthYear: number | null;
      zip: string | null;
      state: string | null;
      city: string | null;
      addressLine2: string | null;
      addressLine1: string | null;
      personalEmail: string | null;
      workEmail: string | null;
      phoneNumber: string | null;
      identificationSource: string | null;
      jobTitle: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    hqPhone: string | null;
    hqEmail: string | null;
    hqAddressSource: string | null;
    hqZip: string | null;
    hqState: string | null;
    hqCity: string | null;
    hqAddressLine2: string | null;
    hqAddressLine1: string | null;
    twitterUrl: string | null;
    facebookUrl: string | null;
    hasEquityFinancing: boolean | null;
    /** @format date-time */
    acquiredDate: string | null;
    acquirerName: string | null;
    acquirerDomain: string | null;
    /** @format double */
    revenue: number | null;
    /** @format double */
    yearFounded: number | null;
    /** @format double */
    employeeCount: number | null;
    legalName: string | null;
    organizationType: string;
    operatingStatus: string;
  };
  domain: string;
  id: string;
}

export interface BusinessSSEData {
  enrichments: {
    /** @format date-time */
    createdAt: string | null;
    createdBy: string | null;
    urlsVisited: string[];
    status: 'QUEUED' | 'RUNNING' | 'COMPLETE' | 'ERROR' | null;
    explanation: string | null;
    value: any;
    outputTypeClassificationOptions: string[];
    outputType: 'BOOLEAN' | 'STRING' | 'NUMBER' | 'CLASSIFICATION';
    useReasoning: boolean;
    scrapeTopics: string[];
    prompt: string;
    name: string;
    id: string;
  }[];
  domain: string;
  id: string;
}

export enum Type24EnumsBusinessDataMatchConfidence {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export type BusinessDataMatchConfidence =
  Type24EnumsBusinessDataMatchConfidence;

/** Construct a type with a set of properties K of type T */
export type RecordStringString = object;

export type V2GetDetailedBusinessResponse = BusinessSSEData & {
  owners: {
    usaData?: {
      /** @format double */
      retireIntent: number | null;
      /** @format double */
      sellBusinessIntent: number | null;
      childrenPresentInHousehold: boolean | null;
      /** @format double */
      otherHouseholdMemberBirthYear: number | null;
      /** @format double */
      birthYear: number | null;
      /** @format double */
      birthMonth: number | null;
      /** @format double */
      birthDay: number | null;
      /** @format double */
      age: number | null;
      isMarriedActual: boolean | null;
      isMarried: boolean | null;
      /** @format double */
      adultChild2BirthYear: number | null;
      /** @format double */
      adultChild1BirthYear: number | null;
      jobTitle: string;
      lastName: string;
      firstName: string;
    };
    pdlData?: {
      matchConfidenceExplanation: string | null;
      matchConfidence: BusinessDataMatchConfidence | null;
      /** @format double */
      birthYear: number | null;
      fullName: string;
      lastName: string;
      middleName: string | null;
      firstName: string;
      mobilePhone: string | null;
      personalEmail: string | null;
      workEmail: string | null;
      /** @format date-time */
      addressLastUpdated: string | null;
      addressZip: string | null;
      addressState: string | null;
      addressCity: string | null;
      addressLine2: string | null;
      addressLine1: string | null;
      /** @format double */
      numLinkedInConnections: number;
      linkedInUrl: string;
      linkedInUsername: string;
      /** @format double */
      companyFoundedYear: number | null;
      companyIndustry: string | null;
      companyLinkedInUrl: string | null;
      companyTwitterUrl: string | null;
      companyFacebookUrl: string | null;
      companyEmployeeRange: string;
      companyPdlId: string;
      companyLinkedInId: string | null;
      companyName: string;
      /** @format date-time */
      jobLastVerified: string;
      /** @format double */
      jobTitleStartYear: number | null;
      jobTitleRole: string;
      jobTitle: string;
      pdlId: string;
    };
    jobTitle: string;
    lastName: string;
    firstName: string;
    id: string;
  }[];
  website?: {
    domain: string;
    zip: string | null;
    state: string | null;
    city: string | null;
    addressLine2: string | null;
    addressLine1: string | null;
    isUnionized: boolean;
    naicsCode: string;
    googleLink: string | null;
    instagramLink: string | null;
    twitterLink: string | null;
    youtubeLink: string | null;
    facebookLink: string | null;
    emailAddress: string | null;
    serviceAreaStates: string[];
    customerSegments: {
      description: string;
      keyPhrase: string;
    }[];
    productsAndServices: {
      description: string;
      keyPhrase: string;
    }[];
    serviceAreaInfo: string;
    customerSegmentInfo: string;
    productServiceInfo: string;
    generalInfo: string;
    shortDescription: string;
    description: string;
    casualName: string;
    name: string;
  };
  canonical?: {
    owner?: {
      linkedInSource: string | null;
      linkedInUrl: string | null;
      birthYearSource: string | null;
      /** @format double */
      birthYear: number | null;
      addressSource: string | null;
      zip: string | null;
      state: string | null;
      city: string | null;
      addressLine2: string | null;
      addressLine1: string | null;
      personalEmailSource: string | null;
      personalEmail: string | null;
      workEmailSource: string | null;
      workEmail: string | null;
      phoneNumberSource: string | null;
      phoneNumber: string | null;
      identificationSource: string | null;
      jobTitle: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    hqPhoneSource: string | null;
    hqPhone: string | null;
    hqEmailSource: string | null;
    hqEmail: string | null;
    hqAddressSource: string | null;
    hqZip: string | null;
    hqState: string | null;
    hqCity: string | null;
    hqAddressLine2: string | null;
    hqAddressLine1: string | null;
    twitterSource: string | null;
    twitterUrl: string | null;
    facebookSource: string | null;
    facebookUrl: string | null;
    hasEquityFinancingSource: string | null;
    hasEquityFinancing: boolean | null;
    acquirerSource: string | null;
    /** @format date-time */
    acquiredDate: string | null;
    acquirerName: string | null;
    acquirerDomain: string | null;
    revenueSource: string | null;
    /** @format double */
    revenue: number | null;
    yearFoundedExplanation: string | null;
    yearFoundedSource: string | null;
    /** @format double */
    yearFounded: number | null;
    employeeCountSource: string | null;
    /** @format double */
    employeeCount: number | null;
    legalNameExplanation: string | null;
    legalNameSource: string | null;
    legalName: string | null;
    organizationTypeSource: string;
    organizationType: string;
    operatingStatusSource: string;
    operatingStatus: string;
  };
  pitchbook?: {
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    acquirer?: {
      investorBusinessPitchbook?: {
        domain: string | null;
        pitchbookUrl: string;
        description: string;
      };
      investorFund?: {
        domain: string | null;
        investorStatus: string | null;
        fundType: string | null;
        /** @format double */
        yearFounded: number | null;
        description: string;
        website: string | null;
        pitchbookUrl: string;
        pitchbookId: string;
      };
      /** @format date-time */
      acquisitionDate: string | null;
      name: string;
    };
    acquisitions: {
      domain: string | null;
      description: string;
      name: string;
      pitchbookUrl: string;
      pitchbookId: string;
      businessId: string;
    }[];
    investors: {
      investorBusinessPitchbook: {
        pitchbookUrl: string | null;
        description: string | null;
        domain: string | null;
      };
      investorFund: {
        investorStatus: string | null;
        fundType: string | null;
        /** @format double */
        yearFounded: number | null;
        pitchbookUrl: string | null;
        pitchbookId: string | null;
        website: string | null;
      };
      investorName: string | null;
    }[];
    faq: {
      value: string;
      type: string;
    }[];
    investments: {
      companyDomain: string | null;
      industry: string | null;
      dealType: string | null;
      dealDate: string | null;
      companyName: string;
    }[];
    /** @format double */
    numInvestments: number;
    /** @format double */
    numFinancingRounds: number;
    patents: {
      value: string;
      type: string;
    }[];
    /** @format double */
    numEmployees: number | null;
    parentCompany: string | null;
    status: string;
    /** @format double */
    yearFounded: number | null;
    latestDealType: string | null;
    description: string;
    name: string;
    pitchbookUrl: string;
    pitchbookId: string;
  };
  googleMaps: {
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    priceRange: string | null;
    /** @format double */
    longitude: number | null;
    /** @format double */
    latitude: number | null;
    googleMapsImageUrl: string | null;
    /** @format double */
    rating: number | null;
    /** @format double */
    numReviews: number;
    hoursOfOperation: RecordStringString | null;
    phone: string | null;
    address: string;
    category: string | null;
    name: string;
    googleMapsId: string;
  }[];
  linkedIn?: {
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    linkedInUrl: string;
    hqLocation: string | null;
    /** @format double */
    foundedYear: number | null;
    crunchbaseUrl: string | null;
    organizationType: string | null;
    selfReportedNumEmployees: string | null;
    about: string | null;
    /** @format double */
    numEmployees: number;
    /** @format double */
    numFollowers: number;
    locations: string[];
    name: string;
    linkedInId: string;
  };
  dnb?: {
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    phoneNumber: string | null;
    /** @format double */
    longitude: number | null;
    /** @format double */
    latitude: number | null;
    zip: string | null;
    state: string;
    city: string;
    addressLine2: string | null;
    addressLine1: string | null;
    legalStructure: string | null;
    ownershipType: string | null;
    employeeCountIsActual: boolean;
    naicsCode: string;
    /** @format double */
    foundedYear: number | null;
    /** @format date-time */
    incorporationDate: string | null;
    /** @format double */
    employeeCount: number | null;
    /** @format double */
    revenue: number | null;
    businessName: string;
    operatingStatus: string;
    dunsNumber: string;
  };
  ppp?: {
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    businessType: string;
    /** @format double */
    debtInterestAmount: number | null;
    /** @format double */
    rentAmount: number | null;
    /** @format double */
    mortgageInterestAmount: number | null;
    /** @format double */
    payrollAmount: number | null;
    /** @format double */
    utilitiesAmount: number | null;
    /** @format double */
    numEmployees: number;
    loanLender: string;
    /** @format double */
    loanAmount: number;
    loanTerm: string;
    loanStatus: string;
    zip: string;
    state: string;
    city: string;
    name: string;
  };
  crunchbase?: {
    acquisitions: {
      /** @format double */
      acquisitionPrice: number | null;
      /** @format date-time */
      date: string;
      acquiredName: string | null;
      acquiredDomain: string | null;
    }[];
    matchConfidenceExplanation: string | null;
    matchConfidence: BusinessDataMatchConfidence | null;
    semrush?: {
      /** @format double */
      visitDuration: number | null;
      /** @format double */
      pageViewsPerVisit: number | null;
      /** @format double */
      globalTrafficRank: number | null;
      /** @format double */
      bounceRate: number | null;
    };
    acquiredBy?: {
      acquirerDomain: string | null;
      acquirerCrunchbaseId: string | null;
      /** @format double */
      acquisitionPrice: number | null;
      /** @format date-time */
      date: string;
      transactionName: string | null;
      acquirerName: string;
    };
    fundingRounds: {
      investors: {
        isLeadInvestor: boolean | null;
        investorId: string;
        investorName: string;
        crunchbaseFundingRoundInvestorId: string;
      }[];
      /** @format double */
      fundingAmount: number | null;
      title: string | null;
      /** @format date-time */
      announcedDate: string | null;
      crunchbaseFundingRoundId: string;
    }[];
    news: {
      url: string;
      title: string;
      publisher: string | null;
      /** @format date-time */
      date: string;
      id: string;
    }[];
    founders: string[];
    /** @format double */
    itSpend: number | null;
    hqLocation: string;
    locations: string[];
    contacts: {
      linkedin_id: string | null;
      job_title: string;
      name: string;
    }[];
    /** @format double */
    monthlyWebsiteVisitsMoMGrowth: number | null;
    /** @format date-time */
    monthlyWebsiteVisitsDate: string | null;
    /** @format double */
    monthlyWebsiteVisits: number | null;
    contactPhone: string | null;
    contactEmail: string | null;
    /** @format date-time */
    foundedDate: string | null;
    socialMediaLinks: string[];
    operatingStatus: string;
    /** @format double */
    crunchbaseRank: number;
    crunchbaseId: string;
    crunchbaseUrl: string;
    legalName: string | null;
    name: string;
  };
};

export interface KeywordMatch {
  /** @format double */
  score: number;
  inputKeyword: string;
  keyword: string;
}

export interface BusinessSearchResponseItem {
  lists: {
    listName: string;
    listId: string;
  }[];
  customerSegments: {
    description: string;
    keyPhrase: string;
  }[];
  productsAndServices: {
    description: string;
    keyPhrase: string;
  }[];
  state: string | null;
  city: string | null;
  /** @format double */
  revenue: number | null;
  /** @format double */
  yearFounded: number | null;
  /** @format double */
  numEmployees: number | null;
  shortDescription: string;
  domain: string;
  customerSegmentMatches: KeywordMatch[];
  productServiceMatches: KeywordMatch[];
  /** @format double */
  totalScore: number;
  name: string;
  businessId: string;
}

export interface BusinessSearchResponse {
  /** @format double */
  pageSize: number;
  /** @format double */
  numResults: number;
  results: BusinessSearchResponseItem[];
}

export interface BusinessSearchBody {
  excludeListIds: string[];
  enrichmentFilters: {
    value: {
      /** @format double */
      max?: number;
      /** @format double */
      min?: number;
      equals?: boolean | string | number | null;
    };
    enrichmentId: string;
  }[];
  isAcquirer: boolean | null;
  ownerFilter: {
    retireIntent: {
      /** @format double */
      max: number | null;
      /** @format double */
      min: number | null;
    };
    sellBusinessIntent: {
      /** @format double */
      max: number | null;
      /** @format double */
      min: number | null;
    };
    childrenBirthYear: {
      /** @format double */
      maxYear: number | null;
      /** @format double */
      minYear: number | null;
    };
    childrenPresent: boolean | null;
    maritalStatus: boolean | null;
    spouseBirthYear: {
      /** @format double */
      maxYear: number | null;
      /** @format double */
      minYear: number | null;
    };
    birthYear: {
      /** @format double */
      maxYear: number | null;
      /** @format double */
      minYear: number | null;
    };
    requireAddress: boolean;
    requirePhone: boolean;
    requireEmail: boolean;
    required: boolean;
  };
  /** @format double */
  pageNum: number;
  /** @format double */
  pageSize: number;
  hasEquityFinancingFilter: boolean | null;
  franchiseFilter: boolean | null;
  unionizedFilter: boolean | null;
  hqStates: string[];
  foundedYearFilter: {
    /** @format double */
    maxYear: number | null;
    /** @format double */
    minYear: number | null;
  };
  revenueFilter: {
    /** @format double */
    maxRevenue: number | null;
    /** @format double */
    minRevenue: number | null;
  };
  employeeFilter: {
    /** @format double */
    maxEmployees: number | null;
    /** @format double */
    minEmployees: number | null;
  };
  customerSegments: {
    exclusions: {
      /** @format double */
      threshold: number;
      keyPhrases: string[];
    };
    /** @format double */
    minMatches: number;
    /** @format double */
    weight: number;
    /** @format double */
    threshold: number;
    keyPhrases: string[];
  };
  productsAndServices: {
    exclusions: {
      /** @format double */
      threshold: number;
      keyPhrases: string[];
    };
    /** @format double */
    minMatches: number;
    /** @format double */
    weight: number;
    /** @format double */
    threshold: number;
    keyPhrases: string[];
  };
}

export interface BusinessRelatedKeywordsResponse {
  customerSegments: string[];
  productsAndServices: string[];
}

export interface BusinessRelatedKeywordsBody {
  customerSegments: string[];
  productsAndServices: string[];
}

export interface BusinessEnrichmentSSEData {
  valueCounts: {
    /** @format double */
    error: number;
    /** @format double */
    completed: number;
    /** @format double */
    running: number;
    /** @format double */
    queued: number;
  };
  id: string;
}

export enum Type24EnumsEnrichmentOutputType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  CLASSIFICATION = 'CLASSIFICATION',
}

export type EnrichmentOutputType = Type24EnumsEnrichmentOutputType;

export interface GetEnrichmentsResponse {
  /** @format double */
  totalEnrichableBusinesses: number;
  enrichments: (BusinessEnrichmentSSEData & {
    updatedBy: string;
    /** @format date-time */
    updatedAt: string;
    createdBy: string;
    /** @format date-time */
    createdAt: string;
    outputTypeClassificationOptions: string[];
    outputType: EnrichmentOutputType;
    useReasoning: boolean;
    prompt: string;
    name: string;
  })[];
}

export interface EnrichmentBody {
  outputTypeClassificationOptions: string[];
  outputType: EnrichmentOutputType;
  useReasoning: boolean;
  prompt: string;
  name: string;
}

export interface PerformBusinessEnrichmentBody {
  businessId: string;
}

export interface PerformMassEnrichmentBody {
  enrichmentKey: string;
}

export interface ListOpportunitiesResponse {
  opportunities: {
    /** @format date-time */
    dateUpdated: string;
    /** @format date-time */
    dateCreated: string;
    nextSteps: string;
    relationshipStatus: string;
    statusLabel: string;
    businessName: string;
    leadId: string;
    id: string;
  }[];
}

export interface GetLeadResponse {
  opportunity: {
    /** @format date-time */
    dateUpdated: string;
    /** @format date-time */
    dateCreated: string;
    nextSteps: string;
    relationshipStatus: string;
    statusLabel: string;
  };
  domain: string | null;
  description: string;
  phone: string | null;
  email: string | null;
  contactName: string;
  businessName: string;
  id: string;
}

export interface AskAIBody {
  question: string;
}

export enum Type24EnumsCrmAIEnrichmentValueSourceType {
  LEAD_INFO = 'LEAD_INFO',
  OPPORTUNITY_INFO = 'OPPORTUNITY_INFO',
  CUSTOM_ACTIVITY = 'CUSTOM_ACTIVITY',
  EMAIL = 'EMAIL',
  NOTE = 'NOTE',
  SMS = 'SMS',
  CALL_TRANSCRIPT = 'CALL_TRANSCRIPT',
  ZOOM_TRANSCRIPT = 'ZOOM_TRANSCRIPT',
}

export type CrmAIEnrichmentValueSourceType =
  Type24EnumsCrmAIEnrichmentValueSourceType;

export interface CRMAIResponse {
  sources: {
    zoomAudioUrl: string | null;
    metadata: {
      value: string;
      key: string;
    }[];
    excerpt: string;
    id: string | null;
    field: string | null;
    type: CrmAIEnrichmentValueSourceType;
  }[];
  explanation: string;
  answer: any;
}

export interface GetAIResponse {
  response: CRMAIResponse;
}

export enum Type24EnumsCrmAIEnrichmentOutputType {
  BOOLEAN = 'BOOLEAN',
  STRING = 'STRING',
  NUMBER = 'NUMBER',
  CLASSIFICATION = 'CLASSIFICATION',
  DATE = 'DATE',
}

export type CrmAIEnrichmentOutputType = Type24EnumsCrmAIEnrichmentOutputType;

export enum Type24EnumsCrmAIEnrichmentStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
}

export type CrmAIEnrichmentStatus = Type24EnumsCrmAIEnrichmentStatus;

export interface GetEnrichmentColumnsResponse {
  columns: {
    values: {
      value: any;
      columnId: string;
      status: CrmAIEnrichmentStatus;
      leadId: string;
    }[];
    outputTypeClassificationOptions: string[];
    outputType: CrmAIEnrichmentOutputType;
    prompt: string;
    name: string;
    id: string;
  }[];
}

export interface CreateEnrichmentColumnBody {
  outputTypeClassificationOptions: string[];
  outputType: CrmAIEnrichmentOutputType;
  prompt: string;
  name: string;
}

export interface UpdateEnrichmentColumnBody {
  outputTypeClassificationOptions: string[];
  outputType: CrmAIEnrichmentOutputType;
  prompt: string;
}

export interface GetEnrichmentValueResponse {
  sources: {
    metadata: {
      value: string;
      key: string;
    }[];
    excerpt: string;
    id: string | null;
    field: string | null;
    type: CrmAIEnrichmentValueSourceType;
  }[];
  explanation: string | null;
  value: any;
  columnId: string;
  leadId: string;
}

export interface ListViewsResponse {
  views: {
    name: string;
    id: string;
  }[];
}

export interface GetLLMContextResponse {
  context: string;
}

export interface GetEmployeesResponse {
  employees: {
    email: string;
    fullName: string;
    lastName: string;
    firstName: string;
    id: string;
  }[];
}

export interface GetListsResponse {
  lists: {
    /** @format double */
    businessCount: number;
    createdBy: {
      name: string;
      id: string;
    } | null;
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    createdAt: string;
    name: string;
    id: string;
  }[];
  /** @format double */
  total: number;
  /** @format double */
  pageSize: number;
  /** @format double */
  pageNum: number;
  [key: string]: any;
}

export interface GetListResponse {
  id: string;
  name: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  createdBy: {
    name: string;
    id: string;
  } | null;
  /** @format double */
  businessCount: number;
  enrichments: (
    | {
        enrichmentType: 'AI';
        name: string;
        id: string;
      }
    | {
        enrichmentType: 'PERSONALIZED_MESSAGE';
        name: string;
        id: string;
      }
  )[];
  [key: string]: any;
}

export enum Type24EnumsListEnrichmentStatus {
  QUEUED = 'QUEUED',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  BLOCKED = 'BLOCKED',
  SKIPPED = 'SKIPPED',
}

export type ListEnrichmentStatus = Type24EnumsListEnrichmentStatus;

export interface GetListPageResponse {
  id: string;
  name: string;
  /** @format double */
  pageSize: number;
  /** @format double */
  pageNum: number;
  /** @format double */
  totalBusinesses: number;
  businesses: {
    enrichmentValues: (
      | {
          ai?: {
            urlsVisited: string[];
            explanation: string;
            value: string;
          };
          error?: string;
          status: ListEnrichmentStatus;
          enrichmentType: 'AI';
          enrichmentId: string;
        }
      | {
          error?: string;
          status: ListEnrichmentStatus;
          enrichmentType: 'PERSONALIZED_MESSAGE';
          enrichmentId: string;
        }
    )[];
    website?: {
      domain: string;
      zip: string | null;
      state: string | null;
      city: string | null;
      addressLine2: string | null;
      addressLine1: string | null;
      isFranchise: boolean;
      isUnionized: boolean;
      serviceAreaStates: string[];
      customerSegments: {
        description: string;
        keyPhrase: string;
      }[];
      productsAndServices: {
        description: string;
        keyPhrase: string;
      }[];
      serviceAreaInfo: string;
      customerSegmentInfo: string;
      productServiceInfo: string;
      generalInfo: string;
      shortDescription: string;
      description: string;
      casualName: string;
      name: string;
    };
    owner?: {
      usaData?: {
        /** @format double */
        adultChild2BirthYear: number | null;
        /** @format double */
        adultChild1BirthYear: number | null;
        /** @format double */
        retireIntent: number | null;
        /** @format double */
        sellBusinessIntent: number | null;
        childrenPresentInHousehold: boolean | null;
        /** @format double */
        otherHouseholdMemberBirthYear: number | null;
        /** @format double */
        birthYear: number | null;
        /** @format double */
        birthMonth: number | null;
        /** @format double */
        birthDay: number | null;
        /** @format double */
        age: number | null;
        isMarriedActual: boolean | null;
        isMarried: boolean | null;
      };
      linkedInSource: string | null;
      linkedInUrl: string | null;
      /** @format double */
      birthYear: number | null;
      zip: string | null;
      state: string | null;
      city: string | null;
      addressLine2: string | null;
      addressLine1: string | null;
      personalEmail: string | null;
      workEmail: string | null;
      phoneNumber: string | null;
      identificationSource: string | null;
      jobTitle: string | null;
      lastName: string | null;
      firstName: string | null;
    };
    canonical?: {
      hqPhone: string | null;
      hqEmail: string | null;
      hqAddressSource: string | null;
      hqZip: string | null;
      hqState: string | null;
      hqCity: string | null;
      hqAddressLine2: string | null;
      hqAddressLine1: string | null;
      twitterUrl: string | null;
      facebookUrl: string | null;
      hasEquityFinancing: boolean | null;
      /** @format date-time */
      acquiredDate: string | null;
      acquirerName: string | null;
      acquirerDomain: string | null;
      /** @format double */
      revenue: number | null;
      /** @format double */
      yearFounded: number | null;
      /** @format double */
      employeeCount: number | null;
      legalName: string | null;
      organizationType: string;
      operatingStatus: string;
    };
    domain: string;
    id: string;
  }[];
  [key: string]: any;
}

export interface CreateListBody {
  name: string;
  [key: string]: any;
}

export interface AddBusinesses {
  listIds: string[];
  businessIds: string[];
  [key: string]: any;
}

export interface RemoveBusinessesBody {
  businessIds: string[];
  [key: string]: any;
}

export interface MoveBusinessesBody {
  businessIds: string[];
  toListId: string;
  [key: string]: any;
}

export enum Type24EnumsListEnrichmentType {
  PERSONALIZED_MESSAGE = 'PERSONALIZED_MESSAGE',
  AI = 'AI',
  OWNER_LINKEDIN = 'OWNER_LINKEDIN',
  OWNER_TWITTER = 'OWNER_TWITTER',
}

export type ListEnrichmentType = Type24EnumsListEnrichmentType;

export enum Type24EnumsListEnrichmentAIQuestionType {
  CLASSIFICATION = 'CLASSIFICATION',
  BINARY = 'BINARY',
  QUALITATIVE = 'QUALITATIVE',
  QUANTITATIVE = 'QUANTITATIVE',
}

export type ListEnrichmentAIQuestionType =
  Type24EnumsListEnrichmentAIQuestionType;

export interface GetListEnrichmentResponse {
  id: string;
  name: string;
  enrichmentType: ListEnrichmentType;
  businessListId: string;
  ai?: {
    classificationOptions: string[];
    scrapeTopics: string[];
    useReasoning: boolean;
    questionType: ListEnrichmentAIQuestionType;
    question: string;
  };
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  [key: string]: any;
}

export interface ListEnrichmentAI {
  enrichmentType: 'AI';
  ai: {
    classificationOptions: string[];
    scrapeTopics: string[];
    useReasoning: boolean;
    questionType: ListEnrichmentAIQuestionType;
    question: string;
  };
  [key: string]: any;
}

export interface ListEnrichmentPersonalizedMessage {
  enrichmentType: 'PERSONALIZED_MESSAGE';
  [key: string]: any;
}

export type CreateListEnrichmentBody = {
  businessListId: string;
  name: string;
} & (ListEnrichmentAI | ListEnrichmentPersonalizedMessage);

export type UpdateListEnrichmentBody = {
  enrichmentType: ListEnrichmentType;
  name: string;
  id: string;
} & ListEnrichmentAI;

export interface GetMarketIntelReportListResponse {
  marketIntelReports: {
    status: string;
    date: string;
    domain: string;
    business: string;
    id: string;
  }[];
}

export type MarketIntelReportStatus = any;

export interface Type24ResultDefaultSelectionPrisma24MarketIntelReportPayload {
  status: MarketIntelReportStatus;
  customerCallSummary: string;
  /** @format double */
  yearsInOperation: number;
  /** @format double */
  employees: number;
  /** @format double */
  ebitda: number;
  /** @format double */
  sde: number;
  /** @format double */
  revenue: number;
  /** @format date-time */
  date: string;
  domain: string;
  business: string;
  title: string;
  userId: string;
  id: string;
}

/** Model MarketIntelReport */
export type MarketIntelReport =
  Type24ResultDefaultSelectionPrisma24MarketIntelReportPayload;

export type GetMarketIntelReportResponse = MarketIntelReport & {
  valuationText: {
    bullets: string[];
    title: string;
  }[];
  valuationRanges: {
    isRecommended: boolean;
    /** @format double */
    low: number;
    /** @format double */
    high: number;
    name: string;
  }[];
  factors: {
    negativeDescription: string;
    negativeTitle: string;
    positiveDescription: string;
    positiveTitle: string;
    name: string;
  }[];
  similarTransactions: {
    nonCompeteTerm: string | null;
    /** @format double */
    sellerFinancingPercentage: number | null;
    /** @format double */
    cashDownPaymentPercentage: number | null;
    /** @format double */
    sde: number | null;
    /** @format double */
    ebitda: number | null;
    /** @format double */
    revenue: number | null;
    /** @format double */
    transactionPrice: number | null;
    /** @format date-time */
    saleDate: string;
    businessDescription: string;
    /** @format double */
    score: number;
    inReport: boolean;
    id: string;
  }[];
};

export type GenerateMarketIntelReportResponse = MarketIntelReport;

export interface SimilarBvrTransactionsResponse {
  transactions: {
    transactionId: string;
    description: string;
    /** @format double */
    rerankerScore: number;
    /** @format double */
    cosineSimilarity: number;
  }[];
}

export interface UpdateMarketIntelReportBody {
  similarTransactions?: {
    inReport: boolean;
    id: string;
  }[];
  valuationText?: {
    bullets: string[];
    title: string;
  }[];
  valuationRanges?: {
    isRecommended: boolean;
    /** @format double */
    low: number;
    /** @format double */
    high: number;
    name: string;
  }[];
  factors?: {
    negativeDescription: string;
    negativeTitle: string;
    positiveDescription: string;
    positiveTitle: string;
    name: string;
  }[];
  businessName?: string;
  domain?: string;
}

export interface BizBuySellListingResponse {
  bbsId: string;
  url: string;
  directoryUrl: string;
  isActive: boolean;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  contents: {
    obfuscatedInfo: {
      longDescription: string;
      shortDescription: string;
      name: string;
      listingContentsId: string;
      id: string;
    } | null;
    /** @format date-time */
    createdAt: string;
    serviceAreaStates: string[];
    customerSegmentsEmbedding: any;
    customerSegmentsStr: string | null;
    customerSegments: {
      description: string;
      keyPhraseStr: string;
      listingContentsId: string;
      id: string;
    }[];
    productsAndServicesEmbedding: any;
    productsAndServicesStr: string | null;
    productsAndServices: {
      description: string;
      keyPhraseStr: string;
      listingContentsId: string;
      id: string;
    }[];
    isFranchise: boolean;
    ownsRealEstate: boolean | null;
    reasonForSelling: string | null;
    /** @format double */
    numEmployees: number | null;
    locationState: string;
    locationCity: string | null;
    /** @format double */
    rent: number | null;
    /** @format double */
    inventory: number | null;
    /** @format double */
    ffe: number | null;
    /** @format double */
    ebitda: number | null;
    /** @format double */
    yearFounded: number | null;
    /** @format double */
    revenue: number | null;
    /** @format double */
    cashFlow: number | null;
    /** @format double */
    askingPrice: number | null;
    keyFacts: string[];
    longDescription: string;
    shortDescription: string;
    derivedGeneralInfo: string;
    derivedIndustryCode: string;
    title: string;
    listingId: string;
    id: string;
  };
  [key: string]: any;
}

export interface PaginatedResponseBizBuySellListingResponse {
  data: BizBuySellListingResponse[];
  /** @format double */
  total: number;
  /** @format double */
  page: number;
  /** @format double */
  pageSize: number;
  /** @format double */
  totalPages: number;
  [key: string]: any;
}

export interface ScrapedBizBuySellListingResponse {
  listings: {
    /** @format date-time */
    updatedAt: string;
    /** @format date-time */
    createdAt: string;
    isActive: boolean;
    directoryUrl: string;
    url: string;
    bbsId: string;
  }[];
  [key: string]: any;
}

export interface ScrapeBizBuySellListingInput {
  listings: {
    directoryUrl: string;
    url: string;
    bbsId: string;
  }[];
  [key: string]: any;
}

export interface ZoomWebhookBody {
  payload: any;
  event: string;
  /** @format double */
  event_ts: number;
  download_token?: string;
}

export interface CloseSearchResponse {
  org?: {
    name: string;
  };
  deal?: {
    url: string;
    deal_id: string;
    stage: string;
    status: string;
    value: string;
    title: string;
  };
  contact?: {
    address: string;
    state: string;
    postal: string;
    town: string;
    phone_number: string;
    email: string;
    contact_id: string;
    url: string;
    last_name: string;
    first_name: string;
  };
  found: boolean;
}

export interface ApolloWebhookBody {
  people: {
    phone_numbers: {
      sanitized_number: string;
    }[];
    id: string;
  }[];
}

export interface KixieWebhookBody {
  data: {
    businessnumber?: string;
    customernumber?: string;
    number?: string;
    webhookHeaders?: string | null;
    webhookurl: string;
    businessid: string;
    hookevent: 'endcall' | 'disposition';
    powerlistContactDetails?:
      | {
          result: {
            lastDialOutcome?: string | null;
            outcome?: string | null;
            status?: string;
            powerlistId?: string;
            powerlistContactId?: string;
            email?: string | null;
            title?: string | null;
            companyName: string | null;
            lastName: string | null;
            firstName: string | null;
          };
        }
      | any[];
    crm?: string;
    callDetails: {
      outcome?: string;
      webhookurl: string;
      dealid?: string;
      contactid?: number | string | '';
      callEndDate?: string | null;
      answerDate?: string;
      fromExt?: string | null;
      toExt?: string | null;
      /** @format double */
      extensionDial?: number;
      powerlistsessionid?: string | number;
      HScalltype?: string;
      powerlistid: string | number;
      cadenceactionprocessid?: string | null;
      destinationName?: string;
      email: string;
      calleridName: string;
      lname?: string;
      fname?: string;
      disposition: string;
      fromnumber164: string;
      tonumber164: string;
      recordingsid?: string | null;
      recordingurl: string;
      callstatus: string;
      calltype: 'incoming' | 'outgoing';
      externalid?: string;
      /** @format double */
      amount?: number | null;
      /** @format double */
      duration: number | null;
      calldate: string;
      /** @format double */
      businessid?: number;
      callid: string;
    };
  };
}

export interface CloseCommsUpdateBody {
  event: {
    data: any;
    object_type: string;
    lead_id?: string | null;
  };
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>;

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>;
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = '/';
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === 'number' ? value : `${value}`,
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&');
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => 'undefined' !== typeof query[key],
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key),
      )
      .join('&');
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : '';
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string')
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== 'string'
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams,
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken,
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ''}${path}${
        queryString ? `?${queryString}` : ''
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === 'undefined' || body === null
            ? null
            : payloadFormatter(body),
      },
    ).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title main
 * @version 1.0.0
 * @license ISC
 * @baseUrl /
 * @contact
 *
 * The main repo
 */
export class Api<
  SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
  files = {
    /**
     * No description
     *
     * @name CreateSignedUrl
     * @request POST:/files/signed_url
     */
    createSignedUrl: (params: RequestParams = {}) =>
      this.request<CreateSignedUrlResponse, any>({
        path: `/files/signed_url`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @name CheckHealth
     * @request GET:/health
     */
    checkHealth: (params: RequestParams = {}) =>
      this.request<
        {
          message: string;
        },
        any
      >({
        path: `/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  landing = {
    /**
     * No description
     *
     * @name AddInboundBusiness
     * @request POST:/landing/inbound_business
     */
    addInboundBusiness: (
      data: {
        entryPoint: string;
        referringDomain: string;
        internalContent: string;
        utmTerm: string;
        utmContent: string;
        utmCampaign: string;
        utmMedium: string;
        utmSource: string;
        sourceSurface: string;
        phoneNumber: string;
        emailAddress: string;
        lastName: string;
        firstName: string;
        timelineToSell: string;
        monthlyProfit: string;
        employeeRange: string;
        revenueRange: string;
        domain: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/landing/inbound_business`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Scrape
     * @request POST:/landing/match/scrape
     */
    scrape: (
      data: {
        recaptcha: string;
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ScrapeInboundBusinessResponse,
        {
          reason: string;
        }
      >({
        path: `/landing/match/scrape`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBuyers
     * @request POST:/landing/match/get_buyers
     */
    getBuyers: (
      data: {
        entryPoint: string;
        referringDomain: string;
        internalContent: string;
        utmTerm: string;
        utmContent: string;
        utmCampaign: string;
        utmMedium: string;
        utmSource: string;
        sessionReplayUrl: string;
        employeeRange: string;
        revenueRange: string;
        customerSegments: string[];
        productServices: string[];
        domain: string;
        businessName: string;
        scrapeId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PotentialBuyersResponse, any>({
        path: `/landing/match/get_buyers`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AddContactInfo
     * @request POST:/landing/match/contact_info
     */
    addContactInfo: (
      data: {
        sessionReplayUrl: string;
        monthlyProfit: string;
        timelineToSell: string;
        phoneNumber: string;
        emailAddress: string;
        lastName: string;
        firstName: string;
        scrapeId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/landing/match/contact_info`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SubmitDomain
     * @request POST:/landing/valuation/submit_domain
     */
    submitDomain: (data: SubmitDomainBody, params: RequestParams = {}) =>
      this.request<
        SubmitDomainResponse,
        {
          reason: string;
        }
      >({
        path: `/landing/valuation/submit_domain`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SubmitFinancials
     * @request POST:/landing/valuation/submit_financials
     */
    submitFinancials: (
      data: SubmitFinancialsBody,
      params: RequestParams = {},
    ) =>
      this.request<
        SubmitFinancialsResponse,
        {
          reason: string;
        }
      >({
        path: `/landing/valuation/submit_financials`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SubmitContact
     * @request POST:/landing/valuation/submit_contact
     */
    submitContact: (data: SubmitContactBody, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/landing/valuation/submit_contact`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @name Register
     * @request POST:/auth/register
     */
    register: (
      data: {
        accessKey: string;
        password: string;
        emailAddress: string;
        phone: string;
        orgName: string;
        lastName: string;
        firstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Login
     * @request POST:/auth/login
     */
    login: (
      data: {
        password: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/login`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Logout
     * @request POST:/auth/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name Me
     * @request GET:/auth/me
     * @secure
     */
    me: (params: RequestParams = {}) =>
      this.request<WhoAmIResponse, any>({
        path: `/auth/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateAccount
     * @request POST:/auth/me
     * @secure
     */
    updateAccount: (
      data: {
        intro: string;
        email: string;
        phone: string;
        lastName: string;
        firstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/me`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ForgotPassword
     * @request POST:/auth/forgot_password
     */
    forgotPassword: (
      data: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/auth/forgot_password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ResetPassword
     * @request POST:/auth/reset_password
     */
    resetPassword: (
      data: {
        token: string;
        password: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/reset_password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdatePassword
     * @request POST:/auth/update_password
     * @secure
     */
    updatePassword: (
      data: {
        newPassword: string;
        currentPassword: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/auth/update_password`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  autopilot = {
    /**
     * No description
     *
     * @name CreateAutopilot
     * @request POST:/autopilot
     * @secure
     */
    createAutopilot: (
      data: {
        /** @format double */
        maxBusinessReviewedPerDay: number;
        /** @format double */
        maxSuccessPerDay: number;
        daysActive: DayOfWeek[];
        timeWindowTimeZone: string;
        /** @format double */
        timeWindowEndHour: number;
        /** @format double */
        timeWindowStartHour: number;
        autoApprove: boolean;
        sendNewEmails: boolean;
        processNewBusinesses: boolean;
        jobTitles: string[];
        emailAddress: string;
        lastName: string;
        firstName: string;
        subjectInstruction: string;
        signature: string;
        emails: {
          /** @format double */
          waitForDays: number;
          instruction: string;
          examples: string[];
        }[];
        requirements: {
          sources: AutopilotDataSource[];
          ifNotFound: boolean;
          prompt: string;
          name: string;
        }[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/autopilot`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilots
     * @request GET:/autopilot
     * @secure
     */
    getAutopilots: (params: RequestParams = {}) =>
      this.request<GetAutopilotsResponse, any>({
        path: `/autopilot`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilotManage
     * @request GET:/autopilot/{id}/manage
     * @secure
     */
    getAutopilotManage: (id: UUID, params: RequestParams = {}) =>
      this.request<GetAutopilotManageResponse, any>({
        path: `/autopilot/${id}/manage`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManagePipeline
     * @request GET:/autopilot/{id}/manage/pipeline
     * @secure
     */
    getManagePipeline: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManagePipelineResponse, any>({
        path: `/autopilot/${id}/manage/pipeline`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageQueue
     * @request GET:/autopilot/{id}/manage/queue
     * @secure
     */
    getManageQueue: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageQueueResponse, any>({
        path: `/autopilot/${id}/manage/queue`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageResearch
     * @request GET:/autopilot/{id}/manage/research
     * @secure
     */
    getManageResearch: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageResearchResponse, any>({
        path: `/autopilot/${id}/manage/research`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateRequirement
     * @request POST:/autopilot/{campaignId}/requirements
     * @secure
     */
    createRequirement: (
      campaignId: UUID,
      data: {
        sources: AutopilotDataSource[];
        ifNotFound: boolean;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/requirements`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditRequirement
     * @request PATCH:/autopilot/{campaignId}/requirements/{requirementId}
     * @secure
     */
    editRequirement: (
      campaignId: UUID,
      requirementId: UUID,
      data: {
        sources: AutopilotDataSource[];
        ifNotFound: boolean;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/requirements/${requirementId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteRequirement
     * @request DELETE:/autopilot/{campaignId}/requirements/{requirementId}
     * @secure
     */
    deleteRequirement: (
      campaignId: UUID,
      requirementId: UUID,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/requirements/${requirementId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetManageEngage
     * @request GET:/autopilot/{id}/manage/engage
     */
    getManageEngage: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotManageEngageResponse, any>({
        path: `/autopilot/${id}/manage/engage`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAutopilot
     * @request GET:/autopilot/{id}
     */
    getAutopilot: (
      id: UUID,
      query?: {
        /** @default false */
        showFailed?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotViewResponse, any>({
        path: `/autopilot/${id}`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteCampaign
     * @request DELETE:/autopilot/{id}
     * @secure
     */
    deleteCampaign: (id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/autopilot/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameCampaign
     * @request PATCH:/autopilot/{id}
     * @secure
     */
    renameCampaign: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBusinessView
     * @request GET:/autopilot/{campaignId}/business/{businessId}
     */
    getBusinessView: (
      campaignId: UUID,
      businessId: UUID,
      params: RequestParams = {},
    ) =>
      this.request<GetAutopilotBusinessViewResponse, any>({
        path: `/autopilot/${campaignId}/business/${businessId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateEmailCol
     * @request POST:/autopilot/{id}/email
     * @secure
     */
    createEmailCol: (
      id: string,
      data: {
        /** @format double */
        waitForDays: number;
        instruction: string;
        examples: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditEmailCol
     * @request PATCH:/autopilot/{id}/email/{colId}
     * @secure
     */
    editEmailCol: (
      id: UUID,
      colId: UUID,
      data: {
        /** @format double */
        waitForDays: number;
        instruction: string;
        examples: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/email/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEmailCol
     * @request DELETE:/autopilot/{campaignId}/email/{colId}
     * @secure
     */
    deleteEmailCol: (
      campaignId: UUID,
      colId: UUID,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/email/${colId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureAutopilot
     * @request PATCH:/autopilot/{id}/configure
     * @secure
     */
    configureAutopilot: (
      id: UUID,
      data: {
        /** @format double */
        maxBusinessReviewedPerDay: number;
        /** @format double */
        maxSuccessPerDay: number;
        daysActive: DayOfWeek[];
        timeWindowTimeZone: string;
        /** @format double */
        timeWindowEndHour: number;
        /** @format double */
        timeWindowStartHour: number;
        autoApprove: boolean;
        sendNewEmails: boolean;
        processNewBusinesses: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/configure`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureSuggestions
     * @request PATCH:/autopilot/{id}/configure_suggestions
     * @secure
     */
    configureSuggestions: (
      id: UUID,
      data: {
        excludeCustomerSegment: string[];
        excludeProductService: string[];
        /** @format double */
        revenueFilterMax: number | null;
        /** @format double */
        revenueFilterMin: number | null;
        /** @format double */
        employeeFilterMax: number | null;
        /** @format double */
        employeeFilterMin: number | null;
        geoFilterServiceAreaStates: string[];
        geoFilterHqStates: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/configure_suggestions`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureEngage
     * @request PATCH:/autopilot/{id}/engage
     * @secure
     */
    configureEngage: (
      id: UUID,
      data: {
        emailAddress: string;
        lastName: string;
        firstName: string;
        subjectInstruction: string;
        signature: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/engage`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ConfigureAutopilotJobTitles
     * @request PATCH:/autopilot/{id}/job_titles
     * @secure
     */
    configureAutopilotJobTitles: (
      id: UUID,
      data: {
        jobTitles: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/job_titles`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddToAutopilot
     * @request POST:/autopilot/{id}/add
     * @secure
     */
    addToAutopilot: (
      id: UUID,
      data: {
        isSuggested?: boolean;
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAllFromSearch
     * @request POST:/autopilot/{campaignId}/add_search
     * @secure
     */
    addAllFromSearch: (
      campaignId: UUID,
      data: {
        searchQuery: {
          softwareOnly: boolean;
          excludeLists?: string[];
          excludeKeywords?: {
            customerSegments: string[];
            productServices: string[];
          };
          fundingFilter?: {
            mustHave: boolean;
            funded?: {
              /** @format double */
              maxFunding: number | null;
              /** @format double */
              minFunding: number | null;
            };
          };
          revenueFilter?: {
            mustHave: boolean;
            /** @format double */
            maxRevenue: number | null;
            /** @format double */
            minRevenue: number | null;
          };
          employeeFilter?: {
            /** @format double */
            maxEmployees: number | null;
            /** @format double */
            minEmployees: number | null;
          };
          geoFilter?: {
            mustHaveServiceAreas: boolean;
            serviceAreasStates: string[];
            hqStates: string[];
          };
          /** @format double */
          customerSegmentWeight: number;
          /** @format double */
          productServiceWeight: number;
          customerSegmentsMatchAll: boolean;
          customerSegments: string[];
          productsAndServicesMatchAll: boolean;
          productsAndServices: string[];
          description: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/add_search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveFromAutopilot
     * @request POST:/autopilot/{id}/remove
     * @secure
     */
    removeFromAutopilot: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${id}/remove`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunSequences
     * @request POST:/autopilot/{id}/run
     * @secure
     */
    runSequences: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        MarketIntelReportStatus,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/run`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RegenerateEmail
     * @request POST:/autopilot/{id}/email/run
     * @secure
     */
    regenerateEmail: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        MarketIntelReportStatus,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/email/run`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveSequence
     * @request POST:/autopilot/{campaignId}/sequence/{businessId}/approve
     * @secure
     */
    approveSequence: (
      campaignId: UUID,
      businessId: UUID,
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        MarketIntelReportStatus,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/sequence/${businessId}/approve`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEmails
     * @request PATCH:/autopilot/{campaignId}/emails/{businessId}
     * @secure
     */
    updateEmails: (
      campaignId: UUID,
      businessId: UUID,
      data: {
        emails: {
          content: string;
          colId: string;
        }[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/emails/${businessId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEmail
     * @request PATCH:/autopilot/{campaignId}/{businessId}/email/{colId}
     * @secure
     */
    updateEmail: (
      campaignId: UUID,
      businessId: UUID,
      colId: UUID,
      data: {
        content: string;
        /** @format date-time */
        sendTime: string | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/email/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveSuggested
     * @request DELETE:/autopilot/{id}/suggested
     * @secure
     */
    removeSuggested: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${id}/suggested`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SendEmail
     * @request POST:/autopilot/{campaignId}/send_email
     * @secure
     */
    sendEmail: (
      campaignId: string,
      data: {
        colId: string;
        businessId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/send_email`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSequenceContactInfo
     * @request PATCH:/autopilot/{campaignId}/{businessId}/contact
     * @secure
     */
    updateSequenceContactInfo: (
      campaignId: string,
      businessId: string,
      data: {
        contactJobTitle: string;
        contactEmailAddress: string;
        contactLastName: string;
        contactFirstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/contact`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateSequenceSubject
     * @request PATCH:/autopilot/{campaignId}/{businessId}/subject
     * @secure
     */
    updateSequenceSubject: (
      campaignId: string,
      businessId: string,
      data: {
        subject: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/subject`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CompleteImport
     * @request POST:/autopilot/import/{fileId}/complete
     * @secure
     */
    completeImport: (
      fileId: UUID,
      data: {
        mapping: {
          website: string;
        };
        autopilotId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/import/${fileId}/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ImportDomains
     * @request POST:/autopilot/{campaignId}/importDomains
     * @secure
     */
    importDomains: (
      campaignId: string,
      data: {
        domains: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/importDomains`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSuggested
     * @request GET:/autopilot/{campaignId}/suggested
     * @secure
     */
    getSuggested: (campaignId: string, params: RequestParams = {}) =>
      this.request<
        GetSimilarCompaniesResponse,
        {
          reason: string;
        }
      >({
        path: `/autopilot/${campaignId}/suggested`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name OverrideRequirementResult
     * @request PATCH:/autopilot/{campaignId}/{businessId}/override_requirement_result
     * @secure
     */
    overrideRequirementResult: (
      campaignId: string,
      businessId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/autopilot/${campaignId}/${businessId}/override_requirement_result`,
        method: 'PATCH',
        secure: true,
        ...params,
      }),
  };
  billing = {
    /**
     * No description
     *
     * @name CreateCheckboutSessions
     * @request POST:/billing/session
     * @secure
     */
    createCheckboutSessions: (params: RequestParams = {}) =>
      this.request<
        {
          checkoutUrl: string;
        },
        any
      >({
        path: `/billing/session`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateAdditionalCreditSession
     * @request POST:/billing/additional_credits
     * @secure
     */
    createAdditionalCreditSession: (params: RequestParams = {}) =>
      this.request<
        {
          checkoutUrl: string;
        },
        any
      >({
        path: `/billing/additional_credits`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSubscription
     * @request GET:/billing/subscription
     * @secure
     */
    getSubscription: (params: RequestParams = {}) =>
      this.request<GetSubscriptionResponse, any>({
        path: `/billing/subscription`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CancelSubscription
     * @request DELETE:/billing/subscription
     * @secure
     */
    cancelSubscription: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/billing/subscription`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetCreditsLeft
     * @request GET:/billing/credits
     * @secure
     */
    getCreditsLeft: (params: RequestParams = {}) =>
      this.request<GetCreditsLeftResponse, any>({
        path: `/billing/credits`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name Webhook
     * @request POST:/billing/webhook
     */
    webhook: (data: MarketIntelReportStatus, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/billing/webhook`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  businesses = {
    /**
     * No description
     *
     * @name GetDataForSimilarSearch
     * @request POST:/businesses/similar_search_data
     * @secure
     */
    getDataForSimilarSearch: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        SimilarSearchDataResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/similar_search_data`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBusinessDetailed
     * @request GET:/businesses/{id}/detailed
     * @secure
     */
    getBusinessDetailed: (id: UUID, params: RequestParams = {}) =>
      this.request<
        GetBusinessDetailedResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/${id}/detailed`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSimilar
     * @request GET:/businesses/{id}/similar
     * @secure
     */
    getSimilar: (id: UUID, params: RequestParams = {}) =>
      this.request<
        SimilarCompaniesResponse,
        {
          reason: string;
        }
      >({
        path: `/businesses/${id}/similar`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses
     * @request POST:/businesses/search
     * @secure
     */
    searchBusinesses: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        softwareOnly: boolean;
        excludeLists?: string[];
        excludeKeywords?: {
          customerSegments: string[];
          productServices: string[];
        };
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceWeight: number;
        customerSegmentsMatchAll: boolean;
        customerSegments: string[];
        productsAndServicesMatchAll: boolean;
        productsAndServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse, any>({
        path: `/businesses/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses2
     * @request POST:/businesses/search2
     * @secure
     */
    searchBusinesses2: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        softwareOnly: boolean;
        excludeLists?: string[];
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        mustHaveTextMatch: boolean;
        mustHaveSemanticMatch: boolean;
        /** @format double */
        semanticSearchWeight: number;
        /** @format double */
        textSearchWeight: number;
        /** @format double */
        customerSegmentSemanticThreshold: number;
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceSemanticThreshold: number;
        /** @format double */
        productServiceWeight: number;
        /** @format double */
        descriptionSemanticThreshold: number;
        /** @format double */
        descriptionWeight: number;
        customerSegments: string;
        productsAndServices: string;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse2, any>({
        path: `/businesses/search2`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses3
     * @request POST:/businesses/search3
     * @secure
     */
    searchBusinesses3: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        excludeLists?: string[];
        excludeKeywords?: {
          customerSegments: string[];
          productServices: string[];
        };
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentSemanticThreshold: number;
        /** @format double */
        customerSegmentWeight: number;
        customerSegments: string;
        /** @format double */
        productServiceSemanticThreshold: number;
        /** @format double */
        productServiceWeight: number;
        productsAndServices: string;
        /** @format double */
        descriptionSemanticThreshold: number;
        /** @format double */
        descriptionWeight: number;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse3, any>({
        path: `/businesses/search3`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses4
     * @request POST:/businesses/search4
     * @secure
     */
    searchBusinesses4: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        excludeLists?: string[];
        excludeKeywords?: {
          customerSegments: string[];
          productServices: string[];
        };
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentSemanticThreshold: number;
        /** @format double */
        customerSegmentWeight: number;
        customerSegments: string;
        /** @format double */
        productServiceSemanticThreshold: number;
        /** @format double */
        productServiceWeight: number;
        productsAndServices: string;
        /** @format double */
        descriptionSemanticThreshold: number;
        /** @format double */
        descriptionWeight: number;
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse3, any>({
        path: `/businesses/search4`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses5
     * @request POST:/businesses/search5
     * @secure
     */
    searchBusinesses5: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        softwareOnly: boolean;
        excludeLists?: string[];
        excludeKeywords?: {
          customerSegments: string[];
          productServices: string[];
        };
        fundingFilter?: {
          mustHave: boolean;
          funded?: {
            /** @format double */
            maxFunding: number | null;
            /** @format double */
            minFunding: number | null;
          };
        };
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceWeight: number;
        customerSegments: string[];
        productsAndServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse, any>({
        path: `/businesses/search5`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SearchBusinesses6
     * @request POST:/businesses/search6
     * @secure
     */
    searchBusinesses6: (
      data: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
        revenueFilter?: {
          mustHave: boolean;
          /** @format double */
          maxRevenue: number | null;
          /** @format double */
          minRevenue: number | null;
        };
        employeeFilter?: {
          /** @format double */
          maxEmployees: number | null;
          /** @format double */
          minEmployees: number | null;
        };
        geoFilter?: {
          mustHaveServiceAreas: boolean;
          serviceAreasStates: string[];
          hqStates: string[];
        };
        /** @format double */
        customerSegmentWeight: number;
        /** @format double */
        productServiceWeight: number;
        customerSegments: string[];
        productsAndServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SearchResponse, any>({
        path: `/businesses/search6`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name FindRelatedKeywords
     * @request POST:/businesses/related
     * @secure
     */
    findRelatedKeywords: (
      data: {
        customerSegments: string[];
        productServices: string[];
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RelatedKeywordsResponse, any>({
        path: `/businesses/related`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBusinessByDomain
     * @request GET:/businesses/by_domain
     * @secure
     */
    getBusinessByDomain: (
      query: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/businesses/by_domain`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  listings = {
    /**
     * No description
     *
     * @name Me
     * @request GET:/listings/me
     * @secure
     */
    me: (params: RequestParams = {}) =>
      this.request<ListingWhoAmIResponse, any>({
        path: `/listings/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateProfile
     * @request PATCH:/listings/me
     * @secure
     */
    updateProfile: (
      data: OmitListingWhoAmIResponseEmailAddress,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/listings/me`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListing
     * @request GET:/listings/{id}
     */
    getListing: (id: string, params: RequestParams = {}) =>
      this.request<GetListingResponse, any>({
        path: `/listings/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name LogListingOpen
     * @request POST:/listings/{id}/log_open
     */
    logListingOpen: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listings/${id}/log_open`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadBlindProfile
     * @request GET:/listings/{id}/download_blind_profile
     */
    downloadBlindProfile: (
      id: string,
      query?: {
        /** @default false */
        isFileDownload?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/listings/${id}/download_blind_profile`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SendOneTimeCode
     * @request POST:/listings/send_otp
     */
    sendOneTimeCode: (
      data: {
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          newAccount: boolean;
        },
        any
      >({
        path: `/listings/send_otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VerifyOneTimeCode
     * @request POST:/listings/verify_otp
     */
    verifyOneTimeCode: (
      data: {
        code: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/listings/verify_otp`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name Logout
     * @request POST:/listings/logout
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/listings/logout`,
        method: 'POST',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateNdaRequest
     * @request POST:/listings/{id}/nda_request
     * @secure
     */
    createNdaRequest: (
      id: string,
      data: {
        referrer?: string;
        ownerPostTransactionExpectation: string;
        financingStatusExplanation: string;
        financingStatus: ListingBuyerNDARequestFinancingStatus;
        relevantExperience?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/listings/${id}/nda_request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNdaFile
     * @request GET:/listings/{id}/nda_file
     * @secure
     */
    getNdaFile: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/listings/${id}/nda_file`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetNdaSignUrl
     * @request POST:/listings/{id}/nda_sign_url
     * @secure
     */
    getNdaSignUrl: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          helloSignUrl: string;
        },
        any
      >({
        path: `/listings/${id}/nda_sign_url`,
        method: 'POST',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name OnNdaSigned
     * @request POST:/listings/{id}/on_nda_signed
     * @secure
     */
    onNdaSigned: (
      id: string,
      data: {
        sessionReplayUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/listings/${id}/on_nda_signed`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadCim
     * @request GET:/listings/{id}/download_cim
     */
    downloadCim: (
      id: string,
      query?: {
        /** @default false */
        isFileDownload?: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/listings/${id}/download_cim`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadAllDataRoomFiles
     * @request GET:/listings/{id}/data_room/download_all
     * @secure
     */
    downloadAllDataRoomFiles: (id: string, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/listings/${id}/data_room/download_all`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadSingleDataRoomFile
     * @request GET:/listings/{id}/data_room/download_file
     * @secure
     */
    downloadSingleDataRoomFile: (
      id: string,
      query: {
        fileName: string;
        requestId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/listings/${id}/data_room/download_file`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  clients = {
    /**
     * No description
     *
     * @name GetProjects
     * @request GET:/clients
     * @secure
     */
    getProjects: (params: RequestParams = {}) =>
      this.request<GetProjectsResponse, any>({
        path: `/clients`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateProject
     * @request POST:/clients
     * @secure
     */
    createProject: (data: CreateProjectBody, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
        },
        {
          message: string;
        }
      >({
        path: `/clients`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProject
     * @request GET:/clients/{id}
     * @secure
     */
    getProject: (id: string, params: RequestParams = {}) =>
      this.request<GetProjectResponse, any>({
        path: `/clients/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateProject
     * @request PATCH:/clients/{id}
     * @secure
     */
    updateProject: (
      id: string,
      data: {
        transcripts: string[];
        firmName: string;
        advisorPhone: string;
        advisorEmail: string;
        advisorTitle: string;
        advisorName: string;
        ownerEmail: string;
        codeName: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          message: string;
        }
      >({
        path: `/clients/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteProject
     * @request DELETE:/clients/{id}
     * @secure
     */
    deleteProject: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateBlindProfile
     * @request POST:/clients/{id}/generate_blind_profile
     * @secure
     */
    generateBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_blind_profile`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateBlindProfile
     * @request PATCH:/clients/{id}/blind_profile
     * @secure
     */
    updateBlindProfile: (
      id: string,
      data: {
        showAwards: boolean;
        showRecurringRevenue: boolean;
        showRevenueMix: boolean;
        showVendorsAndPartners: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        isCompleted?: boolean;
        headerLogoUrl: string;
        /** @format double */
        numCustomers: number;
        headcountByRole: HeadcountByRole;
        topCustomersByRevenue: number[];
        recurringRevenue: RecurringRevenue;
        financialInfo: FinancialInfo;
        revenueGeoDistribution: RevenueGeoDistribution;
        revenueMix: RevenueMix;
        certifications: string[];
        vendorsAndPartners: string[];
        verticals: string[];
        investmentConsiderations: InvestmentConsiderations;
        primaryProductServices: string[];
        description: string;
        subtitle: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/blind_profile`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCustomBlindProfile
     * @request PATCH:/clients/{id}/blind_profile/custom
     * @secure
     */
    updateCustomBlindProfile: (
      id: string,
      data: {
        customBlindProfileUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/blind_profile/custom`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateCim
     * @request POST:/clients/{id}/generate_cim
     * @secure
     */
    generateCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_cim`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateIntake
     * @request POST:/clients/{id}/generate_intake
     * @secure
     */
    generateIntake: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_intake`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateListing
     * @request POST:/clients/{id}/generate_listing
     * @secure
     */
    generateListing: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_listing`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateListing
     * @request PATCH:/clients/{id}/listing
     * @secure
     */
    updateListing: (
      id: string,
      data: {
        scheduleMeetingDeadline: string;
        description: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/listing`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListing
     * @request GET:/clients/{id}/listing
     * @secure
     */
    getListing: (id: string, params: RequestParams = {}) =>
      this.request<GetBrokerListingResponse, any>({
        path: `/clients/${id}/listing`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCim
     * @request PATCH:/clients/{id}/cim
     * @secure
     */
    updateCim: (
      id: string,
      data: {
        showBalanceSheet: boolean;
        showIncomeStatement: boolean;
        showVendorsAndPartners: boolean;
        showGrowthOpportunities: boolean;
        showCustomerAcquisition: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        showRevenueMix: boolean;
        growthOppertunities: GrowthOpportunities;
        keyMetricsHighlights: string[];
        marketingStrategyHighlights: string[];
        customerAcquisitionHighlights: string[];
        acquisitionChannels: AcquisitionChannels;
        vendorsAndPartners: string[];
        footerLogoUrl: string;
        topCustomersByRevenue: number[];
        revenueGeoDistribution: RevenueGeoDistribution;
        revenueMix: RevenueMix;
        leadershipBios: LeadershipBios;
        isCompleted?: boolean;
        incomeStatementImgUrl: string;
        balanceSheetImgUrl: string;
        productsAndServices: {
          valueProps: string[];
          features: string[];
          description: string;
          name: string;
        }[];
        verticalsSummary: string[];
        growthSummary: string[];
        customerSummary: string[];
        peopleSummary: string[];
        investmentConsiderations: string[];
        valueProps: string[];
        companyOverview: string;
        oneLinerDescription: string;
        processBlurb: string;
        firmName: string;
        advisorEmail: string;
        advisorTitle: string;
        advisorName: string;
        ownerTitle: string;
        ownerName: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/cim`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateCustomCim
     * @request PATCH:/clients/{id}/cim/custom
     * @secure
     */
    updateCustomCim: (
      id: string,
      data: {
        customCimUrl: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/cim/custom`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteMatchedBuyer
     * @request DELETE:/clients/{id}/matched_buyer
     * @secure
     */
    deleteMatchedBuyer: (
      id: string,
      data: {
        acquirerWebsite: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/matched_buyer`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddMatchedBuyer
     * @request POST:/clients/{id}/matched_buyer
     * @secure
     */
    addMatchedBuyer: (
      id: string,
      data: {
        matchNotes: string;
        /** @format double */
        matchScore: number;
        acquirerDescription: string;
        acquirerName: string;
        acquirerWebsite: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/matched_buyer`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateBuyerList
     * @request POST:/clients/{id}/generate_buyer_list
     * @secure
     */
    generateBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/generate_buyer_list`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadBlindProfile
     * @request GET:/clients/{id}/download_blind_profile
     * @secure
     */
    downloadBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/download_blind_profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadCim
     * @request GET:/clients/{id}/download_cim
     * @secure
     */
    downloadCim: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/download_cim`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SetIntakeComplete
     * @request PATCH:/clients/{id}/intake/complete
     * @secure
     */
    setIntakeComplete: (
      id: string,
      data: {
        completed: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/intake/complete`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockBlindProfile
     * @request POST:/clients/{id}/unlock_blind_profile
     * @secure
     */
    unlockBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_blind_profile`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockCim
     * @request POST:/clients/{id}/unlock_cim
     * @secure
     */
    unlockCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_cim`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockBlindProfile
     * @request POST:/clients/{id}/lock_blind_profile
     * @secure
     */
    lockBlindProfile: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_blind_profile`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockCim
     * @request POST:/clients/{id}/lock_cim
     * @secure
     */
    lockCim: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_cim`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name LockBuyerList
     * @request POST:/clients/{id}/lock_buyer_list
     * @secure
     */
    lockBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/lock_buyer_list`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UnlockBuyerList
     * @request POST:/clients/{id}/unlock_buyer_list
     * @secure
     */
    unlockBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/unlock_buyer_list`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name ExportBuyersCsv
     * @request GET:/clients/{id}/export_buyers_csv
     * @secure
     */
    exportBuyersCsv: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/export_buyers_csv`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateClientDataRoom
     * @request POST:/clients/{id}/data_room
     * @secure
     */
    createClientDataRoom: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetClientDataRoom
     * @request GET:/clients/{id}/data_room
     * @secure
     */
    getClientDataRoom: (id: string, params: RequestParams = {}) =>
      this.request<GetClientDataRoomRequestsResponse, any>({
        path: `/clients/${id}/data_room`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateClientDataRoomRequest
     * @request POST:/clients/{id}/data_room/request
     * @secure
     */
    createClientDataRoomRequest: (
      id: string,
      data: {
        preLOI: boolean;
        category: string;
        description: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteClientDataRoomRequest
     * @request DELETE:/clients/{id}/data_room/request/{requestId}
     * @secure
     */
    deleteClientDataRoomRequest: (
      id: string,
      requestId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request/${requestId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateClientDataRoomRequest
     * @request PATCH:/clients/{id}/data_room/request/{requestId}
     * @secure
     */
    updateClientDataRoomRequest: (
      id: string,
      requestId: string,
      data: {
        preLOI: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/request/${requestId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetAdjustedEbitdaInfo
     * @request POST:/clients/{id}/get_adjusted_ebitda_info
     * @secure
     */
    getAdjustedEbitdaInfo: (
      id: string,
      data: {
        incomeStatementFileId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AdjustedEBITDAInfoResponse, any>({
        path: `/clients/${id}/get_adjusted_ebitda_info`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CalculateValuation
     * @request POST:/clients/{id}/calculate_valuation
     * @secure
     */
    calculateValuation: (
      id: string,
      data: {
        useO1: boolean;
        addBackAnswers: {
          answer: string;
          question: string;
          /** @format double */
          totalExpenseAmount: number;
          expenseName: string;
        }[];
        /** @format double */
        ebitda: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<ValuationInfoResponse, any>({
        path: `/clients/${id}/calculate_valuation`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadDataRoomRequest
     * @request GET:/clients/{id}/data_room/{requestId}
     * @secure
     */
    downloadDataRoomRequest: (
      id: string,
      requestId: string,
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/clients/${id}/data_room/${requestId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadDataRoomFile
     * @request GET:/clients/{id}/data_room/{requestId}/{fileName}
     * @secure
     */
    downloadDataRoomFile: (
      id: string,
      requestId: string,
      fileName: string,
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/clients/${id}/data_room/${requestId}/${fileName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadAllDataRoomFiles
     * @request GET:/clients/{id}/data_room/download_all
     * @secure
     */
    downloadAllDataRoomFiles: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/clients/${id}/data_room/download_all`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListingBuyer
     * @request GET:/clients/buyer/{email}
     * @secure
     */
    getListingBuyer: (email: string, params: RequestParams = {}) =>
      this.request<GetListingBuyerResponse, any>({
        path: `/clients/buyer/${email}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateNdaRequestStatus
     * @request PATCH:/clients/{id}/nda_request/{buyerEmail}
     * @secure
     */
    updateNdaRequestStatus: (
      id: string,
      buyerEmail: string,
      data: {
        reviewStatus: ListingBuyerAdvisorReviewStatus;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/nda_request/${buyerEmail}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ReleaseNda
     * @request POST:/clients/{id}/nda_request/{buyerEmail}/release
     * @secure
     */
    releaseNda: (id: string, buyerEmail: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/clients/${id}/nda_request/${buyerEmail}/release`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameDataRoomFile
     * @request PATCH:/clients/{id}/data_room/{requestId}/rename
     * @secure
     */
    renameDataRoomFile: (
      id: string,
      requestId: string,
      data: {
        newFileName: string;
        oldFileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/data_room/${requestId}/rename`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdatePostNdaStatus
     * @request PATCH:/clients/{id}/nda/{buyerEmail}/post_nda_status
     * @secure
     */
    updatePostNdaStatus: (
      id: string,
      buyerEmail: string,
      data: {
        postNDAStatus: ListingBuyerPostNDAStatus;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/clients/${id}/nda/${buyerEmail}/post_nda_status`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  clientPortal = {
    /**
     * No description
     *
     * @name AuthWithEmail
     * @request POST:/client_portal/sign_in
     */
    authWithEmail: (
      data: {
        accessCode: string;
        email: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          clientId: string;
        },
        {
          reason: string;
        }
      >({
        path: `/client_portal/sign_in`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetUnauthenticatedData
     * @request GET:/client_portal/{id}/unauthenticated
     */
    getUnauthenticatedData: (id: string, params: RequestParams = {}) =>
      this.request<GetUnauthenticatedClientPortalDataResponse, any>({
        path: `/client_portal/${id}/unauthenticated`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name VerifyAccessCode
     * @request POST:/client_portal/verify_access_code
     */
    verifyAccessCode: (
      data: {
        accessCode: string;
        projectId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/client_portal/verify_access_code`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPortalData
     * @request GET:/client_portal/{id}/authenticated
     * @secure
     */
    getPortalData: (id: string, params: RequestParams = {}) =>
      this.request<
        GetClientPortalDataResponse,
        {
          reason: string;
        }
      >({
        path: `/client_portal/${id}/authenticated`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetIntake
     * @request GET:/client_portal/intake
     * @secure
     */
    getIntake: (params: RequestParams = {}) =>
      this.request<GetClientPortalIntakeResponse, any>({
        path: `/client_portal/intake`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateIntake
     * @request PATCH:/client_portal/intake
     * @secure
     */
    updateIntake: (
      data: {
        showCustomerAcquisition: boolean;
        showAwards: boolean;
        showRecurringRevenue: boolean;
        showRevenueMix: boolean;
        showVendorsAndPartners: boolean;
        showCustomerConcentration: boolean;
        showRevenueGeo: boolean;
        additionalInfo: string;
        certifications: string[];
        awards: string[];
        buyerExclusions: BuyerExclusions;
        prospectiveBuyers: ProspectiveBuyers;
        otherInvestmentConsiderations: string[];
        recurringRevenueNotes: string;
        recurringRevenue: RecurringRevenue;
        revenueNotes: string;
        revenueMix: RevenueMix;
        ebitdaAdjustments: string;
        financialInfo: FinancialInfo;
        growthOpportunitiesDetails: string;
        customerAcquisitionDetails: string;
        acquisitionChannels: AcquisitionChannels;
        vendersAndPartners: string[];
        verticals: string[];
        revenueConcentrationDetails: string;
        topCustomersByRevenue: number[];
        revenueGeoDistribution: RevenueGeoDistribution;
        retentionDescription: string;
        /** @format double */
        numCustomers: number;
        primaryProductDescription: string;
        headcountByRole: HeadcountByRole;
        leadershipBios: LeadershipBios;
        otherPostTransactionPlan: string;
        ceoPostTransactionPlan: string;
        founderPostTransactionPlan: string;
        valuationExpectation: string;
        objectiveDescription: string;
        companyOverview: string;
        corpType: string;
        otherLocations: string[];
        hqState: string;
        hqCity: string;
        incorporationState: string;
        /** @format double */
        yearFounded: number;
        contactPhoneNumber: string;
        contactPersonalEmail: string;
        contactWorkEmail: string;
        contactTitle: string;
        contactName: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/intake`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetClientPortalDataRoom
     * @request GET:/client_portal/data_room
     * @secure
     */
    getClientPortalDataRoom: (params: RequestParams = {}) =>
      this.request<GetClientPortalDataRoomResponse, any>({
        path: `/client_portal/data_room`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateSignedUrl
     * @request POST:/client_portal/data_room/{requestId}/signed_url
     * @secure
     */
    createSignedUrl: (
      requestId: string,
      data: {
        fileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CreateSignedDataRoomUrlResponse, any>({
        path: `/client_portal/data_room/${requestId}/signed_url`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateDataRoomFile
     * @request POST:/client_portal/data_room/{requestId}/file
     * @secure
     */
    createDataRoomFile: (
      requestId: string,
      data: {
        /** @format double */
        size: number;
        fileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/data_room/${requestId}/file`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadDataRoomFile
     * @request GET:/client_portal/data_room/{requestId}/{fileName}
     * @secure
     */
    downloadDataRoomFile: (
      requestId: string,
      fileName: string,
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/client_portal/data_room/${requestId}/${fileName}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameDataRoomFile
     * @request PATCH:/client_portal/data_room/{requestId}/{fileName}
     * @secure
     */
    renameDataRoomFile: (
      requestId: string,
      fileName: string,
      data: {
        newFileName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/data_room/${requestId}/${fileName}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteDataRoomFile
     * @request DELETE:/client_portal/data_room/{requestId}/{fileName}
     * @secure
     */
    deleteDataRoomFile: (
      requestId: string,
      fileName: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/data_room/${requestId}/${fileName}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateDataRoomRequestCompleted
     * @request POST:/client_portal/data_room/{requestId}/complete
     * @secure
     */
    updateDataRoomRequestCompleted: (
      requestId: string,
      data: {
        completed: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/data_room/${requestId}/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadBlindProfile
     * @request GET:/client_portal/download_blind_profile
     * @secure
     */
    downloadBlindProfile: (params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/client_portal/download_blind_profile`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveBlindProfile
     * @request PATCH:/client_portal/{id}/approve_blind_profile
     * @secure
     */
    approveBlindProfile: (
      id: string,
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/${id}/approve_blind_profile`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadCim
     * @request GET:/client_portal/download_cim
     * @secure
     */
    downloadCim: (params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/client_portal/download_cim`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ApproveCim
     * @request PATCH:/client_portal/approve_cim
     * @secure
     */
    approveCim: (
      data: {
        approved: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/approve_cim`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name SetIntakeComplete
     * @request PATCH:/client_portal/intake/complete
     * @secure
     */
    setIntakeComplete: (
      data: {
        completed: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/intake/complete`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBuyers
     * @request GET:/client_portal/buyers
     * @secure
     */
    getBuyers: (params: RequestParams = {}) =>
      this.request<GetClientPortalBuyersResponse, any>({
        path: `/client_portal/buyers`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name SetBuyerRejected
     * @request PATCH:/client_portal/buyers/reject
     * @secure
     */
    setBuyerRejected: (
      data: {
        buyerEmailAddress: string;
        isRejected: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/buyers/reject`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AcceptAndSignNda
     * @request POST:/client_portal/nda_sign_url
     * @secure
     */
    acceptAndSignNda: (
      data: {
        buyerEmailAddress: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          helloSignUrl: string;
        },
        any
      >({
        path: `/client_portal/nda_sign_url`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name OnNdaSigned
     * @request POST:/client_portal/on_nda_signed
     * @secure
     */
    onNdaSigned: (
      data: {
        buyerEmailAddress: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client_portal/on_nda_signed`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListingBuyer
     * @request GET:/client_portal/buyer/{email}
     * @secure
     */
    getListingBuyer: (email: string, params: RequestParams = {}) =>
      this.request<GetClientPortalListingBuyerResponse, any>({
        path: `/client_portal/buyer/${email}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetChecklist
     * @request GET:/client_portal/checklist
     * @secure
     */
    getChecklist: (params: RequestParams = {}) =>
      this.request<GetChecklistResponse, any>({
        path: `/client_portal/checklist`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  email = {
    /**
     * No description
     *
     * @name LogEmailView
     * @request GET:/email/{id}/view
     */
    logEmailView: (id: UUID, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/email/${id}/view`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  };
  enrichments = {
    /**
     * No description
     *
     * @name CreateContactInfo
     * @request POST:/enrichments/{listId}/contact_info
     * @secure
     */
    createContactInfo: (
      listId: UUID,
      data: {
        jobTitles: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/contact_info`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditContactInfo
     * @request PATCH:/enrichments/{listId}/contact_info/{colId}
     * @secure
     */
    editContactInfo: (
      listId: UUID,
      colId: UUID,
      data: {
        jobTitles: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/contact_info/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateIsBootstrapped
     * @request POST:/enrichments/{listId}/is_bootstrapped
     * @secure
     */
    createIsBootstrapped: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/is_bootstrapped`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditIsBootstrapped
     * @request PATCH:/enrichments/{listId}/is_bootstrapped/{colId}
     * @secure
     */
    editIsBootstrapped: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/is_bootstrapped/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateIsValid
     * @request POST:/enrichments/{listId}/is_valid
     * @secure
     */
    createIsValid: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/is_valid`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditIsValid
     * @request PATCH:/enrichments/{listId}/is_valid/{colId}
     * @secure
     */
    editIsValid: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/is_valid/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateLinkedIn
     * @request POST:/enrichments/{listId}/linkedin
     * @secure
     */
    createLinkedIn: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/enrichments/${listId}/linkedin`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditLinkedIn
     * @request PATCH:/enrichments/{listId}/linkedin/{colId}
     * @secure
     */
    editLinkedIn: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/linkedin/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreatePersonalizedMessage
     * @request POST:/enrichments/{listId}/personalized_message
     * @secure
     */
    createPersonalizedMessage: (
      listId: UUID,
      data: {
        instruction: string;
        examples: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/personalized_message`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditPersonalizedMessage
     * @request PATCH:/enrichments/{listId}/personalized_message/{colId}
     * @secure
     */
    editPersonalizedMessage: (
      listId: UUID,
      colId: UUID,
      data: {
        instruction: string;
        examples: string[];
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/personalized_message/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreatePitchbook
     * @request POST:/enrichments/{listId}/pitchbook
     * @secure
     */
    createPitchbook: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/pitchbook`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditPitchbook
     * @request PATCH:/enrichments/{listId}/pitchbook/{colId}
     * @secure
     */
    editPitchbook: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/pitchbook/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateScrape
     * @request POST:/enrichments/{listId}/scrape
     * @secure
     */
    createScrape: (
      listId: UUID,
      data: {
        useReasoning: boolean;
        dataSources: ScrapeDataSource[];
        dataTypeExtra: EnrichmentClassificationExtra | null;
        dataType: EnrichScrapeDataType;
        extractInfoTopics: string | null;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/scrape`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditScrape
     * @request PATCH:/enrichments/{listId}/scrape/{colId}
     * @secure
     */
    editScrape: (
      listId: UUID,
      colId: UUID,
      data: {
        useReasoning: boolean;
        extractInfoTopics: string | null;
        dataSources: ScrapeDataSource[];
        dataTypeExtra: EnrichmentClassificationExtra | null;
        dataType: EnrichScrapeDataType;
        prompt: string;
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/scrape/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateSimplifiedName
     * @request POST:/enrichments/{listId}/simplified_name
     * @secure
     */
    createSimplifiedName: (
      listId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/enrichments/${listId}/simplified_name`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name EditSimplifiedName
     * @request PATCH:/enrichments/{listId}/simplified_name/{colId}
     * @secure
     */
    editSimplifiedName: (
      listId: UUID,
      colId: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/enrichments/${listId}/simplified_name/${colId}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  internal = {
    /**
     * No description
     *
     * @name PrepMeeting
     * @request POST:/internal/prep
     * @secure
     */
    prepMeeting: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<PrepMeetingResponse, any>({
        path: `/internal/prep`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ScrapeBusiness
     * @request POST:/internal/scrape_business
     * @secure
     */
    scrapeBusiness: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        ScrapeBuyerMatchBusiness,
        {
          reason: string;
        }
      >({
        path: `/internal/scrape_business`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPotentialBuyers
     * @request POST:/internal/potential_buyers
     * @secure
     */
    getPotentialBuyers: (
      data: {
        customerSegments: string[];
        productServices: string[];
        domain: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetPotentialBuyersResponse, any>({
        path: `/internal/potential_buyers`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetPotentialBuyersXlsx
     * @request POST:/internal/potential_buyers_xlsx
     * @secure
     */
    getPotentialBuyersXlsx: (
      data: {
        customerSegments: string[];
        productServices: string[];
        domain: string;
        businessName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UUID, any>({
        path: `/internal/potential_buyers_xlsx`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  lists = {
    /**
     * No description
     *
     * @name GetLists
     * @request GET:/lists
     * @secure
     */
    getLists: (params: RequestParams = {}) =>
      this.request<V1GetListsResponse, any>({
        path: `/lists`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateList
     * @request POST:/lists
     * @secure
     */
    createList: (
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/lists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetList
     * @request GET:/lists/{id}
     * @secure
     */
    getList: (
      id: UUID,
      query: {
        /** @format double */
        pageNum: number;
        /** @format double */
        pageSize: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        V1GetListResponse,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteList
     * @request DELETE:/lists/{id}
     * @secure
     */
    deleteList: (id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RenameList
     * @request PATCH:/lists/{id}
     * @secure
     */
    renameList: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEnrichmentDetails
     * @request GET:/lists/enrichment/{colId}/{businessId}
     * @secure
     */
    getEnrichmentDetails: (
      colId: UUID,
      businessId: UUID,
      params: RequestParams = {},
    ) =>
      this.request<
        GetEnrichmentDetails,
        {
          reason: string;
        }
      >({
        path: `/lists/enrichment/${colId}/${businessId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSimilarCompanies
     * @request POST:/lists/{id}/similar
     * @secure
     */
    getSimilarCompanies: (
      id: UUID,
      data: {
        excludeCustomerSegment: string[];
        excludeProductService: string[];
        /** @format double */
        revenueFilterMax: number | null;
        /** @format double */
        revenueFilterMin: number | null;
        /** @format double */
        employeeFilterMax: number | null;
        /** @format double */
        employeeFilterMin: number | null;
        geoFilterServiceAreaStates: string[];
        geoFilterHqStates: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        GetSimilarCompanies,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/similar`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveSimilarBuinesses
     * @request DELETE:/lists/{id}/similar
     * @secure
     */
    removeSimilarBuinesses: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/similar`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteImportError
     * @request DELETE:/lists/{listId}/import_errors/{id}
     * @secure
     */
    deleteImportError: (listId: UUID, id: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/import_errors/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteAllImportError
     * @request DELETE:/lists/{listId}/import_errors
     * @secure
     */
    deleteAllImportError: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/import_errors`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEnrichment
     * @request DELETE:/lists/enrichment/{colId}
     * @secure
     */
    deleteEnrichment: (colId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/enrichment/${colId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddToList
     * @request POST:/lists/{id}/add
     * @secure
     */
    addToList: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/add`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveFromList
     * @request POST:/lists/{id}/remove
     * @secure
     */
    removeFromList: (
      id: UUID,
      data: {
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/remove`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name MoveBusinesses
     * @request POST:/lists/{id}/move
     * @secure
     */
    moveBusinesses: (
      id: UUID,
      data: {
        newListId: string;
        businessIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${id}/move`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DownloadList
     * @request GET:/lists/{id}/download
     * @secure
     */
    downloadList: (id: UUID, params: RequestParams = {}) =>
      this.request<
        UUID,
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/download`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RunEnrichmentColumn
     * @request POST:/lists/{listId}/enrichment/{colId}/run
     * @secure
     */
    runEnrichmentColumn: (
      listId: UUID,
      colId: UUID,
      data: {
        onlyEmptyAndErrors: boolean;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        MarketIntelReportStatus,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/enrichment/${colId}/run`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name StopAllEnrichments
     * @request POST:/lists/{listId}/enrichment/stop
     * @secure
     */
    stopAllEnrichments: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/enrichment/stop`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name StopAllImports
     * @request POST:/lists/{listId}/imports/stop
     * @secure
     */
    stopAllImports: (listId: UUID, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/lists/${listId}/imports/stop`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunSpecificEnrichments
     * @request POST:/lists/{listId}/enrichment/run
     * @secure
     */
    runSpecificEnrichments: (
      listId: UUID,
      data: {
        businessIds: string[];
        colIds: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/lists/${listId}/enrichment/run`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetImportColumnNames
     * @request GET:/lists/import/{fileId}/columns
     * @secure
     */
    getImportColumnNames: (fileId: UUID, params: RequestParams = {}) =>
      this.request<GetImportColumnNamesResponse, any>({
        path: `/lists/import/${fileId}/columns`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CompleteImport
     * @request POST:/lists/import/{fileId}/complete
     * @secure
     */
    completeImport: (
      fileId: UUID,
      data: {
        mapping: {
          numEmployees: string | null;
          funding: string | null;
          revenue: string | null;
          website: string;
          state: string | null;
          city: string | null;
        };
        listId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/import/${fileId}/complete`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ImportDomains
     * @request POST:/lists/{listId}/importDomains
     * @secure
     */
    importDomains: (
      listId: UUID,
      data: {
        domains: string[];
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/importDomains`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAllFromSearch
     * @request POST:/lists/{listId}/add_search
     * @secure
     */
    addAllFromSearch: (
      listId: UUID,
      data: {
        searchQuery: {
          softwareOnly: boolean;
          excludeLists?: string[];
          excludeKeywords?: {
            customerSegments: string[];
            productServices: string[];
          };
          fundingFilter?: {
            mustHave: boolean;
            funded?: {
              /** @format double */
              maxFunding: number | null;
              /** @format double */
              minFunding: number | null;
            };
          };
          revenueFilter?: {
            mustHave: boolean;
            /** @format double */
            maxRevenue: number | null;
            /** @format double */
            minRevenue: number | null;
          };
          employeeFilter?: {
            /** @format double */
            maxEmployees: number | null;
            /** @format double */
            minEmployees: number | null;
          };
          geoFilter?: {
            mustHaveServiceAreas: boolean;
            serviceAreasStates: string[];
            hqStates: string[];
          };
          /** @format double */
          customerSegmentWeight: number;
          /** @format double */
          productServiceWeight: number;
          customerSegmentsMatchAll: boolean;
          customerSegments: string[];
          productsAndServicesMatchAll: boolean;
          productsAndServices: string[];
          description: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/add_search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name AddAllFromSearch3
     * @request POST:/lists/{listId}/add_search3
     * @secure
     */
    addAllFromSearch3: (
      listId: UUID,
      data: {
        searchQuery: {
          excludeLists?: string[];
          excludeKeywords?: {
            customerSegments: string[];
            productServices: string[];
          };
          fundingFilter?: {
            mustHave: boolean;
            funded?: {
              /** @format double */
              maxFunding: number | null;
              /** @format double */
              minFunding: number | null;
            };
          };
          revenueFilter?: {
            mustHave: boolean;
            /** @format double */
            maxRevenue: number | null;
            /** @format double */
            minRevenue: number | null;
          };
          employeeFilter?: {
            /** @format double */
            maxEmployees: number | null;
            /** @format double */
            minEmployees: number | null;
          };
          geoFilter?: {
            mustHaveServiceAreas: boolean;
            serviceAreasStates: string[];
            hqStates: string[];
          };
          /** @format double */
          customerSegmentSemanticThreshold: number;
          /** @format double */
          customerSegmentWeight: number;
          customerSegments: string;
          /** @format double */
          productServiceSemanticThreshold: number;
          /** @format double */
          productServiceWeight: number;
          productsAndServices: string;
          /** @format double */
          descriptionSemanticThreshold: number;
          /** @format double */
          descriptionWeight: number;
          description: string;
        };
      },
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/lists/${listId}/add_search3`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DuplicateList
     * @request POST:/lists/{id}/duplicate
     * @secure
     */
    duplicateList: (
      id: UUID,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        {
          reason: string;
        }
      >({
        path: `/lists/${id}/duplicate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateStrategicFit
     * @request POST:/lists/generate_strategic_fit
     * @secure
     */
    generateStrategicFit: (
      data: {
        otherConsiderations: string;
        targetEndMarkets: string;
        targetProductServices: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StrategicFitResponse, any>({
        path: `/lists/generate_strategic_fit`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  prospects = {
    /**
     * No description
     *
     * @name GetProspectList
     * @request GET:/prospects
     * @secure
     */
    getProspectList: (params: RequestParams = {}) =>
      this.request<GetProspectListResponse, any>({
        path: `/prospects`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateProspect
     * @request POST:/prospects
     * @secure
     */
    createProspect: (
      data: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/prospects`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetProspect
     * @request GET:/prospects/{id}
     * @secure
     */
    getProspect: (id: string, params: RequestParams = {}) =>
      this.request<GetProspectResponse, any>({
        path: `/prospects/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteProspect
     * @request DELETE:/prospects/{id}
     * @secure
     */
    deleteProspect: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prospects/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateProspectName
     * @request PUT:/prospects/{id}/name
     * @secure
     */
    updateProspectName: (
      id: string,
      data: {
        name: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/prospects/${id}/name`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateBuyerList
     * @request POST:/prospects/{id}/generateBuyerList
     * @secure
     */
    generateBuyerList: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prospects/${id}/generateBuyerList`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateTearSheet
     * @request POST:/prospects/{id}/generateTearSheet
     * @secure
     */
    generateTearSheet: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prospects/${id}/generateTearSheet`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBuyerListCsv
     * @request GET:/prospects/{id}/getBuyerListCsv
     * @secure
     */
    getBuyerListCsv: (id: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/prospects/${id}/getBuyerListCsv`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  v2Auth = {
    /**
     * No description
     *
     * @name Logout
     * @request POST:/v2Auth/logout
     * @secure
     */
    logout: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v2Auth/logout`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name Me
     * @request GET:/v2Auth/me
     * @secure
     */
    me: (params: RequestParams = {}) =>
      this.request<V2WhoAmIResponse, any>({
        path: `/v2Auth/me`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateAccount
     * @request POST:/v2Auth/me
     * @secure
     */
    updateAccount: (
      data: {
        email: string;
        lastName: string;
        firstName: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v2Auth/me`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  v2Businesses = {
    /**
     * No description
     *
     * @name GetBusiness
     * @request GET:/v2Businesses
     * @secure
     */
    getBusiness: (
      query: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        V2GetBusinessResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Businesses`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetDetailedBusiness
     * @request GET:/v2Businesses/detailed
     * @secure
     */
    getDetailedBusiness: (
      query: {
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        V2GetDetailedBusinessResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Businesses/detailed`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name Search
     * @request POST:/v2Businesses/search
     * @secure
     */
    search: (data: BusinessSearchBody, params: RequestParams = {}) =>
      this.request<BusinessSearchResponse, any>({
        path: `/v2Businesses/search`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RelatedKeywords
     * @request POST:/v2Businesses/relatedKeywords
     * @secure
     */
    relatedKeywords: (
      data: BusinessRelatedKeywordsBody,
      params: RequestParams = {},
    ) =>
      this.request<BusinessRelatedKeywordsResponse, any>({
        path: `/v2Businesses/relatedKeywords`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEnrichments
     * @request GET:/v2Businesses/enrichments
     * @secure
     */
    getEnrichments: (params: RequestParams = {}) =>
      this.request<GetEnrichmentsResponse, any>({
        path: `/v2Businesses/enrichments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateEnrichment
     * @request POST:/v2Businesses/enrichments
     * @secure
     */
    createEnrichment: (data: EnrichmentBody, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v2Businesses/enrichments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEnrichment
     * @request PUT:/v2Businesses/enrichments/{id}
     * @secure
     */
    updateEnrichment: (
      id: string,
      data: EnrichmentBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v2Businesses/enrichments/${id}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEnrichment
     * @request DELETE:/v2Businesses/enrichments/{id}
     * @secure
     */
    deleteEnrichment: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v2Businesses/enrichments/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunEnrichment
     * @request POST:/v2Businesses/enrichments/{id}/run
     * @secure
     */
    runEnrichment: (
      id: string,
      data: PerformBusinessEnrichmentBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v2Businesses/enrichments/${id}/run`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name PerformMassEnrichment
     * @request POST:/v2Businesses/enrichments/{id}/mass
     * @secure
     */
    performMassEnrichment: (
      id: string,
      data: PerformMassEnrichmentBody,
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/v2Businesses/enrichments/${id}/mass`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name StopMassEnrichment
     * @request POST:/v2Businesses/enrichments/{id}/stop
     * @secure
     */
    stopMassEnrichment: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v2Businesses/enrichments/${id}/stop`,
        method: 'POST',
        secure: true,
        ...params,
      }),
  };
  crm = {
    /**
     * No description
     *
     * @name ListOpportunities
     * @request GET:/crm/views/{viewId}
     * @secure
     */
    listOpportunities: (viewId: string, params: RequestParams = {}) =>
      this.request<ListOpportunitiesResponse, any>({
        path: `/crm/views/${viewId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLead
     * @request GET:/crm/lead/{leadId}
     * @secure
     */
    getLead: (leadId: string, params: RequestParams = {}) =>
      this.request<GetLeadResponse, any>({
        path: `/crm/lead/${leadId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AskAiAsync
     * @request POST:/crm/lead/{leadId}/askAIAync
     * @secure
     */
    askAiAsync: (leadId: string, data: AskAIBody, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/crm/lead/${leadId}/askAIAync`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AskAi
     * @request POST:/crm/lead/{leadId}/askAI
     * @secure
     */
    askAi: (leadId: string, data: AskAIBody, params: RequestParams = {}) =>
      this.request<GetAIResponse, any>({
        path: `/crm/lead/${leadId}/askAI`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEnrichmentColumns
     * @request GET:/crm/{viewId}/enrichments
     * @secure
     */
    getEnrichmentColumns: (viewId: string, params: RequestParams = {}) =>
      this.request<GetEnrichmentColumnsResponse, any>({
        path: `/crm/${viewId}/enrichments`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateEnrichmentColumn
     * @request POST:/crm/{viewId}/enrichments
     * @secure
     */
    createEnrichmentColumn: (
      viewId: string,
      data: CreateEnrichmentColumnBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/crm/${viewId}/enrichments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateEnrichmentColumn
     * @request PUT:/crm/enrichments/{columnId}
     * @secure
     */
    updateEnrichmentColumn: (
      columnId: string,
      data: UpdateEnrichmentColumnBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/crm/enrichments/${columnId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteEnrichmentColumn
     * @request DELETE:/crm/enrichments/{columnId}
     * @secure
     */
    deleteEnrichmentColumn: (columnId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/crm/enrichments/${columnId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunEnrichmentColumn
     * @request POST:/crm/enrichments/{columnId}/run
     * @secure
     */
    runEnrichmentColumn: (columnId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/crm/enrichments/${columnId}/run`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name RunEnrichmentCell
     * @request POST:/crm/enrichments/{columnId}/run/{leadId}
     * @secure
     */
    runEnrichmentCell: (
      columnId: string,
      leadId: string,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/crm/enrichments/${columnId}/run/${leadId}`,
        method: 'POST',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetEnrichmentValue
     * @request GET:/crm/enrichments/{leadId}/{columnId}
     * @secure
     */
    getEnrichmentValue: (
      columnId: string,
      leadId: string,
      params: RequestParams = {},
    ) =>
      this.request<GetEnrichmentValueResponse, any>({
        path: `/crm/enrichments/${leadId}/${columnId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ListViews
     * @request GET:/crm/views
     * @secure
     */
    listViews: (params: RequestParams = {}) =>
      this.request<ListViewsResponse, any>({
        path: `/crm/views`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetLlmContext
     * @request GET:/crm/lead/{leadId}/llmContext
     * @secure
     */
    getLlmContext: (leadId: string, params: RequestParams = {}) =>
      this.request<GetLLMContextResponse, any>({
        path: `/crm/lead/${leadId}/llmContext`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  employees = {
    /**
     * No description
     *
     * @name GetEmployees
     * @request GET:/employees
     * @secure
     */
    getEmployees: (params: RequestParams = {}) =>
      this.request<GetEmployeesResponse, any>({
        path: `/employees`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
  };
  v2Lists = {
    /**
     * No description
     *
     * @name GetLists
     * @request GET:/v2Lists
     * @secure
     */
    getLists: (
      query?: {
        search?: string;
        employeeId?: string;
        /**
         * @format double
         * @default 50
         */
        pageSize?: number;
        /**
         * @format double
         * @default 0
         */
        pageNum?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetListsResponse, any>({
        path: `/v2Lists`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateList
     * @request POST:/v2Lists
     * @secure
     */
    createList: (data: CreateListBody, params: RequestParams = {}) =>
      this.request<GetListResponse, any>({
        path: `/v2Lists`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetList
     * @request GET:/v2Lists/{listId}
     * @secure
     */
    getList: (listId: string, params: RequestParams = {}) =>
      this.request<
        GetListResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/${listId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListPage
     * @request GET:/v2Lists/{listId}/page
     * @secure
     */
    getListPage: (
      listId: string,
      query?: {
        /**
         * @format double
         * @default 100
         */
        pageSize?: number;
        /**
         * @format double
         * @default 0
         */
        pageNum?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        GetListPageResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/${listId}/page`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name AddBusinessesToLists
     * @request POST:/v2Lists/add-businesses
     * @secure
     */
    addBusinessesToLists: (data: AddBusinesses, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/v2Lists/add-businesses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name ExportListAsCsv
     * @request GET:/v2Lists/{listId}/export
     * @secure
     */
    exportListAsCsv: (listId: string, params: RequestParams = {}) =>
      this.request<UUID, any>({
        path: `/v2Lists/${listId}/export`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name RemoveBusinessesFromList
     * @request DELETE:/v2Lists/{listId}/businesses
     * @secure
     */
    removeBusinessesFromList: (
      listId: string,
      data: RemoveBusinessesBody,
      params: RequestParams = {},
    ) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/${listId}/businesses`,
        method: 'DELETE',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name MoveBusinessesToList
     * @request POST:/v2Lists/{listId}/move-businesses
     * @secure
     */
    moveBusinessesToList: (
      listId: string,
      data: MoveBusinessesBody,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/v2Lists/${listId}/move-businesses`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetListEnrichment
     * @request GET:/v2Lists/enrichments/{enrichmentId}
     * @secure
     */
    getListEnrichment: (enrichmentId: string, params: RequestParams = {}) =>
      this.request<
        GetListEnrichmentResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/enrichments/${enrichmentId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateListEnrichment
     * @request PUT:/v2Lists/enrichments/{enrichmentId}
     * @secure
     */
    updateListEnrichment: (
      enrichmentId: string,
      data: UpdateListEnrichmentBody,
      params: RequestParams = {},
    ) =>
      this.request<
        GetListEnrichmentResponse,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/enrichments/${enrichmentId}`,
        method: 'PUT',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteListEnrichment
     * @request DELETE:/v2Lists/enrichments/{enrichmentId}
     * @secure
     */
    deleteListEnrichment: (enrichmentId: string, params: RequestParams = {}) =>
      this.request<
        void,
        {
          reason: string;
        }
      >({
        path: `/v2Lists/enrichments/${enrichmentId}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateListEnrichment
     * @request POST:/v2Lists/{listId}/enrichments
     * @secure
     */
    createListEnrichment: (
      listId: string,
      data: CreateListEnrichmentBody,
      params: RequestParams = {},
    ) =>
      this.request<GetListEnrichmentResponse, any>({
        path: `/v2Lists/${listId}/enrichments`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  marketIntel = {
    /**
     * No description
     *
     * @name GetMarketIntelReportList
     * @request GET:/market-intel
     * @secure
     */
    getMarketIntelReportList: (params: RequestParams = {}) =>
      this.request<GetMarketIntelReportListResponse, any>({
        path: `/market-intel`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name CreateMarketIntelReport
     * @request POST:/market-intel
     * @secure
     */
    createMarketIntelReport: (
      data: {
        businessName: string;
        domain: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetMarketIntelReportResponse, any>({
        path: `/market-intel`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GenerateMarketIntelReport
     * @request POST:/market-intel/{id}/generate
     * @secure
     */
    generateMarketIntelReport: (
      id: string,
      data: {
        /** @format double */
        targetYears: number;
        /** @format double */
        targetEmployees: number;
        /** @format double */
        targetEBITDA: number;
        /** @format double */
        targetSDE: number;
        /** @format double */
        targetRevenue: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<GenerateMarketIntelReportResponse, any>({
        path: `/market-intel/${id}/generate`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetMarketIntelReport
     * @request GET:/market-intel/{id}
     * @secure
     */
    getMarketIntelReport: (id: string, params: RequestParams = {}) =>
      this.request<GetMarketIntelReportResponse, any>({
        path: `/market-intel/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteMarketIntelReport
     * @request DELETE:/market-intel/{id}
     * @secure
     */
    deleteMarketIntelReport: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/market-intel/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @name GetSimilarBvrTransactions
     * @request POST:/market-intel/similarTransactions
     * @secure
     */
    getSimilarBvrTransactions: (
      data: {
        description: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SimilarBvrTransactionsResponse, any>({
        path: `/market-intel/similarTransactions`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name UpdateMarketIntelReport
     * @request POST:/market-intel/{id}/update
     * @secure
     */
    updateMarketIntelReport: (
      id: string,
      data: UpdateMarketIntelReportBody,
      params: RequestParams = {},
    ) =>
      this.request<GetMarketIntelReportResponse, any>({
        path: `/market-intel/${id}/update`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  };
  bizbuysell = {
    /**
     * No description
     *
     * @name GetBizBuySellListings
     * @request GET:/bizbuysell/listings
     * @secure
     */
    getBizBuySellListings: (
      query?: {
        /** @format double */
        page?: number;
        /** @format double */
        pageSize?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<PaginatedResponseBizBuySellListingResponse, any>({
        path: `/bizbuysell/listings`,
        method: 'GET',
        query: query,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name ScrapeBizBuySellListing
     * @request POST:/bizbuysell/listings
     * @secure
     */
    scrapeBizBuySellListing: (
      data: ScrapeBizBuySellListingInput,
      params: RequestParams = {},
    ) =>
      this.request<ScrapedBizBuySellListingResponse, any>({
        path: `/bizbuysell/listings`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name GetBizBuySellListing
     * @request GET:/bizbuysell/listings/{id}
     * @secure
     */
    getBizBuySellListing: (id: string, params: RequestParams = {}) =>
      this.request<BizBuySellListingResponse | null, any>({
        path: `/bizbuysell/listings/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @name DeleteBizBuySellListing
     * @request DELETE:/bizbuysell/listings/{id}
     * @secure
     */
    deleteBizBuySellListing: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/bizbuysell/listings/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  };
}
