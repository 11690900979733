import React from 'react';
import {
  Typography,
  Theme,
  useTheme,
  Button,
  IconButton,
  Switch,
} from '@mui/joy';
import { tss } from 'tss-react';
import { Control, FieldErrors, useFieldArray } from 'react-hook-form';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData, ShowSections } from './types';

type GTMProps = {
  control: Control<CIMData>;
  errors: FieldErrors<CIMData>;
  projectLocked: boolean;
  showSections: ShowSections;
  setShowSections: React.Dispatch<React.SetStateAction<ShowSections>>;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: theme.spacing(2),
    width: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  toggle: {
    marginLeft: 'auto',
  },
}));

export default function GTM({
  control,
  errors,
  projectLocked,
  showSections,
  setShowSections,
}: GTMProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: acquisitionChannelsFields,
    append: appendAcquisitionChannel,
    remove: removeAcquisitionChannel,
  } = useFieldArray({ control, name: 'acquisitionChannels' });

  const {
    fields: customerAcquisitionHighlightsFields,
    append: appendCustomerAcquisitionHighlight,
    remove: removeCustomerAcquisitionHighlight,
  } = useFieldArray({ control, name: 'customerAcquisitionHighlights' });

  const {
    fields: marketingStrategyHighlightsFields,
    append: appendMarketingStrategyHighlight,
    remove: removeMarketingStrategyHighlight,
  } = useFieldArray({ control, name: 'marketingStrategyHighlights' });

  const {
    fields: keyMetricsHighlightsFields,
    append: appendKeyMetricsHighlight,
    remove: removeKeyMetricsHighlight,
  } = useFieldArray({ control, name: 'keyMetricsHighlights' });

  return (
    <div className={classes.container}>
      <div className={classes.row}>
        <Typography level="h2">Go to Market</Typography>
        <Switch
          checked={showSections.customerAcquisition}
          onChange={(event) =>
            setShowSections((prev) => ({
              ...prev,
              customerAcquisition: event.target.checked,
            }))
          }
          disabled={projectLocked}
          className={classes.toggle}
        />
      </div>

      {showSections.customerAcquisition && (
        <>
          <Typography level="h4">Acquisition Channels</Typography>
          {acquisitionChannelsFields.map((field, index) => (
            <div key={field.id} className={classes.row}>
              <ControlledInput
                name={`acquisitionChannels.${index}.name`}
                control={control}
                error={errors.acquisitionChannels?.[index]?.name}
                label="Channel Name"
                className={classes.fullWidth}
                inputProps={{ disabled: projectLocked }}
              />
              <ControlledInput
                name={`acquisitionChannels.${index}.percentage`}
                control={control}
                error={errors.acquisitionChannels?.[index]?.percentage}
                label="Percentage"
                inputProps={{ disabled: projectLocked, type: 'number' }}
              />
              <IconButton
                onClick={() => removeAcquisitionChannel(index)}
                size="sm"
                color="danger"
                variant="outlined"
                disabled={projectLocked}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() =>
              appendAcquisitionChannel({ name: '', percentage: 0 })
            }
            disabled={projectLocked}
          >
            Add Acquisition Channel
          </Button>

          <Typography level="h4">Customer Acquisition Highlights</Typography>
          {customerAcquisitionHighlightsFields.map((field, index) => (
            <div key={field.id} className={classes.row}>
              <ControlledInput
                name={`customerAcquisitionHighlights.${index}.value`}
                control={control}
                error={errors.customerAcquisitionHighlights?.[index]?.value}
                className={classes.fullWidth}
                inputProps={{ disabled: projectLocked }}
              />
              <IconButton
                onClick={() => removeCustomerAcquisitionHighlight(index)}
                size="sm"
                color="danger"
                variant="outlined"
                disabled={projectLocked}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() => appendCustomerAcquisitionHighlight({ value: '' })}
            disabled={projectLocked}
          >
            Add Customer Acquisition Highlight
          </Button>

          <Typography level="h4">Marketing Strategy Highlights</Typography>
          {marketingStrategyHighlightsFields.map((field, index) => (
            <div key={field.id} className={classes.row}>
              <ControlledInput
                name={`marketingStrategyHighlights.${index}.value`}
                control={control}
                error={errors.marketingStrategyHighlights?.[index]?.value}
                className={classes.fullWidth}
                inputProps={{ disabled: projectLocked }}
              />
              <IconButton
                onClick={() => removeMarketingStrategyHighlight(index)}
                size="sm"
                color="danger"
                variant="outlined"
                disabled={projectLocked}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() => appendMarketingStrategyHighlight({ value: '' })}
            disabled={projectLocked}
          >
            Add Marketing Strategy Highlight
          </Button>

          <Typography level="h4">Key Metrics Highlights</Typography>
          {keyMetricsHighlightsFields.map((field, index) => (
            <div key={field.id} className={classes.row}>
              <ControlledInput
                name={`keyMetricsHighlights.${index}.value`}
                control={control}
                error={errors.keyMetricsHighlights?.[index]?.value}
                className={classes.fullWidth}
                inputProps={{ disabled: projectLocked }}
              />
              <IconButton
                onClick={() => removeKeyMetricsHighlight(index)}
                size="sm"
                color="danger"
                variant="outlined"
                disabled={projectLocked}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          ))}
          <Button
            color="primary"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() => appendKeyMetricsHighlight({ value: '' })}
            disabled={projectLocked}
          >
            Add Key Metrics Highlight
          </Button>
        </>
      )}
    </div>
  );
}
