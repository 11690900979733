import React from 'react';

type Props = {
  text: string;
  hightlights: {
    startIdx: number;
    length: number;
  }[];
};

export default function TextWithHighlights({
  text,
  hightlights,
}: Props): JSX.Element {
  const parts: React.ReactNode[] = [];
  let currentIdx = 0;
  hightlights
    .sort((a, b) => a.startIdx - b.startIdx)
    .forEach((h) => {
      parts.push(text.substring(currentIdx, h.startIdx));
      parts.push(
        <mark>{text.substring(h.startIdx, h.startIdx + h.length)}</mark>,
      );
      currentIdx = h.startIdx + h.length;
    });
  parts.push(text.substring(currentIdx, text.length));
  return <>{parts}</>;
}
