import React from 'react';
import { Typography } from '@mui/joy';
import { compactNumber } from 'src/utils';

type Props = {
  num: number | null;
};

export default function FinancialCell({ num }: Props) {
  if (num == null) {
    return null;
  }
  return <Typography level="body-md">${compactNumber(num)}</Typography>;
}
