import React from 'react';
import {
  Theme,
  Typography,
  useTheme,
  IconButton,
  Autocomplete,
  Chip,
  Switch,
} from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';
import { tss } from 'tss-react';
import { useFieldArray, Control, UseFormSetValue } from 'react-hook-form';
import { CIMData, ShowSections } from './types';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.body,
    zIndex: 1,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: theme.spacing(1),
    overflowY: 'auto',
  },
  fullWidth: {
    width: '100%',
  },
}));

export type VendorsProps = {
  control: Control<CIMData>;
  setValue: UseFormSetValue<CIMData>;
  projectLocked: boolean;
  showSections: ShowSections;
  setShowSections: React.Dispatch<React.SetStateAction<ShowSections>>;
};

export default function Vendors({
  control,
  setValue,
  projectLocked,
  showSections,
  setShowSections,
}: VendorsProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { fields: vendorsAndPartnersFields } = useFieldArray({
    control,
    name: 'vendorsAndPartners',
  });

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography level="h3">Vendors and Partners</Typography>
        <Switch
          checked={showSections.vendorsAndPartners}
          onChange={(event) =>
            setShowSections((prev) => ({
              ...prev,
              vendorsAndPartners: event.target.checked,
            }))
          }
          disabled={projectLocked}
        />
      </div>
      {showSections.vendorsAndPartners && (
        <Autocomplete
          multiple
          freeSolo
          className={classes.fullWidth}
          disabled={projectLocked}
          options={[]}
          size="lg"
          value={vendorsAndPartnersFields.map((field) => field.value)}
          onChange={(_, newValue) => {
            const newFields = newValue.map((value) => ({ value }));
            setValue('vendorsAndPartners', newFields);
          }}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="soft"
                color="warning"
                {...getTagProps({ index })}
                key={option}
                endDecorator={
                  <IconButton
                    size="sm"
                    variant="plain"
                    color="neutral"
                    onClick={() => {
                      const newValue = vendorsAndPartnersFields.filter(
                        (_, i) => i !== index,
                      );
                      setValue('vendorsAndPartners', newValue);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                }
              >
                {option}
              </Chip>
            ))
          }
        />
      )}
    </div>
  );
}
