import React from 'react';
import { tss } from 'tss-react';
import { Select, Option } from '@mui/joy';

const useStyles = tss.create(() => ({
  select: {
    width: 96,
  },
}));

type Props = {
  value: number;
  setValue: (val: number) => void;
};

export default function TimeSelect({ value, setValue }: Props) {
  const { classes } = useStyles();
  return (
    <Select
      value={value}
      className={classes.select}
      onChange={(e, newVal) => setValue(newVal!)}
    >
      <Option value={0}>12 AM</Option>
      <Option value={1}>1 AM</Option>
      <Option value={2}>2 AM</Option>
      <Option value={3}>3 AM</Option>
      <Option value={4}>4 AM</Option>
      <Option value={5}>5 AM</Option>
      <Option value={6}>6 AM</Option>
      <Option value={7}>7 AM</Option>
      <Option value={8}>8 AM</Option>
      <Option value={9}>9 AM</Option>
      <Option value={10}>10 AM</Option>
      <Option value={11}>11 AM</Option>
      <Option value={12}>12 PM</Option>
      <Option value={13}>1 PM</Option>
      <Option value={14}>2 PM</Option>
      <Option value={15}>3 PM</Option>
      <Option value={16}>4 PM</Option>
      <Option value={17}>5 PM</Option>
      <Option value={18}>6 PM</Option>
      <Option value={19}>7 PM</Option>
      <Option value={20}>8 PM</Option>
      <Option value={21}>9 PM</Option>
      <Option value={22}>10 PM</Option>
      <Option value={23}>11 PM</Option>
    </Select>
  );
}
