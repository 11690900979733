import React, { useCallback, useEffect, useState } from 'react';
import {
  Business,
  Delete,
  Edit,
  MoreVert,
  RocketLaunch,
} from '@mui/icons-material';
import { tss } from 'tss-react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  Dropdown,
  List,
  ListItem,
  ListItemButton,
  Menu,
  MenuButton,
  MenuItem,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import { GetAutopilotsResponse } from 'src/generated/api';
import { getAPI } from 'src/api';
import LoadingContainer from 'src/components/LoadingContainer';
import RenameModal from './Manage/RenameModal';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  contents: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 650,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    width: '100%',
  },
  frank: {
    width: 72,
    height: 72,
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  createForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2),
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
  },
  viewSelector: {
    marginBottom: theme.spacing(2),
  },
  toolbar: {
    marginTop: theme.spacing(2),
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  createButton: {
    marginLeft: 'auto',
  },
  listItemRight: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  emptyState: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: theme.shadow.lg,
  },
  lists: {
    marginTop: theme.spacing(4),
    boxShadow: theme.shadow.lg,
  },
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  optionsMenu: {
    padding: theme.spacing(1),
    width: 24,
    height: 24,
  },
  optionsIcon: {
    width: 18,
    height: 18,
  },
}));

export default function Autopilots() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes } = useStyles({ theme });
  const [renameModalData, setRenameModalData] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [autopilotsData, setAutopilotsData] =
    useState<GetAutopilotsResponse | null>(null);
  const api = getAPI();
  const fetchData = useCallback(async () => {
    const res = await api.autopilot.getAutopilots();
    setAutopilotsData(res.data);
  }, [api]);
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <div className={classes.container}>
      <div className={classes.contents}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography level="h3" className={classes.title}>
              <RocketLaunch />
              Autopilot
            </Typography>
            <Typography level="body-lg">
              Completely automate your deal sourcing with our AI broker
            </Typography>
          </div>
          <Button
            className={classes.createButton}
            size="lg"
            color="success"
            onClick={() => navigate('/autopilot/create')}
          >
            Create
          </Button>
        </div>
        <LoadingContainer isLoading={autopilotsData == null}>
          {autopilotsData?.autopilots.length === 0 ? (
            <Card className={classes.emptyState} variant="plain">
              <Typography level="h4">
                You have no autopilots campaigns
              </Typography>
              <Typography level="body-lg">
                Create your first campaign to your deal sourcing on autopilot
              </Typography>
            </Card>
          ) : (
            <List className={classes.lists}>
              {autopilotsData?.autopilots.map((l) => (
                <ListItem key={l.id}>
                  <ListItemButton
                    className={classes.listItem}
                    onClick={() => navigate(`/autopilot/${l.id}`)}
                  >
                    <Typography level="h4">{l.name}</Typography>
                    <Typography
                      level="body-lg"
                      className={classes.listItemRight}
                    >
                      {l.size}
                      <Business />
                    </Typography>
                    <Dropdown>
                      <MenuButton
                        color="neutral"
                        variant="plain"
                        className={classes.optionsMenu}
                        slotProps={{
                          root: {
                            onClick: (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            },
                          },
                        }}
                      >
                        <MoreVert className={classes.optionsIcon} />
                      </MenuButton>
                      <Menu placement="bottom-end">
                        <MenuItem
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setRenameModalData({ id: l.id, name: l.name });
                          }}
                        >
                          <Edit />
                          Rename
                        </MenuItem>
                        <MenuItem
                          color="danger"
                          onClick={async (e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            await api.autopilot.deleteCampaign(l.id);
                            await fetchData();
                          }}
                        >
                          <Delete />
                          Delete
                        </MenuItem>
                      </Menu>
                    </Dropdown>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
        </LoadingContainer>
      </div>
      {renameModalData && (
        <RenameModal
          open
          id={renameModalData.id}
          currentName={renameModalData.name}
          onClose={() => {
            fetchData();
            setRenameModalData(null);
          }}
        />
      )}
    </div>
  );
}
