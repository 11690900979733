/* eslint-disable no-param-reassign */
import React, { useContext, useCallback, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import {
  Typography,
  Card,
  IconButton,
  Theme,
  useTheme,
  CircularProgress,
  Switch,
} from '@mui/joy';
import { tss } from 'tss-react';
import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { getAPI } from 'src/api';
import { uploadedLink } from 'src/utils';
import { SnackbarContext } from 'src/contexts/snackbar';
import { CIMData, ShowSections } from './types';

type FinancialsProps = {
  projectLocked: boolean;
  control: Control<CIMData>;
  showSections: ShowSections;
  setShowSections: React.Dispatch<React.SetStateAction<ShowSections>>;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2, 0),
  },
  section: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  imagePreview: {
    marginTop: theme.spacing(1),
    position: 'relative',
  },
  image: {
    maxWidth: '100%',
    maxHeight: 300,
  },
  deleteButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  uploadZone: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    height: 300,
    width: '100%',
    borderRadius: theme.spacing(1),
    borderWidth: 1,
    borderStyle: 'dashed',
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',
  },
  removeImageButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    zIndex: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.9)',
    },
  },
  uploadedImage: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  pdfPreviewWrapper: {
    position: 'sticky',
    top: 0,
    height: '100vh',
    overflowY: 'auto',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default function Financials({
  projectLocked,
  control,
  showSections,
  setShowSections,
}: FinancialsProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const { showSnackbar } = useContext(SnackbarContext);

  const { field: balanceSheetField } = useController({
    name: 'balanceSheetImgUrl',
    control,
  });

  const { field: incomeStatementField } = useController({
    name: 'incomeStatementImgUrl',
    control,
  });

  const [isUploading, setIsUploading] = useState<
    'balanceSheet' | 'incomeStatement' | null
  >(null);

  const handleFileUpload = useCallback(
    async (acceptedFiles: File[], type: 'balanceSheet' | 'incomeStatement') => {
      setIsUploading(type);
      try {
        const {
          data: { fileId, url },
        } = await api.files.createSignedUrl();
        await axios.put(url, acceptedFiles[0], {
          headers: {
            'Content-Type': 'application/octet-stream',
          },
        });
        const imageUrl = uploadedLink(fileId);

        if (type === 'balanceSheet') {
          balanceSheetField.onChange(imageUrl);
        } else {
          incomeStatementField.onChange(imageUrl);
        }

        showSnackbar({
          color: 'success',
          message: `${type === 'balanceSheet' ? 'Balance Sheet' : 'Income Statement'} uploaded successfully`,
        });
      } catch (error) {
        showSnackbar({
          color: 'danger',
          message: `Failed to upload ${type === 'balanceSheet' ? 'Balance Sheet' : 'Income Statement'}`,
        });
      } finally {
        setIsUploading(null);
      }
    },
    [api.files, balanceSheetField, incomeStatementField, showSnackbar],
  );

  const onDropRejected = useCallback(() => {
    showSnackbar({
      color: 'danger',
      message: 'Only image files are supported!',
    });
  }, [showSnackbar]);

  const handleRemoveImage = useCallback(
    (type: 'balanceSheet' | 'incomeStatement') => {
      if (type === 'balanceSheet') {
        balanceSheetField.onChange('');
      } else {
        incomeStatementField.onChange('');
      }
    },
    [balanceSheetField, incomeStatementField],
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography level="h3">Financials</Typography>
        </div>
        <Card>
          <div className={classes.section}>
            <div className={classes.header}>
              <Typography level="h4">Income Statement</Typography>
              <Switch
                startDecorator={showSections.incomeStatement ? 'Show' : 'Hide'}
                checked={showSections.incomeStatement}
                onChange={(e) =>
                  setShowSections({
                    ...showSections,
                    incomeStatement: e.target.checked,
                  })
                }
              />
            </div>
            <Dropzone
              accept={{ 'image/*': [] }}
              onDropAccepted={(files) =>
                handleFileUpload(files, 'incomeStatement')
              }
              onDropRejected={onDropRejected}
              multiple={false}
              disabled={projectLocked || isUploading === 'incomeStatement'}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.uploadZone}>
                  <input {...getInputProps()} />
                  {incomeStatementField.value ? (
                    <>
                      <img
                        src={incomeStatementField.value}
                        alt="Income Statement"
                        className={classes.uploadedImage}
                      />
                      <IconButton
                        className={classes.removeImageButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage('incomeStatement');
                        }}
                        size="sm"
                        disabled={projectLocked}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : isUploading === 'incomeStatement' ? (
                    <div className={classes.emptyState}>
                      <CircularProgress />
                      <Typography level="body-md">
                        Uploading Income Statement...
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.emptyState}>
                      <Typography level="body-md">
                        Drop an image here or click to upload Income Statement
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
          <div className={classes.section}>
            <div className={classes.header}>
              <Typography level="h4">Balance Sheet</Typography>
              <Switch
                startDecorator={showSections.balanceSheet ? 'Show' : 'Hide'}
                checked={showSections.balanceSheet}
                onChange={(e) =>
                  setShowSections({
                    ...showSections,
                    balanceSheet: e.target.checked,
                  })
                }
              />
            </div>
            <Dropzone
              accept={{ 'image/*': [] }}
              onDropAccepted={(files) =>
                handleFileUpload(files, 'balanceSheet')
              }
              onDropRejected={onDropRejected}
              multiple={false}
              disabled={projectLocked || isUploading === 'balanceSheet'}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.uploadZone}>
                  <input {...getInputProps()} />
                  {balanceSheetField.value ? (
                    <>
                      <img
                        src={balanceSheetField.value}
                        alt="Balance Sheet"
                        className={classes.uploadedImage}
                      />
                      <IconButton
                        className={classes.removeImageButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveImage('balanceSheet');
                        }}
                        size="sm"
                        disabled={projectLocked}
                      >
                        <CloseIcon />
                      </IconButton>
                    </>
                  ) : isUploading === 'balanceSheet' ? (
                    <div className={classes.emptyState}>
                      <CircularProgress />
                      <Typography level="body-md">
                        Uploading Balance Sheet...
                      </Typography>
                    </div>
                  ) : (
                    <div className={classes.emptyState}>
                      <Typography level="body-md">
                        Drop an image here or click to upload Balance Sheet
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        </Card>
      </div>
    </div>
  );
}
