import React from 'react';
import { tss } from 'tss-react';
import { Button, Theme, useTheme } from '@mui/joy';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  btn: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    height: 32,
    padding: theme.spacing(1),
  },
  solidBtn: {
    // So the size doesnt change from outlined -> solid
    padding: 9,
  },
}));

type DataSourceButtonProps<T> = {
  source: T;
  selectedDataSources: Set<T>;
  setDataSources: (fn: (prev: Set<T>) => Set<T>) => void;
  children: React.ReactNode | React.ReactNode[];
};

export default function DataSourceButton<T>({
  source,
  selectedDataSources,
  setDataSources,
  children,
}: DataSourceButtonProps<T>) {
  const theme = useTheme();
  const { classes, cx } = useStyles({ theme });
  return (
    <Button
      className={cx(classes.btn, {
        [classes.solidBtn]: selectedDataSources.has(source),
      })}
      variant={selectedDataSources.has(source) ? 'solid' : 'outlined'}
      onClick={() =>
        setDataSources((prev: Set<T>) => {
          const newSet = new Set([...prev]);
          if (prev.has(source)) {
            newSet.delete(source);
          } else {
            newSet.add(source);
          }
          return newSet;
        })
      }
    >
      {children}
    </Button>
  );
}
