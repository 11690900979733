import React from 'react';
import { InfoOutlined, SvgIconComponent } from '@mui/icons-material';
import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  InputProps,
  Textarea,
} from '@mui/joy';
import {
  Controller,
  Control,
  RegisterOptions,
  ControllerRenderProps,
} from 'react-hook-form';

type Props = {
  label?: string;
  name: string;
  className?: string;
  required?: boolean;
  minLength?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  StartDecorator?: SvgIconComponent;
  error?: { message?: string };
  inputProps?: InputProps;
  pattern?: string;
  extraRules?: RegisterOptions;
  fullWidth?: boolean;
  textArea?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: (
    field: ControllerRenderProps<any, string>, // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => Partial<ControllerRenderProps<any, string>>;
};

export function ControlledInput({
  error,
  label,
  name,
  className,
  required,
  minLength,
  control,
  StartDecorator,
  inputProps,
  pattern,
  extraRules,
  fullWidth = true,
  textArea = false,
  children,
}: Props) {
  const rules: RegisterOptions = { ...extraRules };
  if (required) {
    rules.required = {
      value: true,
      message: 'This is required',
    };
  }
  if (minLength) {
    rules.minLength = {
      value: minLength,
      message: `This must be at least ${minLength} characters`,
    };
  }
  return (
    <FormControl className={className} error={Boolean(error)}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          const customField = children ? children(field) : field;
          return textArea ? (
            <Textarea
              {...customField}
              size="lg"
              minRows={3}
              startDecorator={
                StartDecorator && <StartDecorator color="primary" />
              }
              disabled={inputProps?.disabled}
              slotProps={{
                textarea: {
                  placeholder: inputProps?.placeholder,
                },
              }}
            />
          ) : (
            <Input
              fullWidth={fullWidth}
              size="lg"
              startDecorator={
                StartDecorator && <StartDecorator color="primary" />
              }
              slotProps={{
                input: {
                  pattern,
                },
              }}
              {...inputProps}
              {...customField}
            />
          );
        }}
      />
      {error && (
        <FormHelperText>
          <InfoOutlined />
          {error.message}
        </FormHelperText>
      )}
    </FormControl>
  );
}
