import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getAPI } from 'src/api';
import { GetClientDataRoomRequestsResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import {
  Theme,
  useTheme,
  Table,
  Sheet,
  Typography,
  Select,
  Option,
  Button,
  IconButton,
  Modal,
  Box,
  ModalDialog,
  ModalClose,
  FormControl,
  FormLabel,
  Card,
  Input,
} from '@mui/joy';
import {
  ChevronLeft,
  CheckCircle,
  Add,
  Delete,
  Download,
  Edit,
  Close,
  Check,
} from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { formatDate, formatFileSize } from 'src/utils';
import LoadingContainer from 'src/components/LoadingContainer';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    width: '100%',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  category: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(1, 2),
    textAlign: 'left',
    verticalAlign: 'top',
  },
  nameCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  nameCellRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  preLoiSelect: {
    marginTop: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: 500,
    gap: theme.spacing(1),
  },
  table: {
    marginTop: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    alignItems: 'flex-start',
    marginTop: theme.spacing(1),
  },
  files: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(2),
  },
  fileCard: {
    padding: theme.spacing(1),
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  fileInfo: {
    flex: 1,
    overflow: 'hidden',
  },
  fileName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  fileDetails: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  fileActions: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

type NewRequestForm = {
  name: string;
  description: string;
  category: string;
  preLOI: boolean;
};

const Categories = [
  'Financial Information',
  'Sales and Marketing',
  'Company Overview',
  'Human Resources',
  'Legal Documents',
] as const;

export default function DataRoom() {
  const { id } = useParams();
  const [data, setData] = useState<GetClientDataRoomRequestsResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const api = getAPI();
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const navigate = useNavigate();

  const { control, handleSubmit, reset, setValue } = useForm<NewRequestForm>({
    defaultValues: {
      name: '',
      description: '',
      category: '',
      preLOI: true,
    },
  });

  const fetch = useCallback(async () => {
    try {
      const res = await api.clients.getClientDataRoom(id!);
      setData(res.data);
    } finally {
      setIsLoading(false);
    }
  }, [id, api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const handleGenerateDataRoom = useCallback(async () => {
    await api.clients.createClientDataRoom(id!);
    fetch();
  }, [id, api.clients, fetch]);

  const handleUpdatePreLOI = useCallback(
    async (requestId: string, preLOI: boolean) => {
      await api.clients.updateClientDataRoomRequest(id!, requestId, { preLOI });
      fetch();
    },
    [id, api.clients, fetch],
  );

  const handleDeleteRequest = useCallback(
    async (requestId: string) => {
      await api.clients.deleteClientDataRoomRequest(id!, requestId);
      fetch();
    },
    [id, api.clients, fetch],
  );

  const handleAddRequest = useCallback(
    async (formData: NewRequestForm) => {
      await api.clients.createClientDataRoomRequest(id!, formData);
      setIsModalOpen(false);
      reset();
      fetch();
    },
    [id, api.clients, fetch, reset, setIsModalOpen],
  );

  const handleDownloadAll = useCallback(async () => {
    const response = await api.clients.downloadAllDataRoomFiles(id!, {
      format: 'blob',
    });
    const blob = new Blob([response.data]);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${id}_all_data_room_files.zip`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }, [id, api.clients]);

  const handleDownloadRequest = useCallback(
    async (requestId: string) => {
      const response = await api.clients.downloadDataRoomRequest(
        id!,
        requestId,
        {
          format: 'blob',
        },
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${id}_data_room_${requestId}.zip`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    [id, api.clients],
  );

  const handleDownloadFile = useCallback(
    async (requestId: string, fileName: string) => {
      const response = await api.clients.downloadDataRoomFile(
        id!,
        requestId,
        fileName,
        {
          format: 'blob',
        },
      );
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    [id, api.clients],
  );

  const [editingFileName, setEditingFileName] = useState<string | null>(null);
  const [newFileName, setNewFileName] = useState<string>('');

  const handleRenameFile = useCallback(
    async (requestId: string) => {
      // Ensure the file extension remains the same
      const oldExtension = editingFileName!.split('.').pop();
      const newFileNameWithExtension = `${newFileName}.${oldExtension}`;

      await api.clients.renameDataRoomFile(id!, requestId, {
        oldFileName: editingFileName!,
        newFileName: newFileNameWithExtension,
      });
      setEditingFileName(null);
      setNewFileName('');
      fetch();
    },
    [id, api.clients, fetch, editingFileName, newFileName],
  );

  const groupedRequests = data?.requests.reduce(
    (acc, request) => {
      if (!acc[request.category]) {
        acc[request.category] = [];
      }
      acc[request.category].push(request);
      return acc;
    },
    {} as Record<string, typeof data.requests>,
  );

  return (
    <LoadingContainer isLoading={isLoading}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>
            <IconButton onClick={() => navigate(`/clients/${id}`)}>
              <ChevronLeft />
            </IconButton>
            <Typography level="h2">Data Room</Typography>
          </div>
          <div className={classes.actions}>
            {Boolean(data?.requests.length) && (
              <>
                <Button
                  onClick={handleDownloadAll}
                  startDecorator={<Download />}
                  variant="outlined"
                  color="neutral"
                >
                  Download All
                </Button>
                <Button
                  onClick={() => setIsModalOpen(true)}
                  startDecorator={<Add />}
                  variant="outlined"
                  color="success"
                >
                  Add Request
                </Button>
              </>
            )}
          </div>
        </div>

        {!data?.requests.length ? (
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Typography level="body-lg" mb={2}>
              No data room requests.
            </Typography>
            <Button size="lg" onClick={handleGenerateDataRoom}>
              Generate
            </Button>
          </Box>
        ) : (
          Object.entries(groupedRequests || {}).map(([category, requests]) => (
            <Sheet key={category} className={classes.category}>
              <Typography level="h3">{category}</Typography>
              <Table className={classes.table}>
                <tbody>
                  {requests.map((request) => (
                    <tr key={request.id}>
                      <td style={{ flexGrow: 1 }} className={classes.tableCell}>
                        <div className={classes.nameCell}>
                          <div className={classes.nameCellRow}>
                            <Typography level="body-md">
                              <b>{request.name}</b>
                            </Typography>
                            {request.isCompleted && (
                              <CheckCircle color="success" />
                            )}
                          </div>
                          <Typography level="body-sm">
                            {request.description}
                          </Typography>
                          {request.files.length > 0 && (
                            <div className={classes.files}>
                              {request.files.map((file) => (
                                <Card
                                  key={file.name}
                                  className={classes.fileCard}
                                  variant="outlined"
                                  color="neutral"
                                >
                                  <div className={classes.fileInfo}>
                                    {editingFileName === file.name ? (
                                      <form
                                        onSubmit={(e) => {
                                          e.preventDefault();
                                          handleRenameFile(request.id);
                                        }}
                                      >
                                        <Input
                                          value={newFileName}
                                          onChange={(e) => {
                                            // Remove file extension from input
                                            const nameWithoutExtension =
                                              e.target.value.split('.')[0];
                                            setNewFileName(
                                              nameWithoutExtension,
                                            );
                                          }}
                                          autoFocus
                                        />
                                      </form>
                                    ) : (
                                      <Typography className={classes.fileName}>
                                        {file.name}
                                      </Typography>
                                    )}
                                    <div className={classes.fileDetails}>
                                      <Typography level="body-sm">
                                        {formatFileSize(file.size)}
                                      </Typography>
                                      <Typography level="body-sm">•</Typography>
                                      <Typography level="body-sm">
                                        Uploaded {formatDate(file.uploadedAt)}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div className={classes.fileActions}>
                                    {editingFileName === file.name ? (
                                      <>
                                        <IconButton
                                          onClick={() =>
                                            handleRenameFile(request.id)
                                          }
                                          variant="solid"
                                          color="success"
                                        >
                                          <Check />
                                        </IconButton>
                                        <IconButton
                                          onClick={() => {
                                            setEditingFileName(null);
                                            setNewFileName('');
                                          }}
                                          variant="outlined"
                                          color="danger"
                                        >
                                          <Close />
                                        </IconButton>
                                      </>
                                    ) : (
                                      <>
                                        <IconButton
                                          onClick={() => {
                                            setEditingFileName(file.name);
                                            setNewFileName(
                                              file.name.split('.')[0],
                                            );
                                          }}
                                          variant="outlined"
                                          color="neutral"
                                        >
                                          <Edit />
                                        </IconButton>
                                        <IconButton
                                          onClick={() =>
                                            handleDownloadFile(
                                              request.id,
                                              file.name,
                                            )
                                          }
                                          variant="outlined"
                                          color="neutral"
                                        >
                                          <Download />
                                        </IconButton>
                                      </>
                                    )}
                                  </div>
                                </Card>
                              ))}
                            </div>
                          )}
                        </div>
                      </td>
                      <td style={{ width: 180 }} className={classes.tableCell}>
                        <Select
                          className={classes.preLoiSelect}
                          value={request.preLOI ? 'pre' : 'post'}
                          onChange={(_, value) =>
                            handleUpdatePreLOI(request.id, value === 'pre')
                          }
                        >
                          <Option value="pre">Pre-LOI</Option>
                          <Option value="post">Post-LOI</Option>
                        </Select>
                      </td>
                      <td className={classes.tableCell} style={{ width: 100 }}>
                        <div className={classes.actions}>
                          <IconButton
                            onClick={() => handleDownloadRequest(request.id)}
                            variant="outlined"
                            color="primary"
                            disabled={!request.isCompleted}
                          >
                            <Download />
                          </IconButton>
                          <IconButton
                            onClick={() => handleDeleteRequest(request.id)}
                            variant="outlined"
                            color="danger"
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Sheet>
          ))
        )}

        <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ModalDialog>
            <ModalClose />
            <Typography level="h4" mb={2}>
              Add File Request
            </Typography>
            <form
              onSubmit={handleSubmit(handleAddRequest)}
              className={classes.form}
            >
              <ControlledInput
                name="name"
                label="Name"
                control={control}
                required
              />
              <ControlledInput
                name="description"
                label="Description"
                control={control}
                required
                textArea
              />
              <FormControl>
                <FormLabel>Category</FormLabel>
                <Select
                  name="category"
                  defaultValue=""
                  onChange={(_, value) => setValue('category', value as string)}
                  required
                >
                  {Categories.map((category) => (
                    <Option key={category} value={category}>
                      {category}
                    </Option>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Pre-LOI</FormLabel>
                <Select
                  name="preLOI"
                  defaultValue={true}
                  onChange={(_, value) => setValue('preLOI', value as boolean)}
                >
                  <Option value={true}>Yes</Option>
                  <Option value={false}>No</Option>
                </Select>
              </FormControl>
              <Button type="submit" fullWidth>
                Add Request
              </Button>
            </form>
          </ModalDialog>
        </Modal>
      </div>
    </LoadingContainer>
  );
}
