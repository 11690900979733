import { IMaskInput, IMaskInputProps } from 'react-imask';
import React from 'react';

export interface PhoneMaskAdapterCustomProps {
  onChange: (event: { target: { value: string; name: string } }) => void;
  name: string;
}

export default function PhoneMaskAdapter(
  props: PhoneMaskAdapterCustomProps & IMaskInputProps<HTMLInputElement>,
  ref: React.Ref<HTMLInputElement>,
) {
  const { onChange, ...other } = props;
  return (
    // @ts-expect-error This library has bad typing
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { value, name: props.name } })}
      overwrite
    />
  );
}
