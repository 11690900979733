import React, { useEffect, useState } from 'react';

import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Textarea,
  Theme,
  Typography,
  useTheme,
} from '@mui/joy';
import {
  EnrichColType,
  V1GetListResponse,
  Type24EnumsEnrichColType,
} from 'src/generated/api';
import { tss } from 'tss-react';
import { ChevronLeft, Close, InfoOutlined } from '@mui/icons-material';
import { getAPI } from 'src/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ControlledInput } from 'src/components/ControlledInput';
import { logEvent } from 'src/analytics';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  contents: {
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  header: {
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  accordion: {
    marginTop: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  accordionSummary: {
    display: 'flex',
    flexDirection: 'column',
  },
  examplesList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  instructionsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  closeButton: {
    width: 20,
    height: 20,
    minHeight: 0,
    minWidth: 0,
    marginLeft: 'auto',
  },
  closeIcon: {
    width: 16,
    height: 16,
  },
  formLabel: {
    width: '100%',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  backButton: {
    minWidth: 0,
    paddingLeft: 0,
  },
}));

type Props = {
  enrichment: V1GetListResponse['enrichmentColumns'][number] | null;
  listId: string;
  onClose: (modified?: { id: string; type: EnrichColType }) => void;
  onBack: () => void;
};

const defaultInstruction =
  'Describe something interesting about the product and its unique ' +
  'selling proposition that caught my eye in relation to a relevant current or emerging industry ' +
  "trend. \n\nWrite the result with the following sentence structure: Company X's product Y [something " +
  'interesting about this product] caught my eye (especially given [interesting relevant industry trend])';

const defaultExample =
  "AuditPRO's solution suite, which appear both accessible out-of-the-box, " +
  'yet scalable to the needs of larger organizations, really caught my eye (especially as small' +
  'to mid-sized organizations are finally starting to migrate away from pen and paper).';

export default function PersonalizedMessage({
  enrichment,
  listId,
  onClose,
  onBack,
}: Props) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: 'Personalized Message',
      instruction: defaultInstruction,
    },
    values: {
      name: enrichment?.name ?? 'Personalized Message',
      instruction:
        enrichment?.personalizedMessage?.instruction ?? defaultInstruction,
    },
  });
  const [examples, setExamples] = useState<string[]>([]);
  const onSubmit: SubmitHandler<{
    name: string;
    instruction: string;
  }> = async (data) => {
    const api = getAPI();
    let modifiedId: string;
    if (enrichment) {
      await api.enrichments.editPersonalizedMessage(listId, enrichment.id, {
        name: data.name,
        instruction: data.instruction,
        examples,
      });
      modifiedId = enrichment.id;
      logEvent({
        name: 'enrichment_update_submit',
        type: 'click',
        extra: {
          enrichmentType: 'PERSONALIZED_MESSAGE',
        },
      });
    } else {
      const res = await api.enrichments.createPersonalizedMessage(listId, {
        name: data.name,
        instruction: data.instruction,
        examples,
      });
      modifiedId = res.data.id;
      logEvent({
        name: 'enrichment_create_submit',
        type: 'click',
        extra: {
          enrichmentType: 'PERSONALIZED_MESSAGE',
        },
      });
    }
    onClose({
      id: modifiedId,
      type: Type24EnumsEnrichColType.PERSONALIZED_MESSAGE,
    });
    reset();
    setExamples([]);
  };
  useEffect(() => {
    if (enrichment?.personalizedMessage == null) {
      setExamples([defaultExample]);
      return;
    }
    setExamples(enrichment.personalizedMessage.examples);
  }, [enrichment]);
  return (
    <div className={classes.contents}>
      <div className={classes.header}>
        <div className={classes.headerRow}>
          {!enrichment && (
            <IconButton onClick={onBack} className={classes.backButton}>
              <ChevronLeft />
            </IconButton>
          )}
          <Typography level="h4">Write a Customized Message</Typography>
        </div>
        <Typography level="body-md">
          Craft personalized copy based on the company's description, products,
          services, and end markets served
        </Typography>
      </div>
      <ControlledInput
        required
        label="Column Name"
        name="name"
        control={control}
        error={errors.name}
      />
      <br />
      <Typography level="body-md">
        <b>Instruction</b>
      </Typography>
      <Typography level="body-sm">
        Provide detailed instructions for how you want AI to structure your
        message
      </Typography>
      <FormControl error={Boolean(errors.instruction)}>
        <Controller
          name="instruction"
          control={control}
          rules={{ required: 'This is required!' }}
          render={({ field }) => <Textarea minRows={3} size="md" {...field} />}
        />
        {errors.instruction && (
          <FormHelperText>
            <InfoOutlined />
            {errors.instruction.message}
          </FormHelperText>
        )}
      </FormControl>
      <br />
      <Typography level="body-md">
        <b>Examples</b>
      </Typography>
      <Typography level="body-sm">
        To get better results, provide some examples of the copy you have in
        mind. The more examples you give, the better AI will understand your
        ask.
      </Typography>
      <div className={classes.examplesList}>
        {examples.map((example, idx) => (
          <FormControl key={idx}>
            <FormLabel className={classes.formLabel}>
              Example {idx + 1}
              <IconButton
                color="danger"
                variant="outlined"
                className={classes.closeButton}
                onClick={() => {
                  setExamples((prev) => {
                    const newVal = [...prev];
                    newVal.splice(idx, 1);
                    return newVal;
                  });
                }}
              >
                <Close className={classes.closeIcon} />
              </IconButton>
            </FormLabel>
            <Textarea
              size="md"
              minRows={3}
              value={example}
              onChange={(e) => {
                setExamples((prev) => {
                  const newVal = [...prev];
                  newVal[idx] = e.target.value;
                  return newVal;
                });
              }}
            />
          </FormControl>
        ))}
      </div>
      <Button
        variant="outlined"
        color="success"
        onClick={() => {
          setExamples((prev) => [...prev, '']);
        }}
      >
        Add Example
      </Button>
      <Button onClick={handleSubmit(onSubmit)} className={classes.submitButton}>
        {enrichment == null ? 'Create' : 'Edit'}
      </Button>
    </div>
  );
}
