import React, { useContext } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { tss } from 'tss-react';
import posthog from 'posthog-js';
import {
  extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
// eslint-disable-next-line import/no-extraneous-dependencies
import { LicenseInfo } from '@mui/x-license-pro';
import { CssVarsProvider, Theme, useTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';
import {
  Card,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from '@mui/joy';
import {
  ListAlt,
  Search,
  Settings,
  RocketLaunch,
  Person,
  LocalPhone,
} from '@mui/icons-material';
import joyTheme from './theme';
import Tracking from './components/Tracking';
import { AuthContext } from './contexts/auth';
import { ErrorView } from './components/ErrorBoundary';
import constants from './constants';
import { assetLink } from './utils';

const drawerHeight = 72;
const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100vh',
    width: '100vw',
  },
  drawer: {
    borderRadius: 0,
    position: 'fixed',
    width: '100%',
    height: drawerHeight,
    zIndex: theme.zIndex.table,
    display: 'flex',
    flexDirection: 'row',
    boxShadow: theme.shadow.sm,
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
  },
  itemIcon: {
    width: 28,
    height: 28,
  },
  unselectedColor: {
    color: theme.palette.text.secondary,
  },
  selectedColor: {
    color: theme.palette.primary.softColor,
  },
  item: {
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
  },
  content: {
    position: 'absolute',
    top: drawerHeight,
    width: '100%',
    height: `calc(100% - ${drawerHeight}px)`,
    overflowY: 'auto',
  },
  links: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 'auto',
    gap: theme.spacing(1),
  },
}));

type NavLinkProps = {
  Icon: React.FC<{ className: string }>;
  title: string;
  path: string;
};

LicenseInfo.setLicenseKey(constants.muiProLicense);

function NavLink({ Icon, title, path }: NavLinkProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { classes, cx } = useStyles({ theme });
  const location = useLocation();
  const selected = location.pathname.includes(path);
  return (
    <ListItem>
      <ListItemButton
        onClick={() => navigate(path)}
        className={classes.item}
        selected={selected}
        color={selected ? 'primary' : undefined}
      >
        <ListItemDecorator>
          <Icon
            className={cx(classes.itemIcon, {
              [classes.unselectedColor]: !selected,
              [classes.selectedColor]: selected,
            })}
          />
        </ListItemDecorator>
        <ListItemContent>
          <Typography
            level="title-lg"
            className={cx({
              [classes.unselectedColor]: !selected,
              [classes.selectedColor]: selected,
            })}
          >
            {title}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

const materialTheme = materialExtendTheme();

export function Layout() {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  posthog.setPersonPropertiesForFlags({ email: user?.email });
  const autopilotEnabled = posthog.isFeatureEnabled('autopilot');
  const brokerOSEnabled = posthog.isFeatureEnabled('broker-os');
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={joyTheme}>
        <Tracking>
          <CssBaseline />
          <div className={classes.container}>
            <Card className={classes.drawer}>
              <img
                className={classes.title}
                onClick={() => navigate('/search')}
                src={assetLink('logo.svg')}
                width={132}
                height={28}
                alt="logo"
              />
              <div className={classes.links}>
                {autopilotEnabled && (
                  <NavLink
                    Icon={RocketLaunch}
                    title="Autopilot"
                    path="/autopilot"
                  />
                )}
                <NavLink Icon={Search} title="Search" path="/search" />
                <NavLink Icon={ListAlt} title="Lists" path="/lists" />
                {brokerOSEnabled && (
                  <NavLink Icon={Person} title="Clients" path="/clients" />
                )}
                <NavLink Icon={Settings} title="Settings" path="/settings" />
                {brokerOSEnabled && (
                  <NavLink Icon={LocalPhone} title="Call Prep" path="/prep" />
                )}
              </div>
            </Card>
            <div className={classes.content}>
              <ErrorBoundary FallbackComponent={ErrorView}>
                <Outlet />
              </ErrorBoundary>
            </div>
          </div>
        </Tracking>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
}
