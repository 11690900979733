import React from 'react';
import { Theme, Typography, useTheme } from '@mui/joy';
import { tss } from 'tss-react';
import { V1GetListResponse } from 'src/generated/api';
import {
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { EnrichmentColProps } from './Header';
import EnrichmentCellWrapper from './Wrapper';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  cell: {
    wordBreak: 'break-word',
  },
  clickableCell: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
    gap: 2,
    wordBreak: 'break-word',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.neutral.plainHoverBg,
    },
  },
  enrichmentHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  colHeaderText: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

type ValueProps = {
  value: string | null;
  isUrl?: boolean;
};

function Value({ value, isUrl = false }: ValueProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  if (!value) {
    return (
      <div className={classes.cell}>
        <Typography level="body-sm" textColor="tertiary">
          <i>Not Available</i>
        </Typography>
      </div>
    );
  }
  if (isUrl) {
    return (
      <div className={classes.clickableCell} onClick={() => window.open(value)}>
        <Typography level="body-sm" textColor="text.primary">
          {value}
        </Typography>
      </div>
    );
  }
  return (
    <div className={classes.cell}>
      <Typography level="body-sm" textColor="text.primary">
        {value}
      </Typography>
    </div>
  );
}

type ColumnHeaderProps = {
  title: string;
};

function ColumnHeader({ title }: ColumnHeaderProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  return (
    <div className={classes.enrichmentHeader}>
      <Typography level="body-md" className={classes.colHeaderText}>
        <b>{title}</b>
      </Typography>
    </div>
  );
}

export function getColumns(enrichmentColProps: EnrichmentColProps) {
  return [
    {
      field: `${enrichmentColProps.col.id}-url`,
      width:
        enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-url`] ??
        250,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.linkedIn?.url;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <Value isUrl value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="LinkedIn URL" />,
    },
    {
      field: `${enrichmentColProps.col.id}-connected-employees`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-connected-employees`
        ] ?? 250,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.linkedIn?.connectedEmployees;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const val = enrichVal?.linkedIn?.connectedEmployees.toString() ?? null;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <Value value={val} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="# Profiles Connected" />,
    },
    {
      field: `${enrichmentColProps.col.id}-founded`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-founded`
        ] ?? 100,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.linkedIn?.yearFounded;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.linkedIn?.yearFounded?.toString();
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <Value value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Founded" />,
    },
    {
      field: `${enrichmentColProps.col.id}-followers`,
      width:
        enrichmentColProps.columnWidths[
          `${enrichmentColProps.col.id}-followers`
        ] ?? 150,
      sortable: true,
      disableColumnMenu: true,
      valueGetter: (
        data: GridValueGetterParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const val = data.row.enrichmentValues.find(
          (x) => x.colId === enrichmentColProps.col.id,
        );
        return val?.linkedIn?.numFollowers;
      },
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.linkedIn?.numFollowers.toString();
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <Value value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="# Followers" />,
    },
    {
      field: `${enrichmentColProps.col.id}-size`,
      width:
        enrichmentColProps.columnWidths[`${enrichmentColProps.col.id}-size`] ??
        200,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (
        data: GridRenderCellParams<V1GetListResponse['businesses'][number]>,
      ) => {
        const enrichVal = data.row.enrichmentValues.find(
          (v) => v.colId === enrichmentColProps.col.id,
        );
        const strVal = enrichVal?.linkedIn?.size;
        return (
          <EnrichmentCellWrapper
            value={enrichVal}
            enrichmentColProps={enrichmentColProps}
            businessId={data.row.id}
          >
            <Value value={strVal ?? null} />
          </EnrichmentCellWrapper>
        );
      },
      renderHeader: () => <ColumnHeader title="Size" />,
    },
  ];
}
