import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { formatDistanceToNow, format } from 'date-fns';
import { tss } from 'tss-react';
import {
  Close,
  ChevronLeft,
  Schedule,
  ThumbUp,
  BorderColor,
  ThumbDown,
  EditNote,
  LockOpen,
  Email,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Theme,
  Typography,
  useTheme,
  Modal,
  ModalDialog,
  ModalClose,
  Tooltip,
  Select,
  Option,
  Checkbox,
  Input,
} from '@mui/joy';
import { getAPI } from 'src/api';
import { ControlledInput } from 'src/components/ControlledInput';
import {
  GetBrokerListingResponse,
  ListingBuyerNDAStatus,
  ListingBuyerAdvisorReviewStatus,
  Type24EnumsListingBuyerAdvisorReviewStatus,
  ListingBuyerPostNDAStatus,
  ListingBuyerActivityType,
} from 'src/generated/api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  buyerTypeLabels,
  compactNumber,
  financingStatusLabels,
  ownerTransitionLabels,
} from 'src/utils';
import { getConfig } from 'src/config';
import LoadingContainer from 'src/components/LoadingContainer';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
    gap: theme.spacing(2),
    maxWidth: 1200,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    boxShadow: theme.shadow.sm,
    borderRadius: theme.radius.md,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1),
    gap: theme.spacing(1),
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 'auto',
  },
  requests: {
    border: 'none',
    '& .MuiDataGrid-row': {
      backgroundColor: 'transparent !important',
      cursor: 'default',
    },
    '& .MuiDataGrid-cell': {
      backgroundColor: 'transparent !important',
    },
    overflowY: 'auto',
    maxHeight: '50vh',
  },
  modal: {
    width: 700,
    overflowY: 'scroll',
  },
  ndaDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  buyerDetails: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  buyerDetailsRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
  },
  buyerDetailsItem: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 0,
  },
  headerRight: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    marginLeft: 'auto',
  },
  buyerCell: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
  cellCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cellRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
  },
  cellRowIcon: {
    width: 16,
    height: 16,
    flexShrink: 0,
  },
  stageButtons: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  cardHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}));

type ListingFormData = {
  name: string;
  description: string;
  scheduleMeetingDeadline: string;
};

type BuyerDetails = {
  buyerEmailAddress: string;
  buyerPhoneNumber: string | null;
  firstName: string;
  lastName: string;
  linkedinUrl: string | null;
  firmName: string | null;
  jobTitle: string | null;
  type: string;
  criteriaRevenueRange: string;
  criteriaEbitdaRange: string;
  criteriaDealSizeMin: number;
  criteriaDealSizeMax: number;
  criteriaIndustryFocus: string[];
  previousAcquisitions: string[];
};

const activityTypeLabels: Record<ListingBuyerActivityType, string> = {
  OPEN_LISTING: 'Opened Listing',
  DOWNLOAD_TEASER: 'Downloaded Teaser',
  DOWNLOAD_CIM: 'Downloaded CIM',
  DOWNLOAD_DATA_ROOM_FILE: 'Downloaded File',
  DOWNLOAD_ALL_DATA_ROOM_FILES: 'Downloaded All Files',
};

export const postNDAStatusLabels: Record<ListingBuyerPostNDAStatus, string> = {
  PENDING: 'Pending',
  REQUESTED_INITIAL_MEETING: 'Requested Meeting',
  INITIAL_MEETING_SCHEDULED: 'Meeting Scheduled',
  IN_DISCUSSIONS: 'In Discussions',
  LOI: 'LOI Submitted',
  NOT_INTERESTED: 'Not Interested',
};

function isBuyerNDAStatusVisible(status: ListingBuyerNDAStatus) {
  return (
    status === ListingBuyerNDAStatus.RELEASED ||
    status === ListingBuyerNDAStatus.REMOVED_BY_ADVISOR_AFTER_NDA ||
    status === ListingBuyerNDAStatus.REMOVED_BY_OWNER_AFTER_NDA
  );
}

const activeStatuses = [
  ListingBuyerNDAStatus.BUYER_SIGNED,
  ListingBuyerNDAStatus.ADVISOR_APPROVED,
  ListingBuyerNDAStatus.SELLER_SIGNED,
  ListingBuyerNDAStatus.RELEASED,
];

export default function BuyerManagement() {
  const { id } = useParams<{ id: string }>();
  const api = getAPI();
  const theme = useTheme();
  const [listing, setListing] = useState<GetBrokerListingResponse | null>(null);
  const { classes } = useStyles({ theme });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isSubmitting, errors },
  } = useForm<ListingFormData>();

  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();
  const [selectedNDA, setSelectedNDA] = useState<
    GetBrokerListingResponse['ndaRequests'][0] | null
  >(null);
  const [buyerDetails, setBuyerDetails] = useState<BuyerDetails | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBuyerDetailsLoading, setIsBuyerDetailsLoading] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<
    GetBrokerListingResponse['ndaRequests'][number] | null
  >(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedBuyers, setSelectedBuyers] = useState<string[]>([]);

  const fetchListingData = useCallback(async () => {
    if (id) {
      const res = await api.clients.getListing(id);
      setListing(res.data);
      reset({
        name: res.data.name,
        description: res.data.description,
        scheduleMeetingDeadline: res.data.scheduleMeetingDeadline,
      });
    }
  }, [id, api, reset]);

  useEffect(() => {
    fetchListingData();
  }, [fetchListingData]);

  const onSubmit = async (data: ListingFormData) => {
    if (id) {
      await api.clients.updateListing(id, data);
      reset(data);
      setIsEditing(false);
    }
  };

  const handleSeeMore = useCallback(
    async (nda: GetBrokerListingResponse['ndaRequests'][0]) => {
      setSelectedNDA(nda);
      setIsModalOpen(true);
      setIsBuyerDetailsLoading(true);
      try {
        const buyerData = await api.clients.getListingBuyer(
          nda.buyerEmailAddress,
        );
        setBuyerDetails(buyerData.data);
      } finally {
        setIsBuyerDetailsLoading(false);
      }
    },
    [api],
  );

  const handleApproveReject = useCallback(
    async (emailAddress: string, status: ListingBuyerAdvisorReviewStatus) => {
      await api.clients.updateNdaRequestStatus(id!, emailAddress, {
        reviewStatus: status,
      });
      await fetchListingData();
    },
    [api, fetchListingData, id],
  );

  const handleReleaseNDA = useCallback(
    async (emailAddress: string) => {
      await api.clients.releaseNda(id!, emailAddress);
      await fetchListingData();
    },
    [api, fetchListingData, id],
  );

  const handleUpdatePostNDAStatus = useCallback(
    async (buyerEmail: string, postNDAStatus: ListingBuyerPostNDAStatus) => {
      await api.clients.updatePostNdaStatus(id!, buyerEmail, { postNDAStatus });
      await fetchListingData();
    },
    [api, fetchListingData, id],
  );

  const handleViewActivities = useCallback(
    (buyer: GetBrokerListingResponse['ndaRequests'][number]) => {
      setSelectedActivities(buyer);
    },
    [],
  );

  const renderNDAStatus = useCallback(
    (buyerEmailAddress: string, status: ListingBuyerNDAStatus) => {
      switch (status) {
        case ListingBuyerNDAStatus.REQUESTED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <Schedule color="warning" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>NDA Requested</b>
                </Typography>
              </div>
              <Typography level="body-sm">Next: Buyer Signs NDA</Typography>
            </div>
          );
        case ListingBuyerNDAStatus.BUYER_SIGNED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <BorderColor color="success" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Buyer Signed NDA</b>
                </Typography>
              </div>
              <div className={classes.stageButtons}>
                <Button
                  size="sm"
                  variant="solid"
                  color="success"
                  onClick={() =>
                    handleApproveReject(
                      buyerEmailAddress,
                      Type24EnumsListingBuyerAdvisorReviewStatus.APPROVED,
                    )
                  }
                >
                  Approve
                </Button>
                <Button
                  size="sm"
                  variant="outlined"
                  color="danger"
                  onClick={() =>
                    handleApproveReject(
                      buyerEmailAddress,
                      Type24EnumsListingBuyerAdvisorReviewStatus.REJECTED,
                    )
                  }
                >
                  Reject
                </Button>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.ADVISOR_REJECTED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <Close color="error" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Advisor Rejected</b>
                </Typography>
              </div>
              <div className={classes.stageButtons}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="success"
                  onClick={() =>
                    handleApproveReject(
                      buyerEmailAddress,
                      Type24EnumsListingBuyerAdvisorReviewStatus.APPROVED,
                    )
                  }
                >
                  Approve
                </Button>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.ADVISOR_APPROVED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <ThumbUp color="success" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Advisor Approved</b>
                </Typography>
              </div>
              <Typography level="body-sm">Next: Owner Signs NDA</Typography>
              <div className={classes.stageButtons}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="danger"
                  onClick={() =>
                    handleApproveReject(
                      buyerEmailAddress,
                      Type24EnumsListingBuyerAdvisorReviewStatus.REJECTED,
                    )
                  }
                >
                  Reject
                </Button>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.SELLER_REJECTED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <ThumbDown color="error" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Rejected by Owner</b>
                </Typography>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.SELLER_SIGNED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <EditNote color="success" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>NDA Completed</b>
                </Typography>
              </div>
              <div className={classes.stageButtons}>
                <Tooltip title="Release NDA and Grant Data Room Access">
                  <Button
                    size="sm"
                    variant="solid"
                    color="success"
                    onClick={() => handleReleaseNDA(buyerEmailAddress)}
                  >
                    Release NDA
                  </Button>
                </Tooltip>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.RELEASED:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <LockOpen color="success" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Pre-LOI Data Room</b>
                </Typography>
              </div>
              <div className={classes.stageButtons}>
                <Tooltip title="Remove Buyer from Process">
                  <Button
                    size="sm"
                    variant="outlined"
                    color="danger"
                    onClick={() =>
                      handleApproveReject(
                        buyerEmailAddress,
                        Type24EnumsListingBuyerAdvisorReviewStatus.REJECTED,
                      )
                    }
                  >
                    Remove
                  </Button>
                </Tooltip>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.REMOVED_BY_ADVISOR_AFTER_NDA:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <Close color="error" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Removed by Advisor</b>
                </Typography>
              </div>
              <div className={classes.stageButtons}>
                <Button
                  size="sm"
                  variant="outlined"
                  color="neutral"
                  onClick={() =>
                    handleApproveReject(
                      buyerEmailAddress,
                      Type24EnumsListingBuyerAdvisorReviewStatus.APPROVED,
                    )
                  }
                >
                  Undo
                </Button>
              </div>
            </div>
          );
        case ListingBuyerNDAStatus.REMOVED_BY_OWNER_AFTER_NDA:
          return (
            <div className={classes.cellCol}>
              <div className={classes.cellRow}>
                <Close color="error" className={classes.cellRowIcon} />
                <Typography level="body-sm" textColor="text.primary">
                  <b>Removed by Owner</b>
                </Typography>
              </div>
            </div>
          );
        default:
          return null;
      }
    },
    [classes, handleApproveReject, handleReleaseNDA],
  );

  const ndaRequestColumns: GridColDef<
    GetBrokerListingResponse['ndaRequests'][number]
  >[] = useMemo(
    () => [
      {
        field: 'buyerName',
        headerName: 'Buyer',
        width: 180,
        renderCell: (params) => (
          <Tooltip title="View Buyer Details">
            <Box
              className={classes.buyerCell}
              onClick={() => handleSeeMore(params.row)}
            >
              <Typography level="body-sm" textColor="text.primary">
                <b>{params.row.buyerName}</b>
              </Typography>
              <Typography level="body-sm">
                <i>{buyerTypeLabels[params.row.buyerType]}</i>
              </Typography>
              {params.row.buyerJobTitle && (
                <Typography level="body-sm">
                  {params.row.buyerJobTitle}
                </Typography>
              )}
              {params.row.buyerFirmName && (
                <Typography level="body-sm">
                  {params.row.buyerFirmName}
                </Typography>
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'stage',
        headerName: 'Stage',
        width: 200,
        renderCell: (params) =>
          renderNDAStatus(
            params.row.buyerEmailAddress,
            params.value as ListingBuyerNDAStatus,
          ),
      },
      {
        field: 'postNDAStatus',
        headerName: 'Post-NDA Status',
        width: 200,
        sortable: true,
        sortComparator: (v1, v2, cellParams1, cellParams2) => {
          const isVisible1 = isBuyerNDAStatusVisible(
            cellParams1.api.getRow(cellParams1.id).stage,
          );
          const isVisible2 = isBuyerNDAStatusVisible(
            cellParams2.api.getRow(cellParams2.id).stage,
          );
          if (!isVisible1 && !isVisible2) return 0;
          if (!isVisible1) return 1;
          if (!isVisible2) return -1;
          return v1.localeCompare(v2);
        },
        renderCell: (params) => {
          if (!isBuyerNDAStatusVisible(params.row.stage)) {
            return null;
          }

          return (
            <Select
              value={params.row.postNDAStatus}
              onChange={(_, value) => {
                if (value) {
                  handleUpdatePostNDAStatus(
                    params.row.buyerEmailAddress,
                    value as ListingBuyerPostNDAStatus,
                  );
                }
              }}
              size="sm"
              sx={{ width: '100%' }}
            >
              {Object.entries(postNDAStatusLabels).map(([value, label]) => (
                <Option key={value} value={value}>
                  {label}
                </Option>
              ))}
            </Select>
          );
        },
      },
      {
        field: 'activities',
        headerName: 'Last Activity',
        width: 200,
        sortable: true,
        sortComparator: (v1, v2) => {
          const date1 = v1.length > 0 ? new Date(v1[0].createdAt).getTime() : 0;
          const date2 = v2.length > 0 ? new Date(v2[0].createdAt).getTime() : 0;
          return date1 - date2;
        },
        renderCell: (params) => {
          if (params.row.activities.length === 0) {
            return null;
          }

          const lastActivity = params.row.activities[0];
          return (
            <Tooltip title="View All Activities" placement="top">
              <Box
                className={classes.cellCol}
                onClick={() => handleViewActivities(params.row)}
                sx={{ cursor: 'pointer' }}
              >
                <Typography level="body-sm" textColor="text.primary">
                  <b>{activityTypeLabels[lastActivity.type]}</b>
                </Typography>
                <Typography level="body-sm">
                  {formatDistanceToNow(new Date(lastActivity.createdAt), {
                    addSuffix: true,
                  })}
                </Typography>
              </Box>
            </Tooltip>
          );
        },
      },
      {
        field: 'relevantExperience',
        headerName: 'Relevant Exp.',
        width: 250,
        renderCell: (params) =>
          params.value ? (
            <Typography level="body-sm">{params.value}</Typography>
          ) : (
            <div className={classes.cellRow}>
              <Close color="error" className={classes.cellRowIcon} />
              <Typography level="body-sm">None</Typography>
            </div>
          ),
      },
      {
        field: 'ownerPostTransactionExpectation',
        headerName: 'Owner Transition',
        width: 200,
        renderCell: (params) => (
          <Typography level="body-sm" textColor="text.primary">
            {ownerTransitionLabels[params.value as string]}
          </Typography>
        ),
      },
      {
        field: 'financingStatus',
        headerName: 'Financing',
        width: 250,
        renderCell: (params) => (
          <div className={classes.cellCol}>
            <Typography level="body-sm" textColor="text.primary">
              <b>{financingStatusLabels[params.row.financingStatus]}</b>
            </Typography>
            <Typography level="body-sm">
              {params.row.financingStatusExplanation}
            </Typography>
          </div>
        ),
      },
      {
        field: 'referringDomain',
        headerName: 'Referred By',
        width: 200,
        renderCell: (params) => (
          <div className={classes.cellCol}>
            <Typography level="body-sm" textColor="text.primary">
              {params.row.referringDomain}
            </Typography>
          </div>
        ),
      },
      {
        field: 'buyerSignDate',
        headerName: 'Buyer Signed',
        width: 120,
        renderCell: (params) =>
          params.value && (
            <Typography level="body-sm">
              {format(new Date(params.value), 'MM/dd')}
            </Typography>
          ),
      },
      {
        field: 'sellerSignDate',
        headerName: 'Seller Signed',
        width: 120,
        renderCell: (params) =>
          params.value && (
            <Typography level="body-sm">
              {format(new Date(params.value), 'MM/dd')}
            </Typography>
          ),
      },
      {
        field: 'ndaReleaseDate',
        headerName: 'NDA Released',
        width: 120,
        renderCell: (params) =>
          params.value && (
            <Typography level="body-sm">
              {format(new Date(params.value), 'MM/dd')}
            </Typography>
          ),
      },
    ],
    [
      classes,
      handleSeeMore,
      renderNDAStatus,
      handleUpdatePostNDAStatus,
      handleViewActivities,
    ],
  );

  const config = getConfig();

  const filteredBuyers = useMemo(() => {
    if (!listing) return [];
    let filtered = listing.ndaRequests;
    if (showActiveOnly) {
      filtered = filtered.filter((buyer) =>
        activeStatuses.includes(buyer.stage),
      );
    }
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (buyer) =>
          buyer.buyerName.toLowerCase().includes(query) ||
          buyer.buyerFirmName?.toLowerCase().includes(query) ||
          false,
      );
    }

    return filtered;
  }, [listing, showActiveOnly, searchQuery]);

  // Function to generate mailto links with filtered email addresses
  const generateMailtoLink = useCallback((emailAddresses: string[]) => {
    if (!emailAddresses.length) return '';
    return `mailto:?bcc=${emailAddresses.join(',')}`;
  }, []);

  return (
    <Box className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <IconButton onClick={() => navigate(`/clients/${id}`)}>
            <ChevronLeft />
          </IconButton>
          <Typography level="h2">Buyer Management</Typography>
        </div>
        <Button
          component="a"
          color="primary"
          size="lg"
          variant="outlined"
          startDecorator={<Email />}
          target="_blank"
          href={generateMailtoLink(selectedBuyers)}
          disabled={selectedBuyers.length === 0}
          sx={{ mr: 1 }}
        >
          Email Selected ({selectedBuyers.length}) Buyers
        </Button>
        <Button
          color="neutral"
          size="lg"
          variant="outlined"
          onClick={() => {
            window.open(`${config.hosts.listings}/${id}`, '_blank');
          }}
        >
          View Listing
        </Button>
        {!isEditing && (
          <Button
            size="lg"
            variant="outlined"
            onClick={() => setIsEditing(true)}
          >
            Edit Listing
          </Button>
        )}
      </div>
      <div className={classes.card}>
        {!isEditing ? (
          <>
            <Typography level="body-lg">
              <b>{control._formValues.name}</b>
            </Typography>
            <Typography level="body-md">
              {control._formValues.description}
            </Typography>
            <Typography level="body-md">
              <b>Buyer Meeting Deadline:</b>{' '}
              {control._formValues.scheduleMeetingDeadline || 'TBD'}
            </Typography>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <ControlledInput
              name="name"
              label="Name"
              control={control}
              required
              error={errors.name}
            />
            <ControlledInput
              name="description"
              label="Description"
              control={control}
              required
              error={errors.description}
              textArea
            />
            <ControlledInput
              name="scheduleMeetingDeadline"
              label="Buyer Meeting Deadline"
              control={control}
              required
              error={errors.scheduleMeetingDeadline}
            />
            <Box className={classes.buttons}>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  reset();
                }}
                disabled={isSubmitting}
                variant="outlined"
                color="neutral"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!isDirty || isSubmitting}
                loading={isSubmitting}
              >
                Save Changes
              </Button>
            </Box>
          </form>
        )}
      </div>
      <div className={classes.card}>
        <div className={classes.cardHeader}>
          <Typography level="h3">Buyers</Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
            <Input
              size="md"
              placeholder="Search buyers..."
              startDecorator={<SearchRoundedIcon />}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ width: 200 }}
            />
            <Checkbox
              checked={showActiveOnly}
              onChange={(event) => setShowActiveOnly(event.target.checked)}
              label="Show Active Only"
            />
          </Box>
        </div>
        {listing && (
          <DataGrid
            className={classes.requests}
            rows={filteredBuyers}
            columns={ndaRequestColumns}
            getRowId={(row) => row.buyerEmailAddress}
            rowSelectionModel={selectedBuyers}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectedBuyers(newSelectionModel as string[]);
            }}
            getRowHeight={() => 'auto'}
            disableRowSelectionOnClick
            checkboxSelection
            keepNonExistentRowsSelected
          />
        )}
      </div>
      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <ModalDialog className={classes.modal}>
          <ModalClose />
          <Typography level="h3">Buyer Details</Typography>
          <LoadingContainer isLoading={isBuyerDetailsLoading}>
            {selectedNDA && buyerDetails && (
              <div className={classes.buyerDetails}>
                <div className={classes.buyerDetailsRow}>
                  <div className={classes.buyerDetailsItem}>
                    <Typography level="body-md">
                      <b>
                        {buyerDetails.firstName} {buyerDetails.lastName}
                      </b>
                    </Typography>
                    <Typography level="body-md">
                      <a href={`mailto:${buyerDetails.buyerEmailAddress}`}>
                        {buyerDetails.buyerEmailAddress}
                      </a>
                    </Typography>
                    {buyerDetails.buyerPhoneNumber && (
                      <Typography level="body-md">
                        {buyerDetails.buyerPhoneNumber}
                      </Typography>
                    )}
                    {buyerDetails.firmName && (
                      <>
                        <Typography level="body-md">
                          {buyerDetails.jobTitle}
                        </Typography>
                        <Typography level="body-md">
                          {buyerDetails.firmName}
                        </Typography>
                      </>
                    )}
                    {buyerDetails.linkedinUrl && (
                      <Typography level="body-md">
                        <a
                          href={buyerDetails.linkedinUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          LinkedIn
                        </a>
                      </Typography>
                    )}
                  </div>
                  <div className={classes.buyerDetailsItem}>
                    <Typography level="body-md">
                      <b>Buyer Type</b>
                    </Typography>
                    <Typography level="body-md">{buyerDetails.type}</Typography>
                  </div>
                </div>
                <div className={classes.buyerDetailsRow}>
                  <div className={classes.buyerDetailsItem}>
                    <Typography level="body-md">
                      <b>Acquisition Criteria</b>
                    </Typography>
                    <Typography level="body-md">
                      Revenue: {buyerDetails.criteriaRevenueRange}
                    </Typography>
                    <Typography level="body-md">
                      EBITDA: {buyerDetails.criteriaEbitdaRange}
                    </Typography>
                    <Typography level="body-md">
                      Deal Size: $
                      {compactNumber(buyerDetails.criteriaDealSizeMin)} to $
                      {compactNumber(buyerDetails.criteriaDealSizeMax)}
                    </Typography>
                  </div>
                  <div className={classes.buyerDetailsItem}>
                    <Typography level="body-md">
                      <b>Industry Focus</b>
                    </Typography>
                    {buyerDetails.criteriaIndustryFocus.length === 0 && (
                      <Typography level="body-md">None</Typography>
                    )}
                    {buyerDetails.criteriaIndustryFocus.map((industry) => (
                      <Typography level="body-md">{industry}</Typography>
                    ))}
                  </div>
                </div>
                <div>
                  <Typography level="body-md">
                    <strong>Previous Acquisitions</strong>
                  </Typography>
                  {buyerDetails.previousAcquisitions.length === 0 && (
                    <Typography level="body-md">None</Typography>
                  )}
                  {buyerDetails.previousAcquisitions.map((acquisition) => (
                    <Typography level="body-md">{acquisition}</Typography>
                  ))}
                </div>
              </div>
            )}
          </LoadingContainer>
        </ModalDialog>
      </Modal>
      <Modal
        open={!!selectedActivities}
        onClose={() => setSelectedActivities(null)}
      >
        <ModalDialog className={classes.modal}>
          <ModalClose />
          <Typography level="h3">
            Buyer Activities - {selectedActivities?.buyerName}
          </Typography>
          {selectedActivities && (
            <Box>
              {selectedActivities.activities.map((activity, index) => (
                <Box key={index} sx={{ mb: 1 }}>
                  <Typography level="body-md">
                    <b>{activityTypeLabels[activity.type]}</b>
                    {activity.type === 'DOWNLOAD_DATA_ROOM_FILE' &&
                      activity.extraData && (
                        <>: {activity.extraData.fileName}</>
                      )}
                  </Typography>
                  <Typography level="body-sm">
                    {format(new Date(activity.createdAt), 'PPpp')}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </ModalDialog>
      </Modal>
    </Box>
  );
}
