import { Card, Chip, IconButton, Theme, Typography, useTheme } from '@mui/joy';
import { useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { getAPI } from 'src/api';
import { GetBusinessDetailedResponse } from 'src/generated/api';
import { tss } from 'tss-react';
import LoadingContainer from 'src/components/LoadingContainer';
import {
  Facebook,
  Google,
  Instagram,
  Language,
  Place,
  Twitter,
  YouTube,
} from '@mui/icons-material';
import { assetLink, capitalizeWords, compactNumber } from 'src/utils';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    padding: theme.spacing(6),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  contents: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1000,
  },
  location: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  card: {
    flex: 1,
    marginTop: theme.spacing(3),
  },
  detailsFooter: {
    display: 'flex',
    flexDirection: 'row',
  },
  keyInfoItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0,
    padding: theme.spacing(2),
  },
  keyInfoChips: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  keyInfoChip: {
    height: 24,
  },
  links: {
    marginLeft: 'auto',
  },
  linkIcon: {
    width: 24,
    height: 24,
  },
  keyInfo: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    alignItems: 'flex-start',
  },
  ratingsStats: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
  },
  reviewHeader: {
    display: 'flex',
    flexDirection: 'row',
  },
  reviewContent: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2),
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}));

export default function Business() {
  const theme = useTheme();
  const { id } = useParams();
  const { classes } = useStyles({ theme });
  const [data, setData] = useState<GetBusinessDetailedResponse | null>(null);
  const fetchBusiness = useCallback(async () => {
    const api = getAPI();
    const res = await api.businesses.getBusinessDetailed(id!);
    setData(res.data);
  }, [id]);
  useEffect(() => {
    fetchBusiness();
  }, [fetchBusiness]);
  if (data == null) {
    return (
      <LoadingContainer isLoading>
        <></>
      </LoadingContainer>
    );
  }
  return (
    <div className={classes.container}>
      <LoadingContainer isLoading={data == null}>
        <div className={classes.contents}>
          <Typography level="h2">{data?.name}</Typography>
          <div className={classes.keyInfo}>
            {data!.revenue && (
              <Card
                variant="soft"
                color="primary"
                className={classes.keyInfoItem}
              >
                <Typography level="h3">
                  ${compactNumber(data!.revenue)}
                </Typography>
                <Typography level="body-sm">Revenue</Typography>
              </Card>
            )}
            <Card
              variant="soft"
              color="primary"
              className={classes.keyInfoItem}
            >
              <Typography level="h3">{data!.numEmployees}</Typography>
              <Typography level="body-sm">Employees</Typography>
            </Card>
            <div className={classes.keyInfoChips}>
              {data?.scrapeData.sellsToConsumers && (
                <Chip color="success" className={classes.keyInfoChip}>
                  Sells to Consumers
                </Chip>
              )}
              {data?.scrapeData.sellsToGovernment && (
                <Chip color="success" className={classes.keyInfoChip}>
                  Sells to Government
                </Chip>
              )}
              {data?.scrapeData.sellsToBusinesses && (
                <Chip color="success" className={classes.keyInfoChip}>
                  Sells to Businesses
                </Chip>
              )}
              {data.scrapeData.serviceAreaStates.map((state) => (
                <Chip color="neutral" className={classes.keyInfoChip}>
                  {state}
                </Chip>
              ))}
            </div>
          </div>
          <Card variant="outlined" className={classes.card}>
            <Typography level="body-md">
              {data?.scrapeData.description}
            </Typography>
            <div className={classes.detailsFooter}>
              <Typography level="body-md" className={classes.location}>
                <Place /> {data?.city}, {data?.state}
              </Typography>
              <div className={classes.links}>
                {data?.website && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.website);
                    }}
                  >
                    <Language className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.gmbUrl && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.gmbUrl!);
                    }}
                  >
                    <Google className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.scrapeData.facebookLink && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.scrapeData.facebookLink!);
                    }}
                  >
                    <Facebook className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.scrapeData.twitterLink && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.scrapeData.twitterLink!);
                    }}
                  >
                    <Twitter className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.scrapeData.youtubeLink && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.scrapeData.youtubeLink!);
                    }}
                  >
                    <YouTube className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.scrapeData.instagramLink && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.scrapeData.instagramLink!);
                    }}
                  >
                    <Instagram className={classes.linkIcon} />
                  </IconButton>
                )}
                {data?.scrapeData.yelpLink && (
                  <IconButton
                    variant="plain"
                    onClick={() => {
                      window.open(data!.scrapeData.yelpLink!);
                    }}
                  >
                    <img
                      className={classes.linkIcon}
                      src={assetLink('yelp.png')}
                    />
                  </IconButton>
                )}
              </div>
            </div>
          </Card>
          <div className={classes.cardRow}>
            <Card variant="outlined" className={classes.card}>
              <Typography level="h4">Products & Services</Typography>
              {data.scrapeData.productsAndServices.map((ps) => (
                <div>
                  <Typography level="body-md">
                    <b>{capitalizeWords(ps.keyPhrase)}</b>
                  </Typography>
                  <Typography
                    level="body-sm"
                    textColor={theme.palette.text.primary}
                  >
                    {ps.description}
                  </Typography>
                </div>
              ))}
            </Card>
            <Card variant="outlined" className={classes.card}>
              <Typography level="h4">Customer Segments</Typography>
              {data.scrapeData.customerSegments.map((cs) => (
                <div>
                  <Typography level="body-md">
                    <b>{capitalizeWords(cs.keyPhrase)}</b>
                  </Typography>
                  <Typography level="body-sm">{cs.description}</Typography>
                </div>
              ))}
            </Card>
          </div>
        </div>
      </LoadingContainer>
    </div>
  );
}
