import { useEffect, useRef } from 'react';
import {
  ListingBuyerNDARequestFinancingStatus,
  Type24EnumsListingBuyerNDARequestFinancingStatus,
  Type24EnumsListingBuyerType,
} from './generated/api';

export function compactNumber(num: number, numDecimals = 0) {
  return Intl.NumberFormat('en', {
    notation: 'compact',
    maximumFractionDigits: numDecimals,
  }).format(num);
}

export function capitalizeWords(str: string) {
  return str
    .split(' ')
    .map((w) => w[0].toUpperCase() + w.substring(1))
    .join(' ');
}

export function assetLink(str: string) {
  return `https://assets.offdeal.io/${str}`;
}

export function uploadedLink(fileId: string) {
  if (fileId.startsWith('https://assets.offdeal.io/')) {
    return fileId;
  }
  return `https://uploads.offdeal.io/${fileId}`;
}

const states = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['Armed Forces Americas', 'AA'],
  ['Armed Forces Europe', 'AE'],
  ['Armed Forces Pacific', 'AP'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'NP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['US Virgin Islands', 'VI'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
];

export function stateAbbrToName(abbr: string) {
  return states.find((s) => s[1] === abbr)![0];
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function formatFileSize(bytes: number): string {
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  let size = bytes;
  let unitIndex = 0;

  while (size >= 1024 && unitIndex < units.length - 1) {
    size /= 1024;
    unitIndex += 1;
  }

  return `${size.toFixed(2)} ${units[unitIndex]}`;
}

export function formatDate(date: string): string {
  return new Date(date).toLocaleDateString();
}

export const ownerTransitionLabels: Record<string, string> = {
  no_involvement: 'No Involvement',
  short_term_transition: '0 - 6 months',
  medium_term_transition: '6 - 12 months',
  long_term_transition: '12+ months',
  open_to_discussion: 'Open to Discussion',
};

export const financingStatusLabels: Record<
  ListingBuyerNDARequestFinancingStatus,
  string
> = {
  [Type24EnumsListingBuyerNDARequestFinancingStatus.YES]: 'Secured',
  [Type24EnumsListingBuyerNDARequestFinancingStatus.IN_PROGRESS]: 'In Progress',
  [Type24EnumsListingBuyerNDARequestFinancingStatus.NO]: 'No Financing',
};

export const buyerTypeLabels: Record<Type24EnumsListingBuyerType, string> = {
  [Type24EnumsListingBuyerType.INDIVIDUAL]: 'Individual',
  [Type24EnumsListingBuyerType.STRATEGIC]: 'Strategic',
  [Type24EnumsListingBuyerType.PRIVATE_EQUITY]: 'Private Equity',
  [Type24EnumsListingBuyerType.SEARCH_FUND]: 'Search Fund',
};
