import React, { useCallback, useContext, useRef, useState } from 'react';
import { PlayArrow, Edit, Delete, MoreVert } from '@mui/icons-material';
import {
  Typography,
  Theme,
  useTheme,
  Menu,
  MenuItem,
  IconButton,
} from '@mui/joy';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getAPI } from 'src/api';
import { tss } from 'tss-react';
import { V1GetListResponse } from 'src/generated/api';
import { SnackbarContext } from 'src/contexts/snackbar';
import { logEvent } from 'src/analytics';

export type EnrichmentColProps = {
  listId: string;
  selectedRows: string[];
  col: V1GetListResponse['enrichmentColumns'][number];
  showEnrichmentModal: () => void;
  refetch: () => void;
  getFirstTenRowIds: () => string[];
  columnWidths: Record<string, number>;
  titleOverride?: string;
  onInfoClick?: (data: {
    prompt: string;
    value: string;
    explanation: string | null;
    steps: {
      str: string;
      imgUrl: string | null;
    }[];
  }) => void;
  resetSelectedRows: () => void;
};

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  enrichmentHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    alignItems: 'flex-end',
  },
  enrichmentButton: {
    width: 24,
    height: 24,
    minHeight: 0,
    minWidth: 0,
    padding: 0,
  },
  enrichActions: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  enrichmentIcon: {
    width: 20,
    height: 20,
  },
  colHeaderText: {
    textWrap: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

export default function EnrichmentHeader({
  selectedRows,
  listId,
  col,
  showEnrichmentModal,
  refetch,
  getFirstTenRowIds,
  resetSelectedRows,
  titleOverride,
}: EnrichmentColProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });
  const api = getAPI();
  const enrichmentEl = useRef<HTMLButtonElement>(null);
  const [showEnrichmentMenu, setShowEnrichmentMenu] = useState(false);
  const moreEl = useRef<HTMLButtonElement>(null);
  const [showMoreMenu, setShowMoreMenu] = useState(false);
  const { showSnackbar } = useContext(SnackbarContext);
  const runEnrichment = useCallback(
    async (onlyEmptyError: boolean) => {
      showSnackbar({
        color: 'success',
        message: `Started enrichment for "${col.name}"`,
      });
      logEvent({
        name: 'run_enrichment_col_click',
        type: 'click',
        extra: {
          colType: col.type,
          onlyEmptyAndError: JSON.stringify(onlyEmptyError),
        },
      });
      await api.lists.runEnrichmentColumn(listId, col.id, {
        onlyEmptyAndErrors: onlyEmptyError,
      });
      setTimeout(() => {
        refetch();
      }, 1000);
    },
    [listId, api, refetch, showSnackbar, col],
  );
  const runEnrichmentFirstTenRows = useCallback(async () => {
    showSnackbar({
      color: 'success',
      message: `Started enrichments for "${col.name}"`,
    });
    await api.lists.runSpecificEnrichments(listId, {
      colIds: [col.id],
      businessIds: getFirstTenRowIds(),
    });
    setTimeout(() => {
      refetch();
    }, 1000);
  }, [listId, api, refetch, showSnackbar, col, getFirstTenRowIds]);
  const runEnrichmentForSelectedRows = useCallback(async () => {
    showSnackbar({
      color: 'success',
      message: `Started enriching "${col.name}" for ${selectedRows.length} rows`,
    });
    await api.lists.runSpecificEnrichments(listId, {
      colIds: [col.id],
      businessIds: selectedRows,
    });
    resetSelectedRows();
    setTimeout(() => {
      refetch();
    }, 1000);
  }, [
    listId,
    api,
    refetch,
    showSnackbar,
    col,
    selectedRows,
    resetSelectedRows,
  ]);
  return (
    <div className={classes.enrichmentHeader}>
      <Typography level="body-md" className={classes.colHeaderText}>
        <b>{titleOverride ?? col.name}</b>
      </Typography>
      <div className={classes.enrichActions}>
        <IconButton
          color="success"
          variant="outlined"
          className={classes.enrichmentButton}
          ref={enrichmentEl}
          onClick={(e) => {
            setShowEnrichmentMenu(!showEnrichmentMenu);
            e.stopPropagation();
          }}
        >
          <PlayArrow className={classes.enrichmentIcon} />
        </IconButton>
        <ClickAwayListener onClickAway={() => setShowEnrichmentMenu(false)}>
          <Menu
            placement="bottom-end"
            open={showEnrichmentMenu}
            anchorEl={enrichmentEl.current}
            onClose={() => setShowEnrichmentMenu(false)}
          >
            <MenuItem
              onClick={() => {
                setShowEnrichmentMenu(false);
                runEnrichmentFirstTenRows();
              }}
            >
              Enrich first 10 rows
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowEnrichmentMenu(false);
                runEnrichmentForSelectedRows();
              }}
            >
              Enrich selected rows
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowEnrichmentMenu(false);
                runEnrichment(true);
              }}
            >
              Enrich empty & error rows
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowEnrichmentMenu(false);
                runEnrichment(false);
              }}
            >
              Enrich all rows
            </MenuItem>
          </Menu>
        </ClickAwayListener>
        <IconButton
          color="primary"
          className={classes.enrichmentButton}
          onClick={(e) => {
            setShowMoreMenu(!showMoreMenu);
            e.stopPropagation();
          }}
          ref={moreEl}
          variant="outlined"
        >
          <MoreVert className={classes.enrichmentIcon} />
        </IconButton>
        <ClickAwayListener onClickAway={() => setShowMoreMenu(false)}>
          <Menu
            placement="bottom-end"
            open={showMoreMenu}
            onClose={() => setShowMoreMenu(false)}
            anchorEl={moreEl.current}
          >
            <MenuItem
              onClick={() => {
                setShowMoreMenu(false);
                showEnrichmentModal();
              }}
            >
              <Edit />
              Edit
            </MenuItem>
            <MenuItem
              color="danger"
              onClick={async () => {
                await api.lists.deleteEnrichment(col.id);
                await refetch();
                setShowMoreMenu(false);
              }}
            >
              <Delete />
              Delete
            </MenuItem>
          </Menu>
        </ClickAwayListener>
      </div>
    </div>
  );
}
