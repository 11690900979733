/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
  Button,
  Theme,
  Typography,
  useTheme,
  Card,
  Table,
  IconButton,
  Switch,
} from '@mui/joy';
import { tss } from 'tss-react';
import { useFieldArray, Control } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';

import { ControlledInput } from 'src/components/ControlledInput';
import { CIMData, ShowSections } from './types';

const useStyles = tss.withParams<{ theme: Theme }>().create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    width: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    gap: theme.spacing(1),
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    paddingBottom: theme.spacing(2),
    position: 'sticky',
    top: 0,
    backgroundColor: theme.palette.background.body,
    zIndex: 1,
  },
  title: {},
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  textArea: {
    width: '100%',
    marginTop: theme.spacing(2),
  },
  card: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  tableCell: {
    padding: theme.spacing(1),
    verticalAlign: 'bottom',
    fontSize: 18,
  },
  revenueMixTable: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    tableLayout: 'fixed',
  },
  revenueMixNameCell: {
    width: 'auto',
  },
  revenueMixPercentageCell: {
    width: 180,
  },
  revenueMixActionCell: {
    width: 96,
  },
  customersByRevenueItems: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
  },
  customerByRevenueText: {
    width: 128,
  },
  customersByRevenueItem: {
    flexBasis: '45%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  customerByRevenueInput: {
    width: 128,
  },
  addLocationButton: {
    marginTop: theme.spacing(2),
  },
  sectionHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}));

export type RevenueBreakdownProps = {
  control: Control<CIMData>;
  projectLocked: boolean;
  showSections: ShowSections;
  setShowSections: React.Dispatch<React.SetStateAction<ShowSections>>;
};

export default function RevenueBreakdown({
  control,
  projectLocked,
  showSections,
  setShowSections,
}: RevenueBreakdownProps) {
  const theme = useTheme();
  const { classes } = useStyles({ theme });

  const {
    fields: revenueMixFields,
    append: appendRevenueMix,
    remove: removeRevenueMix,
  } = useFieldArray({
    control,
    name: 'revenueMix',
  });

  const {
    fields: geoFields,
    append: appendGeo,
    remove: removeGeo,
  } = useFieldArray({
    control,
    name: 'revenueGeoDistribution',
  });

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.header}>
          <Typography level="h3" className={classes.title}>
            Revenue Breakdown
          </Typography>
        </div>
        <Card className={classes.card}>
          <div className={classes.sectionHeader}>
            <Typography level="h4">Revenue by Product</Typography>
            <Switch
              checked={showSections.revenueMix}
              startDecorator={showSections.revenueMix ? 'Show' : 'Hide'}
              onChange={(event) =>
                setShowSections({
                  ...showSections,
                  revenueMix: event.target.checked,
                })
              }
            />
          </div>
          {showSections.revenueMix && (
            <>
              <Table className={classes.revenueMixTable}>
                <thead>
                  <tr>
                    <th className={classes.revenueMixNameCell}>Name</th>
                    <th className={classes.revenueMixPercentageCell}>
                      % of Total Revenue
                    </th>
                    <th className={classes.revenueMixActionCell}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {revenueMixFields.map((field, index) => (
                    <tr key={field.id}>
                      <td
                        className={`${classes.tableCell} ${classes.revenueMixNameCell}`}
                      >
                        <ControlledInput
                          name={`revenueMix.${index}.name`}
                          control={control}
                          inputProps={{
                            disabled: projectLocked,
                          }}
                          required
                          fullWidth
                        />
                      </td>
                      <td
                        className={`${classes.tableCell} ${classes.revenueMixPercentageCell}`}
                      >
                        <ControlledInput
                          name={`revenueMix.${index}.percentage`}
                          control={control}
                          inputProps={{
                            type: 'number',
                            endDecorator: '%',
                            disabled: projectLocked,
                          }}
                          required
                        />
                      </td>
                      <td
                        className={`${classes.tableCell} ${classes.revenueMixActionCell}`}
                      >
                        <IconButton
                          onClick={() => removeRevenueMix(index)}
                          color="danger"
                          variant="outlined"
                          size="lg"
                          disabled={projectLocked}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                startDecorator={<AddIcon />}
                color="success"
                variant="outlined"
                disabled={projectLocked}
                onClick={() =>
                  appendRevenueMix({
                    name: '',
                    percentage: 0,
                  })
                }
              >
                Add Revenue Mix
              </Button>
            </>
          )}
        </Card>
        <Card variant="outlined" className={classes.card}>
          <div className={classes.sectionHeader}>
            <Typography level="h4">% of Revenue by Geography</Typography>
            <Switch
              checked={showSections.revenueGeo}
              startDecorator={showSections.revenueGeo ? 'Show' : 'Hide'}
              onChange={(event) =>
                setShowSections({
                  ...showSections,
                  revenueGeo: event.target.checked,
                })
              }
            />
          </div>
          {showSections.revenueGeo && (
            <>
              <Table>
                <thead>
                  <tr>
                    <th>Location</th>
                    <th>Percentage</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {geoFields.map((field, index) => (
                    <tr key={field.id}>
                      <td>
                        <ControlledInput
                          name={`revenueGeoDistribution.${index}.name`}
                          control={control}
                          inputProps={{
                            placeholder: 'Midwest',
                            disabled: projectLocked,
                          }}
                          required
                        />
                      </td>
                      <td>
                        <ControlledInput
                          name={`revenueGeoDistribution.${index}.percentage`}
                          control={control}
                          inputProps={{
                            type: 'number',
                            endDecorator: '%',
                            disabled: projectLocked,
                          }}
                          required
                        />
                      </td>
                      <td>
                        <IconButton
                          onClick={() => removeGeo(index)}
                          size="lg"
                          color="danger"
                          variant="outlined"
                          disabled={projectLocked}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                color="success"
                variant="outlined"
                startDecorator={<AddIcon />}
                onClick={() => appendGeo({ name: '', percentage: '0' })}
                className={classes.addLocationButton}
                disabled={projectLocked}
              >
                Add Geography
              </Button>
            </>
          )}
        </Card>
        <Card variant="outlined" className={classes.card}>
          <div className={classes.sectionHeader}>
            <Typography level="h4">Top 10 Customers by Revenue (%)</Typography>
            <Switch
              checked={showSections.customerConcentration}
              startDecorator={
                showSections.customerConcentration ? 'Show' : 'Hide'
              }
              onChange={(event) =>
                setShowSections({
                  ...showSections,
                  customerConcentration: event.target.checked,
                })
              }
            />
          </div>
          {showSections.customerConcentration && (
            <div className={classes.customersByRevenueItems}>
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
                <div className={classes.customersByRevenueItem} key={index}>
                  <Typography
                    level="body-lg"
                    className={classes.customerByRevenueText}
                  >
                    Customer {index + 1}
                  </Typography>
                  <ControlledInput
                    key={index}
                    name={`topCustomersByRevenue.${index}`}
                    control={control}
                    inputProps={{
                      type: 'number',
                      endDecorator: '%',
                      disabled: projectLocked,
                    }}
                    className={classes.customerByRevenueInput}
                    required
                  />
                </div>
              ))}
            </div>
          )}
        </Card>
      </div>
    </div>
  );
}
